import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();

export function getProductList(callback, objectArray = {}, limit = 6) {
    const cateSlug = objectArray.cateSlug ? objectArray.cateSlug : [];
    const brandSlug = objectArray.brandSlug ? objectArray.brandSlug : [];
    const price = objectArray.price ? objectArray.price : [];
    const name = objectArray.name ? objectArray.name : '';
    const best = objectArray.best ? objectArray.best : '';
    const sort = objectArray.sortOrder ? objectArray.sortOrder : 'popular';

    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/products',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        params: {
            limit: limit,
            cate: cateSlug,
            brand: brandSlug,
            price: price,
            name: name,
            sort: sort,
            best: best
        },
    }

    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch(error => {
        console.log(error);
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })
}

export function getProductDetail(callback, sku) {
    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/products/' + sku,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        }
    }

    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch(error => {
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })
}

export function getFavouriteProductList(callback, objectArray = {}, limit = 6) {
    const cateSlug = objectArray.cateSlug ? objectArray.cateSlug : [];
    const brandSlug = objectArray.brandSlug ? objectArray.brandSlug : [];
    const price = objectArray.price ? objectArray.price : [];
    const sort = objectArray.sortOrder ? objectArray.sortOrder : 'popular';
    const best = objectArray.best ? objectArray.best : '';

    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/products',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        params: {
            limit: limit,
            cate: cateSlug,
            brand: brandSlug,
            price: price,
            sort: sort,
            favourite: true,
            best: best
        },
    }

    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch(error => {
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })

    return result;
}

export function setFavouriteProduct(callback, sku) {
    const options = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/favourite/' + sku,
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        }
    }

    axios(options).then(res => {
        callback();
    }).catch(error => {
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })
}

export function getBestProductList(callback, limit = 6) {
    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/products',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        params: {
            limit: limit,
            best: true,
            sort: 'desc'
        },
    }

    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch(error => {
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })

    return result;
}

export function getRecentProductList(callback, limit = 6) {
    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/products',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        params: {
            limit: limit,
            recent: true,
            sort: 'desc'
        },
    }

    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch(error => {
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })

    return result;
}

export function getSearchList(callback) {
    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/product_list',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        params: {
            'search': true,
            'active': 1,
            'for_sale': 1
        }
    }

    axios(options).then(res => {
        callback(res.data.message, res.data.total);
    }).catch(error => {
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })
}

export function getBrandList(callback) {
    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/brands',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        }
    }

    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch(error => {
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })

    return result;
}

export function getBrandMenu(callback) {
    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/brandmenus',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        }
    }

    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch(error => {
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })

    return result;
}

export function getCategoryList(callback) {
    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/categories',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        }
    }

    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch(error => {
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })

    return result;
}

export function getPromoImage(callback) {
    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/get_home_promo',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        }
    }
    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch(error => {
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })

    return options;
}