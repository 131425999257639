import React, { Component } from "react";
import { Grid, Header, Image, Button, Label } from 'semantic-ui-react';
import OrderProgressBar from '../parts/OrderProgressBar';
import { getOrderList } from '../utils/OrderAPI';

export default class OrderSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: []
        }
    }

    componentDidMount() {
        // get the 1st current order in the list
        getOrderList((result) => {
            this.setState({
                orders: result
            })
        }, true, 1)
    }

    render() {
        let orders = this.state.orders;
        if (orders.length === 0) {
            return (
                <div>
                    <Header size='small' className='marginBottom'>
                        You currently have no processing orders.
                    </Header>
                </div>
            )
        } else {
            let order = orders[0];

            return (
                <div>
                    <Header size='medium' className='marginBottom lightGray normal'>Order Number</Header>
                    <p className='large marginBottom'><strong className='large'>#{order.order_no}</strong></p>
                    {/*<p className='regular'>Status: <strong className='regular'>{order.order_text_status}</strong></p>*/}

                    <OrderProgressBar orderStatus={order.status} />

                    <Grid className='orderItems'>
                        <Grid.Row columns={4}>
                            {
                                order.products.map((item, k) => {
                                    return (
                                        <Grid.Column key={k} className='marginBottom20'>
                                            <div className='orderItem'>
                                                <Image src={item.image_link} fluid />
                                                {(() => {
                                                    if (item.quantity > 1) {
                                                        return (
                                                            <Label size='mini' color='orange' circular className='absolute bottom right8'>
                                                                {item.quantity}
                                                            </Label>
                                                        )
                                                    }
                                                })()}

                                            </div>
                                        </Grid.Column>
                                    )
                                })
                            }
                        </Grid.Row>
                    </Grid>

                    <a target='_blank' className={order.tracking_url !== null && order.tracking_url !== '' ? '' : 'hide'} rel="noopener noreferrer" href={order.tracking_url}><Button basic fluid className='borderPrimary borderRadius10 primaryColor small'><strong>TRACKING</strong></Button></a>


                </div>
            )
        }
    }
}