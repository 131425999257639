import React, { Component } from "react";
import { Grid, Header, Image} from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export default class BlankRegisterComplete extends Component {
    render() {
        
        return (
            <Grid.Column verticalAlign='middle' style={{ textAlign: 'center' }} width={16} className='center'>
                <Image src='/img/ClubConnect_Landscape.svg' size='tiny' centered />
                <Header as='h3' color='black'>
                    Successfully added in new club
                </Header>

                <Grid>
                    <Grid.Row>
                        <Grid.Column style={{ textAlign: 'center' }} width={16} className='maxWidth230 center'>
                            <Image src='/img/positive-vote.svg' className='center' />
                        </Grid.Column>
                        <Grid.Column width={16} className='marginTop30'>
                            <Link to='/add-users'><p className='small'>Add More</p></Link>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Column>
        );
    }
}
