import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Grid, Divider, Image, Button } from 'semantic-ui-react';
import { connect } from "react-redux";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {

};

class MyFooter extends Component {
    render() {
        if (this.props.root.hideHeader === true ) {
            return null
        } else {
            return (
                <>
                <Button color='orange' fluid className='noBorderRadius height51px' onClick={() => {window.scrollTo({top: 0, behavior: 'smooth'});}}>BACK TO TOP</Button>
                <Grid padded columns='equal' className='bgPrimary white padding40 bgLogo relative overflowXHidden zIndex1 myFooter'>
                    <Grid.Row stretched style={{ paddingBottom: 0 }} only='tablet mobile'>
                        <Grid.Column textAlign='center'>
                            <div className='logo'>
                                <Image inline style={{ width: '100px' }} src='/img/ClubConnect_Landscape.svg' href='/' />
                            </div>

                            <Divider clearing inverted />

                            <div className='aboutUs'>
                                <div className='regular bold'>About Us</div>
                                <div className='small paddingTop10'>
                                    <p>
                                        <Link to="/our-story" className='white'>Our Story</Link>
                                    </p>
                                    <p>
                                        <a href='https://www.asahi.com.au/' rel="noopener noreferrer"  target='_blank' className='white'>Corporate Site</a>
                                    </p>
                                    <p>
                                        <Link to="/liquor-licences" className='white'>Liquor Licences</Link>
                                    </p>
                                    <p>
                                        <Link to="/faqs" className='white'>FAQs</Link> 
                                    </p>
                                </div>
                            </div>

                            <div className='letUsHelpYou marginTop40'>
                                <div className='regular bold white'>Policies</div>
                                <div className='small paddingTop10'>
                                    <p>
                                        <Link to="/privacy-policy" className='white'>Privacy Policy</Link>
                                    </p>
                                    <p>
                                        <Link to="/terms-conditions" className='white'>Terms & Conditions</Link>
                                    </p>
                                    <p>
                                        <Link to='/refund-policy' className='white'>Refunds & Returns Policy</Link>
                                    </p>
                                    <p className='link'>
                                        <Link to='/change-of-mind' className='white'>Change of Mind Policy</Link>
                                    </p>
                                </div>
                            </div>

                            <Divider clearing inverted />

                            <div className='copyright small'>
                                <div>© 2021 Club Connect</div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row stretched style={{ paddingBottom: 0 }} only='computer' columns={5}>
                        <Grid.Column width={3}>
                            <div className='logo'>
                                <Image inline style={{ width: '100px' }} src='/img/ClubConnect_Landscape.svg' href='/' />
                            </div>
                        </Grid.Column>

                        <Grid.Column width={3}>
                            <div className='aboutUs'>
                                <div className='regular bold'>About Us</div>
                                <div className='small paddingTop10'>
                                    <p>
                                        <Link to="/our-story" className='white'>Our Story</Link>
                                    </p>
                                    <p>
                                        <a href='https://www.asahi.com.au/' rel="noopener noreferrer" target='_blank' className='white'>Corporate Site</a>
                                    </p>
                                    <p>
                                        <Link to="/liquor-licences" className='white'>Liquor Licences</Link>
                                    </p>
                                    <p>
                                        <Link to="/faqs" className='white'>FAQs</Link>
                                    </p>
                                </div>
                            </div>
                        </Grid.Column>

                        <Grid.Column width={3}>
                            <div className='letUsHelpYou'>
                                <div className='regular bold white'>Policies</div>
                                <div className='small paddingTop10'>
                                    <p>
                                        <Link to="/privacy-policy" className='white'>Privacy Policy</Link>
                                    </p>
                                    <p>
                                        <Link to="/terms-conditions" className='white'>Terms & Conditions</Link>
                                    </p>
                                    <p>
                                        <Link to='/refund-policy' className='white'>Refunds & Returns Policy</Link>
                                    </p>
                                    <p className='link'>
                                        <Link to='/change-of-mind' className='white'>Change of Mind Policy</Link>
                                    </p>
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    
                    <Grid.Row stretched style={{ paddingBottom: 0 }} only='computer'>
                        <Grid.Column width={16}>
                            <Divider clearing inverted />
                            <div className='copyright small flex marginTop40 marginBottom40'>
                                <div>© 2021 Club Connect</div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    
                </Grid>
                </>
            );
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyFooter);