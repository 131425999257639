import React, { useEffect, useState } from 'react';
import ChatPopUp from './ChatPopUp';
import InvasivePopUp from './InvasivePopUp';
import LongPopup from './LongPopup';
import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();

export function PopUpController() {
    const [popUps, setPopUps] = useState([]);

    useEffect(() => {
        const options = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/get_in_site_message',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + cookies.get('token')
            }
        }
    
        axios(options).then(res => {
            setPopUps(Object.values(res.data.message));
        }).catch(error => {
            if(error) {
                console.log(error);
            } 
        })
    },[])

    

    return <React.Fragment>
        {
        popUps.filter((x) => {
            if (!localStorage.getItem(`message-${x.id}`)) {
                return true;
            } else {
                return parseInt(localStorage.getItem(`message-${x.id}`)) + x.cool_down*60*1000 < Date.now();
            }
        }).map((x) => {
            switch (x.type) {
                case 1: return <InvasivePopUp {...x}/>
                case 2: return <LongPopup {...x}/>
                case 3: return <ChatPopUp {...x}/>
                default: return <React.Fragment/>
            }
        })}
    </React.Fragment>
}
