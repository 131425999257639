import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { Divider, Icon, Menu } from 'semantic-ui-react';
import { connect } from "react-redux";
import { myAuth } from '../utils/PrivateRoute';
import * as Constants from '../Constants';
import TagManager from "react-gtm-module";
import Cookies from 'universal-cookie';
import { getUserDetail } from '../utils/UserAPI';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {};

const cookies = new Cookies();

class MobileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: '',
        }
    }

    componentDidMount() {
        if (cookies.get('token')) {
            getUserDetail((result) => {
                this.setState({
                    'user': result.user
                })
            })
        }
    }


    handleLogout() {
        TagManager.dataLayer({
            dataLayer: {
                'event': 'signing_out'
            }
        });

        myAuth.signout(() => {
            window.location.reload();
        });
    }

    render() {
        const club = this.state.user.club;
        let menu = Constants.MENU;
        if (club) {
            if (parseInt(club.licence_verify) === 1) {
                menu = Constants.MENU;
            } else {
                menu = Constants.NON_ALC_MENU;
            }
        }

        return (
            <div className={'mobileMenu bgPrimary ' + (this.props.active ? 'slide-out' : '')}>
                <div className='paddingLeft2em paddingTop1rem marginBottom20' onClick={() => this.props.handleMobileMenu()}>
                    <Icon name='close' size='big' className='cursor white' />
                </div>
                <Menu inverted secondary icon='labeled' vertical fluid className='noMargin' size='large'>
                    {
                        menu.map((v, k) => {
                            return (
                                <Menu.Item href={v.link} as='a' key={k}>
                                    {v.name}
                                </Menu.Item>
                            )
                        })
                    }
                    <Divider />
                    <Menu.Item href='/your-account' as='a'>
                        Your Details
                    </Menu.Item>
                    <Menu.Item href='/your-rebates' as='a'>
                        Partnership Fund
                    </Menu.Item>
                    <Menu.Item href='/your-rebates' as='a'>
                        Partnership Fund
                    </Menu.Item>
                    <Menu.Item href='/rebate-history' as='a'>
                        Partnership Fund History
                    </Menu.Item>
                    <Menu.Item as='a' onClick={this.handleLogout.bind(this)}>
                        Sign out
                    </Menu.Item>

                </Menu>

            </div>
        );
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MobileMenu));