import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();

export function setProductToCart(id, qty) {
    const options = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/update_cart',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        data: {
            'id': id,
            'qty': parseInt(qty)
        }
    }

    return options;
}

export function getCurrentCart() {
    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/cart',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        }
    }

    return options;
}

export function getDeliveryWindows(callback) {
    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/delivery_time',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        }
    }
    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch(error => {
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })

    return options;
}

export function getOrderList(callback, currentOrder = true, limit = 20) {
    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/orders',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        params: {
            'current': currentOrder,
            'limit': limit
        }
    }
    let result = [];
    let total = 0;

    axios(options).then(res => {
        result = res.data.message;
        total = res.data.total;
        callback(result, total);
    }).catch(error => {
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })
}

export function getRepaymentOrder(callback, orderNumbers = [], userId) {
    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/get_repayment_order',
        headers: {
            'Accept': 'application/json',
        },
        params: {
            'orderNumbers': orderNumbers,
            'userId': userId
        }
    }
    let orders = [];

    axios(options).then((res) => {
        if (res.status === 206) {
            orders = res.data.message;
            callback(orders, false)
        } else {
            orders = res.data.message;
            callback(orders, true);
        }

    }).catch(error => {
        console.log(error)
        alert(error.response);
    })
}