import React, { Component } from "react";
import { Grid, Header } from 'semantic-ui-react';
import { connect } from "react-redux";
import { pageLogin, pageNotLogin } from '../action/view';
import BackButton from "../parts/BackButton";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageLogin,
    pageNotLogin
};

class LiquorLicences extends Component {
    constructor(props) {
        super(props);

        this.props.pageNotLogin();
    }

    render() {

        return (
            <Grid padded className='loginBg'>
                <Grid.Row>
                    <Grid.Column width={16} className='marginTop30 alignCenter'>
                        <Header as='h2'>
                            Liquor Licences
                        </Header>

                        <div className='maxWidth70p overflowYAuto center padding15 alignLeft'>
                            <Grid>
                                <Grid.Row columns={1}>
                                    <Grid.Column textAlign='center'>
                                        <p>National - 57708780</p>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                        <div className='login maxWidth70p center marginTop20'>
                            <BackButton />
                        </div>

                    </Grid.Column>

                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LiquorLicences);