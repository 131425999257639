import React, { Component } from "react";
import { Grid, Header } from "semantic-ui-react";
import { connect } from "react-redux";
import { pageLogin, pageNotLogin } from "../action/view";
import BackButton from "../parts/BackButton";
import { Link } from "react-router-dom";

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  pageLogin,
  pageNotLogin,
};

class TermConditions extends Component {
  constructor(props) {
    super(props);

    this.props.pageNotLogin();
  }

  render() {
    return (
      <Grid padded className="loginBg">
        <Grid.Row>
          <Grid.Column width={16} className="marginTop30 alignCenter">
            <Header as="h2">Terms and Conditions</Header>

            <div className="maxWidth70p center padding15 alignLeft">
              <p>
                Welcome to <strong>Club Connect</strong>, a program operated by
                Asahi Beverages (Asahi Beverages, we, us or our). Below are the
                terms and conditions which apply to your use of Club Connect
                (Terms). Please review and ensure you are comfortable with the
                Terms before using Club Connect. If you have any questions about
                the Terms please contact us via our contact centre on
                clubconnect.org.au
              </p>

              <Header as="h3">Purchases & Partnership Fund:</Header>
              <p>
                We will pay you a Partnership Fund for purchases of products you
                make using our nominated online ordering platform (Products),
                subject to the following conditions: (a) purchases of Products
                may be subject to separate terms and conditions provided to you
                at the time of sale which will apply in addition to these Terms;
                (b) the dollar value of Partnership Fund will be as stated on
                the Club Connect platform, or shared with you specifically, from
                time to time; (c) Products you purchase must be supplied by you
                to an end consumer for consumption; (d) Partnership Fund will be
                calculated by reconciling its records of Products purchased by
                you and paid within the timeframe specified on the Club Connect
                platform into your nominated bank account, and (e) we may from
                time to time specify to you that certain Products are not
                eligible for Partnership Fund
              </p>

              <Header as="h3">Use of data:</Header>
              <p>
                We will collect, hold, use and disclose your personal
                information in accordance with our privacy policy available
                here. Please review our privacy policy before using Club
                Connect.
              </p>

              <Header as="h3">Your Account:</Header>
              <p>
                As part of the set-up process for Club Connect we will require
                you to set up an account (Your Account). You are fully
                responsible for maintaining Your Account (including the security
                and authorised use of and access to Your Account), and we will
                not be responsible or liable for any improper or otherwise
                unauthorised use of or access to Your Account, including
                unauthorised purchases or other activity not unauthorised by you
                as the proper manager of Your Account. Please keep Your Account
                secure at all times and notify us immediately if you become
                aware of any breach of such security.
              </p>

              <Header as="h3">Termination & suspension:</Header>
              <p>
                We may terminate your access to and use of Club Connect
                immediately on notice to you if you breach a material provision
                of these Terms. We may suspend your access to and use of Club
                Connect immediately on notice to you if we need to for a
                legitimate business need.
              </p>

              <Header as="h3">Liability:</Header>
              <p>
                To the maximum extent permitted by law, and its related entities
                excludes all liability for any loss or damage of any kind
                (including special, indirect or consequential loss or loss of
                business) arising out of or in connection with your use or Club
                Connect or any content on the platform, and the use or
                performance of Club Connect. Where the law (including without
                limitation the Competition and Consumer Act 2010 (Cth)) implies
                a warranty or guarantee into these Terms which may not lawfully
                be excluded, then provided it is fair and reasonable to do so,
                Asahi Beverages' liability for breach of such a warranty or
                guarantee will be limited at our option, to any one or more of
                the following: (a) in the case of goods: replacement of the
                goods or the supply of equivalent goods; repair of the goods;
                payment of the cost of replacing the goods or acquiring
                equivalent goods; or payment of the cost of having the goods
                repaired; and (b) in the case of services, to either resupplying
                the services or payment of the cost of having the services
                supplied again.
              </p>

              <Header as="h3">Information & security:</Header>
              <p>
                While we endeavour to take reasonable care in preparing and
                maintaining the information on Club Connect we do not warrant
                the accuracy, reliability, adequacy or completeness of any
                content. You acknowledge and accept that such content may
                include technical inaccuracies and typographical errors, and
                subject to change at any time without notice and may not
                necessarily be up-to-date or accurate at the time you view it.
                We do not guarantee that access to Club Connect will be
                uninterrupted or free from viruses or anything else which may
                damage any device which accesses Club Connect or any data on
                such a device. You must not attempt to change, add to, remove,
                deface, hack or otherwise interfere with Club Connect or any
                material or content displayed on Club Connect.
              </p>

              <Header as="h3">Intellectual property:</Header>
              <p>
                As between you and us, we own or licence all intellectual
                property rights in any content on Club Connect (Our Content). We
                grant you a non-exclusive, royalty free, non-transferable,
                personal licence to use Our Content for the purpose of using
                Club Connect in accordance with these Terms. To the extent that
                you provide any content to us (Your Content), you provide us
                with a non-exclusive, royalty free licence to use and copy Your
                Content as required for us to provide Club Connect, and you
                warrant that your provision and our use of Your Content will not
                infringe the rights of any third party or contravene the
                Australian Privacy Principles under the Privacy Act 1988 (Cth).
              </p>

              <Header as="h3">Third party sites:</Header>
              <p>
                The Club Connect platform may contain links to other apps,
                websites or platforms operated by third parties (Third Party
                Platforms). Unless otherwise indicated, Asahi Beverages or its
                related entities does not control, endorse, sponsor or approve
                any such Third Party Platforms or their content nor provide any
                warranty or take any responsibility whatsoever for any aspect of
                Third Party Platforms or their content.
              </p>

              <Header as="h3">General:</Header>
              <ol type="a">
                <li>
                  These Terms represent the entire agreement between the parties
                  in relation to your use and our provision of Club Connect and
                  supersedes all prior discussions, negotiations, understandings
                  or agreements between the parties;
                </li>
                <li>
                  These Terms are governed by the laws of the state of Victoria,
                  Australia and each party submits to the exclusive jurisdiction
                  of the courts of Victoria, Australia and the courts of appeal
                  from them in respect of any proceedings arising under or in
                  connection with these Terms;
                </li>
                <li>
                  A provision of these Terms, or right, power or remedy created
                  under it may not be varied or waived except in writing signed
                  by the party or parties to be bound;
                </li>
                <li>
                  If the whole or any part of a provision of these Terms are
                  void, unenforceable or illegal in a jurisdiction it is severed
                  for that jurisdiction and the remainder of this agreement has
                  full force and effect and the validity or enforceability of
                  that provision in any other jurisdiction is not affected.
                </li>
              </ol>
            </div>

            <Header as="h2">Campaign Terms and Conditions</Header>

            <div className="maxWidth70p center padding15 alignLeft">
              <ul>
                <li>
                  <Link to="/cub-cricket-t-c">
                    Cricket Australia (October 2022 - Nov 2022)
                  </Link>
                </li>
                <li>
                  <Link to="august-bonus-case">
                    ‘AUGUST BONUS CASE’ Terms and Conditions
                  </Link>
                </li>
                <li>
                  <Link to="july-bonus-case-t-c">
                    ‘JULY BONUS CASE’ Terms and Conditions
                  </Link>
                </li>
                <li>
                  <Link to="nrl-nsw-t-c">
                    NRL NSW Activation Campaign Terms and Conditions
                  </Link>
                </li>
                <li>
                  <Link to="nrl-qld-t-c">
                    NRL QLD Activation Campaign Terms and Conditions
                  </Link>
                </li>
                <li>
                  <Link to="bowls-australia-campaign-t-c">
                    Club Connect x Bowls Australia Promotion Terms and
                    Conditions
                  </Link>
                </li>
                <li>
                  <Link to="vic-campaign-t-c">
                    Club Connect Campaign (Vic) Promotion Terms and Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/afl-club-activation-t-c">
                    AFL Club Activation Campaign Terms and Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/gatorade-nswrl-t-c">
                    Gatorade x NSWRL x Club Connect Promotion Terms and
                    Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/cool-ridge-t-c">
                    ‘Cool Ridge' Terms and Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/joel-selwood-t-c">
                    ‘JOEL SELWOOD’ Terms and Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/activation-t-c-2022">
                    New Clubs Activation - Case Giveaway (Jan 2022 - Dec 2022)
                  </Link>
                </li>
                <li>
                  <Link to="/nonalc-activation-t-c-2022">
                    Non-Alc New Clubs Activation - Case Giveaway (Jan 2022 - Dec
                    2022)
                  </Link>
                </li>
                <li>
                  <Link to="/cricket-campaign-vic-t-c">
                    Club Connect Cricket Campaign (Vic) Promotion (Jan 2022 -
                    Feb 2022)
                  </Link>
                </li>
                <li>
                  <Link to="/activation-t-c">
                    New Clubs Activation - Case Giveaway (May 2021 - Dec 2021)
                  </Link>
                </li>
                <li>
                  <Link to="/cricket-t-c">
                    Gatorade Cricket - Case Giveaway (Sept 2021 - Dec 2021)
                  </Link>
                </li>
                <li>
                  <Link to="/nonalc-activation-t-c">
                    Non-Alc New Clubs Activation - Case Giveaway (May 2021 - Dec
                    2021)
                  </Link>
                </li>
                <li>
                  <Link to="/relaunch-term-conditions">
                    AFL NSW and AFL QLD - Case Giveaway (May 2021 - Dec 2021)
                  </Link>
                </li>
                <li>
                  <Link to="/cub-afl-t-c">
                    AFL VIC - Player Appearance (June 2021 - July 2021)
                  </Link>
                </li>
                <li>
                  <Link to="/cub-waafl-t-c">
                    AFL WA - Player Appearance (July 2021)
                  </Link>
                </li>
                <li>
                  <Link to="/cub-aflse-t-c">
                    AFLSE - Case Giveway (May 2021 - July 2021)
                  </Link>
                </li>
                <li>
                  <Link to="/cub-aflwa-t-c">
                    AFLWA - Case Giveway (May 2021 - July 2021)
                  </Link>
                </li>
              </ul>
            </div>
            <div className="login maxWidth70p center marginTop20">
              <BackButton />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TermConditions);
