import React, { Component } from "react";
import { Grid, Header, Icon, Placeholder } from 'semantic-ui-react';
import { connect } from "react-redux";
import { getUser } from '../action/user';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    getUser
};

class ShippingAddress extends Component {
    render() {
        const club = this.props.user.club;

        let address = 'No shipping address';

        if(club.shipping_address !== '') {
            address = club.name + ' ' + club.shipping_address + ' ' + club.suburb + ' ' + club.state + ' ' + club.postcode;
        }

        let shippingAddress = () => {
            return (
                <Header className='regular noMargin'>
                    Shipping Address
                        <Header.Subheader className='small lightGray noMargin'>
                        {address}
                    </Header.Subheader>
                </Header>
            )
        }

        if(this.props.user.loading) {
            return (
                <Grid verticalAlign='middle' stretched>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Placeholder fluid>
                                <Placeholder.Image />
                            </Placeholder>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        } else {
            return (
                <Grid verticalAlign='middle' stretched>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <Icon name='checkmark' circular fitted className='bgPrimary white'></Icon>
                        </Grid.Column>

                        <Grid.Column width={13}>
                            {shippingAddress()}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            );
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShippingAddress);
