import React, { Component } from "react";
import { Header, Image, Grid, Placeholder, Accordion } from 'semantic-ui-react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

export default class BrandSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: false
        }
    }

    handleClick() {
        this.setState({
            activeIndex: !this.state.activeIndex
        })
    }

    loadingBrandSlide(brands, isLoading) {
        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 13,
            slidesToScroll: 5,
            arrows: false,
            lazyLoad: 'progressive',
            pauseOnDotsHover: true,
            pauseOnFocus: true,
            className: 'category',
            swipeToSlide: true,
            easing: 'ease'
        };
        
        if(isLoading === true) {
            const arrayLoop = 5;
            let placeholderList = [];
            let count = 0;

            while (count < arrayLoop) {
                count++;
                placeholderList.push(
                    <Grid key={count}>
                        <Grid.Column style={{ paddingTop: 0, paddingBottom: 0 }} className='margin15'>
                            <div className='bgBlackOpacity borderRadius10 relative'>
                                <Placeholder>
                                    <Placeholder.Image />
                                </Placeholder>
                            </div>
                        </Grid.Column>
                    </Grid>
                )
            }

            return (<Slider {...settings}>{placeholderList}</Slider>);
        } else {
            if(brands.length > 0) {
                return (
                    <Slider {...settings} className='productSlider brand'>
                        {
                            brands.map((value, key) => {
                                return (
                                    <div key={key}>
                                        <Link to={'/brand/' + value.slug.toLowerCase()} className='link'>
                                            <div className='relative bgWhite' style={{width: '71px', height: '71px'}}>
                                                <Image verticalAlign='middle' fluid src={process.env.REACT_APP_HOST_URL + '/storage/images/' + (value.image_link ? value.image_link : 'placeholder-brand.png')} className='bgWhite vertical-center'/>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                )
            } else {
                return null
            }
        }
    }

    loadingBrand(brands, isLoading) {
        if(isLoading === true) {
            const arrayLoop = 5;
            let placeholderList = [];
            let count = 0;

            while (count < arrayLoop) {
                count++;
                placeholderList.push(
                    <Grid key={count}>
                        <Grid.Column style={{ paddingTop: 0, paddingBottom: 0 }} className='margin15'>
                            <div className='bgBlackOpacity borderRadius10 relative'>
                                <Placeholder>
                                    <Placeholder.Image />
                                </Placeholder>
                            </div>
                        </Grid.Column>
                    </Grid>
                )
            }

            return (<>{placeholderList}</>);
        } else {
            if(brands.length > 0) {
                return (
                    <>
                        {
                            brands.map((value, key) => {
                                return (
                                    <div key={key} style={{width: '71px', height: '71px'}} className='bgWhite relative brandItem'>
                                        <Link to={'/brand/' + value.slug.toLowerCase()} className='link'>
                                            <Image style={{height: '71px'}} verticalAlign='middle' fluid src={process.env.REACT_APP_HOST_URL + '/storage/images/' + (value.image_link ? value.image_link : 'placeholder-brand.png')} className='bgWhite vertical-center'/>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </>
                )
            } else {
                return null
            }
        }
    }

    mapBrand(brands) {
        return (
            <>
                {
                    brands.map((value, key) => {
                        return (
                            <Link key={key} to={'/brand/' + value.slug.toLowerCase()} className='link'>
                                {value.name}
                            </Link>
                        )
                    })
                }
            </>
        )
    }

    render() {
        const brands = this.props.data;
        const isLoading = this.props.isLoading;

        return (
            <Grid.Column width={16}>
                <Grid className='noDisplayMobile'>
                    <Grid.Row >
                        <Grid.Column>
                            <Header size='huge' className='noMargin alignCenter'>Browse by Brand
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Grid>
                    <Grid.Row className='noPadding noLargeScreen' only='computer'>
                        <Grid.Column className='noPadding' computer={16}>
                            {this.loadingBrandSlide(brands, isLoading)}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row largeScreen={16} only='large screen'>
                        <Grid.Column className='brandListDesktop'>
                            {this.loadingBrand(brands, isLoading)}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row only='mobile tablet'>
                        <Grid.Column className='noPadding'>
                            <Accordion className='accordBrand' vertical>
                                <Accordion.Title
                                    active={this.state.activeIndex}
                                    content='Brand'
                                    index={0}
                                    onClick={this.handleClick.bind(this)}
                                />
                                <Accordion.Content active={this.state.activeIndex} content={this.mapBrand(brands)} />
                            </Accordion>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Column>
        )
    }
}