import React, { Component } from "react";


class LandingPageAbout extends Component {
    render() {
        return (
            <>
                <div className="container mx-auto flex my-14 flex-col px-4 lg:px-0 lg:flex-row">
                    <div className='flex flex-1 content-center mb-8 lg:mb-0 lg:mr-10'>
                        <div className="h-min max-w-580">
                            <h2 className="text-2xl font-bold">What is Club Connect?</h2>
                            <hr className="w-24 h-1.5 bg-black my-8" />
                            <p className="text-2xl">Check out our instructional video. This explains Club Connect in a little more detail.</p>
                        </div>
                    </div>
                    <div className="flex-1 relative overflow-hidden max-w-full pb-iframe-mobile lg:pb-iframe">
                        <iframe title='vimeo' className="absolute w-full h-full left-0 top-0" src="https://player.vimeo.com/video/667036759?h=66bd6f1837&portrait=0&byline=0&title=0&controls=0" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                    </div>
                </div>
            </>
        )
    }
}

export default LandingPageAbout;
