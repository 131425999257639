import React, { Component } from "react";
import { withRouter, Link } from 'react-router-dom';
import { Grid, Icon, Search, Dropdown, Label, Image, List, Container, Header, Ref } from 'semantic-ui-react';
import * as Constants from '../Constants';
import { connect } from "react-redux";
import { getCart } from '../action/cart';
import { myAuth } from '../utils/PrivateRoute';
import Cookies from 'universal-cookie';
import _ from 'lodash';
import { getCategoryList, getSearchList, getBrandMenu } from '../utils/ProductAPI';
import { getRebate, getUserDetail } from '../utils/UserAPI';
import { formatCurrency, htmlEntities } from '../utils/Utils';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module'

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    getCart
};

const cookies = new Cookies();

const resultRenderer = ({ name, title }) => <Label key={title}>{name}</Label>

resultRenderer.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.string
}

class MyHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            category: 'all',
            toSearch: false,
            categories: [],
            rebate: 0,
            searchList: [],
            brands: null,
            result: [],
            openSearch: false,
            user: '',
            menuHeight: 0,
        }
        this.divRef = React.createRef()
    }

    componentDidMount() {
        if (cookies.get('token')) {
            getCategoryList((result) => {
                let options = [];
                result.forEach(currentItem => {
                    let obj = {};
                    obj.key = currentItem.id;
                    obj.text = currentItem.name;
                    obj.value = currentItem.slug;

                    options.push(obj);
                })
                this.setState({
                    'categories': options
                })
            })

            getBrandMenu((result) => {
                this.setState({
                    'brands': result
                })
            })

            getRebate((result) => {
                this.setState({
                    rebate: result
                })
            })

            getUserDetail((result) => {
                this.setState({
                    'user': result.user
                })
            })

            if (this.props.cart.firstLoading === true) {
                this.props.getCart();
            }

            getSearchList((result) => {
                this.setState({
                    searchList: result
                })
            }, true)
        }

        if (this.divRef.current) {
            const styles = (this.divRef.current).getBoundingClientRect()
            this.setState({
                menuHeight: styles.bottom
            })
        }
    }

    onChangeSelect(e, { value }) {
        this.setState({
            category: value !== '' ? value : 'all'
        });
    }

    keyPressed(e) {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();

            this.search();
        }
    }

    search() {
        if (this.state.search.length === 0) {
            alert('Please input your search query')
        } else {
            this.props.history.push('/search/' + this.state.category + '/' + this.state.search);
        }
    }

    handleLogout() {
        TagManager.dataLayer({
            dataLayer: {
                'event': 'signing_out'
            }
        });

        myAuth.signout(() => {
            window.location.reload();
        });
    }

    handleResultSelect = (e, { result }) => {
        this.setState({
            search: result.name
        }, () => {
            this.props.history.push('/search/' + this.state.category + '/' + htmlEntities(this.state.search));
        })
    }

    handleSearchChange = (e, { value }) => {
        this.setState({
            isLoading: true,
            search: value
        })

        TagManager.dataLayer({
            dataLayer: {
                'search_query': null
            }
        })
        TagManager.dataLayer({
            dataLayer: {
                'event': 'search_product',
                'search_query': value
            }
        })

        setTimeout(() => {
            if (this.state.search.length < 1) {
                return this.setState({
                    search: '',
                    result: [],
                    isLoading: false
                })
            }

            const re = new RegExp(_.escapeRegExp(this.state.search), 'i');

            let isMatch = (result) => re.test(result.name);

            this.setState({
                isLoading: false,
                results: _.filter(this.state.searchList, isMatch),
            })
        }, 300)
    }

    render() {
        const club = this.state.user.club;
        let menu = [];
        if (club) {
            if (parseInt(club.licence_verify) === 1) {
                menu = Constants.MENU;
            } else {
                menu = Constants.NON_ALC_MENU;
            }
        }

        const subMenu = Constants.SUBMENU;
        // get current cart of the user    
        const cartItems = this.props.cart.cartItems;
        const numberItems = _.sumBy(cartItems, function (o) { return o.quantity; });

        /*const cartMobile = () => {
            return (
                <Link to='/cart' className='relative paddingRight5 marginLeft5'>
                    <Image src='/img/shopping-cart.svg' width='13' inline/>
                    <Transition
                        animation='scale'
                        duration={500}
                        visible={numberItems > 0 ? true : false}
                    >
                        <Label size='mini' color='red' circular floating className={this.props.cart.loading ? 'pulsate-fwd' : ''}>
                            {numberItems}
                        </Label>
                    </Transition>

                </Link>
            )
        }*/

        const cartDesktop = () => {
            return (
                <Link to='/cart' className='relative paddingRight5 marginLeft5' onClick={() => TagManager.dataLayer({
                    dataLayer: {
                        event: 'go_to_cart_page'
                    }
                })}>
                    <Image id='GoToCart' src='/img/cart.svg' inline />

                    {(() => {
                        if (numberItems === 0) {
                            return null
                        } else {
                            let displayNum = numberItems;
                            if (numberItems > 99) {
                                displayNum = '99+';
                            }
                            return (<Label size='mini' color='orange' circular className={'numCart ' + (this.props.cart.loading ? 'pulsate-fwd' : '')}>
                                {displayNum}
                            </Label>)
                        }
                    })()}
                </Link>

            )
        }

        const favouriteLink = () => {
            return (
                <Link to='/favourites' className='relative paddingRight5 marginLeft5'>
                    <Image inline src='/img/favourite.svg' />
                </Link>
            )
        }

        const search = () => {
            return (
                <Search id='SearchProduct' fluid className='transparent inverted bgSecondaryLower white height38px'
                    loading={this.state.isLoading}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={_.debounce(this.handleSearchChange, 500, {
                        leading: true
                    })}
                    results={this.state.results}
                    value={this.state.search}
                    resultRenderer={resultRenderer}
                    placeholder='Search Products'
                    minCharacters={2}
                    onKeyPress={this.keyPressed.bind(this)}
                />
            )
        }

        if (cookies.get('token')) {
            let count = 0;
            return (
                <>
                    <div className='headerDarkBg height30px onlyDesktop'>
                        <Grid container padded columns={1} className='white center'>
                            <Grid.Row className='paddingTop5px paddingBottom5px'>
                                <Grid.Column textAlign='right'>
                                    <List bulleted horizontal>
                                        {
                                            subMenu.map((value, key) => {
                                                return (
                                                    <List.Item key={key}>
                                                        <Link to={value.link} className={'white fontSize11px'}>{value.name}</Link>
                                                    </List.Item>
                                                )
                                            })
                                        }
                                    </List>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                    <div className='headerBg'>
                        <Grid container padded columns={3} className='white center'>
                            <Grid.Row className='noPaddingBottom paddingTop6px'>
                                <Grid.Column textAlign='left' width={6} only='mobile tablet'  >
                                    <div>
                                        <Icon size='huge' name='bars' link className='fontSize21px' onClick={() => this.props.handleMobileMenu()} />
                                        <Image inline style={{ width: '50px' }} src='/img/ClubConnect_Landscape.svg' href='/' />

                                        {/*<Image style={{ width: '13px' }} inline src='/img/ClubConnect_Landscape.svg' href='/' />*/}
                                    </div>
                                </Grid.Column>
                                <Grid.Column textAlign='right' className='alignSelfCenter alignItemsCenter flexEnd flex' width={10} only='mobile tablet'>
                                    <div className='alignCenter marginRight20'>
                                        <strong className='marginRight5 fontSize16px'>{formatCurrency(this.state.rebate)}</strong><br />
                                        <span className='fontSize7px'>PARTNERSHIP FUND</span>
                                    </div>
                                    <div className='marginRight20'>
                                        <Image className='cursor' width='18' inline src='/img/magnifying-glass.svg' as='a' onClick={() => {
                                            this.setState({
                                                openSearch: !this.state.openSearch
                                            })
                                        }} />
                                    </div>
                                    <div>
                                        {cartDesktop()}
                                    </div>
                                </Grid.Column>

                                <Grid.Column className='noPaddingBottom' textAlign='left' width={1} only='computer'>
                                    <div>
                                        <Image inline style={{ width: '55px' }} src='/img/ClubConnect_Landscape.svg' href='/' />
                                    </div>
                                </Grid.Column>
                                <Grid.Column only='computer' computer={10} className='alignSelfEnd'>
                                    <Grid padded>
                                        <Grid.Row stretched only='mobile tablet' columns={5}>
                                            {
                                                menu.map((value, key) => {
                                                    return (
                                                        <Grid.Column key={key}>
                                                            <Link to={value.link} className={'menu white textUppercase ' + (key <= 4 ? 'bold' : '')}>{value.name}</Link>
                                                        </Grid.Column>
                                                    )
                                                })
                                            }
                                        </Grid.Row>
                                        <Ref innerRef={this.divRef}>
                                            <Grid.Row only='computer' textAlign='right' className='alignEnd noPaddingBottom'  >
                                                <Grid.Column className='noPadding flex flex-nowrap text-left justify-end menu-item-container'>
                                                    {
                                                        menu.map((value, key) => {
                                                            if (this.state.brands && this.state.brands[value.slug]) {
                                                                count++;
                                                                return (
                                                                    <div key={key} className={'menuItem mega-dropdown ' + (menu === Constants.NON_ALC_MENU && 'mx-2')} >
                                                                        <Link to={value.link} className={'menu white textUppercase browse item ' + ((count <= 7 || value.slug === 'water' || value.slug === 'non-alcoholic-beverages') && 'bold')}>{value.name}</Link>
                                                                        {
                                                                            (count <= 10 || value.slug === 'water') && (
                                                                                <div className='megamenu' style={{ top: this.state.menuHeight }}>
                                                                                    <Container>
                                                                                        <Grid columns={5} centered relaxed stackable textAlign='left'>
                                                                                            {
                                                                                                (this.state.brands && this.state.brands[value.slug]) && this.state.brands[value.slug].map((v, k) => {
                                                                                                    if (k < 4) {
                                                                                                        return (
                                                                                                            <Grid.Column key={k} className='flexDirectionColumn flex'>
                                                                                                                <Header>
                                                                                                                    <Image verticalAlign='middle' src={process.env.REACT_APP_HOST_URL + '/storage/images/' + (v.image_link ? v.image_link : 'placeholder-brand.png')} className='bgWhite vertical-center height30px widthAuto' />
                                                                                                                </Header>
                                                                                                                <div className='marginBottom40'>
                                                                                                                    {v.products.map((p, k) => {
                                                                                                                        if (k <= 4) {
                                                                                                                            return (
                                                                                                                                <div key={k}>
                                                                                                                                    <Link to={'/product/' + p.slug} className='line-clamp-1 black hoverOrange marginBottom10'>
                                                                                                                                        {p.name}
                                                                                                                                    </Link>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        } else {
                                                                                                                            return null
                                                                                                                        }

                                                                                                                    })}
                                                                                                                </div>
                                                                                                                <div className='marginTopAuto'>
                                                                                                                    <Link to={'/brand/' + v.slug} className='line-clamp-1 hoverOrange'>
                                                                                                                        <Header>All {v.name}</Header>
                                                                                                                    </Link>
                                                                                                                </div>

                                                                                                            </Grid.Column>
                                                                                                        )
                                                                                                    }
                                                                                                    else {
                                                                                                        return null
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                            {
                                                                                                (this.state.brands && this.state.brands[value.slug] && this.state.brands[value.slug].length > 4) &&
                                                                                                <Grid.Column className='flexDirectionColumn flex'>
                                                                                                    <Header size='large'>Other {value.name}</Header>
                                                                                                    <div className='marginBottom40'>
                                                                                                        {
                                                                                                            (this.state.brands && this.state.brands[value.slug]) && this.state.brands[value.slug].map((v, k) => {
                                                                                                                if (k >= 4 && k <= 11) {
                                                                                                                    return (
                                                                                                                        <div key={k}>
                                                                                                                            <Link to={'/brand/' + v.slug} className='line-clamp-1 black hoverOrange marginBottom10'>
                                                                                                                                {v.name}
                                                                                                                            </Link>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                } else {
                                                                                                                    return null
                                                                                                                }
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className='marginTopAuto'>
                                                                                                        <Link to={value.link} className='hoverOrange'>
                                                                                                            <Header>All {value.name}</Header>
                                                                                                        </Link>
                                                                                                    </div>
                                                                                                </Grid.Column>
                                                                                            }

                                                                                        </Grid>
                                                                                    </Container>
                                                                                </div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                            else {
                                                                return null
                                                            }
                                                        })
                                                    }
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Ref>
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column computer={5} only='computer' className='noPaddingLeft' textAlign='right'>
                                    <Grid padded>
                                        <Grid.Row textAlign='right' className='flexEnd alignEnd'>
                                            <Grid.Column mobile={10} tablet={10} computer={5} largeScreen={6} style={{ paddingLeft: 0 }}>
                                                {search()}
                                            </Grid.Column>
                                            <Grid.Column tablet={7} computer={5} largeScreen={4} className='alignCenter'>
                                                <strong className='marginRight5 fontSize16px'>{formatCurrency(this.state.rebate)}</strong><br />
                                                <span className='fontSize7px'>PARTNERSHIP FUND</span>
                                            </Grid.Column>
                                            <Grid.Column tablet={6} computer={5} largeScreen={4} textAlign='right' className='flex flexBetween alignSelfCenter noPaddingRight' >
                                                {favouriteLink()}
                                                <Dropdown item icon={<Image className="align-baseline" inline src='/img/touch-id-user.svg' />} compact className='bold accountMenu fontSize21px '>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item className='noPadding'>
                                                            <Link to={{
                                                                pathname: '/your-account'
                                                            }} style={{ color: '#000' }}>
                                                                <p className='paddingDefault'>
                                                                    <Icon name='address card outline' className='marginRight10' />
                                                                    Your Details
                                                                </p>
                                                            </Link>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item className='noPadding'>
                                                            <Link to={{
                                                                pathname: '/your-account',
                                                                state: {
                                                                    selected: 'club'
                                                                }
                                                            }} style={{ color: '#000' }}>
                                                                <p className='paddingDefault'>
                                                                    <Icon name='address book outline' className='marginRight10' />
                                                                    Club Details
                                                                </p>
                                                            </Link>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item className='noPadding'>
                                                            <Link to={{
                                                                pathname: '/your-account',
                                                                state: {
                                                                    selected: 'clubUsers'
                                                                }
                                                            }} style={{ color: '#000' }}>
                                                                <p className='paddingDefault'>
                                                                    <Icon name='users' className='marginRight10' />
                                                                    Club Users
                                                                </p>
                                                            </Link>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item className='noPadding'>
                                                            <Link to={{
                                                                pathname: '/your-account',
                                                                state: {
                                                                    selected: 'invoices'
                                                                }
                                                            }} style={{ color: '#000' }}>
                                                                <p className='paddingDefault'>
                                                                    <Icon name='dollar sign' className='marginRight10' />
                                                                    Invoices
                                                                </p>
                                                            </Link>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item className='noPadding'>
                                                            <Link to={{
                                                                pathname: '/your-account',
                                                                state: {
                                                                    selected: 'invites'
                                                                }
                                                            }} style={{ color: '#000' }}>
                                                                <p className='paddingDefault'>
                                                                    <Icon name='gift' className='marginRight10' />
                                                                    Invite Clubs
                                                                </p>
                                                            </Link>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item className='noPadding'>
                                                            <Link to='/your-rebates' style={{ color: '#000' }}>
                                                                <p className='paddingDefault'>
                                                                    <Icon name='money' className='marginRight10' />
                                                                    Partnership Fund
                                                                </p>
                                                            </Link>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item className='noPadding'>
                                                            <Link to='/rebate-history' style={{ color: '#000' }}>
                                                                <p className='paddingDefault'>
                                                                    <Icon name='history' className='marginRight10' />
                                                                    Partnership Fund History
                                                                </p>
                                                            </Link>
                                                        </Dropdown.Item>
                                                        {/*
                                                            parseInt(this.state.user.role) === 1 &&
                                                            <Dropdown.Item className='noPadding'>
                                                                <Link to='/add-users' style={{ color: '#000' }} >
                                                                    <p className='paddingDefault'>
                                                                        <Icon name='add user' className='marginRight10' />
                                                                        Add New User
                                                                    </p>
                                                                </Link>
                                                            </Dropdown.Item>
                                                            */
                                                        }

                                                        <Dropdown.Item onClick={() => this.handleLogout()} className='noPadding'>
                                                            <p className='paddingDefault'>
                                                                <Icon name='sign-out' className='marginRight10' />
                                                                Sign Out
                                                            </p>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                {cartDesktop()}
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row only='mobile tablet' className={'noPaddingTop noPaddingBottom transition ' + (this.state.openSearch ? 'height50' : 'height0')}>
                                <Grid.Column width={16}>
                                    {search()}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </>
            )
        } else {
            return (
                <Grid padded columns={1} className='headerBg white'>
                    <Grid.Row stretched className='noPaddingBottom'>
                        <Grid.Column className='paddingBottom1em paddingLeft2em paddingRight2em' textAlign='center' width={16}>
                            <div>
                                <Image inline style={{ width: '80px' }} src='/img/ClubConnect_Landscape.svg' href='/' />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }


    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MyHeader));