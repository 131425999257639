import React, { Component } from "react";
import { Grid, Header } from 'semantic-ui-react';
import { connect } from "react-redux";
import { pageLogin, pageNotLogin } from '../action/view';
import BackButton from "../parts/BackButton";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageLogin,
    pageNotLogin
};

class FAQS extends Component {
    constructor(props) {
        super(props);

        this.props.pageNotLogin();
    }
    
    render() {
        return (
            <Grid padded className='loginBg'>
                <Grid.Row>
                    <Grid.Column width={16} className='marginTop30 alignCenter'>
                        <Header as='h2'>
                            FAQs
                        </Header>

                        <div className='maxWidth70p center padding15 alignLeft'>
                        <Header as='h3'>General:</Header>
                            <ol type='1'>
                                <li>
                                    <strong>What is Club Connect?</strong> <br/>
                                    A website where community sporting clubs who don’t have an existing beverage sponsorship can purchase all their wholesale packaged beverage requirements in a single location, have the products delivered for free directly to their club at a time when a volunteer is at the club, and most importantly earn a sponsorship contribution on every purchase into a partnership fund that the club can spend how the please.
                                    <br/><br/>
                                </li>
                                <li>
                                    <strong>Why did Carlton & United Breweries & Asahi create Club Connect??</strong> <br />
                                    We have a large group of wholesale customers (sporting clubs) that don’t have a beverage sponsorship and aren’t engaged by our field sales team. Club Connect allows us to close this gap in our contact model while also providing a valuable service that makes it easier for clubs to meet their beverage needs, while also raising funds for the club.  Club Connect also continues our long and proud history of supporting community sport.
                                    <br /><br />
                                </li>
                                <li>
                                    <strong>Is the website open to anyone?</strong> <br />
                                    Our primary target audience is volunteer run community sporting clubs, who require a curated delivery service and are currently not receiving any sponsorship support from a pub, liquor store or wholesaler. 
                                    <br /><br />
                                </li>
                            </ol>

                            <Header as='h3'>Sign up:</Header>
                            <ol type='1'>
                                <li>
                                    <strong>Am I locked into a contract with Club Connect if I sign up?</strong> <br />
                                    No, not at all. With Club Connect you are free to purchase products elsewhere if you wish and can discontinue use whenever. There is no contract, no sign up fee, no minimum spend, no exclusivity. 
                                    <br /><br />
                                </li>
                                <li>
                                    <strong>My club is currently sponsored by a pub, liquor store or wholesaler. Can I still use Club Connect?</strong> <br />
                                    Great that you are being sponsored, we would recommend you continue with this relationship and reconnect with us if for some reason the sponsorship ends. 
                                    <br /><br />
                                </li>
                                <li>
                                    <strong>How do I sign in?</strong> <br />
                                    Using your email address and password that you receive once your account has been verified.
                                    <br /><br />
                                </li>
                                <li>
                                    <strong>When will my account be verified?</strong> <br />
                                    Verification typically takes one business day
                                    <br /><br />
                                </li>
                                <li>
                                    <strong>What is a liquor licence number? Where can I find it?</strong> <br />
                                    Your clubs liquor licence number will be displayed at your club's bar. You can also search for it online via your State government's liquor licence register. 
                                    <br /><br />
                                </li>
                                <li>
                                    <strong>What is a liquor licence?</strong> <br />
                                    A liquor licence is an approval by your State government to permit alcohol to be served on your premise. The details and restrictions vary by application, so be certain to familiarise yourself with the details of your club before serving any alcohol.
                                    <br /><br />
                                </li>
                                <li>
                                    <strong>What is a liquor licence licensee? How do I find out who it is?</strong> <br />
                                    Each liquor licence must be applied for by an individual on behalf of the club. Your clubs liquor licensee will be displayed at your clubs bar.
                                    <br /><br />
                                </li>
                                <li>
                                    <strong>What is an ABN? How do I find out what it is?</strong> <br />
                                    An ABN is an Australian Business Number, and is a unique identifier assigned to every registered business within Australia. You can search for your club ABN here: <a href='https://abr.business.gov.au/'>https://abr.business.gov.au/</a>
                                    <br /><br />
                                </li>
                                <li>
                                    <strong>Why am I only able to access the website after my account has been verified??</strong> <br />
                                    We need to ensure that the club's business name, ABN, liquor licence (only required for alcohol purchases) and delivery address are validated and we can legally sell to the club before a club is verified and then granted access to the website.   Verification typically takes 1 business day.
                                    <br /><br />
                                </li>
                            </ol>

                            <Header as='h3'>Products:</Header>
                            <ol type='1'>
                                <li>
                                    <strong>What products does Club Connect range?</strong> <br />
                                    We currently range packaged products from CUB, CUB Premium Beverages, Asahi Lifestyle Beverages, McPherson Wines and Smiths Snackfoods. This includes beer, cider, spirits, wine, non-alcoholic products and a selection of bar snacks. We will continue to expand the product offering based on feedback from our customers.
                                    <br /><br />
                                </li>
                                <li>
                                    <strong>Can I order kegs?</strong> <br />
                                    No. We only sell packaged product in case format. If a club requires kegs, they are generally big enough to be serviced by a CUB sales representative.
                                    <br /><br />
                                </li>
                            </ol>

                            <Header as='h3'>Price:</Header>
                            <ol type='1'>
                                <li>
                                    <strong>How do I know I am getting the best prices?</strong> <br />
                                    Club Connect monitors each states total market, ensuring that we are always offering great prices. In addition to the competitive pricing, we also offer a partnership fund on every purchase.
                                    <br /><br />
                                </li>
                            </ol>
                            
                            <Header as='h3'>Order:</Header>
                            <ol type='1'>
                                <li>
                                    <strong>Is there a minimum order quantity?</strong> <br />
                                    No! We understand that each club is different and you are free to order as little or as much as you wish.
                                    <br /><br />
                                </li>
                                <li>
                                    <strong>How do I pay for my order?</strong> <br />
                                    At this time, Club Connect only accepts direct payment from accepted cards.
                                    <br /><br />
                                </li>
                                <li>
                                    <strong>Can I order anything other than beverages and bar snacks?</strong> <br />
                                    At this stage Club Connect exclusively offers beverages to community sporting clubs. However, please contact us at <a href='mailto:admin@clubconnect.net.au'>admin@clubconnect.net.au</a> if you have some ideas as so some additional products that you would like us to range
                                    <br /><br />
                                </li>
                            </ol>

                            <Header as='h3'>Delivery:</Header>
                            <ol type='1'>
                                <li>
                                    <strong>How much is delivery?</strong> <br/>
                                    For Clubs, its free!
                                    <br/><br/>
                                </li>
                                <li>
                                    <strong>Do you deliver on weekends?</strong> <br />
                                    Yes - we have worked hard to ensure that you can get your order delivered at a time that works for you. We deliver Monday to Saturday. Currently we have three delivery windows (Monday to Saturday) which are, 10am - 1pm, 1pm - 4pm or 4pm - 7pm.
                                    <br /><br />
                                </li>
                                <li>
                                    <strong>Can I get the products delivered to an alternate address if I won't be at the club?</strong> <br />
                                    Unfortunately not - due to our Liquor Licence agreements, we must deliver to the address listed on your club’s liquor licence. However, you can choose the time and day of your delivery to make sure it is a time that works for you. 
                                    <br /><br />
                                </li>
                                <li>
                                    <strong>Do I need to be at the club to accept delivery?</strong> <br />
                                    Someone does - either you or a designated representative has to be at the club to sign for delivery. As we are often delivering alcohol, Australian law requires that someone over the age of 18 be at the premise to accept the order. 
                                    <br /><br />
                                </li>
                                <li>
                                    <strong>Do you deliver regionally?</strong> <br />
                                    Currently we only have capability to deliver 150km from the CBD in Melbourne, Sydney, Brisbane and Perth. We will look to expand into other areas as we grow.
                                    <br /><br />
                                </li>
                                <li>
                                    <strong>Who delivers the products?</strong> <br />
                                    The products are delivered by Star Track who are a division of Australia Post.
                                    <br /><br />
                                </li>
                                <li>
                                    <strong>Can I track my order? </strong> <br />
                                    You can track your order on the orders page within the website. This tracking number will take you to the Startrack website (our Club Connects logistics partner) and provide you with regular updates as to when you can expect your delivery to arrive within the selected delivery window.
                                    <br /><br />
                                </li>
                                <li>
                                    <strong>Can I pick my order up rather than have it delivered? </strong> <br />
                                    We’re afraid not. Our orders are all custom packed at our warehouse so it isn't possible to allow pick-ups. However, you can pick the time and day of your delivery to ensure it arrives at a time the works for you.
                                    <br /><br />
                                </li>
                                <li>
                                    <strong>Can I get my product delivered the day after I order it?</strong> <br />
                                    The turn around time for orders is only 24 hours. Place your order before 12pm any day of the week (excluding delivery on Saturday) to receive the next day. To receive a delivery on Saturday, you must place your order by Thursday.
                                    <br /><br />
                                </li>
                                <li>
                                    <strong>Can I choose a specific time that I want my order delivered?</strong> <br />
                                    Yes - Club Connect offers your club the option to choose the time and day of your delivery. Currently we have three delivery windows (Monday to Saturday) which are, 10am - 1pm, 1pm - 4pm or 4pm - 7pm.
                                    <br /><br />
                                </li>
                                <li>
                                    <strong>Can I get my order sent to a different address?</strong> <br />
                                    Unfortunately, due to our Liquor Licence laws, we are compelled to only deliver to the address tied to your liquor licence. 
                                    <br /><br />
                                </li>
                            </ol>

                            <Header as='h3'>Partnership Fund:</Header>
                            <ol type='1'>
                                <li>
                                    <strong>What is a partnership fund?</strong> <br />
                                    A partnership fund is a sum of money that Club Connect give back to your club on every case purchase you make. The fund amount will vary per product. This creates a discretionary fund for your club that is held on the Club Connect website which is paid into your clubs nominated bank account (more details below) at your request OR you can use towards your next Club Connect Order. This fund once paid, can be spent however the club wants to. 
                                    <br /><br />
                                </li>
                            </ol>
                        </div>
                        <div className='login maxWidth70p center marginTop20'>
                            <BackButton/>
                        </div>

                    </Grid.Column>

                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FAQS);