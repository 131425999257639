import React, { Component } from "react";
import ReactPlayer from 'react-player';
import { Grid, Modal, Icon, Popup} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { openModal, closeModal } from '../action/view';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    openModal,
    closeModal
};

class LandingFooter extends Component {
    // todo here for new landing page. We don't have the current design yet which is urgently needed
    render() {
        return (
            <Grid className='white absolute bottom small width100 left1em paddingRight30 paddingLeft30 mobileHeightFix bottom30' >
                <Grid.Row only='tablet computer' columns='5'>
                    <Grid.Column onClick={() => this.props.openModal()} className="cursor">
                        <Popup
                            trigger={
                                <Icon name='video'>
                                </Icon>
                            }
                        >
                            <Popup.Content>
                                Instructional Video
                            </Popup.Content>
                        </Popup>
                    </Grid.Column>
                    <Grid.Column>
                        
                    </Grid.Column>
                    <Grid.Column>
                        
                    </Grid.Column>
                    <Grid.Column>
                        
                    </Grid.Column>
                    <Grid.Column>
                        
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row only='mobile'>
                    <Grid.Column mobile={6} tablet={5} computer={5} className='alignLeft'>
                        <p>
                            © 2021 Club Connect
                        </p>
                    </Grid.Column>
                    <Grid.Column mobile={10} tablet={11} computer={11} className='alignRight'>
                        <Link to='/terms-conditions' className='white'>Terms & Conditions</Link>
                    </Grid.Column>
                </Grid.Row>
                <Modal
                    open={this.props.root.modalOpen}
                    onClose={() => this.props.closeModal()}
                    size='small'
                    closeIcon
                >
                    <Modal.Content className='noPadding'>
                        <ReactPlayer width='100%' controls url='/video/ClubConnect.mp4' playing />
                    </Modal.Content>
                </Modal>
            </Grid>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LandingFooter);