import React, { Component } from "react";
import { Grid, Header } from "semantic-ui-react";
import { connect } from "react-redux";
import { pageLogin, pageNotLogin } from "../action/view";
import BackButton from "../parts/BackButton";
import { Link } from "react-router-dom";

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  pageLogin,
  pageNotLogin,
};

class AugBonusCase extends Component {
  constructor(props) {
    super(props);

    this.props.pageNotLogin();
  }

  render() {
    return (
      <Grid padded className="loginBg">
        <Grid.Row>
          <Grid.Column width={16} className="marginTop30 alignCenter">
            <Header as="h2">‘AUGUST BONUS CASE’ Terms and Conditions </Header>

            <div className="maxWidth70p center padding15 alignLeft">
              <p>
                Club Connect is making available 2 x cases of beer (48 x cans)
                <strong>(Free Goods)</strong> at our discretion free of charge,
                for a limited time only, to eligible community sporting clubs.
              </p>
              <p>
                Please read these Terms & Conditions carefully before placing
                any orders on{" "}
                <a href="https://www.clubconnect.net.au">
                  https://www.clubconnect.net.au
                </a>{" "}
                <strong>(Site)</strong>.
              </p>
              <p>
                Club Connect is a business unit of CUB Pty Ltd trading as
                Carlton & United Breweries, ABN 76 004 056 106{" "}
                <strong>(CUB, we, us)</strong>.
              </p>

              <Header as="h3">1. ELIGIBILITY TO PURCHASE</Header>
              <p>
                Only community sporting clubs or their authorised representative
                are eligible to enter, provided they satisfy the following
                eligibility criteria and are not captured by an exclusion as set
                out in clause 3 below:
                <ul style={{ listStyleType: "lower-alpha" }} className="mb-4">
                  <li>
                    Hold a liquor license for legal on-sale to consumers for
                    immediate consumption in on premise venues in their State{" "}
                    <strong> (Eligible Licensed Purchaser)</strong>; and{" "}
                  </li>
                  <li>
                    Reside in Victoria, New South Wales, Queensland or Western
                    Australia; and
                  </li>
                  <li>
                    Reside within 150km of the capital city within their
                    respective State; and
                  </li>
                  <li>
                    The authorised representative must be aged 18 or over; and
                  </li>
                  <li>
                    Must be an authorised representative (eg committee member)
                    of a registered community sporting club in the relevant
                    league for their area in their State who has been nominated
                    by the committee of their club to be the entrant; and
                  </li>
                  <li>
                    Must register using the Club Connect website{" "}
                    <Link to="/">www.clubconnect.net.au</Link>; and
                  </li>
                  <li>
                    Enter the coupon code ‘AUGBONUSCASES’ at check-out for the
                    free goods to be added to the order; and
                  </li>
                  <li>
                    Register and place their first paid order before [Wednesday
                    24 August 2022, 1:00pm]. Registrations received by Club
                    Connect will be considered final. Registrations are deemed
                    to be received at the time they are received by the Club
                    Connect website and not at the time of submission by the
                    entrant. and
                  </li>
                  <li>
                    Only register once. Entrants must only enter in their own
                    name (ie their individual name, and the name of their
                    registered community sporting club). No club will be
                    entitled to have any more than one entrant and Club Connect
                    will be confirming that the entrant is the person who has
                    been nominated by the club to represent the club with the
                    club president or secretary. Entrants who enter using
                    multiple email/postal addresses, phone numbers, social media
                    accounts or aliases will be excluded. The use of any
                    automated entry software or any other mechanical or
                    electronic means that allows an entrant to automatically
                    enter repeatedly is prohibited and will render all entries
                    submitted by that entrant invalid.
                  </li>
                </ul>
              </p>
              <p>
                If we become aware that you are not an Eligible Licensed
                Purchaser we reserve the right to prevent you from ordering, or
                cancel your order as applicable.
              </p>

              <Header as="h3">2. NO RETAIL ON-SALE </Header>
              <p>
                The goods purchased and the Free Goods must not be on-sold in
                off-premise retail outlets of any kind (including, but not
                limited to, discount retail outlets or bottle-shops). Legal
                entities or individuals who order goods on the Site must only
                supply or sell the goods under the eligible categories listed
                above, directly to consumers for immediate consumption in
                on-premise venues in their State. If we become aware that you
                are, or you intend to, purchase the goods on the Site for retail
                on-sale in off premise outlets, we reserve the right to prevent
                you from ordering, or cancel your order as applicable.
              </p>

              <p>
                You must not alter, damage, remove or tamper with any packaging
                that the goods are supplied in. If you are an Eligible Licensed
                Purchaser, you warrant that you hold all necessary regulatory
                approvals (including licences) to sell the goods to consumers.
              </p>

              <p>
                We are not responsible or liable for any actions taken after
                collection of the product. Entrants and their associated clubs
                are responsible for any and all matters associated with use and
                distribution of the product after collection, including
                on-selling to consumers where applicable and only where they are
                licensed to do so. For the avoidance of doubt, the spirit of
                this promotion, in the interests of supporting grassroots
                sporting clubs in Australia, is that the product is intended for
                consumption on premise at appropriately licensed club rooms
                following all guidelines in relation to the responsible serving
                of alcohol.
              </p>

              <Header as="h3">3.EXCLUSIONS</Header>
              <p>
                The following are excluded from the promotion:
                <ul style={{ listStyleType: "lower-alpha" }} className="mb-4">
                  <li>
                    Community sporting clubs that reside in Northern Territory,
                    South Australia, Australian Capital Territory and Tasmania
                  </li>
                  <li>
                    Clubs in Victoria, New South Wales, Queensland and Western
                    Australia that are located further than 150km from your
                    respective State CBD
                  </li>
                  <li>Existing CUB customers</li>
                  <li>
                    Clubs with an existing CUB sponsorship arrangement (e.g.
                    WAFC)
                  </li>
                </ul>
              </p>

              <Header as="h3">4. YOUR DETAILS </Header>
              <p>
                In order to make purchases on the Site you will be required to
                provide your details, and confirm that you are the nominated
                representative of the community sporting club. In particular,
                you must provide your real name, phone number, e-mail address
                and other requested information as indicated, including your
                liquor license number and ABN. You are required to provide
                information that is true, accurate, current, and complete in all
                respects. Furthermore, you will be required to provide payment
                details that you represent and warrant are both valid and
                correct and you confirm that you are the person referred to in
                the billing information you provide.
              </p>
              <p>
                Please refer to our Privacy Policy for information about how we
                use your data.{" "}
                <a href="https://www.clubconnect.net.au/privacy-policy">
                  https://www.clubconnect.net.au/privacy-policy
                </a>
              </p>

              <Header as="h3">5. MINIMUM ORDERS</Header>
              <p>
                The minimum order that you need to place in order for you to
                receive your Free Goods is one item. E.g. 1 x case of Carlton
                Draught.
              </p>

              <Header as="h3">6. ACCEPTANCE OF YOUR ORDER</Header>
              <p>
                Once you have placed your paid order, you will receive an email
                acknowledging the details of your order. Your club’s Free Goods
                will be attached and delivered to your club with your paid order
                at the time you specified upon checkout. Clubs can only register
                for the Free Goods on one occasion.
              </p>

              <p>
                We reserve the right not to accept your order in the event, for
                example, that we are unable to obtain authorisation for payment,
                that shipping restrictions apply, that the item ordered is out
                of stock or does not satisfy our quality control standards and
                is withdrawn, or that you do not meet the eligibility criteria
                set out within these terms.
              </p>

              <p>
                We may also refuse to process, and therefore accept, a
                transaction for any reason or refuse service to anyone at any
                time at our sole discretion. We will not be liable to you or any
                third party by reason of our withdrawing any product from the
                Site whether or not that product has been sold, removing,
                screening or editing any materials or content on the Site,
                refusing to process a transaction or unwinding or suspending any
                transaction after processing has begun.
              </p>

              <Header as="h3">7. PAYMENT</Header>
              <p>
                The price you must pay for the paid goods on the Site will be
                the price displayed on the Site. Prices shown on the Site are in
                Australian dollars and inclusive of GST.
              </p>
              <p>
                Payment can be made by credit/debit card and must be made at the
                time of order, or the order will not be processed. You confirm
                that the credit/debit card account that is being used is yours
                or that you have been specifically authorised by the owner of
                the credit/debit card account to use it. All credit/debit card
                holders are subject to validation checks and authorisation by
                the card issuer. If the issuer of your payment card refuses to
                authorise payment to us, we will not be liable for any delay or
                non-delivery. We are not responsible for any fees, charges or
                penalties that your bank may charge it in the event we charge an
                amount on your nominated credit/debit card which exceeds the
                amount of funds currently deposited in that account or exceeds
                credit limits.
              </p>
              <p>
                We take reasonable care to make our Site secure. All
                credit/debit card transactions on this site that are processed
                using Braintree’s secure online payment gateway, encrypts your
                card details in a secure host environment. We recommend that you
                take appropriate security precautions when accessing the
                Internet via public Wi-Fi networks or shared computers.
              </p>

              <Header as="h3">8. DELIVERY</Header>
              <p>
                Delivery of free and paid goods is free. Each club's allocation
                of Free Goods will be included as part of the club's first paid
                order.
              </p>
              <p>
                We utilise a courier delivery network that allows you to select
                the time that you want your paid and Free Goods to be delivered
                to your club. You can select your preferred delivery day and
                time slot during the checkout process. Your order shipment
                confirmation will contain tracking details.
              </p>
              <p>
                In order to comply with liquor laws an adult over the age of 18
                years must be present to accept the delivery and will be
                required to present appropriate identification and the ID must
                match the delivery instructions. If you're not present at the
                time of delivery, a redeliver fee may be charged by us, or your
                order may be cancelled.
              </p>

              <Header as="h3">9. TITLE & RISK</Header>
              <p>
                Title to, and property in, any Goods supplied to you remain with
                us and will only pass to you once all moneys owing to us have
                been paid in full.{" "}
              </p>
              <p>
                Risk in the Goods passes to you upon the Goods being removed
                from our premises (or that of our supplier) for delivery to, or
                collection by, you.{" "}
              </p>
              <p>
                You are responsible for arranging, and taking out in its own
                name and cost, any insurance in respect of the Goods from the
                time risk passes to you.{" "}
              </p>
              <p>
                In the limited circumstances where you are required to return
                any Goods to us, risk in the Goods passes to us on confirmation
                of receipt of the Goods by us.{" "}
              </p>
              <p>
                Until full title in and ownership of the Goods passes to you,
                and while the Goods remain in your full control and possession,
                you hold the Goods as our bailee and must not sell, lease,
                dispose of or otherwise deal with the Goods in any way without
                CUB’s prior written consent.
              </p>

              <Header as="h3">10. RETURNS, EXCHANGES & REFUNDS </Header>
              <p>
                In the event you receive goods that are damaged or faulty,
                please contact us (
                <a href="mailto:admin@clubconnect.net.au">
                  admin@clubconnect.net.au
                </a>
                ) within a reasonable time and we will replace the value of the
                damaged goods. We reserve the right to require proof that the
                goods were damaged or faulty.
              </p>
              <p>
                Please refer to the refunds and returns policy on the Club
                Connect website for more details.{" "}
                <a href="https://www.clubconnect.net.au/refund-policy">
                  https://www.clubconnect.net.au/refund-policy
                </a>
              </p>

              <Header as="h3">11. YOUR ACTIVITY</Header>
              <p>
                You agree that you will be personally responsible for your use
                of this Site and for all of your communication and activity on
                and pursuant to this Site. If we determine that you are or have
                been engaged in prohibited activities, are not respectful of
                other users, or otherwise violated these terms, we may deny you
                access to this Site on a temporary or permanent basis.
              </p>

              <Header as="h3">12. MARKETING </Header>
              <p>
                We may send you information, advertising, promotional or other
                marketing material from time to time. This material may be in
                relation to the goods you have ordered, or other products or
                information that we may consider to be relevant, useful or
                otherwise informative to you. To unsubscribe from receiving this
                information, please contact us at{" "}
                <a href="mailto:admin@clubconnect.net.au">
                  admin@clubconnect.net.au{" "}
                </a>
              </p>

              <Header as="h3">13. INDEMNITY</Header>
              <p>
                You hereby indemnify us, and keep us indemnified against all
                actions, claims, proceedings or demands which may be brought
                against us, arising out of any breach of these terms by you.
                Furthermore, if you breach these clauses, in addition to any
                other rights we may have, any pending orders will be cancelled
                and not delivered.
              </p>

              <Header as="h3">14. SITE USE DISCLAIMER</Header>
              <p>
                The Site is provided by us in good faith but we do not make any
                representations or warranties of any kind, express or implied,
                in relation to all or any part of the Site or the Site’s
                contents, and all warranties and representations are hereby
                excluded to the extent permitted by law. The contents of the
                Site do not constitute advice and should not be relied upon in
                making, or refraining from making, any decision. To the extent
                permitted by law, we hereby disclaim all liability (howsoever
                arising) in connection with any loss and/or damage, arising out
                of or in connection with any use of, or inability to use, all or
                any part of the Site’s content, the Site and/or any website to
                which the Site is linked, or any action taken (or refrained from
                being taken) as a result of using any of these.{" "}
              </p>

              <Header as="h3">15. MISCELLANEOUS</Header>
              <p>
                You acknowledge and agree that you have not relied on any
                representations, inducements or statements made to it by us
                regarding the supply of the Goods and you have satisfied
                yourself that the Goods are fit for the purpose for which you
                require the Goods.
              </p>
              <p>
                If any provision of these terms is void, unenforceable or
                illegal and would not be so if words were omitted, then those
                words are to be severed and if this cannot be done, the entire
                provision is to be severed from these terms without affecting
                the validity or enforceability of the remaining provisions.{" "}
              </p>
              <p>
                A failures to exercise or delay in exercising any rights under
                these terms does not constitute a waiver and any right may be
                exercised in the future. Waiver of any of these terms must be in
                writing and is only effective to the extent set out in that
                written waiver.
              </p>
            </div>
            <div className="login maxWidth70p center marginTop20">
              <BackButton />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AugBonusCase);
