import axios from 'axios';
import Cookies from 'universal-cookie';
import { encrypt } from './Utils';

const cookies = new Cookies();

export function registerUserToClub(callback, objectArray = {}) {
    const email = objectArray.email ? objectArray.email : '';
    const password = objectArray.password ? objectArray.password : '';
    const c_password = objectArray.c_password ? objectArray.c_password : '';
    const user_name = objectArray.user_name ? objectArray.user_name : '';
    const club_user_id = objectArray.club_user_id ? objectArray.club_user_id : '';
    const club_code = objectArray.club_code ? objectArray.club_code : '';
    const contact_number = objectArray.contact_number ? objectArray.contact_number : '';

    const options = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/register_to_existing_club',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        data: {
            user_name: user_name,
            email: email,
            password: encrypt(password),
            c_password: encrypt(c_password),
            club_user_id: club_user_id,
            club_code: club_code,
            phone: contact_number
        },
    }

    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch((res) => {
        callback();
        alert('This email address or club name has already been registered.');
    })
}

export function registerUser(callback, objectArray = {}) {
    const email = objectArray.email ? objectArray.email : '';
    const password = objectArray.password ? objectArray.password : '';
    const c_password = objectArray.c_password ? objectArray.c_password : '';
    const name = objectArray.name ? objectArray.name : '';
    const phone = objectArray.phone ? objectArray.phone : '';
    const role = objectArray.role ? objectArray.role : '';
    const club_id = objectArray.club_id ? objectArray.club_id : '';
    const age = objectArray.age ? objectArray.age : '';
    const gender = objectArray.gender ? objectArray.gender : '';
    const sport_type = objectArray.sport_type ? objectArray.sport_type : '';
    const league = objectArray.league ? objectArray.league : '';
    const license_number = objectArray.license_number ? objectArray.license_number : '';
    const clubRelationship = objectArray.clubRelationship ? objectArray.clubRelationship : '';
    const abn = objectArray.abn ? objectArray.abn : '';
    const shipping_address = objectArray.shipping_address ? objectArray.shipping_address : '';
    const suburb = objectArray.suburb ? objectArray.suburb : '';
    const state = objectArray.state ? objectArray.state : '';
    const postcode = objectArray.postcode ? objectArray.postcode : '';

    const licence_name = objectArray.licence_name ? objectArray.licence_name : '';
    const bsb = objectArray.bsb ? objectArray.bsb : '';
    const account_num = objectArray.account_num ? objectArray.account_num : '';
    const account_name = objectArray.account_name ? objectArray.account_name : '';

    const user_name = objectArray.user_name ? objectArray.user_name : '';

    const invite_code = objectArray.invite_code ? objectArray.invite_code : '';
    const club_user_id = objectArray.club_user_id ? objectArray.club_user_id : '';

    const season = objectArray.season ? objectArray.season : 0;

    const options = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/register',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        data: {
            user_name: user_name,
            email: email,
            password: encrypt(password),
            c_password: encrypt(c_password),
            name: name,
            phone: phone,
            role: role,
            club_id: club_id,
            age: age,
            gender: gender,
            sport_type: sport_type,
            league: league,
            license_number: license_number,
            abn: abn,
            shipping_address: shipping_address,
            suburb: suburb,
            state: state,
            postcode: postcode,
            licence_name: licence_name,
            bsb: bsb,
            account_num: account_num,
            account_name: account_name,
            clubRelationship: clubRelationship,
            inviteCode: invite_code,
            club_user_id: club_user_id,
            season: season
        },
    }

    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch((res) => {
        callback();
        alert('This email address or club name has already been registered.');
    })
}

export function registerUserBlank(callback, objectArray = {}) {
    const email = objectArray.email ? objectArray.email : '';
    const name = objectArray.name ? objectArray.name : '';
    const phone = objectArray.phone ? objectArray.phone : '';
    const role = objectArray.role ? objectArray.role : '';
    const club_id = objectArray.club_id ? objectArray.club_id : '';
    const age = objectArray.age ? objectArray.age : '';
    const gender = objectArray.gender ? objectArray.gender : '';
    const sport_type = objectArray.sport_type ? objectArray.sport_type : '';
    const league = objectArray.league ? objectArray.league : '';
    const license_number = objectArray.license_number ? objectArray.license_number : '';
    const clubRelationship = objectArray.clubRelationship ? objectArray.clubRelationship : '';
    const abn = objectArray.abn ? objectArray.abn : '';
    const shipping_address = objectArray.shipping_address ? objectArray.shipping_address : '';
    const suburb = objectArray.suburb ? objectArray.suburb : '';
    const state = objectArray.state ? objectArray.state : '';
    const postcode = objectArray.postcode ? objectArray.postcode : '';

    const licence_name = objectArray.licence_name ? objectArray.licence_name : '';
    const bsb = objectArray.bsb ? objectArray.bsb : '';
    const account_num = objectArray.account_num ? objectArray.account_num : '';
    const account_name = objectArray.account_name ? objectArray.account_name : '';

    const user_name = objectArray.user_name ? objectArray.user_name : '';

    const options = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/register_blank',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        data: {
            user_name: user_name,
            email: email,
            name: name,
            phone: phone,
            role: role,
            club_id: club_id,
            age: age,
            gender: gender,
            sport_type: sport_type,
            league: league,
            license_number: license_number,
            abn: abn,
            shipping_address: shipping_address,
            suburb: suburb,
            state: state,
            postcode: postcode,
            licence_name: licence_name,
            bsb: bsb,
            account_num: account_num,
            account_name: account_name,
            clubRelationship: clubRelationship
        },
    }

    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch((res) => {
        callback();
        alert('This email address or club name has already been registered.');
    })
}

export function checkClubCode(callback, referral_code) {

    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/check_club_code',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        params: {
            referral_code: referral_code
        },
    }

    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch((error) => {
        alert('There is no club with this code.');
    })
}

export function checkClubName(callback, name) {

    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/get_club',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        params: {
            name: name
        },
    }

    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch((error) => {
        alert('This club name has already been registered.');
    })
}

export function checkUserEmail(callback, email) {

    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/get_email',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        params: {
            email: email
        },
    }

    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch((error) => {
        alert('This email address has already been registered.');
    })
}