import React, { Component } from "react";
import { Grid, Header, Icon } from 'semantic-ui-react';
import { connect } from "react-redux";
import { pageLogin, pageNotLogin } from '../action/view';
import BackButton from "../parts/BackButton";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageLogin,
    pageNotLogin
};

class NotFound extends Component {
    constructor(props) {
        super(props);

        this.props.pageNotLogin();
    }

    render() {

        return (
            <Grid padded className='loginBg'>
                <Grid.Row>
                    <Grid.Column width={16} className='marginTop30 alignCenter'>
                        <Header as='h2' icon>
                            <Icon name='frown outline' />
                            404! Page Not Found
                            
                            <Header.Subheader>
                                The page you tried to access is not available. Please go back.
                            </Header.Subheader>
                        </Header>

                        <div className='login maxWidth70p center marginTop20'>
                            <BackButton />
                        </div>

                    </Grid.Column>

                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotFound);