import React, { Component } from "react";
import { Grid, Header } from 'semantic-ui-react';
import { connect } from "react-redux";
import { pageLogin, pageNotLogin } from '../action/view';
import BackButton from "../parts/BackButton";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageLogin,
    pageNotLogin
};

class Testimonials extends Component {
    constructor(props) {
        super(props);

        this.props.pageNotLogin();
    }

    render() {
        return (
            <Grid padded className='loginBg'>
                <Grid.Row>
                    <Grid.Column width={16} className='marginTop30 alignCenter'>
                        <Header as='h2'>
                            Testimonials
                        </Header>

                        <div className='maxWidth70p center padding15 alignLeft'>
                            <Header as='h3'>Stacey, Treasurer, Moorebank Liverpool District Hockey Club</Header>
                            <p>
                                Club Connect has significantly improved our ability to manage the price we pay for alcohol, resulting in a better return for the Club.  As a volunteer-based Club, the usage of our account with Club Connect is also a huge timesaver.  We particularly appreciate the delivery service, especially doing so out of normal business hours, so our volunteers can be on hand to accept the delivery.
                            </p>

                            <Header as='h3'>Bob, Bar Manager, Balnarring Bowls & Social Club</Header>
                            <p>
                                Club Connect is definitely an easier solution for ordering most of the beverages for our bar. It was simple to sign-up, and we can now place the beverage order online and choose the date and time for the delivery to arrive, we also appreciate the updates with the delivery time. We have placed orders for CUB brand beers, non-alcoholic beer and also from their range of pre-mixed drinks. We've been accumulating the Partnership Fund and looking forward to accessing this for additional fundraising for the Club shortly.
                            </p>

                            <Header as='h3'>Greg, Club President, Greensborough Hockey Club</Header>
                            <p>
                                We were looking for an easier solution for our volunteers when sourcing our beverages, and very happy to have found Club Connect. We have been ordering weekly from the great range of beer, cider, RTD’s and sports drink and appreciate the updates with the delivery time – always arriving within the Friday delivery window before our home matches. Club Connect has saved us a lot of time and money this year, it was simple to sign-up and place the orders and we’re looking forward to accessing the sponsorship fund at the end of the year. With such an easy process in place, we know it will be easier to attract more volunteers to help out at the Club moving forward.
                            </p>

                            <Header as='h3'>Neil, Vice President, Mooroolbark Football Club</Header>
                            <p>
                                We started using Club Connect at the start of the season and have been really happy with the service. We’ve ordered from the great range of beer, cider and RTD’s and appreciate the updates with the delivery time – always arriving within the Thursday delivery window before our home matches. Club Connect has saved us a lot of time and money this year, it was simple to sign-up and place the orders and we’re looking forward to accessing the sponsorship fund at the end of the year.
                            </p>
                        </div>


                        <div className='login maxWidth70p center marginTop20'>
                            <BackButton />
                        </div>

                    </Grid.Column>

                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Testimonials);