import React, { Component } from "react";
import { Grid, Placeholder, Header, Button, Icon } from 'semantic-ui-react';
import Slider from 'react-slick';
import { ColorExtractor } from "react-color-extractor";
import { Link } from "react-router-dom";
import { formatCurrency } from "../utils/Utils";

const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1.3,
    slidesToScroll: 1,
    arrows: false,
    lazyLoad: 'progressive',
    pauseOnDotsHover: true,
    pauseOnFocus: true,
    className: 'productFeature',
    swipeToSlide: true
};

export default class ProductFeatures extends Component {
    constructor(props) {
        super(props);
        this.state = {
            colors: [],
        }
    }

    loadingProduct(recentViews, isLoading) {
        if (isLoading === true) {
            const arrayLoop = [1, 2, 3, 4];

            return (
                <>
                    {
                        arrayLoop.map((value, key) => {
                            return (
                                <Grid.Column key={key} only='tablet computer' tablet={16} computer={8} className='relative marginBottom40'>
                                    <div className='bgWhite padding30'>
                                        <Placeholder>
                                            <Placeholder.Header>
                                                <Placeholder.Line />
                                                <Placeholder.Line />
                                            </Placeholder.Header>
                                            <Placeholder.Image />
                                            <Placeholder.Paragraph>
                                                <Placeholder.Line length='medium' />
                                                <Placeholder.Line length='short' />
                                            </Placeholder.Paragraph>
                                        </Placeholder>
                                    </div>
                                </Grid.Column>
                            )
                        })
                    }
                </>
            )
        } else {
            if (recentViews.length > 0) {
                return (
                    <>
                        {
                            recentViews.map((value, key) => {
                                if (key < 4) {
                                    let colorBorder = '8px solid ' + (this.state.colors[key] !== undefined ? this.state.colors[key] : '#F7942D');

                                    return (
                                        <Grid.Column key={key} className='relative marginBottom40' only='tablet computer' tablet={16} computer={8}>
                                            <div className='bgWhite padding30' style={{ borderTop: colorBorder }}>
                                                <Grid>
                                                    <Grid.Row columns={2} verticalAlign='middle'>
                                                        <Grid.Column verticalAlign='middle' className='paddingRight2em'>
                                                            <ColorExtractor getColors={colors => { let currentColor = [...this.state.colors]; currentColor[key] = colors[0]; this.setState(state => ({ colors: [...currentColor] })) }}>
                                                                <img alt='productimg' className='ui centered image' src={value.image_link} />
                                                            </ColorExtractor>
                                                        </Grid.Column>
                                                        <Grid.Column verticalAlign='middle'>
                                                            <Header className='big black relative marginBottom'>
                                                                <Link className='black' to={'/product/' + value.slug}>
                                                                    {value.name}
                                                                    <Header.Subheader className='noMargin large'>
                                                                        {value.quantity_type}
                                                                    </Header.Subheader>
                                                                    <Header.Subheader className='noMarginLeft marginTop15'>
                                                                        <strong>{formatCurrency(value.price)}</strong>
                                                                    </Header.Subheader>
                                                                    <Header.Subheader className='noMarginLeft'>
                                                                        <p className='small cub-yellow miniFont bold'>{'+ ' + formatCurrency(value.rebate) + ' Credit'}</p>
                                                                    </Header.Subheader>

                                                                </Link>
                                                            </Header>
                                                            <Button icon labelPosition='right' className='bgBlack height51px lineHeight30px noBorderRadius noMargin white marginTop10' href={'/product/' + value.slug}>
                                                                SHOP NOW
                                                                <Icon name='right arrow' />
                                                            </Button>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </div>
                                        </Grid.Column>
                                    )
                                } else {
                                    return null;
                                }

                            })
                        }
                    </>
                )
            } else {
                return null
            }
        }
    }

    loadingProductSlide(recentViews, isLoading) {
        if (isLoading === true) {
            const arrayLoop = [1, 2, 3, 4];

            return (
                <Slider {...settings} className='productFeatureSlider'>
                    {
                        arrayLoop.map((value, key) => {
                            if (key < 4) {
                                return (
                                    <div className='relative productSliderCustom' key={key}>
                                        <Grid columns='equal'>
                                            <Grid.Column className='productSlide bgWhite relative margin25'>
                                                <Placeholder>
                                                    <Placeholder.Header>
                                                        <Placeholder.Line />
                                                        <Placeholder.Line />
                                                    </Placeholder.Header>
                                                    <Placeholder.Image />
                                                    <Placeholder.Paragraph>
                                                        <Placeholder.Line length='medium' />
                                                        <Placeholder.Line length='short' />
                                                    </Placeholder.Paragraph>
                                                </Placeholder>
                                            </Grid.Column>
                                        </Grid>
                                    </div>
                                )
                            } else {
                                return null
                            }
                        })
                    }
                </Slider>
            )
        } else {
            if (recentViews.length > 0) {
                return (
                    <Slider {...settings} className='productFeatureSlider'>
                        {
                            recentViews.map((value, key) => {
                                if (key < 4) {
                                    let colorBorder = '8px solid ' + (this.state.colors[key] !== undefined ? this.state.colors[key] : '#F7942D');
                                    return (
                                        <div className='relative productSliderCustom' key={key}>
                                            <Grid columns='equal'>
                                                <Grid.Column className='bgWhite relative margin25' style={{ borderTop: colorBorder }}>

                                                    <Grid>
                                                        <Grid.Row columns={1} verticalAlign='middle'>
                                                            <Grid.Column verticalAlign='middle'>
                                                                <ColorExtractor getColors={colors => { let currentColor = [...this.state.colors]; currentColor[key] = colors[0]; this.setState(state => ({ colors: [...currentColor] })) }}>
                                                                    <img alt='productimg' className='ui image productImage' src={value.image_link} />
                                                                </ColorExtractor>
                                                            </Grid.Column>
                                                            <Grid.Column verticalAlign='middle'>
                                                                <Header className='largeMedium black relative paddingRight30 marginBottom' style={{ minHeight: '95px' }}>
                                                                    <Link className='black' to={'/product/' + value.slug}>
                                                                        {value.name}
                                                                        <Header.Subheader className='noMarginLeft large'>
                                                                            {value.quantity_type}
                                                                        </Header.Subheader>
                                                                    </Link>
                                                                </Header>
                                                                <Button icon labelPosition='right' className='bgBlack height51px lineHeight30px noBorderRadius noMargin white marginTop10' href={'/product/' + value.slug}>
                                                                    SHOP NOW
                                                                    <Icon name='right arrow' />
                                                                </Button>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>

                                                </Grid.Column>
                                            </Grid>
                                        </div>
                                    )
                                } else {
                                    return null;
                                }
                            })
                        }
                    </Slider>
                )
            } else {
                return null
            }
        }
    }

    render() {
        const recentViews = this.props.data;
        const isLoading = this.props.isLoading;

        return (
            <Grid className='marginTop10'>
                <Grid.Row stretched only='tablet computer'>
                    {this.loadingProduct(recentViews, isLoading)}
                </Grid.Row>
                <Grid.Row only='mobile'>
                    <Grid.Column className='noPadding'>
                        {this.loadingProductSlide(recentViews, isLoading)}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}