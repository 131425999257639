import React, { Component } from "react";
import { Grid, Header, Image } from 'semantic-ui-react';
import { formatCurrency } from '../utils/Utils';

export default class ListCartItem extends Component {
    render() {
        const cartItems = this.props.data;

        return cartItems.map((value, key) => {
            return (
                <Grid.Row key={key} verticalAlign='middle'>
                    <Grid.Column width={2} className='noPaddingRight'>
                        <div className='orderItem'>
                            <Image src={value.image_link} className='cartProduct'/>
                        </div>
                    </Grid.Column>

                    <Grid.Column width={3}>
                        <p className='small'>{value.quantity} x</p>
                    </Grid.Column>

                    <Grid.Column width={7} className='noPaddingLeft'>
                        <Header className='small'>
                            {value.name}
                            <Header.Subheader className='lightGray small noMargin'>
                                {value.quantity_type}
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>

                    <Grid.Column width={4} textAlign='right' className='noPaddingLeft'>
                        <strong className='primaryColor small'>{formatCurrency(value.price * value.quantity)}</strong>
                    </Grid.Column>
                </Grid.Row>
            )
        });
    }
}