import CryptoJS from "crypto-js";
import ReactGA from 'react-ga';

export function ValidateEmail(email) {
    const re = /^(([^<>()\\[\]\\.,;:\s@\\"]+(\.[^<>()\\[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i;

    if(re.test(email)) {
        return true;
    } else {
        alert('You have entered an invalid email address.');
        return false;
    }
};

export function ValidateABN(abn) {
    //const re = /^(\d *?){11}$/i;

    //if (re.test(abn)) {
        return true;
    //} else {
    //    alert('You have entered an invalid ABN.');
    //    return false;
    //}
};

export const trackPage = (page) => {
    ReactGA.set({
      page
    });
    ReactGA.pageview(page);
};

export function encrypt(data) {
    let iv = CryptoJS.lib.WordArray.random(16);
    const key = CryptoJS.enc.Base64.parse(process.env.REACT_APP_API_KEY);
    const options = {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    };

    let encrypted = CryptoJS.AES.encrypt(data, key, options);
    encrypted = encrypted.toString();
    iv = CryptoJS.enc.Base64.stringify(iv);
    let result = {
        iv: iv,
        value: encrypted,
        mac: CryptoJS.HmacSHA256(iv + encrypted, key).toString()
    }
    result = JSON.stringify(result);
    result = CryptoJS.enc.Utf8.parse(result);

    return CryptoJS.enc.Base64.stringify(result);
};

export function htmlEntities(str) {
    return encodeURI(str);
}

export function ValidateLicenceNumber(license_number) {
    const re = /[!@#$%^&*(),.?":{}|<>]/g;

    if (re.test(license_number)) {
        alert('You have entered an invalid licence number. Please enter only 1 licence number');
        return false;
    } else {
        return true;
    }
};

export function ValidatePhoneNumber(num) {
    const re = /(\(+61\)|\+61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){6,9}/i;

    if (re.test(num)) {
        return true;
    } else {
        alert('You have entered an invalid phone number.');
        return false;
    }
};

export function ValidateBankAccount(bankAcc) {
    const re = /^[0-9]{6,10}$/i;

    if (re.test(bankAcc)) {
        return true;
    } else {
        alert('You have entered an invalid bank account.');
        return false;
    }
};

export function ValidatePassword(password) {
    const re = /^.{6,}$/i;

    if (re.test(password)) {
        return true;
    } else {
        alert('Your password should be more than 6 characters.');
        return false;
    }
};


export function ValidateBSB(bsb) {
    const re = /\d{3}-?\d{3}/i;

    if (re.test(bsb)) {
        return true;
    } else {
        alert('You have entered an invalid BSB.');
        return false;
    }
}

export function formatCurrency(num, locale = 'en-AU', currency = 'AUD', minimumFractionDigits = 2) {
    if (isNaN(num)) {
        num = 0;
    }

    return parseFloat(num).toLocaleString(locale, { style: 'currency', currency, minimumFractionDigits });
};

export function calculateGST(num) {
    if (isNaN(num)) {
        return num;
    }
    return num/11;
}

export function calculateWET(num) {
    if (isNaN(num)) {
        return num;
    }
    return (num - num/1.29);
}