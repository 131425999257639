import React, { Component } from "react";
import { Header, Image, Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { openModal, slideRegister } from '../action/view';
import { WELCOME, WELCOME_NONALC } from '../Constants';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    openModal,
    slideRegister
};

class LandingPart extends Component {
    render() {
        const settings = {
            dots: true,
            arrows: false,
            infinite: true,
            speed: 3000,
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            lazyLoad: 'progressive',
            pauseOnDotsHover: false,
            pauseOnFocus: true,
            className: 'landing',
            swipeToSlide: true
        };



        return (
            <div className='relative'>
                <div className='top left alignRight md:hidden my-4'>
                    <p className='fontSize16px white marginTop15 noMarginBottom alignRight'>Already a member?
                        <Link className='marginLeft10 bgYellow white hoverDarkenYellowBg inlineFlex padding10-20 noDecoration ' to='/login'>Sign In</Link>
                    </p>
                </div>
                <div>
                    <Header inverted className='white marginTop10 marginBottom20 headerLarge homeHeaderWelcome flex align-top flex-col lg:flex-row lg:pr-12'>
                        <Link to='/'><Image src='/img/ClubConnect_Landscape.svg' className='w-24 h-auto mr-11 flex-1 lg:mt-8' /></Link>
                        <div className="text2xl lg:text-4xl mt-8 lg:mt-0 font-semibold flex-1 leading-tight">Supporting community sport through convenient beverage delivery
                            <hr className="h-1 bg-white max-w-100 mt-6 lg:mb-14" />
                        </div>
                    </Header>
                </div>

                <div>
                    {
                        this.props.nonAlc ?
                            WELCOME_NONALC.map((value, key) => (
                                <div key={key} className="hidden lg:mb-12 md:flex-col lg:flex-row md:flex md:visible md:content-center">
                                    <div className="mr-11">
                                        <Image className="w-28 h-auto" src={value.image} fluid />
                                    </div>
                                    <div className="flex flex-col w-full justify-center">
                                        <Header inverted color='black' size='large' className='marginTop5 noMarginBottom'>
                                            <h1 className='white text-xl lg:text-2xl'>{value.title}</h1>
                                        </Header>
                                        <p className='text-white text-base lg:text-xl'>{value.subtitle}</p>
                                    </div>
                                </div>
                            ))
                            :
                            WELCOME.map((value, key) => (
                                <div key={key} className="hidden lg:mb-12 md:flex-col lg:flex-row md:flex md:visible md:content-center">
                                    <div className="mr-11">
                                        <Image className="w-28 h-auto" src={value.image} fluid />
                                    </div>
                                    <div className="flex flex-col w-full justify-center">
                                        <Header inverted color='black' size='large' className='marginTop5 noMarginBottom'>
                                            <h1 className='white text-xl lg:text-2xl'>{value.title}</h1>
                                        </Header>
                                        <p className='text-white text-base lg:text-xl'>{value.subtitle}</p>
                                    </div>
                                </div>
                            ))
                    }
                    <div className="md:hidden mb-12">

                        <Slider {...settings}>
                            {
                                this.props.nonAlc ?
                                    WELCOME_NONALC.map((value, key) => (
                                        <div key={key}>
                                            <div className='width100px'>
                                                <Image src={value.image} fluid />
                                            </div>
                                            <div>
                                                <Header inverted color='black' size='large' className='center marginTop5 noMarginBottom'>
                                                    <h1 className='home-icon-title'>{value.title}</h1>
                                                </Header>
                                                <p className='home-icon-subtitle'>{value.subtitle}</p>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    WELCOME.map((value, key) => (
                                        <div key={key}>
                                            <div className='width100px'>
                                                <Image src={value.image} fluid />
                                            </div>
                                            <div>
                                                <Header inverted color='black' size='large' className='center marginTop5 noMarginBottom'>
                                                    <h1 className='home-icon-title'>{value.title}</h1>
                                                </Header>
                                                <p className='home-icon-subtitle'>{value.subtitle}</p>
                                            </div>
                                        </div>
                                    ))
                            }
                        </Slider>
                    </div>


                    <div className="md:hidden">
                        <div>
                            {/*<Maintainance color='white' />/*}
                            {/*<p className='largeMedium white'>Register now and you will receive <span className='yellowText'>5 free cases</span> of beer for your club when you place your first order.</p>*/}

                            <Button animated fluid className='regular bgYellow white minHeight40 hoverDarkenYellowBg' onClick={() => this.props.slideRegister()} >
                                <Button.Content>
                                    Sign Up
                                </Button.Content>
                            </Button>

                            <div mobile={12} className='noPadding text-center marginTop40 mb-4'>
                                <div className='inline white large'>Any Questions?</div>
                                <a href='mailto:admin@clubconnect.net.au' className='marginLeft10 inline large yellowText noDecoration'>Contact</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LandingPart));