import React, { Component } from "react";
import {
  Grid,
  Header,
  Icon,
  Button,
  Image,
  Dimmer,
  Loader,
  Message,
} from "semantic-ui-react";
import CheckoutCustomer from "../parts/CheckoutCustomer";
import ShippingAddress from "../parts/ShippingAddress";
import Delivery from "../parts/Delivery";
import PaymentMethod from "../parts/PaymentMethod";
import OrderComplete from "../parts/OrderComplete";
import CartSummary from "../parts/CartSummary";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { pageLogin, pageNotLogin } from "../action/view";
import { getCart, addCouponProducts } from "../action/cart";
import { getDeliveryWindows } from "../utils/OrderAPI";
import { submitCheckout } from "../utils/PaymentAPI";
import { getSurvey } from "../utils/UserAPI";
import TagManager from "react-gtm-module";

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  pageLogin,
  pageNotLogin,
  getCart,
  addCouponProducts,
};

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.scrollEl = React.createRef();

    this.state = {
      selected_delivery_time: false,
      delivery_windows: [],
      surveys: [],
      delivery_time: "",
      delivery_date: "",
      delivery_note: "",
      loading: false,
      submitLoad: false,
      order_success: false,
      selected_date: "",
    };

    this.props.pageLogin("checkout");
  }

  componentDidMount() {
    if (this.props.cart.firstLoading === true) {
      this.props.getCart();
      this.props.addCouponProducts();
    }

    getDeliveryWindows((result) => {
      this.setState({
        delivery_windows: result,
      });
    });

    getSurvey((result) => {
      this.setState({
        surveys: result,
      });
    }, "checkout");
  }

  scrollToBottom() {
    if (this.state.delivery_time) {
      setTimeout(() => {
        this.scrollEl.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 200);
    }
  }

  handleSubmitCheckout(totalCartPrice, nonce, cardStatus, partnershipFund) {
    this.setState({
      loading: true,
    });

    let errorFlag = false;

    const deliveryTime = this.state.delivery_time;
    const deliveryDate = this.state.delivery_date;
    const deliveryNote = this.state.delivery_note;
    const cartId = this.props.cart.cartItems[0].cart_id;

    const today = new Date();
    const currentTime = today.getHours();
    const tomorrowDate = new Date(today);
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);

    const saturdayOrderTime = 19;
    const cutoffOrder = 13;

    // if selected date is tomorrow and the current time is >= 13
    if (this.state.selected_date.includes(tomorrowDate.getDate())) {
      if (currentTime >= cutoffOrder) {
        alert(
          "Sorry, the cutoff time for tomorrow delivery is before 13PM AEST."
        );
        errorFlag = true;
      }
    } else if (this.state.selected_date.includes("Sat")) {
      // selected date is Saturday
      if (currentTime >= saturdayOrderTime && today.getDay() >= 4) {
        alert(
          "Sorry, the cutoff time for saturday delivery is before 19PM AEST and on Thursday."
        );
        errorFlag = true;
      }
    }

    if (errorFlag === false) {
      submitCheckout(
        (res) => {
          this.setState({
            loading: false,
            submitOrder: false,
          });

          if (res.code === 201) {
            alert(res.message);
          } else {
            this.setState({
              order_success: true,
            });
            TagManager.dataLayer({
              dataLayer: {
                ecommerce: null,
              },
            });
            TagManager.dataLayer({
              dataLayer: {
                event: "purchase",
                ecommerce: {
                  value: totalCartPrice,
                  items: this.props.cart.cartItems,
                },
              },
            });
          }
        },
        totalCartPrice,
        nonce,
        deliveryTime,
        deliveryDate,
        deliveryNote,
        cartId,
        cardStatus,
        partnershipFund
      );
    }
  }

  render() {
    let deliveryDate = this.state.delivery_windows;
    
    if (Date.now() <= Date.parse("07/01/2023") && this.props.user.club.state === 'QLD') {
      return <Grid container>
            <Grid.Row
              verticalAlign="middle"
              textAlign="center"
              style={{ height: "150px" }}
              className="zIndex1 top left bgGradientGray"
            >
              <Grid.Column verticalAlign="middle" width={16}>
                <Link to="/" onClick={() => this.props.pageNotLogin()}>
                  <Image
                    id="GoToHome"
                    inline
                    style={{ width: "7em" }}
                    src="/img/ClubConnect_Landscape.svg"
                  />
                </Link>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column verticalAlign="middle" width={16}>
                <Message className="text-center">
                  <Header className="largeMedium text-center">Important Notice for Queensland Customers</Header>
                  <p>
                    Club Connect is temporarily unable to accept orders in 
                    Queensland due to an unexpected interruption to our 
                    logistics and fulfilment capabilities, outside of our 
                    control. We are working hard to resolve this and expect to 
                    be back online from the 1st of July, 2023.
                    <br />
                    In the meantime, if you have any questions, please contact us at <a href="mailto: admin@clubconnect.net.au">admin@clubconnect.net.au</a>.
                    </p>
                  <Link to="/">Return Home</Link>
                </Message>
              </Grid.Column>
            </Grid.Row>
        </Grid>
    }

    if (this.state.order_success) {
      return (
        <OrderComplete
          survey={this.state.surveys.length > 0 ? this.state.surveys[0] : null}
        />
      );
    } else {
      if (
        this.props.cart.firstLoading === false &&
        this.props.cart.cartItems.length === 0
      ) {
        return (
          <Grid container>
            <Grid.Row
              verticalAlign="middle"
              textAlign="center"
              style={{ height: "150px" }}
              className="zIndex1 fixed top left bgGradientGray"
            >
              <Grid.Column verticalAlign="middle" width={16}>
                <Link to="/" onClick={() => this.props.pageNotLogin()}>
                  <Image
                    inline
                    size="tiny"
                    src="/img/ClubConnect_Landscape.svg"
                  />
                </Link>
              </Grid.Column>
            </Grid.Row>

            <Header
              className="small noMargin primaryColor"
              style={{ paddingTop: "150px" }}
            >
              Checkout
            </Header>

            <Grid.Row verticalAlign="middle" centered>
              <Grid.Column width={16} textAlign="center">
                <div
                  className="section card borderRadius10 bgWhite shadow20 padding15"
                  style={{ height: "60vh", marginBottom: "1em" }}
                >
                  <Header className="largeMedium lightGray noMargin">0</Header>
                  <Icon
                    name="cart"
                    size="massive"
                    className="center lightGray"
                  ></Icon>
                  <Header className="largeMedium">Your Cart is empty!</Header>
                  <br />
                  <Link to="/shop" onClick={() => this.props.pageNotLogin()}>
                    <Button primary className="regular borderRadius10 normal">
                      Browse Categories
                    </Button>
                  </Link>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        );
      } else {
        return (
          <Grid container>
            <Grid.Row
              verticalAlign="middle"
              textAlign="center"
              style={{ height: "150px" }}
              className="zIndex1 fixed top left bgGradientGray"
            >
              <Grid.Column verticalAlign="middle" width={16}>
                <Link to="/" onClick={() => this.props.pageNotLogin()}>
                  <Image
                    id="GoToHome"
                    inline
                    style={{ width: "7em" }}
                    src="/img/ClubConnect_Landscape.svg"
                  />
                </Link>
              </Grid.Column>
            </Grid.Row>

            <Header
              className="small noMargin primaryColor"
              style={{ paddingTop: "150px" }}
            >
              Checkout
            </Header>

            <Grid.Row>
              <Dimmer inverted active={this.state.submitLoad}>
                <Loader size="medium">Submitting Order</Loader>
              </Dimmer>
              <Grid.Column
                mobile={16}
                tablet={12}
                computer={12}
                style={{ paddingBottom: "75px" }}
              >
                <div className="section card borderRadius10 bgWhite shadow20 padding15 marginBottom20">
                  <CheckoutCustomer />
                </div>
                <div className="section card borderRadius10 bgWhite shadow20 padding15 marginBottom20">
                  <ShippingAddress />
                </div>
                <div className="section card borderRadius10 bgWhite shadow20 padding15 marginBottom20">
                  <Delivery
                    data={deliveryDate}
                    handleDeliveryNote={(note) =>
                      this.setState({ delivery_note: note })
                    }
                    handleDelivery={(flag, date, time, year) =>
                      this.setState(
                        {
                          selected_delivery_time: flag,
                          delivery_date: date + " " + year,
                          delivery_time: time,
                          selected_date: date,
                        },
                        () => this.scrollToBottom()
                      )
                    }
                  />
                </div>

                <PaymentMethod
                  setLoading={(flag) => this.setState({ submitLoad: flag })}
                  loading={this.state.loading}
                  isDeliverySet={this.state.selected_delivery_time}
                  cartItems={this.props.cart.cartItems}
                  submitCheckout={(
                    totalCartPrice,
                    nonce,
                    cardStatus,
                    partnershipFund
                  ) =>
                    this.handleSubmitCheckout(
                      totalCartPrice,
                      nonce,
                      cardStatus,
                      partnershipFund
                    )
                  }
                />
              </Grid.Column>

              <Grid.Column mobile={16} tablet={4} computer={4}>
                <Grid>
                  <Grid.Row only="computer tablet">
                    <CartSummary isDesktop={true} />
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row only="mobile">
              <CartSummary />
            </Grid.Row>

            <div
              style={{ float: "left", clear: "both" }}
              ref={this.scrollEl}
            ></div>
          </Grid>
        );
      }
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Checkout)
);
