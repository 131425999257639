import React, { Component } from "react";
import { Form, Accordion, Button, Label, Icon, Dropdown, Image} from 'semantic-ui-react';
import _ from 'lodash';
import FilterSection from "./FilterSection";
import FilterModal from "./FilterModal";

export default class FilterContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            price: [],
            brands: [],
            cates: [],
            best: []
        }
    }

    componentDidMount() {
        const currentFilter = this.props.currentFilter;

        if (currentFilter) {
            this.setState({
                price: currentFilter.price,
                brands: currentFilter.brands,
                cates: currentFilter.cates,
                best: currentFilter.best
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const prevFilter = prevProps.currentFilter;
        if (prevFilter !== this.props.currentFilter) {
            this.setState({
                price: this.props.currentFilter.price,
                brands: this.props.currentFilter.brands,
                cates: this.props.currentFilter.cates,
                best: this.props.currentFilter.best
            })
        }
    }

    handleApply() {
        this.props.handleShow(this.state);
    }

    handleDelete(value, type) {
        if (this.state[type].includes(value)) {
            let index = this.state[type].indexOf(value);
            // splice
            this.state[type].splice(index, 1);

            this.setState({
                [type]: this.state[type]
            }, () => {
                this.handleApply();
            })
        }
    }

    onSelect(key, value, name) {

        if (this.state[name].includes(value)) {
            const index = this.state[name].indexOf(value);
            let currentStateValue = [...this.state[name]];
            currentStateValue.splice(index, 1);
            this.setState({
                [name]: currentStateValue
            }, () => {
                this.handleApply();
            })
        } else {
            this.setState({
                [name]: this.state[name].concat(value)
            }, () => {
                this.handleApply();
            });
        }
    }

    clearAll() {
        this.setState({
            price: [],
            brands: [],
            cates: []
        }, () => {
            this.handleApply();
        })
    }

    handleSortList(sort) {
        this.setState({
            sort: sort
        })
        this.props.sortList(sort);
    }

    render() {
        const filters = this.props.filters;
        let price = [];
        let brands = [];
        let cate = [];
        let best = [];
        
        if(filters) {
            price = filters.price;
            brands = filters.brands;
            cate = filters.cates;
            best = filters.best;
        }
        
        const checkForm = (data, name) => (
            <Form>
                <Form.Group grouped>
                    {
                        data.map((value, key) => {
                            return (
                                <div key={key} style={{ position: 'relative' }}>
                                    <Form.Checkbox label={value.name} value={value.id} name={name} onChange={(e, data) => this.onSelect(key, value.id, name)} checked={this.state[name].includes(value.id) ? true : false} />
                                    <span className={'regular absolute right top ' + (this.state[name].includes(value.id) ? 'primaryColor bold' : '')}>[{value.count}]</span>
                                </div>
                            )
                        })
                    }
                </Form.Group>
            </Form>
        )

        const checkFormDropdown = (data, name) => (
            <>
            {data.map((value, key) => {
                return (
                    <Dropdown.Item key={key} style={{ position: 'relative' }}>
                        <Form.Checkbox label={value.name} value={value.id} name={name} onChange={(e, data) => this.onSelect(key, value.id, name)} checked={this.state[name].includes(value.id) ? true : false} />
                        <span>[{value.count}]</span>
                    </Dropdown.Item>
                )
            })}
            </>
        )

        const checkFormDropdownThumb = (data, name) => (
            <>
                {data.map((value, key) => {
                    return (
                        <Dropdown.Item key={key} style={{ position: 'relative' }}>
                            <Form.Checkbox label={<Image verticalAlign='middle' fluid src={process.env.REACT_APP_HOST_URL + '/storage/images/' + (value.image_link ? value.image_link : 'placeholder-brand.png')} className='bgWhite' />} value={value.id} name={name} onChange={(e, data) => this.onSelect(key, value.id, name)} checked={this.state[name].includes(value.id) ? true : false}/>
                        </Dropdown.Item>
                    )
                })}
            </>
        )

        const renderFilter = (type) => {
            const currentAvailableFilterList = this.props.filters ? this.props.filters[type] : [];
            return (
                <>
                    {this.state[type].map((value, key) => {
                        const o = _.find(currentAvailableFilterList, function (o) { return o.id === value; });

                        if(o) {
                            return (
                                <Label as='a' key={key} className='filterLabel'>
                                    {o.name}
                                    <Icon name='delete' onClick={() => this.handleDelete(value, type)} />
                                </Label>
                            )
                        } else {
                            return null
                        }
                    })}
                </>
            )
        }

        const panels = [
            {
                key: 'panel-1',
                title: 'Price',
                content: {
                    content: checkForm(price, 'price')
                }
            },
            {
                key: 'panel-2',
                title: 'Brand',
                content: {
                    content: checkForm(brands, 'brands')
                }
            },
            {
                key: 'panel-3',
                title: 'Category',
                content: {
                    content: checkForm(cate, 'cates')
                }
            },
            {
                key: 'panel-4',
                title: 'Most Popular',
                content: {
                    content: checkForm(best, 'best')
                }
            }
        ];

        let activeIndex = [0];

        return (
            <div className='marginBottom40'>
                {
                    this.props.isDesktop ?
                        (<div className='borderY'>
                            <Dropdown placeholder='PRICE' text='PRICE' className='crazyFilter'>
                                <Dropdown.Menu>
                                    {checkFormDropdown(price, 'price')}
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown placeholder='CATEGORY' text='CATEGORY' className='crazyFilter'>
                                <Dropdown.Menu>
                                    {checkFormDropdown(cate, 'cates')}
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown placeholder='BRAND' text='BRAND' className='crazyFilter crazyFilterThumbnail'>
                                <Dropdown.Menu>
                                    {checkFormDropdownThumb(brands, 'brands')}
                                </Dropdown.Menu>
                            </Dropdown>
                            <FilterSection sortList={(sort) => this.handleSortList(sort)}/>
                        </div>) : 
                        (
                            <FilterModal>
                                <div className='overflowYAuto height86vh'>
                                    <Accordion defaultActiveIndex={activeIndex} panels={panels} exclusive={false} fluid className='filter' />
                                </div>
                            </FilterModal>
                        )
                }

                <div className='marginTop20'>
                    {renderFilter('price')}
                    {renderFilter('cates')}
                    {renderFilter('brands')}
                    {
                        (this.state.brands.length > 0 || this.state.cates.length  > 0 || this.state.price.length > 0)  && 
                        <Button compact className='transparent marginLeft10' onClick={this.clearAll.bind(this)} >
                            Clear All
                        </Button>
                    }
                </div>
            </div>                     
        );
    }
}