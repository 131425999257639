import React, { Component } from "react";
import { Header, Grid, Placeholder, Accordion } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

export default class CategorySlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: false
        }
    }

    handleClick() {
        this.setState({
            activeIndex: !this.state.activeIndex
        })
    }

    loadingCateSlide(categories, isLoading) {
        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: this.props.slideToShow ? this.props.slideToShow : 5,
            slidesToScroll: 2,
            arrows: false,
            lazyLoad: 'progressive',
            pauseOnDotsHover: true,
            pauseOnFocus: true,
            className: 'category',
            swipeToSlide: false,
            responsive: [
                {
                    breakpoint: 999,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 2.4,
                        slidesToScroll: 1,
                        dots: false
                    }
                }
            ]
        };

        if (isLoading === true) {
            const arrayLoop = 5;
            let placeholderList = [];
            let count = 0;

            while (count < arrayLoop) {
                count++;
                placeholderList.push(
                    <Grid key={count}>
                        <Grid.Column style={{ paddingTop: 0, paddingBottom: 0 }} className='margin15'>
                            <div className='bgBlackOpacity borderRadius10 relative'>
                                <Placeholder>
                                    <Placeholder.Image />
                                </Placeholder>
                            </div>
                        </Grid.Column>
                    </Grid>
                )
            }

            return (<Slider {...settings}>{placeholderList}</Slider>);
        } else {
            if (categories.length > 0) {
                return (
                    <Slider {...settings}>
                        {
                            categories.map((value, key) => {
                                return (
                                    <Grid key={key} stretched>
                                        <Grid.Column verticalAlign='middle' style={{ paddingTop: 0, paddingBottom: 0 }} className='margin15'>
                                            <Link to={'/category/' + value.slug.toLowerCase()} className='link'>
                                                <div className='borderRadius10 relative alignCenter' style={{ padding: '60px 5px', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: 'url(' + process.env.REACT_APP_HOST_URL + '/storage/images/' + (value.image_link) + ')' }}>
                                                    <Header className='textCapitalize' inverted style={{ fontSize: '14px', height: '39px', display: 'table', width: '100%' }}>
                                                        <p className='tableCenter'>{value.name.toLowerCase()}</p>
                                                    </Header>
                                                </div>
                                            </Link>
                                        </Grid.Column>
                                    </Grid>
                                )
                            })
                        }
                    </Slider>
                )
            } else {
                return null
            }
        }
    }

    mapBrand(brands) {
        return (
            <>
                {
                    brands.map((value, key) => {
                        return (
                            <Link key={key} to={'/brand/' + value.slug.toLowerCase()} className='link'>
                                {value.name}
                            </Link>
                        )
                    })
                }
            </>
        )
    }

    render() {
        const categories = this.props.data;
        const isLoading = this.props.isLoading;

        return (
            <Grid.Column width={16}>
                <Grid className='noDisplayMobile'>
                    <Grid.Row>
                        <Grid.Column>
                            <Header size='huge' className='noMargin alignCenter'>Browse by Type
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Grid>
                    <Grid.Row className='noPadding' only='tablet computer'>
                        <Grid.Column className='noPadding'>
                            {this.loadingCateSlide(categories, isLoading)}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row only='mobile' className=''>
                        <Grid.Column className='noPadding'>
                            <Accordion className='accordBrand'>
                                <Accordion.Title
                                    active={this.state.activeIndex}
                                    content='Type'
                                    index={0}
                                    onClick={this.handleClick.bind(this)}
                                />
                                <Accordion.Content active={this.state.activeIndex} content={this.mapBrand(categories)} />
                            </Accordion>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Column>
        )
    }
}