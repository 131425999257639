import React, { Component } from "react";
import { Grid } from 'semantic-ui-react';
import { myAuth } from '../utils/PrivateRoute';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { pageWelcomeAlc } from '../action/nonalc';
import { pageLogin, pageNotLogin } from '../action/view';
import LoginFooter from "../parts/LoginFooter";
import ClubRegister from "./ClubRegister";
import LandingPart from "../parts/LandingPart";
import TagManager from "react-gtm-module";
import { trackPage } from "../utils/Utils";
import LandingPageFAQ from "./LandingPageFAQ";
import LandingPageTestimonial from "./LandingPageTestimonial";
import LandingPageAbout from "./LandingPageAbout";
import LandingPageFooter from "./LandingPageFooter";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageLogin,
    pageNotLogin,
    pageWelcomeAlc
};

class Welcome extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: null,
            password: null,
            step: 1,
            name: null,
            user_img: null,
            hidden: true,
            loading: false,
            modalOpen: false,
        };

        this.props.pageLogin();
        this.props.pageWelcomeAlc();

        // check if user has login already?
        if (myAuth.isAuthenticated) {
            this.props.pageNotLogin();
        }
    }

    componentDidMount() {
        trackPage('welcome');
    }

    render() {
        let prevState = '/';

        if (this.props.location.state) {
            prevState = this.props.location.state.from.pathname;
        }

        if (myAuth.isAuthenticated) {
            return (
                <Redirect
                    to={{
                        pathname: prevState
                    }}
                />
            )
        }

        const queryParameters = new URLSearchParams(window.location.search)
        const club_code = queryParameters.get("club")

        return (
            <div className="bg-white welcome-border">
                <Grid celled padded verticalAlign='middle' columns={2} className='h-auto bg-primary-blue welcome-background bg-no-repeat bg-cover bg-center pb-12 md:pb-0 shadow-none'>
                    <Grid.Row verticalAlign='middle' className="container mx-auto shadow-none w-screen welcome-container">
                        <Grid.Column mobile={16} tablet={8} computer={10} className='h-full max-w-900 flex justify-center lg:pr-16'>
                            <LandingPart />
                        </Grid.Column>

                        <Grid.Column mobile={16} tablet={8} computer={6} style={{ textAlign: 'right' }} className={(this.props.root.slideRegister ? 'active' : '') + ' ml-auto pr-4 landing border-0 shadow-none flex h-full slidingMobile flex-col items-center p-0'}>
                            {/* <Maintainance color='black' /> */}

                            <div className="py-8 white text-right w-full hidden md:block md:visible">
                                <p className='fontSize16px font-semibold text-white'>Already a member?
                                    <Link className='marginLeft10 bgYellow white hoverDarkenYellowBg inlineFlex padding10-20 noDecoration' to='/login'
                                        onClick={() => TagManager.dataLayer({
                                            dataLayer: {
                                                event: 'go_to_login_page'
                                            }
                                        })}

                                    >Sign In</Link></p>
                            </div>

                            <div className="bg-white mb-12 pt-8 px-4 w-full">
                                <ClubRegister nonAlc={false} clubCode={club_code} />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>


                <LandingPageAbout />
                <LandingPageTestimonial />
                <LandingPageFAQ />
                <LandingPageFooter />

                < LoginFooter homepage={true} />

            </div >

        );
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Welcome));