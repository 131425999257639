import React, { Component } from "react";
import { Grid, Placeholder, Header } from 'semantic-ui-react';
import { getBrandList } from '../utils/ProductAPI';
import { connect } from "react-redux";
import { pageNotLogin } from '../action/view';
import { Link } from 'react-router-dom';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageNotLogin,
};

class Brands extends Component {
    constructor(props) {
        super(props);

        this.state = {
            'brands': [],
            'isLoading': true
        }
    }

    componentDidMount() {
        getBrandList((result) => this.setState({
            'brands': result,
            'isLoading': false
        }))

        this.props.pageNotLogin();
    }

    loadingBrandSlide(brands, isLoading) {
        if (isLoading === true) {
            const arrayLoop = 30;
            let placeholderList = [];
            let count = 0;

            while (count < arrayLoop) {
                count++;
                placeholderList.push(
                    <Grid.Column key={count} className='marginBottom20'>
                        <div className='bgBlackOpacity borderRadius10 relative'>
                            <Placeholder>
                                <Placeholder.Image />
                            </Placeholder>
                        </div>
                    </Grid.Column>
                )
            }

            return (
                <Grid>
                    <Grid.Row columns='6' only='computer'>
                        {placeholderList}
                    </Grid.Row>
                    <Grid.Row columns='4' only='tablet'>
                        {placeholderList}
                    </Grid.Row>
                    <Grid.Row columns='2' only='mobile'>
                        {placeholderList}
                    </Grid.Row>
                </Grid>
            );
        } else {
            if (brands.length > 0) {
                const brandRender = brands.map((value, key) => {
                        if(value.name !== '') {
                            return (
                                <li key={key}>
                                    <Link to={'/brand/' + value.slug.toLowerCase()} className='link'>
                                        {value.name}
                                    </Link>
                                </li>
                            )
                        } else {
                            return null
                        }
                        
                    });

                return (
                    <Grid>
                        <Grid.Row columns='1' only='computer'>
                            <Grid.Column>
                            <div>
                                <ul className='brandListVertical' style={{'columnCount': 6}}>
                                {
                                    brandRender
                                }
                                </ul>
                            </div>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns='1' only='tablet'>
                            <div>
                                <ul className='brandListVertical' style={{'columnCount': 4}}>
                                {
                                    brandRender
                                }
                                </ul>
                            </div>
                        </Grid.Row>

                        <Grid.Row columns='1' only='mobile'>
                            <div>
                                <ul className='brandListVertical' style={{'columnCount': 2}}>
                                {
                                    brandRender
                                }
                                </ul>
                            </div>
                        </Grid.Row>
                    </Grid>
                )
            } else {
                return null
            }
        }
    }

    render() {
        let brands = this.state.brands;

        return (
            <Grid container columns='equal'>
                <Grid.Row stretched style={{ marginTop: '20px' }}>
                    <Grid.Column width={16} className='marginBottom20'>
                        <Header size='large'>
                            ALL BRANDS
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {this.loadingBrandSlide(brands, this.state.isLoading)}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Brands);
