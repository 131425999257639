import React, { Component } from "react";
import { Grid, Header, Input, Button, Icon, Dimmer, Loader } from 'semantic-ui-react';
import { formatCurrency } from '../utils/Utils';
import { Braintree, HostedField } from 'react-braintree-fields';
import { getToken } from '../utils/PaymentAPI';
import { connect } from "react-redux";
import { Slider } from "react-semantic-ui-range";
import Cleave from 'cleave.js/react';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
};

class PaymentMethod extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: 'unknown',
            valid: false,
            authorization: '',
            token: '',
            error: '',
            loading: false,
            authorizationLoading: true,
            slideValue: 0,
            cleaveNumber: null
        }

        this.numberField = React.createRef();
        this.braintree = React.createRef();
        [
            'onError',
            'getTokenNonce',
            'onCardTypeChange',
            'onAuthorizationSuccess',
            'checkValidInput'
        ].forEach(prop => (this[prop] = this[prop].bind(this)));
    }

    onError(error) {
        this.setState({ error });
    }

    getTokenNonce(flag) {
        const cartItems = this.props.cartItems;
        let totalCartPrice = this.calculateTotalAmount(cartItems);

        const currentCartItems = [];
        cartItems.forEach(item => {
            currentCartItems.push({
                'item_name': item.name,
                'item_id': item.id,
                'price': item.price,
                'item_sku': item.product_sku,
                'quantity': item.quantity
            })
        })

        if (flag) {
            if (this.state.valid && this.props.isDeliverySet) {
                this.props.setLoading(true);

                this.setState({
                    loading: true,
                    valid: false
                });

                this.tokenize().then((token) => {
                    this.setState({ token, error: null });
                    this.props.submitCheckout(totalCartPrice, token.nonce, 'Settling', this.state.slideValue)
                }).catch((error) => {
                    this.setState({ token: null, error, loading: false, valid: true }, () => alert('Your credit/debit card is invalid.'));
                });
            }
        } else {
            if (this.props.isDeliverySet) {
                this.setState({ loading: false, valid: true });
                this.props.submitCheckout(totalCartPrice, null, 'Paid with Partnership Fund', this.state.slideValue)
            }

        }

    }

    onCardTypeChange({ cards }) {
        if (1 === cards.length) {
            const [card] = cards;

            this.setState({ type: card.type });

            if (card.code && card.code.name) {
                this.cvvField.setPlaceholder(card.code.name);
            } else {
                this.cvvField.setPlaceholder('CCV');
            }

        } else {
            this.setState({ type: '' });
            this.cvvField.setPlaceholder('CCV');
        }
    }

    componentDidMount() {
        getToken((result) => this.setState({ authorization: result }));
    }

    renderResult(title, obj) {
        if (!obj) { return null; }
        return (
            <div>
                <b>{title}:</b>
                <pre>{JSON.stringify(obj, null, 4)}</pre>
            </div>
        );
    }

    onAuthorizationSuccess() {
        this.setState({
            authorizationLoading: false
        })
        this.numberField.current.focus();
    }

    checkValidInput(e) {
        if (e.fields.number.isValid === true && e.fields.expirationDate.isValid === true && e.fields.cvv.isValid === true) {
            this.setState({
                valid: true
            })
        } else {
            this.setState({
                valid: false
            })
        }
    }

    calculateTotalAmount(cartItems) {
        let totalCartPrice = 0;

        cartItems.forEach(value => {
            totalCartPrice += value.price * value.quantity;
        });

        return totalCartPrice;
    }

    handleChangeValue(e) {
        const cartItems = this.props.cartItems;
        const totalCartPrice = this.calculateTotalAmount(cartItems);
        let totalFund = this.props.user ? this.props.user.club ? this.props.user.club.rebate : 0 : 0;

        let currentValue = 0;
        const rawValue = e.target.rawValue.replace('$ ', '');


        if (!isNaN(rawValue)) {
            currentValue = rawValue;

            if (totalFund > totalCartPrice) {
                totalFund = totalCartPrice;
            }

            if (currentValue >= totalFund) {
                currentValue = totalFund;
            }

            if (currentValue <= 0) {
                currentValue = 0;
            }
        }

        this.setState({
            slideValue: currentValue
        })
    }

    onCleaveNumberInit(cleave) {
        this.setState({ cleaveNumber: cleave });
    }

    render() {
        const cartItems = this.props.cartItems;
        let totalCartPrice = this.calculateTotalAmount(cartItems);
        let totalFund = this.props.user ? this.props.user.club ? this.props.user.club.rebate : 0 : 0;

        let paymentContent = '';

        const settings = {
            start: 0,
            min: 0,
            max: totalFund > totalCartPrice ? parseFloat(totalCartPrice) : parseFloat(totalFund),
            step: 1,
            onChange: value => {
                if (isNaN(value)) {
                    this.setState({
                        slideValue: 0
                    })
                } else {
                    this.setState({
                        slideValue: value
                    })
                }

            }
        };

        if (this.props.isDeliverySet) {
            paymentContent = () => {
                return (
                    <Grid.Row>
                        <Grid.Column width={3} >
                            <Icon name={this.state.valid ? 'checkmark' : 'ellipsis horizontal'} circular fitted className={this.state.valid ? 'bgPrimary white' : 'darkGray transparent'}></Icon>
                        </Grid.Column>

                        <Grid.Column width={13}>
                            <Header className='regular noMargin'>
                                Payment Method
                                <Header.Subheader className='small lightGray noMargin hidden'>
                                    Select a Payment
                                </Header.Subheader>
                            </Header>

                        </Grid.Column>

                        <Grid.Column width={16} className='marginTop10 marginBottom10'>
                            <Slider
                                style={{
                                    height: "30px",
                                    inner: {
                                        height: '30px'
                                    },
                                    track: {
                                        height: '10px'
                                    },
                                    trackFill: {
                                        height: '10px'
                                    },
                                    thumb: {
                                        top: '2px',
                                        height: '25px',
                                        width: '35px',
                                        borderRadius: '10px'
                                    }

                                }}
                                precision={100}
                                value={this.state.slideValue}
                                color='orange'
                                settings={settings}
                            />
                            <Grid className='marginTop10 marginBottom10'>
                                <Grid.Row>
                                    <Grid.Column width={8} textAlign='left'>
                                        <Header className='small noMargin'>
                                            Total Payable On Credit Card
                                        </Header>
                                        <p className='large marginTop10'>{formatCurrency(totalCartPrice - this.state.slideValue)}</p>
                                    </Grid.Column>
                                    <Grid.Column width={8} textAlign='right'>
                                        <Header className='small noMargin'>
                                            Partnership Fund Used On This Order
                                        </Header>
                                        <span className="input large"><Cleave placeholder="0.00"
                                            options={{
                                                numeral: true,
                                                numeralDecimalMark: '',
                                                delimiter: '',
                                                stripLeadingZeroes: true,
                                                prefix: '$ ',
                                                signBeforePrefix: true
                                            }}
                                            onChange={this.handleChangeValue.bind(this)}
                                            value={this.state.slideValue}
                                            className='noBorder noOutline large marginTop10 alignRight maxWidth90'
                                        /></span>
                                        <hr />
                                        <Header className='small noMargin'>
                                            Partnership Fund Remaining
                                        </Header>
                                        <p className='large'>{formatCurrency(totalFund - this.state.slideValue)}</p>

                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>

                        <Grid.Column width={16} className='marginTop10'>

                            <Braintree
                                ref={this.braintree}
                                authorization={this.state.authorization}
                                onAuthorizationSuccess={this.onAuthorizationSuccess}
                                onError={this.onError}
                                getTokenRef={t => (this.tokenize = t)}
                                onCardTypeChange={this.onCardTypeChange}
                                onValidityChange={this.checkValidInput}
                            >
                                <Grid>
                                    <Dimmer inverted active={this.state.authorizationLoading}>
                                        <Loader size='medium'>Authorisation</Loader>
                                    </Dimmer>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Input fluid iconPosition='left' disabled={this.state.loading || this.props.loading || totalCartPrice - this.state.slideValue === 0} className='borderRadius10 noBorder bgLightGray small paddingTopBottom10 paddingLeft3em'>
                                                <HostedField
                                                    type="number" disabled={this.state.loading || this.props.loading}
                                                    ref={this.numberField}
                                                />
                                                <Icon name='credit card outline' />
                                            </Input>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row className='noPadding'>
                                        <Grid.Column width={10}>
                                            <Input fluid iconPosition='left' disabled={this.state.loading || this.props.loading || totalCartPrice - this.state.slideValue === 0} className='borderRadius10 noBorder bgLightGray small paddingTopBottom10 paddingLeft3em'>
                                                <HostedField type="expirationDate" placeholder="MM/YY" disabled={this.state.loading || this.props.loading || totalCartPrice - this.state.slideValue === 0} />
                                                <Icon name='calendar outline' />
                                            </Input>
                                        </Grid.Column>

                                        <Grid.Column width={6}>
                                            <Input fluid iconPosition='left' disabled={this.state.loading || this.props.loading || totalCartPrice - this.state.slideValue === 0} className='borderRadius10 noBorder bgLightGray small paddingTopBottom10 paddingLeft3em'>
                                                <HostedField type="cvv" placeholder="CCV" disabled={this.state.loading || this.props.loading || totalCartPrice - this.state.slideValue === 0} ref={cvvField => { this.cvvField = cvvField; }} />
                                                <Icon name='lock' />
                                            </Input>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            {
                                                totalCartPrice - this.state.slideValue > 0 ?
                                                    <Button onClick={() => this.getTokenNonce(true)} loading={this.state.loading || this.props.loading} className='regular bgPrimary borderRadius10 white normal' fluid disabled={!this.state.valid || this.props.loading}>Pay {formatCurrency(totalCartPrice - this.state.slideValue)}</Button> :
                                                    <Button onClick={() => this.getTokenNonce(false)} loading={this.state.loading || this.props.loading} className='regular bgPrimary borderRadius10 white normal' fluid disabled={this.props.loading}>Pay {formatCurrency(totalCartPrice - this.state.slideValue)}</Button>
                                            }

                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Braintree>
                        </Grid.Column>
                    </Grid.Row>

                )
            }
        } else {
            paymentContent = () => {
                return (
                    <Grid.Row>
                        <Grid.Column width={3} >
                            <Icon circular fitted className='darkGray transparent'></Icon>
                        </Grid.Column>

                        <Grid.Column width={13}>
                            <Header className='regular noMargin'>
                                Payment Method
                                <Header.Subheader className='small lightGray noMargin'>
                                    Select a Payment
                                </Header.Subheader>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                )
            }
        }

        return (
            <div className={'section card borderRadius10 bgWhite padding15 overflowHidden' + (this.props.isDeliverySet ? ' shadow20 maxHeight450' : ' disabled maxHeight60')}>
                <Grid verticalAlign='middle' stretched className='paymentSelect'>
                    {paymentContent()}
                </Grid>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentMethod);
