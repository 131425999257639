import React, { Component } from "react";
import { Grid, Header, Input, Container, Button, Icon } from 'semantic-ui-react';
import { ValidateEmail } from '../utils/Utils';
import { CSSTransition } from "react-transition-group";
import { myAuth } from '../utils/PrivateRoute';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { pageLogin, pageNotLogin } from '../action/view';
import LoginFooter from "../parts/LoginFooter";
import LandingFooter from "../parts/LandingFooter";
import LandingPart from "../parts/LandingPart";
import _ from "lodash";
import TagManager from "react-gtm-module";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageLogin,
    pageNotLogin
};

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: null,
            password: null,
            step: 1,
            name: null,
            user_img: null,
            hidden: true,
            loading: false
        };

        this.props.pageLogin();

        // check if user has login already?
        if (myAuth.isAuthenticated) {
            this.props.pageNotLogin();
        }
    }

    keyPressed(e) {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this.handleNext();
        }
    }

    handleNext() {
        const step = this.state.step;

        switch (step) {
            case 1:
                if (this.state.email === null) {
                    alert('Please enter your email address.');
                } else {
                    if (ValidateEmail(this.state.email)) {
                        // query database to find first name and last name
                        myAuth.getUserName(this.state.email, (flag) => {
                            this.setState({
                                loading: flag
                            })
                        }, (name) => {
                            this.setState({
                                name: name,
                                user_img: '',
                                step: 2,
                                loading: false
                            })
                        })
                    }
                }
                break;
            case 2:
                if (this.state.password === null) {
                    alert('Please enter your password.');
                } else {
                    TagManager.dataLayer({
                        dataLayer: {
                            'email': null
                        }
                    })
                    TagManager.dataLayer({
                        dataLayer: {
                            'event': 'logging_in',
                            'email': this.state.email
                        }
                    })

                    // check password to login
                    myAuth.authenticate(this.state.email, this.state.password, (flag) => {
                        this.setState({
                            loading: flag
                        })
                    }, () => {
                        this.setState({ loading: false });
                    });
                }
                break;

            default:
                break;
        }
    }

    handlePasswordChange(e) {
        this.setState({ password: e.currentTarget.value })
    }

    handleEmailchange(e) {
        this.setState({ email: e.currentTarget.value });
    }

    handleClearEmail() {
        this.setState({
            email: null,
            first_name: '',
            last_name: '',
            step: 0
        })
    }

    displayStep1() {
        this.setState({
            step: 1
        })
    }

    handleOnPasswordToggle() {
        let flag = false;

        if (this.state.hidden === true) {
            flag = false;
        } else {
            flag = true;
        }

        this.setState({
            hidden: flag
        })
    }

    render() {
        let prevProp = '/';
        if (this.props.location.state) {
            prevProp = this.props.location.state.from;
        }

        if (myAuth.isAuthenticated) {
            return (
                <Redirect
                    to={{
                        pathname: prevProp
                    }}
                />
            )
        }

        return (
            <Grid celled padded columns={2} className='height100vh bgWhite'>
                <Grid.Row>
                    <Grid.Column only='tablet computer' tablet={8} computer={6} className='padding30 primaryGradient nonAlcbackgroundCover center'>
                        <LandingPart nonAlc={this.props.nonAlc} />
                        <LandingFooter />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={10} style={{ textAlign: 'right' }} className='center landing height100vh'>
                        <Grid padded verticalAlign='middle' columns={1} className='height100vh'>
                            <Grid.Row>
                                <Grid.Column className='maxWidth525 center'>
                                    <Container>
                                        <Header color='black' as='h1' className='alignLeft marginTop10 marginBottom20 headerLarge loginWelcome flex flex-wrap md:flex-nowrap'>
                                            {
                                                this.state.step === 1 ?
                                                    'Sign In' : ''
                                            }
                                            <CSSTransition in={this.state.step === 2} unmountOnExit classNames="fade"
                                                timeout={200}>
                                                <div className='inline'>
                                                    Welcome Back
                                                </div>
                                            </CSSTransition>
                                            <Header.Subheader className='floatRight my-4 md:my-0 md:basis-auto ml-auto'>
                                                <CSSTransition in={this.state.step === 2} unmountOnExit classNames="fade" timeout={200}
                                                    onExited={() => this.displayStep1()}
                                                >
                                                    <div className='borderWhite borderRadius10 fontSize16px alignLeft height24 paddingTop5 paddingRight30 paddingBottom5 relative'>
                                                        {
                                                            this.state.user_img === '' ?
                                                                <div className='userProfilePicture alignLeft inline marginLeft10 marginRight10'>{_.capitalize(this.state.name.charAt(0))}</div> :
                                                                (<img src='' alt='user' />)
                                                        }

                                                        <div className='relative inline topMinus5'>{this.state.name}</div>
                                                        <Icon name='close' className='fontSize16px absolute link' onClick={this.handleClearEmail.bind(this)} style={{ right: '0', bottom: '7px' }} />
                                                    </div>
                                                </CSSTransition>
                                            </Header.Subheader>
                                        </Header>
                                    </Container>
                                    <Container>
                                        <div className='login'>
                                            {
                                                this.state.step === 1 ?
                                                    (<Input tabIndex='1' className='borderRadius10 landingInput' fluid autoFocus name='email' disabled={this.state.loading} onKeyPress={this.keyPressed.bind(this)} onChange={this.handleEmailchange.bind(this)} placeholder='Email or Username...' />)
                                                    : ''
                                            }

                                            <CSSTransition in={this.state.step === 2} unmountOnExit classNames="fade"
                                                timeout={200}>
                                                <div className='relative'>
                                                    <Input className='loadingWhite borderRadius10 landingInput' fluid disabled={this.state.loading} autoFocus name='password' type={this.state.hidden ? 'password' : 'text'} onKeyPress={this.keyPressed.bind(this)} onChange={this.handlePasswordChange.bind(this)} placeholder='Password' />
                                                    <i aria-hidden="true" onClick={this.handleOnPasswordToggle.bind(this)} className={this.state.hidden ? 'eye slash icon absolute right20 top20 link' : 'eye icon absolute right20 top20 link'} />
                                                </div>
                                            </CSSTransition>
                                            <Link to='/forgetpassword'><p className='forgotPassword marginTop10 alignLeft menu link'>Forgot Password?</p></Link>
                                        </div>
                                    </Container>
                                    <br />
                                    <Container>
                                        <div className='login'>
                                            <Link to='/welcome' className='floatLeft yellowText borderYellow hoverYellowBg padding10-20 noDecoration'>Register a Club</Link>

                                            <Button tabIndex='2' loading={this.state.loading} disabled={this.state.loading} compact floated='right' className='hoverDarkenYellowBg maxWidth90 regular bgYellow white minHeight40' onClick={this.handleNext.bind(this)}>
                                                <Button.Content>
                                                    {
                                                        this.state.step === 1 ? 'Next' : 'Sign In'
                                                    }
                                                </Button.Content>
                                            </Button>
                                        </div>

                                    </Container>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <LoginFooter />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Login));