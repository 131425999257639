export const GTM_ID = "GTM-WLKFKF5";
export const GTM_ID_MURMUR = "GTM-K3DHGCT";

export const stateOptions = [
  { key: "vic", value: "VIC", text: "Victoria" },
  { key: "nsw", value: "NSW", text: "New South Wales" },
  { key: "tas", value: "TAS", text: "Tasmania" },
  { key: "sa", value: "SA", text: "South Australia" },
  { key: "wa", value: "WA", text: "West Australia" },
  { key: "qld", value: "QLD", text: "Queensland" },
  { key: "act", value: "ACT", text: "Australian Capital Territory" },
  { key: "nt", value: "NT", text: "Nothern Territory" },
];

export const userClubRoles = [
  {key: 1, value: 1, text: "President"},
  {key: 2, value: 2, text: "Vice President"},
  {key: 3, value: 3, text: "Treasurer"},
  {key: 4, value: 4, text: "Secretary"},
  {key: 5, value: 5, text: "Bar / Canteen Manager"},
  {key: 6, value: 6, text: "Committee"},
  {key: 7, value: 7, text: "Other"}
]

export const seasons = [
  {key: 1, value: 1, text: "Summer"},
  {key: 2, value: 2, text: "Winter"},
  {key: 3, value: 3, text: "All Year"},
]

export const sportOptions = [
  { key: "Archery", value: "Archery", text: "Archery" },
  { key: "Athletics", value: "Athletics", text: "Athletics" },
  {
    key: "Australian Rules Football",
    value: "Australian Rules Football",
    text: "Australian Rules Football",
  },
  { key: "Automotive", value: "Automotive", text: "Automotive" },
  { key: "Badminton", value: "Badminton", text: "Badminton" },
  { key: "Baseball", value: "Baseball", text: "Baseball" },
  { key: "Basketball", value: "Basketball", text: "Basketball" },
  { key: "Boating", value: "Boating", text: "Boating" },
  { key: "Bowling", value: "Bowling", text: "Bowling" },
  { key: "Boxing", value: "Boxing", text: "Boxing" },
  { key: "Cricket", value: "Cricket", text: "Cricket" },
  { key: "Cycling", value: "Cycling", text: "Cycling" },
  { key: "Fishing", value: "Fishing", text: "Fishing" },
  { key: "Golf", value: "Golf", text: "Golf" },
  { key: "Hockey", value: "Hockey", text: "Hockey" },
  { key: "Lawn Bowls", value: "Lawn Bowls", text: "Lawn Bowls" },
  { key: "Martial Arts", value: "Martial Arts", text: "Martial Arts" },
  { key: "Motorsport", value: "Motorsport", text: "Motorsport" },
  { key: "Netball", value: "Netball", text: "Netball" },
  { key: "Racing", value: "Racing", text: "Racing" },
  { key: "RSL", value: "RSL", text: "RSL" },
  { key: "Rugby League", value: "Rugby League", text: "Rugby League" },
  { key: "Rugby Union", value: "Rugby Union", text: "Rugby Union" },
  { key: "Soccer", value: "Soccer", text: "Soccer" },
  { key: "Softball", value: "Softball", text: "Softball" },
  {
    key: "Surf Life Saving",
    value: "Surf Life Saving",
    text: "Surf Life Saving",
  },
  { key: "Swimming", value: "Swimming", text: "Swimming" },
  { key: "Table Tennis", value: "Table Tennis", text: "Table Tennis" },
  { key: "Tennis", value: "Tennis", text: "Tennis" },
  { key: "Volleyball", value: "Volleyball", text: "Volleyball" },
  { key: "Other", value: "Other", text: "Other" },
];

export const GA_ID = "UA-189364048-1";

export const MENU = [
  {
    name: "Beer",
    link: "/category/beer",
    slug: "beer",
  },
  {
    name: "Cider",
    link: "/category/cider",
    slug: "cider",
  },
  {
    name: "Spirits",
    link: "/category/spirits",
    slug: "spirits",
  },
  {
    name: "Wine",
    link: "/category/wine",
    slug: "wine",
  },
  {
    name: "Non ALC",
    link: "/category/non-alcoholic-beverages",
    slug: "non-alcoholic-beverages",
  },
  {
    name: "Bar Snacks",
    link: "/category/bar-snacks",
    slug: "bar-snacks",
  },
  {
    name: "Merchandise",
    link: "/category/merchandise",
    slug: "merchandise",
  },
  {
    name: "Brands",
    link: "/brand",
    slug: "brands",
  },
];

export const NON_ALC_MENU = [
  {
    name: "Juice & Iced Tea",
    link: "/category/juice-&-tea",
    slug: "juice-&-tea",
  },
  {
    name: "Non Alc",
    link: "/category/non-alc-beer",
    slug: "non-alc-beer",
  },
  {
    name: "Soft Drink",
    link: "/category/soft-drink",
    slug: "soft-drink",
  },
  {
    name: "Sports Drink",
    link: "/category/sports-drink",
    slug: "sports-drink",
  },
  {
    name: "Water",
    link: "/category/water",
    slug: "water",
  },
  {
    name: "Bar Snacks",
    link: "/category/bar-snacks",
    slug: "bar-snacks",
  },
  {
    name: "Merchandise",
    link: "/category/merchandise",
    slug: "merchandise",
  },
  {
    name: "Brands",
    link: "/brand",
    slug: "brands",
  },
];

export const SUBMENU = [
  {
    name: "Orders",
    link: "/orders",
  },
  {
    name: "Help",
    link: "/help",
  },
];

export const WELCOME = [
  {
    image: "/img/Rectangle 12@2x.png",
    title: "Huge Range & Great Prices",
    subtitle: "Beer, Wine, Spirits, Soft & Sports Drink",
  },
  {
    image: "/img/Rectangle 16@2x.png",
    title: "Free Delivery",
    subtitle: "Choose The Time And The Day",
  },
  {
    image: "/img/Rectangle 18@2x.png",
    title: "Partnership Fund",
    subtitle: "Credit With Every Purchase",
  },
];

export const WELCOME_NONALC = [
  {
    image: "/img/Rectangle 12@2x.png",
    title: "Huge Range & Great Prices",
    subtitle: "Water, Juice, Ice Teas, Soft & Sports Drink",
  },
  {
    image: "/img/Rectangle 16@2x.png",
    title: "Free Delivery",
    subtitle: "Choose The Time And The Day",
  },
  {
    image: "/img/Rectangle 18@2x.png",
    title: "Partnership Fund",
    subtitle: "Credit With Every Purchase",
  },
];

export const rootInitialState = {
  hideHeader: true,
  modalOpen: false,
  slideRegister: false,
  page: "",
};

export const WELCOME_PAGE = {
  nonAlc: false,
};

export const LOGIN_PAGE = "LOGIN_PAGE";
export const NOT_LOGIN_PAGE = "NOT_LOGIN_PAGE";
export const UPDATE_CART = "UPDATE_CART";
export const GET_CART = "GET_CART";
export const GET_CART_LOADING = "GET_CART_LOADING";
export const GET_CART_FINISHED = "GET_CART_FINISHED";
export const UPDATE_CART_LOADING = "UPDATE_CART_LOADING";
export const GET_USER = "GET_USER";
export const GET_USER_LOADING = "GET_USER_LOADING";
export const UPDATE_COUPON_PRODUCTS = "UPDATE_COUPON_PRODUCTS";
export const APPLY_PERCENTAGE = "APPLY_PERCENTAGE";
export const APPLY_COUPON = "APPLY_COUPON";
export const REMOVE_COUPON = "REMOVE_COUPON";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const SLIDE_REGISTER = "SLIDE_REGISTER";
export const WELCOME_ALC = "WELCOME_ALC";
export const WELCOME_ALB = "WELCOME_ALB";

export const dataLayerName = "ClubConnectDataLayer";

export const cartInitialState = {
  cartItems: [],
  loading: true,
  firstLoading: true,
  coupon: "",
  couponLoading: true,
};

export const userInitialState = {
  user: {},
  club: {},
  loading: true,
};

export const time = ["7 - 11AM", "12 - 4PM", "5 - 7PM"];

export const roles = ["Admin", "Club Manager", "Member"];

export const REPAYMENT_STATUS = {
  na: 0,
  needed: 1,
  paid: 2,
};
