import React, { Component } from "react";
import { Grid, Header, Icon, Input, Dropdown, Button, Image, Checkbox, Transition } from 'semantic-ui-react';
import { myAuth } from '../utils/PrivateRoute';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { pageLogin, pageNotLogin } from '../action/view';
import { ValidateEmail, ValidateABN, ValidatePhoneNumber, ValidateLicenceNumber } from '../utils/Utils';
import { CSSTransition } from "react-transition-group";
import BlankRegisterComplete from '../parts/BlankRegisterComplete';
import Cleave from 'cleave.js/react';
import { registerUserBlank, checkClubName, checkUserEmail } from '../utils/RegisterAPI';
import { sportOptions, stateOptions } from '../Constants';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageLogin,
    pageNotLogin
};

class BlankClubRegister extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user_name: '',
            club_name: '',
            sport_type: '',
            league_name: '',
            clubRelationship: '',
            email: '',
            contact_number: '',
            licence_number: '',
            licence_name: '',
            abn: '',
            shipping_address: '',
            state: '',
            postcode: '',
            suburb: '',
            hasRelationship: false,
            billing_diff: false,
            password_hidden: true,
            confirm_hidden: true,
            currentStep: 1,
            nextStep: '',
            success: false,
            loading: false,
            agreedTerm: true
        }

        this.props.pageLogin();

        // check if user has login already?
        if (myAuth.isAuthenticated) {
            this.props.pageNotLogin();
        }
    }

    handleChangeTerm() {
        this.setState({
            agreedTerm: !this.state.agreedTerm
        })
    }

    handleChangeRelationship() {
        this.setState({
            hasRelationship: !this.state.hasRelationship
        })
    }

    checkAgreed(e) {
        e.stopPropagation();

        if (!this.state.agreedTerm) {
            e.preventDefault();
            alert('Please agree to the terms and conditions.');
            return null;
        }
    }

    onHandleNumber(e) {
        let name = e.currentTarget.getAttribute('name');

        this.setState({
            [name]: e.currentTarget.value
        })
    }

    onHandleChange(e, { value }) {
        let name = '';
        if (e.key !== undefined) {
            name = 'state';
        } else {
            name = e.currentTarget.getAttribute('name');
            let type = e.currentTarget.getAttribute('type');

            if (name === null && type === null) {
                name = 'state';
            }
        }

        this.setState({
            [name]: value
        })
    }

    onHandleSportChange(e, { value }) {
        this.setState({
            sport_type: value
        })
    }

    keyPressed(e) {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this.handleNext();
        }
    }

    handleBack() {
        const step = this.state.currentStep;

        if (step - 1 === 0) {
            this.props.history.push('/register');
        } else {
            this.setState({
                currentStep: '',
                nextStep: step - 1
            })
        }
    }

    handleNext() {
        const step = this.state.currentStep;

        switch (step) {
            case 1:
                if (this.state.user_name === '' || this.state.club_name === '' || this.state.sport_type === '' || this.state.league_name === '' || this.state.email === '') {
                    alert('Please complete all required fields')
                } else {
                    this.setState({ loading: true });

                    if (!ValidateEmail(this.state.email)) {
                        break;
                    }

                    checkUserEmail((result) => {
                        if (result === false) {
                            this.setState({
                                currentStep: '',
                                nextStep: 2,
                                loading: false
                            })
                        } else {
                            this.setState({ loading: false });
                            alert('This email address has already been registered.');
                        }

                    }, this.state.email)

                    checkClubName((result) => {
                        if (result === false) {
                            this.setState({
                                currentStep: '',
                                nextStep: 2,
                                loading: false
                            })
                        } else {
                            this.setState({ loading: false });
                            alert('the Club name has already been registered.');
                        }
                    }, this.state.club_name)

                }
                break;

            case 2:
                if (this.state.licence_name === '' || this.state.contact_number === '' || this.state.licence_number === '' || this.state.abn === '' || (this.state.hasRelationship === true && this.state.clubRelationship === '')) {
                    alert('Please complete all required fields.')
                } else {
                    if (ValidateABN(this.state.abn) && ValidatePhoneNumber(this.state.contact_number) && ValidateLicenceNumber(this.state.licence_number)) {
                        this.setState({
                            currentStep: '',
                            nextStep: 3
                        })
                    }
                }
                break;

            case 3:
                if (this.state.shipping_address === '' || this.state.state === '' || this.state.postcode === '' || this.state.suburb === '') {
                    alert('Please complete all required fields')
                } else {
                    // check for address validation
                    //let currentAddress = this.state.shipping_address + ', ' + this.state.suburb + ', ' + this.state.state + ', ' + this.state.postcode;

                    this.setState({ loading: true });
                    let object = {
                        'email': this.state.email,
                        'name': this.state.club_name,
                        'phone': this.state.contact_number,
                        'role': 2, // this is the main club account
                        'sport_type': this.state.sport_type,
                        'league': this.state.league_name,
                        'license_number': this.state.licence_number,
                        'abn': this.state.abn,
                        'shipping_address': this.state.shipping_address,
                        'suburb': this.state.suburb,
                        'state': this.state.state,
                        'postcode': this.state.postcode,
                        'user_name': this.state.user_name,
                        'licence_name': this.state.licence_name,
                        'clubRelationship': this.state.clubRelationship
                    };

                    registerUserBlank((result) => {
                        if (result) {
                            this.setState({
                                currentStep: '',
                                nextStep: 3,
                                success: true,
                                loading: false
                            })
                        } else {
                            this.setState({
                                loading: false
                            })
                        }
                    }, object);
                }
                break;

            default:
                break;
        }
    }

    handleOnPasswordToggle() {
        this.setState({
            password_hidden: !this.state.password_hidden
        })
    }

    handleOnConfirmPasswordToggle() {
        this.setState({
            confirm_hidden: !this.state.confirm_hidden
        })
    }

    handleChangeSelection() {
        this.setState({
            billing_diff: !this.state.billing_diff
        })
    }

    handleAddress(address) {
        let addr = address.fullAddress.split(', ');

        if (addr.length > 0) {
            this.setState({
                shipping_address: addr[0],
                state: address.state,
                postcode: address.postcode,
                suburb: address.locality
            })
        } else {
            alert('The address is not correct.');
        }
    }

    renderSwitch(step) {
        const step1 =
            <Grid>
                <Grid.Row stretched>
                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Your Name</label>
                        <Input tabIndex='1' value={this.state.user_name} name='user_name' className='borderRadius10 landingInput' placeholder='Your Name' onKeyPress={this.keyPressed.bind(this)} onChange={this.onHandleChange.bind(this)} />
                    </Grid.Column>
                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Your Email</label>
                        <Input tabIndex='1' autofocus value={this.state.email} name='email' className='borderRadius10 landingInput' placeholder='Email' onKeyPress={this.keyPressed.bind(this)} onChange={this.onHandleChange.bind(this)} />
                    </Grid.Column>
                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Club Registered Business Name</label>
                        <Input tabIndex='2' value={this.state.club_name} name='club_name' className='borderRadius10 landingInput' placeholder="Club Registered Business Name" onKeyPress={this.keyPressed.bind(this)} onChange={this.onHandleChange.bind(this)} />
                    </Grid.Column>

                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Club Type</label>
                        <Dropdown tabIndex='3' name='sport_type' className='borderRadius10 landingInput' onChange={this.onHandleSportChange.bind(this)}
                            fluid
                            search
                            selection
                            options={sportOptions}
                            value={this.state.sport_type}
                            placeholder='Club Type' />
                    </Grid.Column>

                    <Grid.Column width={16} textAlign='left'>
                        <label className="landingLabel">League Name</label>
                        <Input tabIndex='4' value={this.state.league_name} name='league_name' className='borderRadius10 landingInput' placeholder='League Name' onKeyPress={this.keyPressed.bind(this)} onChange={this.onHandleChange.bind(this)} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>;

        const step2 =
            <Grid>
                <Grid.Row stretched>
                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Contact Number</label>
                        <Input name='contact_number' autofocus className='borderRadius10 landingInput' type='number'>
                            <Cleave tabIndex='1' placeholder='Contact Number' name='contact_number' value={this.state.contact_number} options={{ phone: true, phoneRegionCode: 'AU' }}
                                onChange={this.onHandleNumber.bind(this)} onKeyPress={this.keyPressed.bind(this)} />
                        </Input>
                    </Grid.Column>

                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Liquor Licence Number</label>
                        <Input tabIndex='2' value={this.state.licence_number} name='licence_number' className='borderRadius10 landingInput' placeholder='Liquor Licence Number' onChange={this.onHandleChange.bind(this)} onKeyPress={this.keyPressed.bind(this)} />
                    </Grid.Column>

                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Liquor Licence Licensee</label>
                        <Input tabIndex='3' value={this.state.licence_name} name='licence_name' className='borderRadius10 landingInput' placeholder='Liquor Licence Licensee' onChange={this.onHandleChange.bind(this)} onKeyPress={this.keyPressed.bind(this)} />
                    </Grid.Column>

                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <Checkbox onChange={this.handleChangeRelationship.bind(this)} checked={this.state.hasRelationship} label='Do you currently have a sponsorship with a pub, liquor store or wholesaler' className='small myCheckbox' fitted />
                        <Transition visible={this.state.hasRelationship} animation='fade down' duration={300}>
                            <Input tabIndex='3' value={this.state.clubRelationship} name='clubRelationship' className='marginTop10 borderRadius10 landingInput width100' placeholder='Pub, Liquor Store or Wholesaler name' type='text' onChange={this.onHandleChange.bind(this)} onKeyPress={this.keyPressed.bind(this)} />
                        </Transition>
                    </Grid.Column>

                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">ABN Number</label>
                        <Input tabIndex='4' maxLength='11' value={this.state.abn} name='abn' className='borderRadius10 landingInput' placeholder='ABN' type='text' onChange={this.onHandleChange.bind(this)} onKeyPress={this.keyPressed.bind(this)} />
                    </Grid.Column>

                    <Grid.Column width={16} textAlign='right'>
                        <a tabIndex='5' href='https://abr.business.gov.au/' target='_blank' rel="noopener noreferrer"><p className='menu'>Search for your ABN</p></a>
                    </Grid.Column>
                </Grid.Row>
            </Grid>;

        const step3 =
            <Grid>
                <Grid.Row stretched>
                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Shipping Address</label>
                        <Input autofocus tabIndex='2' placeholder="Street address" name='shipping_address' value={this.state.shipping_address} className='borderRadius10 landingInput' onChange={this.onHandleChange.bind(this)} onKeyPress={this.keyPressed.bind(this)} />
                        {/* <Autocomplete updateAddressFreeText={(address) => this.setState({shipping_address : address})} ={true} placeholder="Shipping address" name='shipping_address' value={this.state.shipping_address} tabIndex='1' updateAddress={(address) => this.handleAddress(address)}/> */}
                    </Grid.Column>

                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Suburb</label>
                        <Input tabIndex='2' value={this.state.suburb} name='suburb' className='borderRadius10 landingInput' placeholder='Suburb' onChange={this.onHandleChange.bind(this)} onKeyPress={this.keyPressed.bind(this)} />
                    </Grid.Column>

                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">State</label>
                        <Dropdown tabIndex='3' name='state' className='borderRadius10 landingInput' onChange={this.onHandleChange.bind(this)}
                            fluid
                            search
                            selection
                            options={stateOptions}
                            value={this.state.state}
                            placeholder='State' />
                    </Grid.Column>

                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Postcode</label>
                        <Input tabIndex='4' value={this.state.postcode} name='postcode' className='borderRadius10 landingInput' placeholder='Postcode' type='number' onChange={this.onHandleChange.bind(this)} onKeyPress={this.keyPressed.bind(this)} />
                    </Grid.Column>

                    {/*<Grid.Column width={16} textAlign='left'>
                        <Checkbox onChange={this.handleChangeSelection.bind(this)} checked={this.state.billing_diff} label='Billing Address is different from shipping' className='small myCheckbox' fitted />
                    </Grid.Column>*/}
                </Grid.Row>
            </Grid>;

        return (
            <div>
                <CSSTransition in={step === 1} unmountOnExit classNames="fade" timeout={150} onExited={() => this.setState({ currentStep: this.state.nextStep })}>
                    {step1}
                </CSSTransition>
                <CSSTransition in={step === 2} unmountOnExit classNames="fade" timeout={150} onExited={() => this.setState({ currentStep: this.state.nextStep })}>
                    {step2}
                </CSSTransition>
                <CSSTransition in={step === 3} unmountOnExit classNames="fade" timeout={150} onExited={() => this.setState({ currentStep: this.state.nextStep })}>
                    {step3}
                </CSSTransition>
            </div>
        );
    }

    render() {
        const steps = <Grid.Column verticalAlign='top' style={{ textAlign: 'center' }} width={16} className='maxWidth525 center stepMobile marginBottom40'>
            <div className="alignLeft marginBottom40">
                <Header as='h1' color='black' icon className='noMarginBottom alignLeft mobileSmallText'>
                    Create a User Account
                </Header>
            </div>

            <div className='minHeight466px mobileMaxHeight200px'>
                {this.renderSwitch(this.state.currentStep)}
            </div>
            <Grid>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={4} computer={4} textAlign='left'>
                        <Button tabIndex='10' fluid loading={this.state.loading} disabled={this.state.loading} compact floated='left' className='regular bgYellow white minHeight40 hoverDarkenYellowBg' onClick={this.handleNext.bind(this)}>
                            <Button.Content visible>
                                {
                                    this.state.currentStep === 3 ? 'Submit' : 'Next'
                                }
                                <Icon name='arrow right' />
                            </Button.Content>
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Grid.Column>;

        return (
            <Grid padded columns={1} className='mobileMaxHeight'>
                <Grid.Row only='mobile' className='absolute top10 right alignRight zIndex1'>
                    <Grid.Column mobile={4} className='absolute top left'>
                        <Link to='/'><Image src='/img/ClubConnect_Landscape.svg' className='homeLogoWelcome' /></Link>
                    </Grid.Column>
                    <Grid.Column mobile={12} floated='right'>
                        <div className='marginBottom20 inline large'>Any Questions?</div> <a href='mailto:admin@clubconnect.net.au' className='marginLeft5 yellowText borderYellow hoverYellowBg inlineFlex padding10-20 noDecoration'>Contact</a>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    {this.state.success ? <BlankRegisterComplete /> : steps}
                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BlankClubRegister);