import React, { Component } from "react";
import { Grid, Header, Icon, Form, TextArea, Popup } from 'semantic-ui-react';
import { CSSTransition } from 'react-transition-group';

export default class Delivery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: '',
            time: '',
            year: '',
            deliveryNote: '',
            popup: false,
            deliveryPopup: false,
            deliveryQuota: null
        }
    }
    handleDateSelect(e) {
        if (this.state.date === e.currentTarget.getAttribute('data-date')) {
            this.setState({
                date: '',
                year: '',
                time: ''
            }, () => {
                this.isSet()
            });
        } else {
            this.setState({
                date: e.currentTarget.getAttribute('data-date'),
                year: e.currentTarget.getAttribute('data-year'),
                time: ''
            }, () => {
                this.isSet()
            })
        }
    }

    handleTimeSelect(quota, e) {
        if (quota !== 0)
            this.setState({
                time: e.currentTarget.getAttribute('data-time'),
                date: e.currentTarget.getAttribute('data-date'),
                year: e.currentTarget.getAttribute('data-year')
            }, () => {
                this.isSet()
            })
    }

    handleTimeSelectHover(id) {
        if (this.state.deliveryPopup)
            this.setState({
                deliveryQuota: id
            })
    }

    isSet() {
        if (this.state.date !== '' && this.state.time !== '') {
            this.props.handleDelivery(true, this.state.date, this.state.time, this.state.year);
        } else {
            this.props.handleDelivery(false, '', '', '');
        }
    }

    handleDeliveryNote(e) {
        let value = e.currentTarget.value
        if (value.match(/[^a-zA-Z0-9 ]/g)) {
            this.setState({
                popup: true
            })
            setTimeout(() => {
                this.setState({ popup: false })
            }, 2500)
        }
        value = e.currentTarget.value.replace(/[^a-zA-Z0-9 ]/g, '');
        this.setState({
            deliveryNote: value
        })
        this.props.handleDeliveryNote(value);
    }

    render() {
        const delivery = this.props.data.map((delivery, key) => {
            let date = delivery.day + ' ' + delivery.date;
            let year = delivery.year;

            let classDeliveryName = 'deliveryTime paddingLeftRight03rem width20p flex';

            if (this.state.date !== '') {
                if (this.state.date === date) {
                    classDeliveryName = 'deliveryTime paddingLeftRight03rem width100 flex flexBetween';
                } else {
                    classDeliveryName = 'deliveryTime width0 overflowHidden';
                }
            }

            return (
                <div key={key} className={classDeliveryName}>
                    <div id={date} className={'borderRadius5 alignCenter borderGray padding5 cursor width100 whitespace timeSelect' + (this.state.date === date ? ' selected' : '')} data-id={key} data-date={date} data-year={year} onClick={this.handleDateSelect.bind(this)}>
                        <p className='noMargin mini lightGray'>{delivery.day}</p>
                        <p className='noMargin tiny lightGray'>{delivery.date}</p>
                    </div>

                    <CSSTransition
                        in={this.state.date === date}
                        unmountOnExit
                        classNames="fade"
                        timeout={300}
                        onEntered={() => this.setState({
                            deliveryPopup: true
                        })}
                        onExited={() => this.setState({
                            deliveryPopup: false
                        })}
                    >
                        <div className='flex'>
                            {
                                delivery.time.map((time, k) => {
                                    return (
                                        <Popup
                                            key={k}
                                            position="top center"
                                            content=
                                            {<p className="whitespace-nowrap">{(delivery.quota[k] === 0 ? "Delivery Window Booked Out" : delivery.quota[k] + ' x Delivery Windows Remaining')}</p>}
                                            open={this.state.deliveryQuota === k && this.state.deliveryPopup && delivery.quota[k] <= 5}
                                            trigger={
                                                <div className='timeSelectWrapper' id={time}>
                                                    <div className={'borderRadius5 paddingTime whitespace timeSelect' + (this.state.time === time ? ' selected' : '') + (delivery.quota[k] <= 5 ? ' borderLightRed' : ' borderGray') + (delivery.quota[k] === 0 ? ' no-quota cursor-not-allowed' : ' cursor')} data-time={time} data-date={date} data-year={year} onClick={this.handleTimeSelect.bind(this, delivery.quota[k])} onMouseEnter={this.handleTimeSelectHover.bind(this, k)} onMouseLeave={() => this.setState({ deliveryQuota: null })}><p className={`lightGray mini ` + (delivery.quota[k] <= 5 && 'lightRed')}>{time}</p></div>
                                                </div>
                                            }
                                        />
                                    )
                                })
                            }
                        </div>
                    </CSSTransition >
                </div >
            )
        })

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Icon name={this.state.date !== '' && this.state.time !== '' ? 'checkmark' : 'ellipsis horizontal'} circular fitted className={this.state.date !== '' && this.state.time ? ' bgPrimary white' : 'darkGray transparent'}></Icon>
                    </Grid.Column>

                    <Grid.Column width={13}>
                        <Header className='regular noMargin'>
                            Delivery
                            <Header.Subheader className='small lightGray noMargin'>
                                Select a Delivery Window
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row className='noMargin noPadding'>
                    <Grid.Column textAlign='right'>
                        <p className='small lightGray'>Next 4 days</p>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns='equal' textAlign='center' className='paddingLeftRight07rem relative'>
                    {delivery}
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form >
                            <Popup
                                content='Please do not enter any special characters'
                                position='right center'
                                open={this.state.popup}
                                trigger={<TextArea placeholder='Delivery Note' onChange={this.handleDeliveryNote.bind(this)} value={this.state.deliveryNote} />}
                            />
                        </Form>
                    </Grid.Column>

                </Grid.Row>
            </Grid >
        );
    }
}