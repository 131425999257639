import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();

export function getClubUsers(callback) {
    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/view_users',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        }
    }

    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch(error => {
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })
}

export function getUserDetail(callback) {
    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/details',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        }
    }

    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch(error => {
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })
}

export function getUserRebateHistory(callback, limit = 20) {
    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/get_rebate_history',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        params: {
            'limit': limit
        }
    }

    let result = [];
    let total = 0;

    axios(options).then(res => {
        result = res.data.message;
        total = res.data.total;
        callback(result, total);
    }).catch(error => {
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })
}

export function sendInviteList(callback, email = '') {
    const options = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/create_invite_code',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        data: {
            email: email
        },
    }

    axios(options).then(res => {
        const result = res.data.message;
        callback(result);
    }).catch((res) => {
        callback('error');
        alert('There is something wrong with the server. Please try again later.');
    })
}

export function resendInvite(callback, id = '') {
    const options = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/resend_invite',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        data: {
            id: id
        },
    }

    axios(options).then(res => {
        const result = res.data.message;
        callback(result);
    }).catch((res) => {
        callback('error');
        alert('There is something wrong with the server. Please try again later.');
    })
}

export function getUserInviteCode(callback, limit = 20) {
    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/get_invite_list',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        params: {
            'limit': limit
        }
    }

    let result = [];
    let total = 0;

    axios(options).then(res => {
        result = res.data.message;
        total = res.data.total;
        callback(result, total);
    }).catch(error => {
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })
}

export function getRebate(callback) {
    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/rebate',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        }
    }

    let result = [];

    axios(options).then(res => {
        result = res.data.message.rebate;
        callback(result);
    }).catch(error => {
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })
}

export function getSurvey(callback, position = 'checkout') {
    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/survey',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        params: {
            'position': position
        }
    }

    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch(error => {
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })
}

export function answerSurvey(callback, answers, survey_id) {
    const options = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/answer',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        data: {
            answers: answers,
            id: survey_id
        }
    }

    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch(error => {
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })
}

export function commentSurvey(callback, comment, survey_id) {
    const options = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/comment_survey',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        data: {
            comment: comment,
            id: survey_id
        }
    }

    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch(error => {
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })
}

export function checkResetPasswordToken(callback, token) {
    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/password/find/' + token,
        headers: {
            'Accept': 'application/json'
        }
    }

    axios(options).then(res => {
        callback(res.data);
    }).catch(error => {
        if (error.response.status === 404) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location = '/';
        } else {
            console.log(error.response);
        }
    })
}

export function resetPassword(callback, object) {
    const options = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/password/reset',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        data: object
    }

    axios(options).then(res => {
        callback(res.data);
    }).catch(error => {
        callback();
        alert('The link is expired or not available anymore.')
    })
}

export function requestResetPassword(callback, email) {
    const options = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/password/create',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        data: {
            email: email
        }
    }

    axios(options).then(res => {
        callback(res.data);
    }).catch(error => {
        callback();
        alert('This email does not match any email in our database.')
    })
}

export function saveLicence(callback, objectArray = {}) {
    const licence_name = objectArray.licence_name ? objectArray.licence_name : '';
    const licence_number = objectArray.licence_number ? objectArray.licence_number : '';

    const options = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/save_licence',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        data: {
            licence_name: licence_name,
            licence_number: licence_number
        }
    }

    axios(options).then(res => {
        const result = res.data.message;
        callback(result);
    }).catch(error => {
        callback();
        alert(error)
    })
}

export function getAddress(callback, address) {
    const options = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/get_address',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        data: {
            address: [
                address
            ]
        }
    }

    axios(options).then(res => {
        callback(res.data);
    }).catch(error => {
        callback();
    })
}

export function inviteClubUser(callback, objectArray = {}) {
    const email = objectArray.email ? objectArray.email : '';

    const options = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/invite_user',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        data: {
            email: email,
        },
    }

    axios(options).then(res => {
        callback(res.data.message);
    }).catch((res) => {
        callback([]);
        alert('Error, try again later');
    })
}

export function updateClubUser(callback, objectArray = {}) {
    const user_id = objectArray.user_id ? objectArray.user_id : '';
    const status = objectArray.status ? objectArray.status : false;

    const options = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/approve_user',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        data: {
            user_id: user_id,
            status: status,
        },
    }

    axios(options).then(res => {
        callback(res.data.message);
    }).catch((res) => {
        callback([]);
        alert('Error, try again later');
    })
}

export function updateUserDetail(callback, objectArray = {}) {
    const email = objectArray.email ? objectArray.email : '';
    const name = objectArray.name ? objectArray.name : '';
    const phone = objectArray.phone ? objectArray.phone : '';
    const club_user_id = objectArray.club_user_id ? objectArray.club_user_id : null;

    const options = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/update_profile',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        data: {
            email: email,
            name: name,
            phone: phone,
            club_user_id: club_user_id
        },
    }

    axios(options).then(res => {
        callback(res.data.message);
    }).catch((res) => {
        callback('error');
        alert('Error updating your details, please try again later');
    })
}

export function requestRebate(callback) {
    const options = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/request_rebates',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        }
    }

    axios(options).then(res => {
        if(res.data.code === 201) {
            callback(res.data.message);    
        } else {
            callback(true);
        }
    }).catch((error) => {
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })
}