import React, { Component } from "react";
import {
  Grid,
  Image,
  Header,
  Button,
  Icon,
  Placeholder,
  Input,
  Label,
} from "semantic-ui-react";
import AddToCartNumber from "../parts/AddToCartNumber";
import { formatCurrency, calculateGST } from "../utils/Utils";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { applyCoupon, deleteCoupon, addCouponProducts } from "../action/cart";
import TagManager from "react-gtm-module";

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  applyCoupon,
  deleteCoupon,
  addCouponProducts,
};

class CartRender extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: "",
    };
  }

  componentDidUpdate() {
    if (
      this.props.cart.coupon !== "" &&
      !this.props.cart.loading &&
      this.props.cart.couponLoading
    ) {
      this.props.addCouponProducts();
    }
  }

  handleApplyCoupon() {
    if (this.state.code !== "") {
      this.props.applyCoupon(this.state.code, this.props.cart.cartItems);
      this.props.addCouponProducts();
    }
  }

  handleChangeCode(e) {
    this.setState({
      code: e.currentTarget.value.toUpperCase(),
    });
  }

  handleDeleteCoupon(e) {
    this.setState({
      code: "",
    });
    this.props.deleteCoupon(this.state.code, this.props.cart.cartItems);
  }

  render() {
    let totalCartPrice = 0;
    let rebate = 0;

    const cartItems = this.props.cart.cartItems;
    const isFirstLoading = this.props.cart.firstLoading;
    let cartRender = () => {
      if (cartItems.length > 0) {
        cartItems.map((value) => {
          totalCartPrice += value.price * value.quantity;
          rebate += value.rebate * value.quantity;

          return null;
        });
      }

      if (isFirstLoading === true) {
        const arrayLoop = [1, 2];

        return (
          <Grid>
            {arrayLoop.map((value, key) => (
              <Grid.Row stretched key={key} verticalAlign="middle">
                <Grid.Column width={3}>
                  <Placeholder>
                    <Placeholder.Image />
                  </Placeholder>
                </Grid.Column>

                <Grid.Column width={4} className="noPadding">
                  <Placeholder>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder>
                </Grid.Column>

                <Grid.Column width={6} className="noPaddingRight">
                  <Placeholder>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder>
                </Grid.Column>

                <Grid.Column
                  width={3}
                  textAlign="right"
                  className="noPaddingLeft"
                >
                  <Placeholder>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder>
                </Grid.Column>
              </Grid.Row>
            ))}
          </Grid>
        );
      } else {
        if (cartItems.length > 0) {
          const currentCartItems = [];
          cartItems.forEach((item) => {
            currentCartItems.push({
              item_name: item.name,
              item_id: item.id,
              price: item.price,
              item_sku: item.product_sku,
              quantity: item.quantity,
            });
          });
          return (
            <Grid>
              {cartItems.map((item, key) => {
                let totalListPrice = item.price * item.quantity;
                return (
                  <Grid.Row stretched key={key} verticalAlign="middle">
                    <Grid.Column width={3}>
                      <div className="cartLine">
                        <Image
                          centered
                          className="productImage"
                          src={item.image_link}
                        />
                      </div>
                    </Grid.Column>

                    <Grid.Column width={4} className="noPadding">
                      {(() => {
                        if (
                          parseInt(item.price) === 0 ||
                          parseInt(item.price) < 0
                        ) {
                          return (
                            <div>
                              <Input
                                type="number"
                                placeholder="0"
                                value={item.quantity}
                                action
                                transparent
                                size="mini"
                                className="borderRadius23 border1"
                              >
                                <Button
                                  disabled
                                  className="transparent mini paddingLeftRight5"
                                  icon
                                >
                                  <Icon name="minus" className="small" />
                                </Button>
                                <input
                                  size="small"
                                  className="alignCenter mini maxWidth15"
                                />
                                <Button
                                  disabled
                                  className="transparent mini paddingLeftRight5"
                                  icon
                                >
                                  <Icon name="plus" className="small" />
                                </Button>
                              </Input>
                            </div>
                          );
                        } else {
                          return (
                            <AddToCartNumber
                              product={item}
                              description={false}
                              size="mini"
                            />
                          );
                        }
                      })()}
                    </Grid.Column>

                    <Grid.Column width={6} className="noPaddingRight">
                      <p className="noMargin small">
                        <strong>{item.name}</strong>
                      </p>
                      <p className="lightGray small">{item.quantity_type}</p>
                    </Grid.Column>

                    <Grid.Column
                      width={3}
                      textAlign="right"
                      className="noPaddingLeft"
                    >
                      <strong className="primaryColor small">
                        {formatCurrency(totalListPrice)}
                      </strong>
                    </Grid.Column>
                  </Grid.Row>
                );
              })}

              <Grid.Row verticalAlign="bottom">
                <Grid.Column computer={11} tablet={11} mobile={16}>
                  {(() => {
                    if (
                      this.props.cart.coupon &&
                      this.props.cart.coupon !== ""
                    ) {
                      return (
                        <div>
                          <Label as="a" className="marginBottom20">
                            {this.props.cart.coupon}
                            <Icon
                              name="close"
                              onClick={this.handleDeleteCoupon.bind(this)}
                            />
                          </Label>
                        </div>
                      );
                    }
                  })()}

                  <Input
                    labelPosition="right"
                    type="text"
                    placeholder="COUPON CODE"
                  >
                    <input
                      value={this.state.code}
                      onChange={this.handleChangeCode.bind(this)}
                    />
                    <Button
                      loading={this.props.loading}
                      disabled={this.props.loading}
                      primary
                      fluid
                      className="regular borderRadius5 normal"
                      onClick={this.handleApplyCoupon.bind(this)}
                    >
                      Apply
                    </Button>
                  </Input>
                </Grid.Column>
                <Grid.Column
                  computer={5}
                  tablet={5}
                  mobile={16}
                  textAlign="right"
                >
                  <Grid stackable verticalAlign="bottom">
                    <Grid.Row>
                      <Grid.Column computer={3} tablet={3} movile={1}>
                        Total:
                      </Grid.Column>
                      <Grid.Column computer={13} tablet={13} mobile={15}>
                        {rebate === 0 ? (
                          ""
                        ) : (
                          <p className="cub-yellow bold small noMargin">
                            + {formatCurrency(rebate)} Credit
                          </p>
                        )}
                        <Header size="huge" className="noMarginTop">
                          {formatCurrency(totalCartPrice)}
                          <Header.Subheader className="small lightGray noMargin">
                            Inc {formatCurrency(calculateGST(totalCartPrice))}{" "}
                            GST
                          </Header.Subheader>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Grid>
                    <Grid.Row verticalAlign="bottom">
                      <Grid.Column width={16}>
                        <Link
                          to="/checkout"
                          onClick={() => {
                            TagManager.dataLayer({
                              dataLayer: {
                                ecommerce: null,
                              },
                            });

                            TagManager.dataLayer({
                              dataLayer: {
                                event: "begin_checkout",
                                ecommerce: {
                                  items: currentCartItems,
                                },
                              },
                            });
                          }}
                        >
                          <Button
                            id="GoToCheckout"
                            primary
                            fluid
                            className="regular borderRadius5 normal"
                            loading={
                              this.props.cart.coupon &&
                              this.props.cart.couponLoading
                                ? true
                                : false
                            }
                            disabled={
                              this.props.cart.coupon &&
                              this.props.cart.couponLoading
                                ? true
                                : false
                            }
                          >
                            Secure Checkout
                          </Button>
                        </Link>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          );
        } else {
          return (
            <Grid stretched className="minHeight450">
              <Grid.Row verticalAlign="middle" centered>
                <Grid.Column width={16} textAlign="center">
                  <Header className="largeMedium lightGray noMargin">0</Header>
                  <Icon
                    name="cart"
                    size="massive"
                    className="center lightGray"
                  ></Icon>
                  <Header className="largeMedium">Your Cart is empty!</Header>
                  <Link to="/shop">
                    <Button
                      primary
                      fluid
                      className="regular borderRadius10 normal"
                    >
                      Browse Categories
                    </Button>
                  </Link>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          );
        }
      }
    };

    return cartRender();
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartRender);
