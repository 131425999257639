import React, { Component } from "react";
import { Divider, Grid, Header } from 'semantic-ui-react';
import { connect } from "react-redux";
import { pageLogin, pageNotLogin } from '../action/view';
import BackButton from '../parts/BackButton';
import { Link } from 'react-router-dom';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageLogin,
    pageNotLogin
};

class AflClubActivation extends Component {
    constructor(props) {
        super(props);

        this.props.pageNotLogin();
    }

    render() {
        return (
            <Grid padded className='loginBg'>
                <Grid.Row>
                    <Grid.Column width={16} className='marginTop30 alignCenter'>
                        <Header as='h2'>
                            AFL Club Activation Campaign Terms and Conditions
                        </Header>

                        <div className='maxWidth70p center padding15 alignLeft'>
                            <Header as='h3'>1. PROMOTER</Header>
                            <p>
                                CUB Pty Ltd (ABN 76 004 056 106), 58 Queens Bridge Street, Southbank VIC 3006.
                            </p>

                            <Header as='h3'>2. WHO CAN ENTER?</Header>
                            <p>Only Australian residents who:</p>
                            <ol>
                                <li>are aged 18 or over;</li>
                                <li>are an authorised representative (e.g. committee member) of an Australian Rules Football community sporting club registered and located in Victoria (Football Club) that is also a registered Club Connect member that is invited to enter the competition; and</li>
                                <li>can take the prize on the date specified by the Promoter.</li>
                            </ol>

                            <Header as='h3'>3. WHO CAN'T ENTER?</Header>
                            <ol>
                                <li>Club Connect members that are not an Australian Rules Football community sporting club (e.g.soccer, tennis, basketball).</li>
                                <li>Existing CUB Pty Ltd customers (excluding Club Connect customers).</li>
                                <li>Directors, officers, management and employees (and their immediate families) of:
                                    <ol>
                                        <li>the Promoter; and</li>
                                        <li>the agencies or companies associated with this competition.</li>
                                    </ol>
                                </li>
                            </ol>

                            <Header as='h3'>4. COMPETITION PERIOD</Header>
                            <p>
                                12.01am (AEST) on 01/06/2022 to 11.59pm (AEST) on 31/07/2022.
                            </p>

                            <Header as='h3'>5. WEBSITE</Header>
                            <p>
                                <Link to='/'> www.clubconnect.net.au</Link>
                            </p>

                            <Header as='h3'>6. QUALIFYING PURCHASE</Header>
                            <p>
                                $500 or more in one transaction on any product/s on the Website.
                            </p>

                            <Header as='h3'>7. ENTRY INSTRUCTIONS</Header>
                            <p>
                                A Football Club (or its authorised representative) must, during the Competition Period:
                            </p>
                            <ol style={{ listStyleType: 'lower-alpha' }} className="mb-4">
                                <li>visit the Website;</li>
                                <li>log in to Club Connect using their Football Club login details; and</li>
                                <li>make, and pay for, a Qualifying Purchase (for the Football Club) on the Website,</li>
                            </ol>
                            <p>
                                to receive 1 automatic entry into the draw.Once you have placed your paid order, you will receive an email acknowledging the details of your order. All orders placed via Club Connect are treated in accordance with the Club Connect Terms and Conditions at <Link to='/terms-conditions'>www.clubconnect.net.au/terms-conditions</Link>.
                            </p>
                            <p>
                                The Promoter is not responsible if your mobile device/desktop is not sufficiently capable for the purpose of submitting an entry.
                            </p>
                            <p>
                                Products purchased via the Website are intended for consumption on premise at appropriately licensed club rooms following all guidelines in relation to the responsible serving of alcohol.
                            </p>

                            <Header as='h3'>8. HOW MANY WINNERS WILL THERE BE AND HOW WILL THEY BE CHOSEN? </Header>
                            <p>There will be 1 winner determined in respect of this competition.</p>
                            <p>There will be 1 draw conducted.</p>
                            <p>The draw will be held at 11am (AEST) on 01/08/2022 at 58 Queens Bridge Street, Southbank VIC 3006.</p>
                            <p>The first valid entry drawn randomly from the entries received during the Competition Period will win the prize.</p>
                            <p>The Promoter may draw additional reserve entries in a draw and record them in order, in case a winning entry/entrant is deemed invalid or a prize is unclaimed (<strong>Reserve Entrants</strong>).</p>

                            <Header as='h3'>9. WHAT CAN I WIN?</Header>
                            <p>
                                There is 1 prize available.
                            </p>
                            <p>
                                The prize is one senior AFL football player visiting the winning Football Club for up to 1 hour on a Tuesday night between 09/08/2022 and 30/08/2022, subject to availability (exact date and time to be agreed upon and negotiated between the Promoter, the AFL and the winning Football Club). The prize is valued at $7,500.
                            </p>
                            <p>
                                The AFL will nominate the AFL player to attend the winning Football Club and is subject to change once confirmed.  If the player confirmed is required to change for any reason what so ever, a player of the same level of seniority will be selected by the AFL. There may be restrictions on what the AFL player is permitted to do during the appearance and this will be confirmed at the time that the AFL player is confirmed.
                            </p>

                            <Header as='h3'>10. Total prize pool </Header>
                            <p>
                                The total prize pool is $7,500.
                            </p>

                            <Header as='h3'>11. WHO WILL THE PRIZE GO TO?  </Header>
                            <p>
                                The prize will be awarded to the winning Football Club.
                            </p>
                            <p>
                                The winning Football Club must remain a Club Connect Member until the prize is awarded/fulfilled.
                            </p>
                            <p>
                                The winning Football Club must not, in the Promoter's determination, have a bad credit history or be a credit risk, due to breaching or having at any time breached credit arrangements with the Promoter.
                            </p>

                            <Header as='h3'>12.	HOW MANY TIMES CAN I ENTER? </Header>
                            <p>
                                There is no limit on the number of entries a Football Club can submit, provided each entry is submitted separately in accordance with these Terms and Conditions.
                            </p>
                            <p>
                                For the avoidance of doubt, only one entry will be recorded per Qualifying Purchase transaction, regardless of the value of the transaction or the number of products purchased.
                            </p>

                            <Header as='h3'>13. HOW AND WHEN WILL THE WINNER/S BE INFORMED?</Header>
                            <p>
                                The winning Football Club will be notified by phone and in writing on the same day of the draw.
                            </p>
                            <p>
                                The winning Football Club's name will be published on the <a href='https://www.cubpromos.com.au/winners' target='_blank' rel="noopener noreferrer">www.cubpromos.com.au/winners</a> on 01/08/2022 for a period of 28 days.
                            </p>

                            <Header as='h3'>14.	UNCLAIMED PRIZE/S</Header>
                            <p><strong>Prize claim date:</strong> 5pm (AEST) on 03/08/2022. </p>
                            <p><strong>Unclaimed prize determination:</strong> 9am (AEST) on 04/08/2022 at the same location as the original draw.</p>
                            <p>
                                If the prize has not been accepted or claimed by the prize claim date above or if, after making all reasonable attempts, the Promoter can't contact the winner (or the winner does not contact the Promoter) by the prize claim date above, the relevant entry/ies will be discarded and the Promoter will re-award the relevant prize/s to Reserve Entrant/s and/or carry out an unclaimed prize draw at the date, time and place stated above to randomly distribute the prize.
                            </p>
                            <p>
                                Any winning Football Club will be informed by phone and in writing within two days of determination and the Football Club's name will be published on the <a href='https://www.cubpromos.com.au/winners' target='_blank' rel="noopener noreferrer">www.cubpromos.com.au/winners</a> on 05/08/2022 for a period of 28 days.
                            </p>

                            <Header as='h3'>15.	COLLECTION AND USE OF YOUR PERSONAL INFORMATION</Header>
                            <p>
                                If you are a winner, you and (if applicable) your Football Club members must take part in all publicity, photography and other promotional activity as the Promoter requires, without any compensation.  You/your Football Club members consent to the Promoter using your name/s and image/s in any promotional or advertising activity.
                            </p>
                            <p>
                                The Promoter may collect your/your Football Club members' personal information directly or through its agents or contractors.  The Promoter will use your/your Football Club members' personal information to conduct and manage the competition. The Promoter may disclose your/your Football Club members' personal information to its related companies, agents and contractors to assist in conducting this competition, communicating with you/your Football Club members or storing data. This may include disclosures to organisations outside Australia including in places such as the Philippines, New Zealand, the UK and the Netherlands.
                            </p>
                            <p>
                                By entering, you consent to the Promoter keeping your personal information on its database to use for future marketing purposes, including contacting you by electronic messaging.  By entering, you consent to receive email or SMS messages from the Promoter without any functional unsubscribe facility.
                            </p>
                            <p>
                                The Promoter’s Privacy Policy (see <a href='http://cub.com.au/privacy-policy/' target='_blank' rel='noopener noreferrer'>http://cub.com.au/privacy-policy/</a>) includes information about:
                            </p>
                            <ol>
                                <li>
                                    how to seek access to the personal information the Promoter holds about you and seek correction of the information; and
                                </li>
                                <li>
                                    how to complain about a privacy breach and how the Promoter will deal with such a complaint.
                                </li>
                            </ol>

                            <Header as='h3'>16.	RESPONSIBLE DRINKING</Header>
                            <p><strong>Enjoy alcohol responsibly.</strong> Consider the safe drinking levels recommended in the National Health and Medical Research Council Australian Guidelines to Reduce Health Risks from Drinking Alcohol – see: <a href='https://www.nhmrc.gov.au/about-us/publications/australian-guidelines-reduce-health-risks-drinking-alcohol' target='_blank' rel='noopener noreferrer'>https://www.nhmrc.gov.au/about-us/publications/australian-guidelines-reduce-health-risks-drinking-alcohol</a>. If this competition is conducted in NSW, see also the NSW Liquor Competition Guidelines and Intoxication Guidelines at <a href='https://www.liquorandgaming.nsw.gov.au/documents/gl/gl4001-liquor-promotion-guidelines.pdf' target='_blank' rel='noopener noreferrer'>https://www.liquorandgaming.nsw.gov.au/documents/gl/gl4001-liquor-promotion-guidelines.pdf</a>. Where relevant, your participation in this competition may be subject to the liquor serving policy of businesses conducting the competition and/or providing the prize.</p>
                            <p>All advertising material for this competition will include a message regarding responsible consumption of alcohol.  </p>

                            <Divider />

                            <p>
                                1. These Terms and Conditions incorporate and must be read together with the details outlined in the table above.  Information about prizes and how to enter forms part of these Terms and Conditions. By entering, you accept these Terms and Conditions.
                            </p>

                            <Header as='h3'>ENTRY</Header>
                            <p>
                                2. Your entry must be received during the Competition Period and will be deemed to be received only when received by the Promoter. Where relevant, submitted entry forms are the Promoter's property. If you return a Qualifying Purchase your entry may be deemed invalid at the Promoter's discretion (unless the product is defective). The Promoter is not liable for any problems with communications networks.  You are responsible for your own costs associated with entering. If you enter using automatically generated entries or multiple phone numbers/email addresses/addresses/aliases, you may be disqualified.
                            </p>

                            <Header as='h3'>PRIZES</Header>
                            <p>
                                3. Prize/s and all elements of prize/s must be taken as and when offered or will be forfeited, and if forfeited, the Promoter will not be liable. Prize/s are subject to any additional terms and conditions imposed by the relevant supplier or the Promoter, including, as relevant:
                            </p>
                            <ol style={{ listStyleType: 'lower-alpha' }} className="mb-4">
                                <li>validity period/s;</li>
                                <li>booking and availability of flights, accommodation and events;</li>
                                <li>conditions of travel and conditions of entry into any event venues (including behaviour requirements, COVID safety requirements and applicable dress codes);</li>
                                <li>conditions of ticket validity and any restrictions on ticket on-sale or transfer;</li>
                                <li>travel dates and specified travel exclusion periods;</li>
                                <li>any additional fees (payable by you) relating to changes made by you/your companion to a travel/event prize after it has been booked;</li>
                                <li>the requirement to obtain all necessary documents to travel to any overseas location specified, including a current and valid passport and any visas required;</li>
                                <li>a requirement to present your credit card when checking in to accommodation; and</li>
                                <li>a requirement that any person taking the prize that is aged under 18 years old must be accompanied by their parent or guardian at all times.</li>
                            </ol>
                            <p>
                                4. You/your Cricket Club members are responsible for all other unspecified costs related to the prize, including meals, transport, insurance (including excesses), in-room charges, additional taxes, mobile or data plans, installation costs, costs associated with ongoing use of the prize, etc.  Event tickets can only be used once and will be deemed invalid if copied.  The Promoter does not control entry to the relevant event location. The winner/s must keep their tickets safe and the Promoter will not replace lost or stolen tickets.
                            </p>
                            <p>
                                5.	The Promoter is not responsible for any dispute between you and any person with whom you choose to, or choose not to, share the prize.
                            </p>
                            <p>
                                6.	Where relevant, the Promoter accepts no responsibility if one or more of the events or activities awarded as part of the prize are abandoned, called off or postponed for any reason.  In that case you/your Cricket Club members forfeit your entitlement to that event/activity.  Similarly, while the Promoter will make all reasonable efforts to ensure the participation of any specified celebrity/ies in the prize element/s, the Promoter and prize suppliers exclude, so far as legally permissible, all liability if for any reason the celebrity/ies do not attend the prize element/s due to circumstances beyond the reasonable control of the Promoter and prize suppliers (e.g. illness).  Unless otherwise specified by the Promoter, you or your Cricket Club will not be given cash or any alternative prize as a substitute for this altered prize element.
                            </p>

                            <Header as='h3'>GENERAL</Header>
                            <p>
                                7.	Any failure by you or (if applicable) your Cricket Club members to comply with the conditions imposed by the prize supplier(s) may result in the prize being cancelled or withdrawn without liability for the Promoter or the prize supplier(s).
                            </p>
                            <p>
                                8.	For event-based/travel prizes, you and (if applicable) your Cricket Club members accept that some aspects of the prize may be inherently risky and that the prize may involve dangerous activities.
                            </p>
                            <p>
                                9.	If specified, in order to participate in the activity/activities awarded as part of the prize, you/your Cricket Club members must comply with any applicable conditions (e.g. height, weight, health and fitness requirements).  You must ensure that you/your Cricket Club members are healthy and fit enough to take the prize.  You/your Cricket Club members must follow all requirements of the people responsible for managing the relevant activity/ies.
                            </p>
                            <p>
                                10.	You/your Cricket Club members must, if required by the Promoter, sign disclaimer and release forms provided by the Promoter in favour of the Promoter and other parties before taking the prize.  If you or any Cricket Club members do not sign, your entry will be deemed invalid and you will lose any entitlement to a prize.
                            </p>
                            <p>
                                11.	If you or your entry are deemed by the Promoter to breach these Terms and Conditions including if the prize is on set dates and you are unable to take the prize on the relevant date/s, your entry (or at the Promoter's discretion, all of your entries) may be discarded.  The Promoter may, at any time, require you/your Cricket Club to produce documentation to establish to the Promoter's satisfaction the validity of your Cricket Club's entries (including documentation establishing your identity, age, place of residence and place of employment).  Failure by the Promoter to enforce any of its rights at any stage does not waive those rights.
                            </p>
                            <p>
                                12.	You must not:
                            </p>
                            <ol style={{ listStyleType: 'lower-alpha' }} className="mb-4">
                                <li>tamper with the entry process;</li>
                                <li>engage in any conduct that may jeopardise the fair and proper conduct of the competition;</li>
                                <li>act in a disruptive, annoying, threatening, abusive or harassing manner;</li>
                                <li>do anything that may diminish the good name or reputation of the Promoter or any of its related entities or of the agencies or companies associated with this competition;</li>
                                <li>breach any law; or</li>
                                <li>behave in a way that is otherwise inappropriate.</li>
                            </ol>
                            <p>
                                13.	If your Cricket Club members can take the prize with you, you are responsible for your Cricket Club members and the Promoter may disqualify all entries from, and prohibit further participation in this competition by, you or any or all of your Cricket Club members if they breach these conditions, whether or not legally bound by them.
                            </p>
                            <p>
                                14.	If you (or your Cricket Club members, if applicable), in the opinion of the Promoter (and/or a medical professional, as relevant to the circumstances), are intoxicated, under the influence of alcohol or any other drug, behave aggressively or offensively, or behave in a manner which may diminish the good name or reputation of the Promoter or any of its related entities or the agencies or companies associated with this competition, is contrary to law or is otherwise inappropriate, the Promoter may cancel the prize or restrict you (and your Cricket Club members) from participating in any elements of the prize, at its discretion.
                            </p>
                            <p>
                                15.	The Promoter is not liable for entries, prize claims or correspondence that are misplaced, misdirected, delayed, lost, incomplete, illegible or incorrectly submitted including failure of any third parties to forward entry forms for inclusion in the draw, where relevant.
                            </p>
                            <p>
                                16.	If any dispute arises between you and the Promoter concerning the conduct of this promotion or claiming a prize, the Promoter will take reasonable steps to consider your point of view, taking into account any facts or evidence you put forward, and to respond to it fairly within a reasonable time.  In all other respects, the Promoter’s decision in connection with all aspects of this competition is final.
                            </p>
                            <p>
                                17.	Prizes cannot be transferred or exchanged nor (except where cash is specified) redeemed for cash.  Without limiting any other term of these Terms and Conditions, all prizes (and elements of prizes) must be taken as and when specified, or will be forfeited with no replacement.  The prize values are correct as at the date of preparing these Terms and Conditions and include any applicable GST.  The Promoter is not responsible for any change in prize value.  You agree that if a prize (or element of a prize) is unavailable for any reason the Promoter may provide another item of equal or higher value, subject to any necessary approval by the state/territory gaming authorities.
                            </p>
                            <p>
                                18.	If publication will take place, by entering, you request that your full address not be published.
                            </p>
                            <p>
                                19.	If this competition cannot run as planned for any reason beyond the Promoter's control, for example due to software, hardware or communications issues, unauthorised intervention, tampering, fraud or technical failure, government directives, pandemic, public health orders and the like, the Promoter may end, change, suspend or cancel the competition or disqualify affected entries/entrants, subject to any necessary approval by the state/territory gaming authorities.
                            </p>
                            <p>
                                20.	The Promoter is not responsible for any tax implications arising from you winning a prize.  You should seek independent financial advice.  If for GST purposes this competition results in any supply being made for non-monetary consideration, you must follow the Australian Taxation Office’s stated view that where the parties are at arm’s length, goods and services exchanged are of equal GST inclusive market values.
                            </p>

                            <Header as='h3'>LIABILITY</Header>
                            <p>
                                21.	Nothing in these Terms and Conditions restricts, excludes or modifies any consumer rights under any statute including the Competition and Consumer Act 2010 (Cth).
                            </p>
                            <p>
                                22.	Subject to the previous paragraph, the Promoter and the agencies and companies associated with this competition are not liable (including in negligence) for any loss (including indirect, special or consequential loss or loss of profits), expense, damage, personal injury (including allergies, skin conditions or other reactions, as relevant), illness or death suffered in connection with this competition or any prize, except for any liability which under statute cannot be excluded (in which case that liability is limited to the greatest extent allowed by law).
                            </p>
                            <p>
                                23.	Without limiting the previous paragraph, the Promoter and the agencies and companies associated with this competition are not liable for any loss of, damage to or delay in delivery of prize/s, or for any damage that occurs to displayed prize/s (where relevant).  Unless otherwise specified, prize/s will only be delivered to addresses in Australia.
                            </p>
                            <p>
                                24.	This competition is in no way sponsored, endorsed or administered by, or associated with any social media platform, including Facebook, Instagram and Twitter.  You provide your information to the Promoter and not to any social media platform.  You completely release any relevant social media platforms from any and all liability.
                            </p>
                        </div>
                        <div className='login maxWidth70p center marginTop20'>
                            <BackButton />
                        </div>

                    </Grid.Column>

                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AflClubActivation);