import * as Constants from "../Constants";
import _ from "lodash";

const cart = (state = Constants.cartInitialState, action) => {
  switch (action.type) {
    case Constants.UPDATE_CART:
      return {
        cartItems: action.payload.cart,
        loading: false,
        firstLoading: state.firstLoading,
        coupon: state.coupon,
        couponLoading: true,
      };
    case Constants.UPDATE_COUPON_PRODUCTS:
      let originalCartItems = state.cartItems;
      // remove free products from previous cartItems if available
      _.remove(originalCartItems, function (o) {
        return parseInt(o.price) <= 0;
      });

      if (Array.isArray(action.payload)) {
        action.payload.forEach(element => {
          originalCartItems.push(element);
        });
      } else {
        originalCartItems.push(action.payload);
      }
      
      return {
        cartItems: originalCartItems,
        loading: false,
        firstLoading: state.firstLoading,
        coupon: state.coupon,
        couponLoading: false,
      };
    case Constants.GET_CART:
      return {
        cartItems: action.payload.cart,
        loading: state.loading,
        firstLoading: false,
        coupon: action.payload.coupon,
        couponLoading: true,
      };
    case Constants.UPDATE_CART_LOADING:
      return {
        cartItems: state.cartItems,
        loading: true,
        firstLoading: state.firstLoading,
        coupon: state.coupon,
        couponLoading: state.couponLoading,
      };
    case Constants.APPLY_COUPON:
      let cartItems = state.cartItems;
      return {
        cartItems: cartItems,
        loading: false,
        firstLoading: state.firstLoading,
        coupon: action.code,
        couponLoading: true,
      };
    case Constants.REMOVE_COUPON:
      let currentCartItems = state.cartItems;
      // remove free products from previous cartItems if available
      _.remove(currentCartItems, function (o) {
        return parseInt(o.price) === 0 || parseInt(o.price) < 0;
      });

      return {
        cartItems: currentCartItems,
        loading: false,
        firstLoading: state.firstLoading,
        coupon: "",
        couponLoading: state.couponLoading,
      };
    case Constants.GET_CART_LOADING:
      return {
        cartItems: state.cartItems,
        loading: state.loading,
        firstLoading: true,
        coupon: state.coupon,
        couponLoading: state.couponLoading,
      };
    case Constants.APPLY_PERCENTAGE:
      _.remove(state.cartItems, function (o) {
        return parseInt(o.price) === 0 || parseInt(o.price) < 0;
      });

      let cartTotal = state.cartItems.reduce(
        (sum, item) => sum + item.price * item.quantity,
        0
      );

      let percentage = {
        cart_item_id: 5,
        image_link: "/img/ClubConnect_Landscape.svg",
        name: "Percentage Off Order Promotion",
        price:
          (cartTotal * action.payload.percentage) / 100 <= action.payload.limit
            ? -(cartTotal * action.payload.percentage) / 100
            : -action.payload.limit,
        product_sku: "",
        product_type: "",
        quantity: 1,
        quantity_type: "Maximum of $" + action.payload.limit,
        rebate: 0,
      };
      state.cartItems.push(percentage);

      return {
        cartItems: state.cartItems,
        loading: false,
        firstLoading: state.firstLoading,
        coupon: state.coupon,
        couponLoading: false,
      };
    default:
      return state;
  }
};

export default cart;
