import React, { Component } from "react";
import ProductList from '../parts/ProductList';
import {getProductList} from '../utils/ProductAPI';
import { connect } from "react-redux";
import { pageNotLogin } from '../action/view';
import { Grid } from 'semantic-ui-react';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageNotLogin,
};

const limit = 12;

class CategoryList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'products': [],
            'isLoading': true,
            'sortOrder': 'asc',
            'currentFilter': {
                price: [],
                brands: [],
                cates: [],
                best:[]
            }
        }
    }
    
    componentDidMount() {
        this.handleSortList('popular');
        this.props.pageNotLogin();
    }

    handleFilterlist(filter) {
        const brandSlug = filter.brands;
        const cateSlug = filter.cates = [this.props.match.params.slug];
        const price = filter.price;
        const best = filter.best.length ? 'true' : '';

        this.setState({
            'isLoading': true
        })

        // get current product number
        const currentProductList = this.state.products.products;
        let currentProductNumber = currentProductList.length;
        
        if (currentProductNumber < limit) {
            currentProductNumber = limit
        }

        getProductList((result) => this.setState({
            'products': result,
            'isLoading': false,
            'currentFilter': filter
        }), { 'cateSlug': cateSlug, 'brandSlug': brandSlug, 'price': price, 'sortOrder': this.state.sortOrder, 'best': best }, currentProductNumber)
    }

    handleSortList(sort) {
        const cateSlug = this.props.match.params.slug;
        const currentFilter = {...this.state.currentFilter};
        currentFilter.cates = [cateSlug];
        this.setState({
            'isLoading': true
        })

        const brandSlug = this.state.currentFilter.brands;
        const price = this.state.currentFilter.price;

        // get current product number
        const currentProductList = this.state.products.products ? this.state.products.products : [];
        const currentProductNumber = currentProductList.length === 0 ? limit : currentProductList.length;

        getProductList((result) => this.setState({
            'products': result,
            'isLoading': false,
            'sortOrder': sort,
            'currentFilter': currentFilter
        }), { 'cateSlug': [cateSlug], 'brandSlug': brandSlug, 'price': price, 'sortOrder': sort, 'best': this.state.best }, currentProductNumber)
    }

    handleNextPage(sort) {
        const cateSlug = this.props.match.params.slug;

        this.setState({
            'isLoading': true
        })

        const brandSlug = this.state.currentFilter.brands;
        const price = this.state.currentFilter.price;

        // get current product number
        const currentProductList = this.state.products.products;
        const currentProductNumber = currentProductList.length;
        
        getProductList((result) => this.setState({
            'products': result,
            'isLoading': false,
            'sortOrder': sort
        }), { 'cateSlug': [cateSlug], 'brandSlug': brandSlug, 'price': price, 'sortOrder': sort, 'best': this.state.best }, currentProductNumber + limit)
    }

    render() {
        return (
            <div>
                <ProductList title={this.props.match.params.slug} data={this.state.products} isAnimate={false} isLoading={this.state.isLoading} nextPage={(sort) => this.handleNextPage(sort)} sortList={(sort) => this.handleSortList(sort)} filterList={(filter) => this.handleFilterlist(filter)} currentFilter={this.state.currentFilter}/>
                {(() => {
                    if (this.props.match.params.slug === 'other' || this.props.match.params.slug === 'spirits' || this.props.match.params.slug === 'non-alcoholic-beverages' || this.props.match.params.slug === 'wine') {
                        return (
                            <Grid container >
                                <Grid.Row>
                                    <Grid.Column>
                                        <p className='small alignRight'>* More products coming soon.</p>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        )
                    }
                })()}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CategoryList);
