import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, pdf } from '@react-pdf/renderer';
import { saveAs } from "file-saver";
import { Button, Icon } from 'semantic-ui-react';
import { formatCurrency, calculateGST, calculateWET } from '../utils/Utils';
import * as Constants from '../Constants';

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#eaeaea',
        position: 'relative',
        width: '100%'
    },
    section: {
        padding: 20,
        flexGrow: 1,
        width: '50%'
    },
    thirdSection: {
        flexGrow: 1
    },
    row: {
        display: 'flex',
        flexFlow: 'row wrap',
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginLeft: 10,
        marginRight: 10
    },
    title: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold'
    },
    logo: {
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        width: '49px',
        marginBottom: '20px'
    },
    body: {
        fontSize: 9
    },
    normal: {
        fontSize: 11
    },
    line: {
        width: '100%',
        height: 0.5,
        backgroundColor: '#000',
        margin: '10 0'
    },
    space: {
        height: 12
    },
    backgroundImageSection: {
        position: 'absolute',
        top: '120px',
        left: '30%',
        zIndex: 9999,
        textAlign: 'center',
        width: '100%'
    },
    backgroundPaidImage: {
        width: '200px',
        height: 'auto'
    }
});

// Create Document Component
const PDFInvoice = (props) => {
    const products = props.order.products;
    const order = props.order;
    const CUBInvoice = [];
    const CUBPremiumInvoice = [];
    const ALBInvoice = [];

    // divide product into invoice space
    products.forEach(p => {
        switch (p.invoice) {
            case 'CUB':
                CUBInvoice.push(p);
                break;

            case 'CUBPremium':
                CUBPremiumInvoice.push(p);
                break;

            case 'ALB':
                ALBInvoice.push(p);
                break;

            default:
                CUBInvoice.push(p);
                break;
        }
    });

    // run through
    const renderInvoice = (products, type) => {
        let invoiceName = 'Carlton & United Breweries Pty Ltd';
        let invoiceABN = '76 004 056 106';

        switch (type) {
            case 'CUB':
                break;

            case 'CUBPremium':
                invoiceName = 'Asahi Premium Beverages Pty Ltd';
                invoiceABN = '49 077 568 480';
                break;

            case 'ALB':
                invoiceName = 'Asahi Beverages Pty Ltd';
                invoiceABN = '51 004 243 994';
                break;

            default:
                break;
        }

        let totalWet = 0;
        let totalPrice = 0;

        const productList = products.map((value, key) => {
            let wet = 0
            let gst = calculateGST(value.price);

            if (value.category.includes('wine') || value.category.includes('Wine')) {
                wet = calculateWET(value.price - gst);
                totalWet += (wet * value.quantity);
            }

            totalPrice += (value.quantity * value.price);

            return (
                <View key={key} style={[styles.row, { marginLeft: 30, marginRight: 30, marginBottom: 10 }]}>
                    <View style={[styles.thirdSection, { width: '35%' }]}>
                        <Text style={styles.body}>{value.name}</Text>
                    </View>
                    <View style={[styles.thirdSection, { width: '5%' }]}>
                        <Text style={[styles.body, { textAlign: 'right' }]}>{value.quantity}</Text>
                    </View>
                    <View style={[styles.thirdSection, { width: '15%' }]}>
                        <Text style={[styles.body, { textAlign: 'right' }]}>{formatCurrency((value.price - gst - wet) * value.quantity)} AUD</Text>
                    </View>
                    <View style={[styles.thirdSection, { width: '15%' }]}>
                        <Text style={[styles.body, { textAlign: 'right' }]}>{formatCurrency(wet * value.quantity)} AUD</Text>
                    </View>
                    <View style={[styles.thirdSection, { width: '15%' }]}>
                        <Text style={[styles.body, { textAlign: 'right' }]}>{formatCurrency(gst * value.quantity)} AUD</Text>
                    </View>
                    <View style={[styles.thirdSection, { width: '15%' }]}>
                        <Text style={[styles.body, { textAlign: 'right' }]}>{formatCurrency(value.price * value.quantity)} AUD</Text>
                    </View>
                </View>
            )
        })

        return (
            <Page size="A4" style={styles.page}>
                <View style={styles.row}>
                    <View style={[styles.section, { marginBottom: 0, paddingBottom: 0 }]}>
                        <Image style={styles.logo} src='/img/ClubConnect_Landscape.png' />
                        <Text style={styles.title}>Tax Invoice</Text>
                    </View>
                </View>

                <View style={styles.backgroundImageSection}>
                    <View style={[styles.section, { marginBottom: 0, paddingBottom: 0 }]}>
                        <Image style={styles.backgroundPaidImage} src='/img/paid.png' />
                    </View>
                </View>

                <View style={styles.row}>
                    <View style={styles.section}>
                        <Text style={styles.body}>
                            Contact Name: {props.order.name}
                        </Text>
                        <Text style={styles.body}>
                            Club: {props.order.club_register_name}
                        </Text>
                        <Text style={styles.body}>
                            Phone: {props.order.phone_contact}
                        </Text>
                        <Text style={styles.line}></Text>
                        <Text style={styles.body}>
                            {props.order.shipping_address}
                        </Text>
                        <Text style={styles.body}>
                            {props.order.suburb}
                        </Text>
                        <Text style={styles.body}>
                            {props.order.state + ' ' + props.order.postcode}
                        </Text>
                    </View>

                    <View style={[styles.section, { textAlign: 'right' }]}>
                        <Text style={styles.body}>
                            {invoiceName}
                        </Text>
                        <Text style={styles.body}>
                            ABN: {invoiceABN}
                        </Text>
                        <Text style={styles.space}></Text>
                        <Text style={styles.line}></Text>
                        <Text style={styles.body}>
                            58 Queensbridge Street
                        </Text>
                        <Text style={styles.body}>
                            Southbank
                        </Text>
                        <Text style={styles.body}>
                            VIC 3006
                        </Text>
                    </View>
                </View>

                <View style={styles.row}>
                    <View style={styles.section}>
                        <Text style={styles.normal}>
                            Order No: {props.order.order_no}
                        </Text>
                        <Text style={styles.normal}>
                            Order Date: {props.order.created_at}
                        </Text>
                    </View>

                    <View style={styles.section}>

                    </View>
                </View>

                <View style={[styles.row, { marginLeft: 30, marginRight: 30 }]}>
                    <View style={[styles.thirdSection, { width: '35%' }]}>
                        <Text style={styles.normal}>Product Name</Text>
                        <Text style={styles.line}></Text>
                    </View>
                    <View style={[styles.thirdSection, { width: '5%' }]}>
                        <Text style={[styles.normal, { textAlign: 'right' }]}>Qty</Text>
                        <Text style={styles.line}></Text>
                    </View>
                    <View style={[styles.thirdSection, { width: '15%' }]}>
                        <Text style={[styles.normal, { textAlign: 'right' }]}>Net Amount</Text>
                        <Text style={styles.line}></Text>
                    </View>
                    <View style={[styles.thirdSection, { width: '15%' }]}>
                        <Text style={[styles.normal, { textAlign: 'right' }]}>WET Amount</Text>
                        <Text style={styles.line}></Text>
                    </View>
                    <View style={[styles.thirdSection, { width: '15%' }]}>
                        <Text style={[styles.normal, { textAlign: 'right' }]}>GST Amount</Text>
                        <Text style={styles.line}></Text>
                    </View>
                    <View style={[styles.thirdSection, { width: '15%' }]}>
                        <Text style={[styles.normal, { textAlign: 'right' }]}>Gross Amount</Text>
                        <Text style={styles.line}></Text>
                    </View>
                </View>

                {productList}

                {(order.repayment && order.repayment === Constants.REPAYMENT_STATUS.paid) ?
                    <View style={[styles.row, { marginLeft: 30, marginRight: 30 }]}>
                        <View style={[styles.thirdSection, { width: '100%' }]}>
                            <Text style={styles.line}></Text>
                            <Text style={[styles.normal, { textAlign: 'left', marginBottom: '5px' }]}>Note: The payment for this order has been received</Text>
                        </View>
                    </View> : null
                }

                <View style={[styles.row, { marginLeft: 30, marginRight: 30 }]}>
                    <View style={[styles.thirdSection, { width: '80%' }]}>
                        <Text style={styles.line}></Text>
                        <Text style={[styles.body, { textAlign: 'right', marginBottom: '5px' }]}>Total Net</Text>
                        <Text style={[styles.body, { textAlign: 'right', marginBottom: '5px' }]}>Total WET Amount</Text>
                        <Text style={[styles.body, { textAlign: 'right', marginBottom: '5px' }]}>Total GST Amount</Text>
                        <Text style={[styles.body, { textAlign: 'right', marginBottom: '5px' }]}>Total Gross Amount</Text>
                        <Text style={[styles.body, { textAlign: 'right', marginBottom: '5px' }]}>Total Partnership Fund Used</Text>
                    </View>
                    <View style={[styles.thirdSection, { width: '20%' }]}>
                        <Text style={styles.line}></Text>
                        <Text style={[styles.body, { textAlign: 'right', marginBottom: '5px' }]}>{formatCurrency(totalPrice - calculateGST(totalPrice) - totalWet)} AUD</Text>
                        <Text style={[styles.body, { textAlign: 'right', marginBottom: '5px' }]}>{formatCurrency(totalWet)} AUD</Text>
                        <Text style={[styles.body, { textAlign: 'right', marginBottom: '5px' }]}>{formatCurrency(calculateGST(totalPrice))} AUD</Text>
                        <Text style={[styles.body, { textAlign: 'right', marginBottom: '5px' }]}>{formatCurrency(totalPrice)} AUD</Text>
                        <Text style={[styles.body, { textAlign: 'right', marginBottom: '5px' }]}>{formatCurrency(order.used_partner_fund ? order.used_partner_fund : 0)} AUD</Text>
                    </View>
                </View>
            </Page>
        )
    }

    return (
        <Document>
            {CUBInvoice.length > 0 && renderInvoice(CUBInvoice, 'CUB')}
            {CUBPremiumInvoice.length > 0 && renderInvoice(CUBPremiumInvoice, 'CUBPremium')}
            {ALBInvoice.length > 0 && renderInvoice(ALBInvoice, 'ALB')}
        </Document>
    )
};

const generatePdfDocument = async (order) => {
    const blob = await pdf((
        <PDFInvoice
            order={order}
        />
    )).toBlob();
    saveAs(blob, 'invoice-' + order.order_no + '.pdf');
};

const DownloadPDF = (props) => {
    if (props.customStyle && props.customStyle === true) {
        if (props.order !== '') {
            return (
                <Button basic icon className='primaryColor regular noBorder noShadow noPadding noBg' onClick={() => generatePdfDocument(props.order)}>
                    <Icon name='download' />
                    <span className='marginLeft10'>Download</span>
                </Button>
            );
        } else {
            return (
                <Button disabled={true} loading={true} basic icon className='primaryColor regular noPadding noBorder noShadow noBg'>
                    <Icon name='download' />
                    <span className='marginLeft10'>Download</span>
                </Button>
            );
        }
    } else {
        if (props.order !== '') {
            return (
                <Button basic size='small' icon className='primaryColor noBorder noShadow noBg' onClick={() => generatePdfDocument(props.order)}>
                    <span className='marginRight5'>Save Invoice</span>
                    <Icon name='download' />
                </Button>
            );
        } else {
            return (
                <Button disabled={true} loading={true} basic size='small' icon className='primaryColor noBorder noShadow noBg'>
                    <span className='marginRight5'>Save Invoice</span>
                    <Icon name='download' />
                </Button>
            );
        }
    }

};

export const PDFLink = React.memo(DownloadPDF, (prevProps, newProps) => {
    //compare props and if no change, return true, else false
    if (newProps.order === '') {
        return true
    } else {
        if (newProps.order !== prevProps.order) {
            return false
        }
    }
})