import React, { Component } from "react";
import { Grid, Header, Button, Icon } from 'semantic-ui-react';
import { myAuth } from '../utils/PrivateRoute';
import { connect } from "react-redux";
import { getUser } from '../action/user';
import TagManager from "react-gtm-module";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    getUser
};

class CheckoutCustomer extends Component {
    componentDidMount() {
        if(!this.props.user.user.id) {
            this.props.getUser();
        }
    }

    handleLogout() {
        TagManager.dataLayer({
            dataLayer: {
                'event': 'signing_out'
            }
        });
        
        myAuth.signout(() => {
            window.location.reload();
        });
    }

    render() {
        const user = this.props.user.user;
        
        return (
            <Grid verticalAlign='middle' stretched>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Icon name='checkmark' circular fitted className='bgPrimary white'></Icon>
                    </Grid.Column>

                    <Grid.Column width={7}>
                        <Header className='regular noMargin'>
                            Customer
                            <Header.Subheader className='small lightGray noMargin'>
                                {user.name}
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>

                    <Grid.Column width={6}>
                        <Button className='small borderRadius5 bold' basic onClick={this.handleLogout.bind(this)}>Sign Out</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutCustomer);
