import * as Constants from '../Constants';

const user = (state = Constants.userInitialState, action) => {
    switch (action.type) {
        case Constants.GET_USER:
            return {
                user: action.user,
                club: action.club,
                loading: false
            }
        case Constants.GET_USER_LOADING:
            return {
                user: state.user,
                club: state.club,
                loading: true
            }
        default:
            return state
    }
}

export default user;