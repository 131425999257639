import React, { useState } from 'react';
import { Header } from 'semantic-ui-react';

function InvasivePopUp(props) {
  const [showPopup0, setShowPopup0] = useState(true);

  const handleClosePopup0 = () => {
    localStorage.setItem(`message-${props.id}`, Date.now());
    setShowPopup0(false);
  };

  return (
    <>
      {showPopup0 && (
        <div
          className='fixed w-screen bg-white bg-opacity-70 h-screen top-0'
          style={{zIndex: 9999}}
        > 
          <div
            className='relative w-96 md:w-max p-5 shadow-md'
            style={{
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              border: '1px solid lightgray',
              borderRadius: '5px',
              minWidth: '15rem'
            }}
          >
            <Header size='huge' className="m-0">{props.heading}</Header>
            <hr style={{ marginBottom: '20px' }} />
            <p>{props.body}</p>
            <button className='bg-primary-blue center ' style={{color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer', display:'flex', justifyContent:'center' }} onClick={handleClosePopup0}>Close</button>
          </div>
        </div>
      )}
    </>
  );
}

export default InvasivePopUp;
