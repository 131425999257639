import React, { Component } from "react";

export default class BackButton extends Component {
    goBack() {
        window.history.back();
    }

    render() {
        return (
            <p onClick={this.goBack.bind(this)} className='padding15 menu noMargin floatLeft textDecoration link inline'>Back</p>
        )
    }
}