import React, { Component } from "react";
import { Grid, Header, Placeholder, Button } from 'semantic-ui-react';
import Product from '../parts/Product';
import FilterContent from "./FilterContent";

export default class ProductList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isUpdating: false,
            sort: 'popular'
        };
    }

    renderProductList(products, isFavourite, isLoading) {
        if (isLoading === true) {
            let count = 0;
            let placeholderCount = 0;

            if (products) {
                count = products.length;
            }

            const arrayLoop = count === 0 ? 12 : count;

            let placeholderList = [];

            while (placeholderCount < arrayLoop) {
                placeholderCount++;
                placeholderList.push(
                    <Grid.Column key={placeholderCount} className='productColumn marginBottom20'>
                        <div className='section card bgWhite shadow20 padding15 height250px'>
                            <Placeholder>
                                <Placeholder.Header>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Image />
                                <Placeholder.Paragraph>
                                    <Placeholder.Line length='medium' />
                                    <Placeholder.Line length='short' />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </div>
                    </Grid.Column>
                );
            }

            return placeholderList
        } else {
            if (products.length === 0) {
                return (
                    <Grid.Column mobile={16} tablet={16} computer={16} className='productColumn marginBottom20'>
                        <Header size='large'>There is no matched product.</Header>
                    </Grid.Column>
                );
            } else {
                return (
                    products.map((product, key) => {
                        return (
                            <Grid.Column className={'productColumn marginBottom20 ' + (product.favourite === 0 && isFavourite ? 'favouriteMove' : '')} key={key}>
                                <div className='section card bgWhite shadow20 padding15'>
                                    <Product product={product} isFavourite={isFavourite} />
                                </div>
                            </Grid.Column>
                        )
                    })
                )
            }
        }
    }

    handleSortList(sort) {
        this.setState({
            sort: sort
        })
        this.props.sortList(sort);
    }

    handleFilter(filter) {
        this.props.filterList(filter);
    }

    handleNextPage() {
        this.props.nextPage(this.state.sort);
    }

    render() {
        const products = this.props.data.products;
        const total = this.props.data.total ? this.props.data.total : 0;
        const filters = this.props.data.filters;
        //const searchType = this.props.searchType !== undefined ? this.props.searchType : '';
        const searchValue = this.props.searchValue !== undefined ? this.props.searchValue : '';
        const isFavourite = this.props.isFavourite;
        const isLoading = this.props.isLoading;
        const title = this.props.title ? this.props.title.replace('-', ' ') : '';

        return (
            <div>
                <Grid container className='marginTop10 marginBottom20'>
                    <Grid.Row stretched style={{ paddingBottom: 0 }} verticalAlign='middle'>
                        <Grid.Column width={16}>
                            <Header className='huge' style={{ marginTop: '5px' }}>
                                <div className='primaryColor bold'>
                                    {
                                        searchValue !== '' ? <>Search for <strong className='textCapitalize'>{searchValue !== '' ? searchValue : ''}</strong></> : <span className='textCapitalize marginRight10'>
                                            {title}
                                        </span>
                                    }
                                    <span className='big normal'>[{total} Products]</span>
                                </div>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row stretched style={{ paddingBottom: 0 }}>
                        <Grid.Column only='computer' computer={16}>
                            <FilterContent sortList={(sort) => this.handleSortList(sort)} currentFilter={this.props.currentFilter} handleShow={(filter) => this.handleFilter(filter)} filters={filters} isDesktop={true} />
                        </Grid.Column>
                        <Grid.Column only='mobile tablet' width={16}>
                            <FilterContent sortList={(sort) => this.handleSortList(sort)} currentFilter={this.props.currentFilter} handleShow={(filter) => this.handleFilter(filter)} filters={filters} isDesktop={false} />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={16} style={{ alignSelf: 'flex-start' }}>
                            <Grid doubling stretched columns={6}>
                                <Grid.Row>
                                    {this.renderProductList(products, isFavourite, isLoading)}
                                </Grid.Row>
                                {(() => {
                                    if (products) {
                                        if (total > products.length) {
                                            return (
                                                <Grid.Row>
                                                    <Grid.Column width={4} textAlign='center'>
                                                        <Button loading={isLoading} disabled={isLoading} size='small' className='borderRadius5 bgPrimary white' onClick={this.handleNextPage.bind(this)}>Load More</Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )
                                        }
                                    }
                                })()}
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}