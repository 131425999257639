import React, { Component } from "react";
import { Grid, Header, Input, Container, Button, Image } from 'semantic-ui-react';
import { ValidateEmail } from '../utils/Utils';
import { CSSTransition } from "react-transition-group";
import { myAuth } from '../utils/PrivateRoute';
import { Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { pageLogin, pageNotLogin } from '../action/view';
import LoginFooter from "../parts/LoginFooter";
import LandingFooter from "../parts/LandingFooter";
import LandingPart from "../parts/LandingPart";
import { requestResetPassword } from '../utils/UserAPI'

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageLogin,
    pageNotLogin
};

class ForgetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: null,
            step: 1,
            loading: false
        };

        this.props.pageLogin();

        // check if user has login already?
        if (myAuth.isAuthenticated) {
            this.props.pageNotLogin();
        }
    }

    keyPressed(e) {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this.handleNext();
        }
    }

    handleNext() {
        const step = this.state.step;

        switch (step) {
            case 1:
                if (this.state.email === null) {
                    alert('Please enter your email address.');
                } else {
                    if (ValidateEmail(this.state.email)) {
                        
                        this.setState({
                            loading: true
                        })

                        requestResetPassword((result) => {
                            if(result) {
                                this.setState({
                                    loading: false,
                                    step: 2
                                })
                            } else {
                                this.setState({
                                    loading: false
                                })
                            }
                            
                        }, this.state.email)
                    }
                }
                break;

            default:
                break;
        }
    }

    handleEmailchange(e) {
        this.setState({ email: e.currentTarget.value });
    }

    displayStep1() {
        this.setState({
            step: 1
        })
    }

    render() {
        if (myAuth.isAuthenticated) {
            return (
                <Redirect
                    to={{
                        pathname: '/'
                    }}
                />
            )
        }

        return (
            <Grid celled padded columns={2} className='height100vh bgWhite'>
                <Grid.Row>
                    <Grid.Column only='tablet computer' tablet={8} computer={6} className='padding30 primaryGradient nonAlcbackgroundCover center'>
                        <LandingPart nonAlc={true}/>
                        <LandingFooter />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={10} style={{ textAlign: 'right' }} className='center landing height100vh'>
                        <Grid padded verticalAlign='middle' columns={1} className='height100vh'>
                            <Grid.Row>
                                <Grid.Column className='maxWidth525 center'>
                                    <Container>
                                        <Header color='black' as='h1' className={(this.state.step === 1 ? 'alignLeft' : 'alignCenter') + ' marginTop10 marginBottom20 headerLarge'}>
                                            
                                            {
                                                this.state.step === 1 ?
                                                    'Password Recovery' : ''
                                            }
                                            <CSSTransition in={this.state.step === 2} unmountOnExit classNames="fade"
                                                timeout={200}>
                                                <div className='inline'>
                                                    Email has been sent
                                                </div>
                                            </CSSTransition>
                                            <Header.Subheader className='regular noMargin'>
                                                {
                                                    this.state.step === 1 ?
                                                        <div style={{ paddingBottom: '5px' }} className='fontSize16px'>Follow the steps to access the website.</div> : ''
                                                }
                                                <CSSTransition in={this.state.step === 2} unmountOnExit classNames="fade" timeout={200} onExited={() => this.displayStep1()}
                                                >
                                                    <div style={{ paddingBottom: '5px' }} className='fontSize16px'>Follow the instructions or <Link to='/forgetpassword'>resend email</Link>.</div>
                                                </CSSTransition>
                                            </Header.Subheader>
                                        </Header>
                                    </Container>
                                    <Container>
                                        <div className='login'>
                                            {
                                                this.state.step === 1 ?
                                                    (<Input autoFocus disabled={this.state.loading} className='landingInput' fluid name='email' tabIndex='1' onKeyPress={this.keyPressed.bind(this)} onChange={this.handleEmailchange.bind(this)} placeholder='Enter Your Email...' />)
                                                    : ''
                                            }

                                            <CSSTransition in={this.state.step === 2} unmountOnExit classNames="fade"
                                                timeout={200}>
                                                <Image src='./img/positive-vote.svg' width='80' centered/>
                                            </CSSTransition>
                                        </div>
                                    </Container>
                                    <br />
                                    <Container>
                                    {
                                        this.state.step === 1 ? <div className='login'>

                                            <Link to='/login'><p className='floatLeft yellowText borderYellow hoverYellowBg inlineFlex padding10-20 noDecoration'>Sign In</p></Link>

                                            <Button tabIndex='2' loading={this.state.loading} disabled={this.state.loading} primary compact floated='right' className='maxWidth90 regular bgYellow white minHeight40 hoverDarkenYellowBg' onClick={this.handleNext.bind(this)}>
                                                <Button.Content>
                                                    Next
                                                </Button.Content>
                                            </Button>
                                        </div> : ''
                                    }
                                    </Container>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <LoginFooter />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgetPassword);