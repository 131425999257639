import { combineReducers } from 'redux'
import root from './root'
import cart from './cart'
import user from './user'
import nonAlc from './nonalc'

export default combineReducers({
    cart,
    user,
    root,
    nonAlc
})