import React, { Component } from "react";
import { Grid, Header } from 'semantic-ui-react';
import { connect } from "react-redux";
import { pageLogin, pageNotLogin } from '../action/view';
import BackButton from "../parts/BackButton";
import { Link } from 'react-router-dom';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageLogin,
    pageNotLogin
};

class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);

        this.props.pageNotLogin();
    }

    render() {
        return (
            <Grid padded className='loginBg'>
                <Grid.Row>
                    <Grid.Column width={16} className='marginTop30 alignCenter'>
                        <Header as='h2'>
                            Asahi Beverages – Privacy Policy (including Credit Reporting)
                            <Header.Subheader className='small'>
                            </Header.Subheader>
                        </Header>

                        <div className='maxWidth70p center padding15 alignLeft'>
                            <Header as='h3'>1. Privacy Policy</Header>

                            <ol type="1">
                                <li>In this policy, the terms, “Asahi Beverages” "we", "us" and "our" means Asahi Holdings (Australia) Pty Ltd (ABN 48 135 315 767) and its subsidiaries. Please read this policy carefully as it explains the way in which we manage and handle personal information, including information collected through this website (the Website). We will handle your personal information in accordance with the applicable privacy principles in Australian and New Zealand laws, including the Australian Privacy Principles set out in the Privacy Act 1988 (Cth) and the Information Privacy Principles in the Privacy Act 1993 (NZ).</li>
    
                                <li>We may revise this policy from time to time. We will display a notice on the Website indicating when any such revisions have been made.</li>
    
                                <li>This policy should be read together with our Website <Link to='/terms-conditions'>Terms and Conditions</Link>. Together, these form the Terms of Use for this site.</li>
    
                                <li>At Asahi Beverages, we take the protection of privacy seriously, including information about minors. We operate our websites and market our products and services in compliance with all applicable Australian and/or New Zealand laws.</li>

                                <li>Persons under the age of 18 are not permitted to use our alcohol branded websites. All visitors to these websites will be required to confirm they are of legal purchase age before they can access alcohol websites.</li>

                                <li>For non-alcohol websites, children under the age of 15 should have the consent of their parent or guardian before providing any personal information to us. If we determine that a person providing us with personal information is under the age of 15, we will not use or maintain his or her personal information without the consent of a parent or guardian.</li>

                                <li>Please be aware that any information that is voluntarily given by a minor to Asahi Beverages on our websites or through our social media channels may in some circumstances be accessed by other parties to generate unsolicited emails or other contacts. We encourage all parents to instruct their children in the safe and responsible use of the internet.</li>
                            </ol>

                            <Header as='h3'>2. Who does this policy apply to and what personal information does Asahi Beverages collect and hold?</Header>

                            <ol>
                                <li>
                                    Collection of personal information by Asahi Beverages
                                    <ul>
                                        <li>This policy applies to all people who deal with Asahi Beverages. This includes customers and consumers of our products, individuals who participate in a game, promotion, survey or other feature conducted or operated by us, and individuals who use our websites. It also includes suppliers to Asahi Beverages, individuals or other entities that enter into sponsorship or other agreements with Asahi Beverages.</li>
                                        <li>We may also provide commercial credit to individuals and the Credit Reporting section of this policy applies in that instance.</li>

                                        <li>The Website provides the platform through which we can efficiently, conveniently and directly connect with the persons listed above in order to perform our various functions and activities.</li>

                                        <li>
                                            To carry out such activities we collect a range of personal information, including the following:
                                            <ul>
                                                <li>
                                                name, date of birth and other identification details;
                                                </li>
                                                <li>
                                                contact details;
                                                </li>
                                                <li>
                                                financial information (such as credit card or bank account numbers);
                                                </li>
                                                <li>
                                                information about how you use our products and services;
                                                </li>
                                                <li>
                                                the personal information specified in the game, promotion, survey or other feature you are participating in; and
                                                </li>
                                                <li>
                                                your employment history where you apply for a job with us.
                                                </li>
                                            </ul>
                                        </li>

                                        <li>
                                        We do not collect any sensitive information about individuals unless we are authorised or required by law to do so, or you have consented to us collecting that information from you for a particular purpose. If you have provided us with sensitive information for a particular purpose, we will only use it for that purpose. In particular, we will not use your sensitive information for direct marketing, unless you have previously agreed to this.
                                        </li>

                                        <li>
                                        You do not have to provide us with your personal or sensitive information. However, if you do not provide us with the personal information we request, we may not be able to provide you with the products, services or other assistance or participation you require.
                                        </li>

                                        <li>
                                        You also have the option of dealing with us anonymously or with a pseudonym. However, this only applies where it is practical for us to deal with you acting anonymously or under a pseudonym (for example, a general enquiry).
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Our use of Cookies

                                    <ul>
                                        <li>
                                            We also collect data about your website usage through the use of cookies and other technologies. Cookies are information that the site sends to your browser, which are stored as small files on your hard drive. The use of cookies allows the site to record what specific pages you have visited. Websites can provide services and tailored information by using cookies together with the information provided by you.
                                            <ul>
                                                <li>
                                                    To improve the content of our websites and e-mail to enhance your experience on our websites, and to tailor the websites to individual users;
                                                </li>
                                                <li>
                                                    To customise our products and services according to the interests of individual users;
                                                </li>
                                                <li>
                                                    To regulate advertising so that members are not repeatedly shown the same ads, and to make adjustments so that members view ads likely to be of interest to them;
                                                </li>
                                                <li>
                                                    To statistically analyse, compile and publish information. No personal information will be included in the end result of this process.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            You can adjust your browser settings to block the use of cookies or to warn that a cookie has been accepted. Most of the services provided on our websites can be accessed even if the browser blocks cookies. There may be some services that only offer limited access, or no access, if cookies are disabled.
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        
                            <Header as='h3'>3. How do we collect and hold personal information?</Header>
                            <ul>
                                <li>
                                Generally, we endeavour to collect personal information directly from the individuals concerned where it is practical for us to do so. However, if this is not practicable, we may also collect personal information from third parties such as our related companies, credit reporting agencies, marketing and PR agencies or your representatives. We may also collect personal information by other means such as through our websites, social media sites or other publicly available sources of information.
                                </li>
                                <li>
                                Sometimes we may also ask you to give us personal information about other people, such as your family or an authorised representative. If you provide us with this personal information, we rely on you to tell those people that you are giving their personal information to us and to provide them with a copy of this policy.
                                </li>
                                <li>
                                We take reasonable steps to ensure the security of your personal information and have put in place reasonable security measures to prevent loss, misuse, or unauthorised access and alterations to personal information under our control. Our employees and service providers are required to maintain the confidentiality of any personal information we hold.
                                </li>
                                <li>
                                Unfortunately, we cannot and do not guarantee that unauthorised access to your personal information will not occur
                                </li>
                                <li>
                                It is important for you to protect against unauthorised access to your password and to your computer. Be sure to sign off when you finish using a shared computer.
                                </li>
                                <li>If we no longer require your personal information, we will take reasonable steps to destroy or alter that information so that it no longer identifies you. Any data destroyed is disposed of in an appropriate manner that protects the privacy of your information.</li>
                                <li>We engage third party service providers to manage some of our business functions. While all information (including personal information) remains stored on servers in Australia and New Zealand, our offshore service providers may access this information from the Philippines. We have taken reasonable steps to ensure that your information will only be used or disclosed by our service providers in a way that is consistent with Australian and New Zealand privacy laws. If you do not consent to our offshore service providers accessing your personal information, you should not provide this to us.
                                </li>
                            </ul>
                            
                            <Header as='h3'>4. Why do we collect, hold, use and disclose personal information?</Header>

                            <ul>
                                <li>
                                We collect your personal information so that we can perform our various functions and activities.
                                </li>
                                <li>
                                    We may also provide commercial credit to individuals and the Credit Reporting section of this policy applies in that instance.

                                    <ul>
                                        <li>
                                        verify your identify;
                                        </li>
                                        <li>
                                            facilitate your registration as a Website user;

                                        </li>
                                        <li>
                                            enable you to participate in promotions and other activities;

                                        </li>
                                        <li>
                                            provide you with products or services that you have requested, and to provide you with any other benefits you may subsequently be entitled to in relation to these products and services;

                                        </li>
                                        <li>
                                            manage and administer a product or service, including charging, billing and collecting debts;

                                        </li>
                                        <li>
                                        conduct checks for creditworthiness or fraud;
                                        </li>
                                        <li>
                                        provide you with news and information in relation to Asahi Beverages and our products and services;
                                        </li>
                                        <li>
                                        develop and improve products and services;
                                        </li>
                                        <li>
                                        promote and market to you products, services and special offers made available by us or our related companies, licensees and suppliers;
                                        </li>
                                        <li>
                                        maintain and develop our business systems and infrastructure, including testing and upgrading these systems;
                                        </li>
                                        <li>
                                        comply with our legal obligations, including for the purpose of or in connection with legal proceedings or if necessary for establishing, defending or exercising legal rights;
                                        </li>
                                        <li>
                                        facilitate other purposes that are notified to you prior to you giving your information to us or that are authorised or required by law;
                                        </li>
                                        <li>
                                        provide or facilitate related purposes to those set out above, where you would reasonably expect us to use or disclose information for that purpose;
                                        </li>
                                        <li>
                                        tell you about other products or services that may be of interest to you;
                                        </li>
                                        <li>
                                        assess your suitability for a job where you have applied for a role;
                                        </li>
                                        <li>
                                        consider any concerns or complaints you raise with Asahi Beverages; and;
                                        </li>
                                        <li>
                                        comply with our legal obligations.
                                        <ul>
                                            <li>
                                            If you wish to opt out of the direct marketing communications we send you (including by email), please follow the unsubscribe instructions in the relevant communications, or email us at: <a href='mailto:consumerrelations@asahi.com.au'>consumerrelations@asahi.com.au</a>.
                                            </li>
                                        </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                To give effect to the purposes described above, we may disclose your personal information to the following individuals and entities:
                                <ul>
                                <li>our professional advisers (such as lawyers and accountants) and service providers (such as marketing and PR agencies);</li>
<li>organisations that we have engaged to provide services or carry out other functions on our behalf (such as outsourcing contractors, marketing, telemarketing, market researchers, website usage analysts) but only to the extent it is necessary to the services they provide to us;</li>
<li>credit providers and credit related service providers;</li>
<li>third party service providers managing some of our business functions; and</li>
<li>government and regulatory authorities and other organisations, or as otherwise required by or authorised under the Privacy Act.</li>

                                </ul>
                                </li>
                            </ul>

                            <Header as='h3'>5. How can you access and correct your information?</Header>

                            <ul>
                                <li>
                                Under the relevant privacy laws, you have the right to:
                                    <ul>
                                        <li>
                                        seek access to the personal information that we collect and hold; and

                                        </li>
                                        <li>
                                        facilitate your registration as a Website user;

                                        </li>
                                    </ul>
                                </li>
                                <li>
                                If you wish to access the personal information that we hold about you, please set out your request in writing, and send it to: <a href='mailto:consumerrelations@asahi.com.au'>consumerrelations@asahi.com.au</a>. Generally we will not charge you for lodging a request, however please note that in some instances we may charge reasonable fees to cover the administrative cost of retrieving and providing access to your personal information.

                                </li>
                                <li>
                                If you seek access to your credit information, we will not require any charge unless you have already made a request in the previous 12 months. If you have made a request in the previous 12 months, we may request a reasonable charge for providing access.

                                </li>
                                <li>
                                If you are of the view that personal information we hold about you is inaccurate, or out-of-date, or if you have other queries about accessing and correcting your personal information, please contact us using the contact details noted above.

                                </li>
                            </ul>

                            <Header as='h3'>6. Credit Reporting</Header>

                            <ol type='1'>
                                <li>
                                    Credit Reporting
                                    <ul>
                                        <li>
                                        We may provide credit as contemplated by the privacy laws to individuals in connection with our products and services (for example, we may provide commercial credit to individuals, and conduct associated credit checks on these individuals). As a result, we may collect credit related information and credit eligibility information about you. For ease of understanding in this policy we refer to this as credit information. This section of the policy sets out how we manage your credit information.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    What credit information do we collect?
                                    <ul>
                                        <li>
                                            We may collect the following types of credit information about you:
                                            <ul>
                                                <li>identification information;</li>
                                                <li>basic information about your credit account and your credit history (including any missed or late repayments that you have made);</li>
                                                <li>details of any credit provided to you by other credit providers or details about information request we make about you to other credit reporting bodies;</li>
                                                <li>
                                                any credit rating or credit assessment score that we have derived or that is provided to us by a credit reporting body; and
                                                </li>
                                                <li>
                                                details of any credit-related court proceedings or insolvency applications that relate to you;
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    How do we collect and hold this credit information?
                                    <ul>
                                        <li>
                                        Please see paragraph 3 of this policy ‘How do we collect and hold personal information’.</li>
                                        <li>In addition to the information listed at point 3, we obtain credit information from credit reporting bodies.</li>
                                    </ul>
                                </li>
                                <li>
                                    How do we collect and hold this credit information?
                                    <ul>
                                        <li>
                                            We may use your credit information in the following ways:
                                            <ul>
                                                <li>
                                                    processing credit-related applications and managing credit that we provide;
                                                </li>
                                                <li>
                                                    determining a credit assessment score for you to help us decide whether or not to provide credit to you;
                                                </li>
                                                <li>
                                                    collecting payments that are owed to us in respect of any credit that we have previously provided to you;
                                                </li>
                                                <li>
                                                    assessing your suitability as guarantor or communicating with a third party who acts as your guarantor
                                                </li>
                                                <li>
                                                    participating in the credit reporting system and providing information to other credit reporting bodies;
                                                </li>
                                                <li>
                                                    dealing with complaints or regulatory matters relating to credit or credit reporting;
                                                </li>
                                                <li>
                                                    as required or authorised by another law; and
                                                </li>
                                                <li>
                                                    where you otherwise expressly consent to the use of disclosure.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            In some cases, the people to whom we may disclose your credit information may be based outside of Australia or New Zealand – please see paragraph 3 of this policy for further information ‘How do we collect and hold Personal Information’.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Access and correction to credit-related information
                                    <ul>
                                        <li>
                                            Please refer to paragraph 5 of this policy – ‘How can you access and correct your information?’
                                        </li>
                                    </ul>
                                </li>
                            </ol>                

                            <Header as='h3'>7. Making a complaint</Header>

                            <ul>
                                <li>
                                    If you wish to make a complaint about how we handle your personal information or your credit information, please contact us at <a href='mailto:consumerrelations@asahi.com.au'>consumerrelations@asahi.com.au</a> setting out your complaint in writing. We will deal with all requests for access to personal information or complaints as quickly as possible and will endeavour to get back to you within a reasonable time frame.
                                </li>
                                <li>
                                    If you have not received a response in less than 30 days or are not satisfied with our response to your complaint, you may lodge a complaint as follows:
                                    <ul>
                                        <li>
                                            in Australia with the Office of the Australian Information Commissioner (“OAIC”) via their website at <a href='http://www.oaic.gov.au/'>http://www.oaic.gov.au/</a>; or
                                        </li>
                                        <li>
                                            in New Zealand with the Office of the Privacy Commissioner via its website at <a href='http://www.privacy.org.nz/'>http://www.privacy.org.nz/</a>.
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <Header as='h3'>8. Additional information and how to contact us</Header>

                            <ul>
                                <li>
                                    If you have any questions or comments about this policy, please set it out in writing, and forward it to us using the contact details below.
                                </li>
                                <li>
                                    Email: <a href='mailto:consumerrelations@asahi.com.au'>consumerrelations@asahi.com.au</a>
                                    <br /><br />
                                    Telephone:<br/>
                                    Australia: 1800 244 054<br/>
                                    New Zealand: 0800 242 754

                                </li>
                            </ul>  
                            
                        </div>
                        <div className='login maxWidth70p center marginTop20'>
                            <BackButton/>
                        </div>  
                        
                    </Grid.Column>
                    
                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrivacyPolicy);