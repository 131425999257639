import React, { Component } from "react";
import { Modal, Form, Header, Button, Icon } from 'semantic-ui-react';
import Cleave from 'cleave.js/react';
import Dropzone from 'react-dropzone';
import {setBankStatement} from '../utils/BankStatementAPI';

const defaultState = {
    submitLoading: false,
    files: [],
    bank_name: '',
    bsb: '',
    account_name: '',
    account_num: '',
    serialiseFile: ''
}

export default class RebateModal extends Component {
    constructor(props) {
        super(props);

        this.onDrop = (files) => {
            if (!files.length)
            return;

            this.setState({files})

            this.createImage(files[0]);
        };

        this.state = {
            ...defaultState
        }
    }

    createImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            this.setState({
                serialiseFile: e.target.result
            })
        };
        reader.readAsDataURL(file);
    }

    handleClose(e) {
        this.props.closeModal();
    }

    onChangeBSB(e) {
        this.setState({
            bsb: e.currentTarget.rawValue
        })
    }

    onChange(e) {
        const type = e.currentTarget.getAttribute('name');
        this.setState({
            [type]: e.currentTarget.value
        });
    }

    handleSubmit() {
        this.setState({
            submitLoading: true
        })

        const files = Array.from(this.state.files);

        const formData = new FormData();

        formData.append('bank_name', this.state.bank_name);
        formData.append('bsb', this.state.bsb);
        formData.append('account_num', this.state.account_num);
        formData.append('account_name', this.state.account_name);
        formData.append('file', files[0]);

        /*const object = {
            bank_name: this.state.bank_name,
            bsb: this.state.bsb,
            account_name: this.state.account_name,
            account_num: this.state.account_num,
            file: this.state.serialiseFile
        }*/

        setBankStatement((result, code) => {
            this.setState({
                submitLoading: false
            })

            if(code === 200) {
                this.setState({
                    ...defaultState
                })
                // update bank statement info for account
                this.props.handleUpdate(result);
                this.handleClose();
                
            } else {
                alert(result);
            }           
        }, formData);
    }

    handleRemove(i) {
        let currentState = [...this.state.files];
        currentState.splice(i, 1);
        this.setState({
            files: currentState
        })
    }

    render() {        
        return (<Modal className="borderRadius10 padding15"
                    open={this.props.modalOpen}
                    onClose={this.handleClose.bind(this)}
                    size='tiny'
                >
                    <Header content='Bank Details' className="borderTopRadius10 noPaddingLeft noPaddingRight noPaddingTop largeMedium"/>
                    <Modal.Content className="noPaddingLeft noPaddingRight">
                        <Form onSubmit={this.handleSubmit.bind(this)}>
                        <Form.Field required>
                                <label>Bank Name</label>
                                <input name='bank_name' value={this.state.bank_name} disabled={this.state.submitLoading} className="borderRadius10 noBorder bgLightGray minHeight40 menu" placeholder='Bank Name' type='text' onChange={this.onChange.bind(this)}></input>
                            </Form.Field>
                            <Form.Field required>
                                <label>Account Name</label>
                                <input name='account_name' value={this.state.account_name} disabled={this.state.submitLoading} className="borderRadius10 noBorder bgLightGray minHeight40 menu" placeholder='John Doe' type='text' onChange={this.onChange.bind(this)}></input>
                            </Form.Field>
                            <Form.Field required>
                                <label>BSB</label>
                                <Cleave disabled={this.state.submitLoading} value={this.state.bsb} placeholder='000-000' className="borderRadius10 noBorder bgLightGray minHeight40 menu widthAuto fitContent" options={{blocks: [3,3], delimiter: '-', numericOnly: true}} onChange={this.onChangeBSB.bind(this)} />
                            </Form.Field>
                            <Form.Field required>
                                <label>Account Number</label>
                                <input name='account_num' value={this.state.account_num} disabled={this.state.submitLoading} className="borderRadius10 noBorder bgLightGray minHeight40 menu" placeholder='12345678' type='number' onChange={this.onChange.bind(this)}></input>
                            </Form.Field>
                            <Form.Field required>
                                <label>Bank Statement</label>
                                <p className='small'>Please provide a bank statement so we can verify your clubs bank details. Please ensure your bank statement clearly shows your clubs Bank Name, Account Name, BSB and Account Number.</p>
                                <Dropzone onDrop={this.onDrop} multiple={false} accept={'.pdf'} disabled={this.state.submitLoading}>
                                {({getRootProps, getInputProps}) => (
                                    <section className='fileDrop flexBetween'>
                                    <div {...getRootProps({className: 'dropzone'})}>
                                        <input {...getInputProps()} />
                                        <p>
                                            <strong>Drop file here or <span className="yellowText">browse</span></strong><br/>
                                            Max 20MB
                                        </p>
                                    </div>
                                    
                                    {
                                        this.state.files.length > 0 
                                            ?   <div className="preview">
                                                    {this.state.files.map((file, i) => (
                                                        <div className="file-preview" key={i}>
                                                            {file.name}<br/>
                                                            {Math.floor(file.size / 1000)}kb

                                                            <Button onClick={this.handleRemove.bind(this, i)} type="button" icon basic size="mini" color="red" circular className="deletePreview">
                                                                <Icon name='delete' />
                                                            </Button>
                                                        </div>
                                                    ))}
                                                </div>
                                            : ''
                                    }
                                    
                                    </section>
                                )}
                                </Dropzone>
                            </Form.Field>

                            <Button type='button' basic className="gray borderRadius10" size="medium" floated='left' loading={this.state.submitLoading} disabled={this.state.submitLoading} onClick={this.handleClose.bind(this)}>Close</Button>

                            <Button type='submit' className="bgYellow white borderRadius10" size="medium" floated='right' loading={this.state.submitLoading} disabled={this.state.submitLoading}>Submit</Button>
                        </Form>
                    </Modal.Content>
                </Modal>)
    }
}