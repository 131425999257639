import React, { Component } from "react";
import { Grid, Header } from 'semantic-ui-react';
import MyMiniCartBtn from './MyMiniCartBtn';
import FavouriteButton from './FavouriteButton';
import { Link } from 'react-router-dom';


export default class Product extends Component {
    render() {
        const product = this.props.product;
        const isSlider = this.props.isSlider;
        const isFavourite = this.props.isFavourite;

        return (
            <Grid.Column className={'productSlide relative bgWhite ' + (isSlider ? 'margin25' : '')}>
                <Header className='fontSize16px primaryColor relative paddingRight30 minHeight100 marginBottom'>
                    <Link to={'/product/' + product.slug}>
                        {product.name}
                        <Header.Subheader className='noMargin menu'>
                            {product.quantity_type}
                        </Header.Subheader>
                    </Link>
                    <FavouriteButton product={product} setFavourite={isFavourite}/>
                </Header>

                <MyMiniCartBtn product={product} />
            </Grid.Column>
        )
    }
}