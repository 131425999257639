import React, { useState } from 'react';
import { Header } from 'semantic-ui-react';


function ChatPopUp(props) {
  const [showPopup0, setShowPopup0] = useState(true);

  const handleClosePopup0 = () => {
    localStorage.setItem(`message-${props.id}`, Date.now());
    setShowPopup0(false);
  };

  return (
    <>
      {showPopup0 && (
        <div className='fixed top-0 w-screen h-screen z-40 pointer-events-none'>
          <div
            className='bg-primary-blue p-5 absolute bottom-5 right-5 w-max max-w-xs md:max-w-lg pointer-events-auto' 
            style={{
              color: 'white',
              zIndex: '30',
              boxShadow: '5px 5px orange', 
              minHeight: '12rem',
              minWidth: '15rem'
            }}
          >
              
            <button


              style={{
                backgroundColor: 'transparent',
                border: 'none',
                color: 'white',
                cursor: 'pointer',
                float: 'right',
                fontSize: '24px',
                fontWeight: 'bold',
              }}
              onClick={handleClosePopup0}
            >
              &times;
            </button>
            <Header size='huge' className='m-0 white'  >{props.heading}</Header>
            <hr className='border-white my-3'/>
            <p>{props.body}</p>
          </div>
        </div>
      )}
    </>
  );
}

export default ChatPopUp;
