import React, { Component } from "react";
import { Dropdown } from 'semantic-ui-react';

const options = [
    { key: 3, text: 'Most Popular', value: 3 },
    { key: 1, text: 'Price (Low - Hi)', value: 1 },
    { key: 2, text: 'Price (Hi - Low)', value: 2 }
]

export default class FilterSection extends Component {
    handleChange(e, {value}) {
        let sort = 'ASC';
        if(value === 2) {
            sort = 'DESC';
        }
        if(value === 3) {
            sort = 'popular';
        }
        this.props.sortList(sort);
    }

    render() {
        return (
            <div className='floatRight moveDropdownToLeft'>
                <Dropdown className='crazyFilter' direction='left' options={options} placeholder='Most Popular' onChange={this.handleChange.bind(this)} defaultValue={3} />
            </div>
        );
    }
}