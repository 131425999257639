import React, { Component } from "react";
import { Grid, Header, Icon } from 'semantic-ui-react';
import { formatCurrency, calculateGST } from '../utils/Utils';
import ListCartItem from '../parts/ListCartItem';
import { connect } from "react-redux";
import _ from 'lodash';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {};

class CartSummary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false
        }
    }

    handleExpand(e) {
        this.setState({
            active: !this.state.active
        })
    }

    render() {
        const cartItems = this.props.cart.cartItems;
        const numberItems = _.sumBy(cartItems, function (o) { return o.quantity; });

        let totalCartPrice = 0;
        let rebate = 0;

        cartItems.map((value) => {
            totalCartPrice += value.price * value.quantity;
            rebate += value.rebate * value.quantity;
            return null;
        });

        if (this.props.isDesktop) {
            return (
                <Grid.Column width={16} className='noPadding'>
                    <div className='section card bgWhite padding15 borderRadius15'>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column textAlign='left' width={14}>
                                    <p className='small lightGray'>Order Summary</p>
                                </Grid.Column>

                                <Grid.Column width={16} style={{ marginTop: '2rem' }}>
                                    <Grid>
                                        <ListCartItem data={cartItems} />
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={4} textAlign='left' verticalAlign='bottom'>
                                    <p className='regular lightGray'>Total:</p>
                                </Grid.Column>

                                <Grid.Column width={12} textAlign='right'>
                                    <Header className='largeMedium'>
                                        <Header.Subheader className={'cub-yellow bold small noMargin' + (rebate === 0 ? ' hidden' : '')}>
                                            + {formatCurrency(rebate)} Credit
                                        </Header.Subheader>
                                        {formatCurrency(totalCartPrice)}
                                        <p className='small lightGray noMargin normal'>Inc {formatCurrency(calculateGST(totalCartPrice))} GST</p>
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Grid.Column>
            );
        } else {
            return (
                <Grid.Row className={'fixed zIndex1 noPadding left width100 height100plus60 normalTransition bgWhite' + (this.state.active ? ' bottom' : ' bottomMinus100calc')}>
                    <Grid.Column width={16} className='noPadding'>
                        <div className='section card bgWhite padding15 height100plus60'>
                            <Grid>
                                <Grid.Row className='cursor' onClick={this.handleExpand.bind(this)}>
                                    <Grid.Column textAlign='left' width={8}>
                                        <Header className='regular'>
                                            {numberItems} item(s)
                                            <Header.Subheader className='lightGray small noMargin'>
                                                Show Details
                                            </Header.Subheader>
                                        </Header>
                                    </Grid.Column>

                                    <Grid.Column textAlign='right' width={8}>
                                        <Header className='largeMedium'>
                                            <Header.Subheader className={'red small noMargin' + (rebate === 0 ? ' hidden' : '')}>
                                                Partnership Fund {formatCurrency(rebate)}
                                            </Header.Subheader>
                                            {formatCurrency(totalCartPrice)}
                                        </Header>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row className={'transition ' + (this.state.active ? '' : 'opacity0')}>
                                    <Grid.Column textAlign='left' width={14}>
                                        <p className='small lightGray'>Order Summary</p>
                                    </Grid.Column>

                                    <Grid.Column textAlign='right' width={2}>
                                        <Icon name='close' className='small cursor lightGray' onClick={this.handleExpand.bind(this)} />
                                    </Grid.Column>

                                    <Grid.Column width={16} style={{ marginTop: '2rem' }}>
                                        <Grid>
                                            <ListCartItem data={cartItems} />
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Grid className='absolute bottom15 width100'>
                                <Grid.Row>
                                    <Grid.Column width={4} textAlign='left' verticalAlign='bottom'>
                                        <p className='regular lightGray'>Total:</p>
                                    </Grid.Column>

                                    <Grid.Column width={12} textAlign='right'>
                                        <Header className='largeMedium'>
                                            <Header.Subheader className={'red small noMargin' + (rebate === 0 ? ' hidden' : '')}>
                                                Partnership Fund {formatCurrency(rebate)}
                                            </Header.Subheader>
                                            {formatCurrency(totalCartPrice)}
                                            <p className='small lightGray noMargin normal'>Inc {formatCurrency(calculateGST(totalCartPrice))} GST</p>
                                        </Header>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            );
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CartSummary);
