import React, { Component } from "react";
import { Grid, Header, Image, Button, Rating, TextArea, Form, Divider, Icon, Checkbox } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { pageLogin, pageNotLogin } from '../action/view';
import { getCart } from '../action/cart';
import { answerSurvey, commentSurvey } from "../utils/UserAPI";
import _ from 'lodash';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageLogin,
    pageNotLogin,
    getCart
};

class OrderComplete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            answers: [],
            lowRate: false,
            active: true,
            displayNPS: true,
            displayComment: false,
            comment: '',
            commentActive: true
        };

        this.props.pageLogin('checkout');
    }

    componentDidMount() {
        this.props.getCart();
    }

    handleRate(e, { rating, dataid }) {
        const currentQuestions = this.props.survey.question;
        let currentAnswers = [...this.state.answers];

        // current change
        // add new answer in
        const currentQuestion = _.find(currentQuestions, (question) => {
            return parseInt(question.id) === parseInt(dataid);
        })

        if (currentQuestion) {
            // valid question
            // add in

            // check if the answer has been there already
            let currentAnswer = _.find(currentAnswers, (answer) => {
                return parseInt(answer.question_id) === parseInt(dataid);
            })

            if (currentAnswer) {
                // modify response
                currentAnswer.response = rating;
            } else {
                currentAnswers.push({
                    question_id: parseInt(dataid),
                    response: rating
                })
            }
        }


        /*if(currentAnswers.length === currentQuestions.length) {
            // sending
            this.setState({
                active: false
            })
    
            answerSurvey((result) => {
                console.log(result);
            }, currentAnswers, this.props.survey.id)
        }*/

        this.setState({
            answers: currentAnswers,
        })

        if (parseInt(rating) <= 6) {
            this.setState({
                lowRate: true
            })
        } else {
            this.setState({
                lowRate: false
            })
        }
    }

    handleCheckbox(e, { checked, dataid }) {
        const currentQuestions = this.props.survey.question;
        let currentAnswers = [...this.state.answers];

        // current change
        // add new answer in
        const currentQuestion = _.find(currentQuestions, (question) => {
            return parseInt(question.id) === parseInt(dataid);
        })
        if (currentQuestion) {
            // valid question
            // add in

            // check if the answer has been there already
            let currentAnswer = _.find(currentAnswers, (answer) => {
                return parseInt(answer.question_id) === parseInt(dataid);
            })

            if (currentAnswer) {
                // modify response
                currentAnswer.response = checked;
            } else {
                currentAnswers.push({
                    question_id: parseInt(dataid),
                    response: checked
                })
            }
        }
        this.setState({
            answers: currentAnswers
        })
    }



    handleChangeComment(e) {
        this.setState({
            comment: e.currentTarget.value
        })
    }

    handleChangeTextArea(e) {
        const currentQuestions = this.props.survey.question;
        let currentAnswers = [...this.state.answers];
        const dataId = e.currentTarget.getAttribute('dataid');
        const rating = e.currentTarget.value;

        // current change
        // add new answer in
        const currentQuestion = _.find(currentQuestions, (question) => {
            return parseInt(question.id) === parseInt(dataId);
        })

        if (currentQuestion) {
            // valid question
            // add in

            // check if the answer has been there already
            let currentAnswer = _.find(currentAnswers, (answer) => {
                return parseInt(answer.question_id) === parseInt(dataId);
            })

            if (currentAnswer) {
                // modify response
                currentAnswer.response = rating;
            } else {
                currentAnswers.push({
                    question_id: parseInt(dataId),
                    response: rating
                })
            }
        }


        /*if(currentAnswers.length === currentQuestions.length) {
            // sending
            this.setState({
                active: false
            })
    
            answerSurvey((result) => {
                console.log(result);
            }, currentAnswers, this.props.survey.id)
        }*/

        this.setState({
            answers: currentAnswers
        })
    }

    handleSubmitComment() {
        const survey_id = this.props.survey.id;
        const comment = this.state.comment;

        this.setState({
            commentActive: false
        })

        commentSurvey((result) => {
            this.setState({
                displayComment: false
            })
        }, comment, survey_id)
    }

    handleSubmitNPS() {
        const currentQuestions = this.props.survey.question;
        let currentAnswers = [...this.state.answers];

        // Place default value for checkbox when not checked
        if (currentQuestions.filter(question => question.type === 'checkbox')) {
            let checkboxs = currentQuestions.filter(question => question.type === 'checkbox')
            // if checkbox is not set, set false
            checkboxs.forEach(checkbox => {
                if (currentAnswers.filter(answer => parseInt(answer.question_id) === parseInt(checkbox.id)).length === 0)
                    currentAnswers.push({
                        question_id: checkbox.id,
                        response: false
                    })

            })
        }

        if (currentQuestions.filter(question => question.type === 'text' && question.name === 'others')) {
            let others = currentQuestions.filter(question => question.type === 'text' && (question.name === 'Others' || question.name === 'others'))
            others.forEach(other => {
                if (currentAnswers.filter(answer => parseInt(answer.question_id) === parseInt(other.id)).length === 0)
                    currentAnswers.push({
                        question_id: other.id,
                        response: 'others'
                    })

            })
        }

        if (currentAnswers.length === currentQuestions.length) {
            // sending
            this.setState({
                active: false
            })

            answerSurvey((result) => {

                this.setState({
                    displayNPS: false
                })

                if (this.state.lowRate) {
                    this.setState({
                        displayComment: true
                    })
                } else {
                    this.setState({
                        displayComment: false
                    })
                }
            }, currentAnswers, this.props.survey.id)
        } else {
            alert('Please fill out all the questions before submitting rating');
        }
    }

    questionTypeRender(value) {
        switch (value.type) {
            case 'numeric':
                // display star rating
                return <Rating clearable={false} disabled={!this.state.active} icon='star' size='massive' maxRating={10} onRate={this.handleRate.bind(this)} dataid={value.id} />;
            case 'checkbox':
                return <Checkbox onChange={this.handleCheckbox.bind(this)} label={{ children: value.name }} dataid={value.id} />
            default:
                return <Form>
                    <TextArea placeholder={value.name} rows={3} disabled={!this.state.active} onChange={this.handleChangeTextArea.bind(this)} dataid={value.id} />
                </Form>;
        }

    }

    displayQuestion(value) {
        if (this.state.displayNPS) {
            if (value.type !== 'checkbox') {
                return <>
                    <p className='large alignCenter mt-8'>{value.name}</p>
                    <div className='alignCenter maxWidth650 center'>
                        {this.questionTypeRender(value)}
                    </div>
                </>
            } else if (value.type === 'checkbox' && value.has_options) {
                return <div className='alignCenter maxWidth650 center'>
                    <p className='large alignCenter mt-8'>{value.name}</p>
                    <div className="maxWidth320 alignLeft mx-auto">
                        {this.props.survey.question.map((question, key) => {
                            if (parseInt(question.parent) === parseInt(value.id)) {
                                return (
                                    <div className="mt-4">
                                        {this.questionTypeRender(question)}
                                    </div>
                                )
                            }
                            return null
                        })}
                    </div>

                </div>
            } else if (value.type === 'checkbox') {
                return (
                    <div className='alignCenter maxWidth650 center'>
                        {this.questionTypeRender(value)}
                    </div>
                )
            }

        } else {
            return <div className='alignCenter maxWidth650 center'>
                <Icon name='check circle outline' color='green' /> Thank you for submitting your feedback.
                <Divider />
                <Link to='../shop' onClick={() => this.props.pageNotLogin()}>
                    <Button className='regular normal bgPrimary borderRadius10 white marginTop20' fluid>
                        Continue Shopping
                    </Button>
                </Link>
            </div>
        }

    }

    render() {
        const survey = this.props.survey; // get the first survey only

        return (
            <Grid container>
                <Grid.Row verticalAlign='middle' textAlign='center' style={{ height: '150px' }} className='zIndex1 fixed top left bgGradientGray'>
                    <Grid.Column verticalAlign='middle' width={16}>
                        {/* <Link to='/' onClick={() => this.props.pageNotLogin()}> */}
                        <Image inline size='tiny' src='/img/ClubConnect_Landscape.svg' />
                        {/* </Link> */}
                    </Grid.Column>
                </Grid.Row>
                <Header className='small noMargin primaryColor' style={{ paddingTop: '150px' }}>Order Confirmation</Header>
                <Grid.Row className='noPaddingBottom' verticalAlign='top'>
                    <Grid.Column width={16}>
                        <div className='section card borderRadius10 bgWhite shadow20 padding15' style={{ minHeight: '60vh', marginBottom: '1em' }}>
                            <Header className='largeMedium' style={{ marginTop: '30px', textAlign: 'center' }}>
                                <Image src='/img/beer.svg' size='massive' className='center flex marginBottom10' style={{ width: '3.5em' }} />
                                Cheers!
                            </Header>
                            <p className='large alignCenter'>
                                <strong>Your order has been placed.<br /> Check the status of it via your <Link className='primaryColor bold' to='/orders' onClick={() => this.props.pageNotLogin()}>orders</Link>.</strong>
                            </p>
                            {
                                survey && <div className='alignCenter maxWidth650 center'>
                                    {
                                        survey.question?.map((value, key) => {
                                            return <div key={key}>
                                                {!value.parent &&
                                                    this.displayQuestion(value)
                                                }

                                            </div>
                                        })
                                    }

                                    {
                                        this.state.displayComment && <>
                                            <Form>
                                                <TextArea placeholder='How can we improve your experience' rows={3} disabled={!this.state.commentActive} onChange={this.handleChangeComment.bind(this)} />
                                                <Button disabled={!this.state.commentActive} basic color='black' className='regular borderRadius10 marginTop20' onClick={this.handleSubmitComment.bind(this)}>
                                                    Submit
                                                </Button>
                                            </Form>
                                        </>
                                    }

                                    {
                                        this.state.displayNPS && <><Button disabled={!this.state.active} basic color='black' className='regular borderRadius10 marginTop20' onClick={this.handleSubmitNPS.bind(this)}>
                                            Submit
                                        </Button>
                                            <Divider /></>
                                    }

                                </div>
                            }

                            {/* <div className='center maxWidth230'>
                                <Link to='../shop' onClick={() => this.props.pageNotLogin()}>
                                    <Button className='regular normal bgPrimary borderRadius10 white marginTop20' fluid>
                                        Continue Shopping
                                    </Button>
                                </Link>
                            </div> */}
                        </div>
                    </Grid.Column>
                </Grid.Row>

            </Grid >
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderComplete);
