import React, { Component } from "react";
import { Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export default class LoginFooter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            homepage: false,
        };
    }
    render() {
        if (this.props.homepage) {
            return (
                <div className="bg-grey py-4">
                    <div className=" w-full text-center">
                        <p>© 2021 Club Connect</p>
                        {/* <div className="flex ml-auto underline">
                            <Link to='/terms-conditions' className='black marginRight10'>Terms & Conditions</Link>
                            <Link to='/privacy-policy' className='black marginRight10'>Privacy Policy</Link>
                            <Link to='/marketing-policy' className='black marginRight10'>Marketing Policy</Link>
                        </div> */}
                    </div>
                </div>
            )
        } else {
            return (
                <Grid className={`left width100 center paddingLeft30 paddingRight30 bottom50`}>
                    < Grid.Row only='tablet computer' >
                        <Grid.Column width={16} className='alignRight noPadding' >
                            <p>© 2021 Club Connect</p>
                        </Grid.Column>
                    </Grid.Row >
                    <Grid.Row only='mobile' className='small'>
                        <Grid.Column mobile={6} tablet={5} computer={5} className='alignLeft'>
                            <p>
                                © 2021 Club Connect
                            </p>
                        </Grid.Column>
                        <Grid.Column mobile={10} tablet={11} computer={11} className='alignRight'>
                            <Link to='/terms-conditions' className='black marginRight10'>Terms & Conditions</Link>
                        </Grid.Column>
                    </Grid.Row>
                </Grid >
            )
        }

    }
}