import Cookies from "universal-cookie";
import axios from "axios";

const cookies = new Cookies();

export function getToken(callback) {
  const options = {
    method: "get",
    url: process.env.REACT_APP_API_URL + "/get_token",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + cookies.get("token"),
    },
  };

  let result = "";

  axios(options)
    .then((res) => {
      result = res.data.message;
      callback(result);
    })
    .catch((error) => {
      if (error.response.status === 403) {
        cookies.remove("token", { path: "/" });
        cookies.remove("name", { path: "/" });
        window.location.reload();
      } else {
        console.log(error.response);
      }
    });

  return options;
}

export function getTokenRepayment(callback, userId, orderNumbers) {
  const options = {
    method: "get",
    url: process.env.REACT_APP_API_URL + "/get_token_repayment",
    headers: {
      Accept: "application/json",
    },
    params: {
      userId: userId,
      orderNumbers: orderNumbers,
    },
  };

  let result = "";

  axios(options)
    .then((res) => {
      result = res.data.message;
      callback(result);
    })
    .catch((error) => {
      if (error.response.status === 403) {
        cookies.remove("token", { path: "/" });
        cookies.remove("name", { path: "/" });
        window.location.reload();
      } else {
        console.log(error.response);
      }
    });

  return options;
}

export function submitRepayment(
  callback,
  amount,
  nonce,
  orderIds,
  userId,
  orderNumbers
) {
  const options = {
    method: "post",
    url: process.env.REACT_APP_API_URL + "/post_repayment",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: {
      amount: amount,
      nonce: nonce,
      orderIds: orderIds,
      userId: userId,
      orderNumbers: orderNumbers,
    },
  };

  axios(options)
    .then((res) => {
      callback(res.data);
    })
    .catch((error) => {
      alert(
        "There is an issue processing your order, please try again later or contact site admin.\r\n\r\nError code: " +
          error.response.data.message
      );
    });
}

export function submitCheckout(
  callback,
  amount,
  nonce,
  deliveryTime,
  deliveryDate,
  deliveryNote,
  cartId,
  cardStatus,
  partnershipFund = 0
) {
  const options = {
    method: "post",
    url: process.env.REACT_APP_API_URL + "/post_checkout",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("token"),
    },
    data: {
      amount: amount,
      nonce: nonce,
      time: deliveryTime,
      date: deliveryDate,
      note: deliveryNote,
      cartId: cartId,
      cardStatus: cardStatus,
      partnershipFund: partnershipFund,
    },
  };

  axios(options)
    .then((res) => {
      callback(res.data);
    })
    .catch((error) => {
      if (error.response.status === 403) {
        cookies.remove("token", { path: "/" });
        cookies.remove("name", { path: "/" });
        window.location.reload();
      } else {
        alert(
          "There is an issue processing your order, please try again later or contact site admin.\r\n\r\nError code: " +
            error.response.data.message
        );

        window.location.reload();
      }
    });
}
