import React, { Component } from "react";
import { Button, Icon, Header, Modal, TransitionablePortal, Grid, Dropdown } from 'semantic-ui-react';
import FilterSection from "./FilterSection";

export default class FilterModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        }
    }

    handleClose(e) {
        e.stopPropagation();

        this.setState({
            visible: !this.state.visible
        })
    }

    handleShowClick(filter) {
        this.setState({
            visible: !this.state.visible
        })

        this.props.handleFilter(filter);
    }

    handleClearAll(e) {
        this.setState({
            visible: !this.state.visible
        })
        this.props.handleClearAll();
    }

    render() {
        const visible = this.state.visible;

        return (
            <div>
                <Grid>
                    <Grid.Row>
                        <Grid.Column only='mobile tablet'>
                            <div className='borderY width100'>
                                <Dropdown placeholder='FILTER BY' text='FILTER BY' className='crazyFilter' onClick={this.handleClose.bind(this)}/>

                                <FilterSection sortList={(sort) => this.props.sortList(sort)} />
                            </div>
                            <TransitionablePortal open={visible} transition={{ animation: 'fade right', duration: 200 }}>
                                <Modal dimmer='inverted' open={visible} size='fullscreen' style={{ height: '100%' }}>
                                    <Modal.Content className='filterModalContent'>
                                        <Button basic className='closeFilter absolute noShadow noBorder top10 left10 regular noPadding noMargin' onClick={this.handleClose.bind(this)} icon>
                                            <Icon name='close' size='huge' />
                                        </Button>
                                        <div className='flex flexBetween baseline'>
                                            <Header className='huge marginTop40 inline'>Filter By</Header>
                                            
                                            <Button compact className='transparent marginLeft10 inline fontWeightRegular noPaddingLeft noPaddingRight' onClick={this.handleClearAll.bind(this)}>
                                                Clear All
                                            </Button>
                                        </div>
                                        {this.props.children}
                                        <Button className={'bgOrange white regular normal noBorderRadius marginTopAuto height51px'} fluid onClick={this.handleClose.bind(this)}>
                                            Apply
                                        </Button>
                                    </Modal.Content>
                                </Modal>
                            </TransitionablePortal>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}