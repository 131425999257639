import React, { Component } from "react";
import { Grid, Header } from 'semantic-ui-react';
import { connect } from "react-redux";
import { pageLogin, pageNotLogin } from '../action/view';
import BackButton from "../parts/BackButton";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageLogin,
    pageNotLogin
};

class RefundPolicy extends Component {
    constructor(props) {
        super(props);

        this.props.pageNotLogin();
    }
    
    render() {
        return (
            <Grid padded className='loginBg'>
                <Grid.Row>
                    <Grid.Column width={16} className='marginTop30 alignCenter'>
                        <Header as='h2'>
                            Refunds & Returns Policy
                        </Header>

                        <div className='maxWidth70p center padding15 alignLeft'>
                            <Header as='h3'>YOUR RIGHT TO A REFUND OF PURCHASE PRICE</Header>
                            <p>
                                You may be entitled to a remedy under the Australian Consumer Law for items you purchase from Asahi if the item is:
                            </p>

                            <ul>
                                <li>
                                    faulty and the fault or defect was not pointed out to you before you purchased it or would not have been apparent to you when you inspected the goods before purchase;
                                </li>
                                <li>
                                    significantly different from the photo or description on the website;
                                </li>
                                <li>
                                    substantially not fit for its purpose (meaning that the item does not do what you reasonably expected it would), and can’t easily be fixed within a reasonable time; or
                                </li>
                                <li>
                                    unsafe,
                                </li>
                            </ul>
                            
                            <p>
                                AND you return the item within a reasonable time after purchase and provide Asahi with proof of purchase, such as your original invoice or bank statement. If there is a major failure with the item, you may choose a refund or exchange. If the failure is minor, we may repair the item within a reasonable time (or, at our discretion, replace or refund the item).
                            </p>

                            <p>
                                Where an item is damaged through misuse or abnormal use, Asahi will not provide a refund, exchange or repair.
                            </p>

                            <p>
                                For a returns, please send us a message with all details of the item, any photos of faults and reason for return.
                            </p>

                            <p>
                                Change of mind purchases must be covered by the consumer and any faulty items will have return shipping refunded.
                            </p>
                        </div>
                        <div className='login maxWidth70p center marginTop20'>
                            <BackButton />
                        </div>

                    </Grid.Column>

                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RefundPolicy);