import "cleave.js/dist/addons/cleave-phone.au";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import reducer from './reducer/index';
import { Provider } from "react-redux";
import TagManager from 'react-gtm-module'
import { GTM_ID, GA_ID, GTM_ID_MURMUR } from './Constants';
import ReactGA from 'react-ga';

const tagManagerArgs = {
    gtmId: GTM_ID
}

const murmurtagManagerArgs = {
    gtmId: GTM_ID_MURMUR
}

TagManager.initialize(murmurtagManagerArgs)
TagManager.initialize(tagManagerArgs)
ReactGA.initialize(GA_ID);

const store = createStore(
    reducer,
    applyMiddleware(
        thunkMiddleware // lets us dispatch() functions
    )
);

ReactDOM.render(<Provider store={store} ><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
