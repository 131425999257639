import React, { Component } from "react";
import CategorySlider from '../parts/CategorySlider';
import BrandSlider from '../parts/BrandSlider';
import { Grid } from 'semantic-ui-react';
import { getBrandList, getCategoryList } from '../utils/ProductAPI';
import { connect } from "react-redux";
import { pageNotLogin } from '../action/view';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageNotLogin,
};

class Shop extends Component {
    constructor(props) {
        super(props);

        this.state = {
            'brands': [],
            'categories': [],
            'isLoadingBrandsView': true,
            'isLoadingCateView': true,
        }
    }

    componentDidMount() {
        getBrandList((result) => this.setState({
            'brands': result,
            'isLoadingBrandsView': false
        }))

        getCategoryList((result) => this.setState({
            'categories': result,
            'isLoadingCateView': false
        }))

        this.props.pageNotLogin();
    }

    render() {
        let categories = this.state.categories;
        let brands = this.state.brands;
        
        return (
            <Grid container columns='equal'>
                <Grid.Row stretched style={{ marginTop: '20px' }}>
                    <CategorySlider slideToShow={5} data={categories} isLoading={this.state.isLoadingCateView} />
                </Grid.Row>

                <Grid.Row stretched style={{ marginTop: '20px' }}>
                    <BrandSlider data={brands} isLoading={this.state.isLoadingBrandsView}/>
                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Shop);
