import React, { Component } from "react";
import { Grid, Header } from 'semantic-ui-react';
import { connect } from "react-redux";
import { pageLogin, pageNotLogin } from '../action/view';
import BackButton from "../parts/BackButton";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageLogin,
    pageNotLogin
};

class ChangePolicy extends Component {
    constructor(props) {
        super(props);

        this.props.pageNotLogin();
    }
    
    render() {
        return (
            <Grid padded className='loginBg'>
                <Grid.Row>
                    <Grid.Column width={16} className='marginTop30 alignCenter'>
                        <Header as='h2'>
                            Change of Mind Policy
                        </Header>

                        <div className='maxWidth70p center padding15 alignLeft'>
                            <Header as='h3'>CHANGE OF MIND</Header>
                            <p>
                                Please choose carefully when placing your order. Under our own 'Change of Mind Policy', if you simply change your mind we may, at our discretion, offer an exchange equivalent to the purchase price for most items provided that:
                            </p>

                            <ol type='a'>
                                <li>
                                    you return the item within 30 days from the date you receive delivery
                                </li>
                                <li>
                                    the item is in its original condition; and
                                </li>
                                <li>
                                    all original labels, sealed packaging and tickets are attached to the item; and
                                </li>
                                <li>
                                    you provide an original tax invoice as proof of purchase; and
                                </li>
                                <li>
                                    discounted merchandise will only be exchanged, not refunded.
                                </li>
                            </ol>

                            <p>
                                We will handle your personal information in accordance with our privacy policy. Please review our policy before proceeding.
                            </p>
                        </div>
                        <div className='login maxWidth70p center marginTop20'>
                            <BackButton />
                        </div>

                    </Grid.Column>

                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePolicy);