import * as Constants from "../Constants";
import { setProductToCart, getCurrentCart } from "../utils/OrderAPI";
import {
  applyCouponCode,
  removeCouponCode,
  updateCouponProducts,
} from "../utils/CouponAPI";
import axios from "axios";
import Cookies from "universal-cookie";
import _ from "lodash";

const cookies = new Cookies();

const dispatchAddToCart = (data) => ({
  type: Constants.UPDATE_CART,
  payload: data,
});

const dispatchAddCouponProducts = (data) => ({
  type: Constants.UPDATE_COUPON_PRODUCTS,
  payload: data,
});

const dispatchChangePercentage = (percentage) => ({
  type: Constants.APPLY_PERCENTAGE,
  payload: percentage,
});

const dispatchApplyCoupon = (data, code, status = null) => ({
  type: Constants.APPLY_COUPON,
  payload: data,
  code: code,
  status: status,
});

const dispatchRemoveCoupon = () => ({
  type: Constants.REMOVE_COUPON,
});

const dispatchGetCart = (data) => ({
  type: Constants.GET_CART,
  payload: data,
});

export const getCartLoading = () => ({ type: Constants.GET_CART_LOADING });

export const updateCartLoading = () => ({
  type: Constants.UPDATE_CART_LOADING,
});

export function addCouponProducts() {
  return (dispatch) => {
    const options = updateCouponProducts();

    axios(options)
      .then((response) => response.data)
      .then((data) => {
        if (data.code === 201) {
          alert(data.message);
          dispatch(dispatchApplyCoupon([], "", "reload"));
        }
        let getMore = data.message.getMore;
        let freeProducts = data.message.products;
        let percentage = data.message.percentage;
        let freeCartItems = [];
        if (freeProducts) {
          freeProducts.forEach((product) => {
            freeCartItems.push({
              cart_item_id: product.id,
              product_sku: product.product_sku,
              quantity: product.qty,
              price: 0,
              rebate: 0,
              product_type: product.product_type,
              quantity_type: product.quantity_type,
              image_link: product.image_link,
              name: product.name,
            });
          });
          dispatch(dispatchAddCouponProducts(freeCartItems));
        } else if (getMore) {
          getMore = getMore[0];
          let freeCartItem = {
            cart_item_id: getMore.id,
            product_sku: getMore.product_sku,
            quantity: getMore.qty,
            price: getMore.price,
            rebate: 0,
            product_type: getMore.product_type,
            quantity_type: getMore.quantity_type,
            image_link: getMore.image_link,
            name: getMore.name,
          };
          dispatch(dispatchAddCouponProducts(freeCartItem));
        } else if (percentage) {
          dispatch(dispatchChangePercentage(percentage));
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          cookies.remove("token", { path: "/" });
          cookies.remove("name", { path: "/" });
          window.location.reload();
        } else {
          console.log(error.response);
        }
      });
  };
}

export function addToCart(id, qty) {
  return (dispatch) => {
    const options = setProductToCart(id, qty);
    dispatch(updateCartLoading());

    axios(options)
      .then((response) => response.data)
      .then((data) => dispatch(dispatchAddToCart(data.message)))
      .catch(function (error) {
        if (error.response.status === 403) {
          cookies.remove("token", { path: "/" });
          cookies.remove("name", { path: "/" });
          window.location.reload();
        } else {
          console.log(error.response);
        }
      });
  };
}

export function applyCoupon(code, cartItems) {
  return (dispatch) => {
    const options = applyCouponCode(code);
    dispatch(updateCartLoading());
    axios(options)
      .then((response) => response.data)
      .then((data) => {
        if (data.code === 201) {
          alert(data.message);
        } else {
          // remove free products from previous cartItems if available
          _.remove(cartItems, function (o) {
            return parseInt(o.price) === 0 || parseInt(o.price) < 0;
          });

          dispatch(dispatchApplyCoupon([], code));
          // if (freeProducts) {
          //   freeProducts.forEach((product) => {
          //     cartItems.push({
          //       cart_item_id: product.id,
          //       product_sku: product.product_sku,
          //       quantity: product.qty,
          //       price: 0,
          //       rebate: 0,
          //       product_type: product.product_type,
          //       quantity_type: product.quantity_type,
          //       image_link: product.image_link,
          //       name: product.name,
          //     });
          //   });
          //   dispatch(dispatchApplyCoupon(cartItems, code));
          // }
          // if (getMore) {
          //   getMore.forEach((product) => {
          //     cartItems.push({
          //       cart_item_id: product.id,
          //       product_sku: product.product_sku,
          //       quantity: product.qty,
          //       price: product.price,
          //       rebate: 0,
          //       product_type: product.product_type,
          //       quantity_type: product.quantity_type,
          //       image_link: product.image_link,
          //       name: product.name,
          //     });
          //   });
          //   dispatch(dispatchApplyCoupon(cartItems, code));
          // }
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          cookies.remove("token", { path: "/" });
          cookies.remove("name", { path: "/" });
          window.location.reload();
        } else {
          console.log(error.response);
        }
      });
  };
}

export function deleteCoupon() {
  return (dispatch) => {
    const options = removeCouponCode();
    dispatch(updateCartLoading());

    axios(options)
      .then((response) => response.data)
      .then((data) => {
        if (data.code === 201) {
          alert(data.message);
        } else {
          dispatch(dispatchRemoveCoupon());
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          cookies.remove("token", { path: "/" });
          cookies.remove("name", { path: "/" });
          window.location.reload();
        } else {
          console.log(error.response);
        }
      });
  };
}

export function getCart() {
  return (dispatch) => {
    const options = getCurrentCart();
    dispatch(getCartLoading());

    return axios(options)
      .then((response) => response.data)
      .then((data) => {
        if (parseInt(data.code) === 201) {
          dispatch(dispatchGetCart({ cart: [] }));
        } else {
          dispatch(dispatchGetCart(data.message));
        }

        if (data.message.coupon !== "" && data.message.coupon) {
          const options = applyCouponCode(data.message.coupon);
          const code = data.message.coupon;

          axios(options)
            .then((response) => response.data)
            .then((data) => {
              if (data.code === 201) {
                alert(data.message);
                dispatch(dispatchApplyCoupon([], "", "reload"));
              } else {
                dispatch(dispatchApplyCoupon([], code, "reload"));
                //   let freeProducts = data.message.products;
                //   let getMore = data.message.getMore;
                //   let cartItems = [];

                //   if (freeProducts) {
                //     freeProducts.forEach((product) => {
                //       cartItems.push({
                //         cart_item_id: product.id,
                //         product_sku: product.product_sku,
                //         quantity: product.qty,
                //         price: 0,
                //         rebate: 0,
                //         product_type: product.product_type,
                //         quantity_type: product.quantity_type,
                //         image_link: product.image_link,
                //         name: product.name,
                //       });
                //     });
                //     dispatch(dispatchApplyCoupon(cartItems, code, "reload"));
                //   }
                //   if (getMore) {
                //     getMore.forEach((product) => {
                //       cartItems.push({
                //         cart_item_id: product.id,
                //         product_sku: product.product_sku,
                //         quantity: product.qty,
                //         price: product.price,
                //         rebate: 0,
                //         product_type: product.product_type,
                //         quantity_type: product.quantity_type,
                //         image_link: product.image_link,
                //         name: product.name,
                //       });
                //     });
                //     dispatch(dispatchApplyCoupon(cartItems, code, "reload"));
                // }
              }
            });
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          cookies.remove("token", { path: "/" });
          cookies.remove("name", { path: "/" });
          window.location.reload();
        } else {
          console.log(error.response);
        }
      });
  };
}
