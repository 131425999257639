import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();

export function updateClubDetails(callback, club = {}) {

    const name = club.name ? club.name : '';
    const sport = club.sport_type ? club.sport_type : '';
    const league = club.league ? club.league : '';
    const phone = club.phone ? club.phone : '';
    const abn = club.abn ? club.abn : '';
    const address = club.shipping_address ? club.shipping_address : '';
    const suburb = club.suburb ? club.suburb : '';
    const state = club.state ? club.state : '';
    const postcode = club.postcode ? club.postcode : '';
    const licence_name = club.licence_name ? club.licence_name : '';
    const license_number = club.license_number ? club.license_number : '';
    const season = club.season ? club.season : '';

    const options = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/update_club',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        data: {
            name: name,
            sport_type: sport,
            league: league,
            phone: phone,
            abn: abn,
            shipping_address: address,
            suburb: suburb,
            state: state,
            postcode: postcode,
            licence_name: licence_name,
            license_number: license_number,
            season: season
        },
    }

    axios(options).then(res => {
        callback(res.data.message);
    }).catch((res) => {
        callback('error');
        alert('Error could not update your club to the selected values.');
    })
}