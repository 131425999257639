import React from "react";
import Home from "./components/Home";
import Shop from "./components/Shop";
import Favourite from "./components/Favourite";
import CategoryList from "./components/CategoryList";
import BrandList from "./components/BrandList";
import Orders from "./components/Orders";
import Cart from "./components/Cart";
import Checkout from "./components/Checkout";
import Repayment from "./components/Repayment";
import ProductListing from "./components/ProductListing";
import SearchResult from "./components/SearchResult";
import YourAccount from "./components/YourAccount";
import Login from "./components/Login";
import Welcome from "./components/Welcome";
import ClubRegister from "./components/ClubRegister";
import PrivacyPolicy from "./components/PrivacyPolicy";
import FAQS from "./components/FAQS";
import RefundPolicy from "./components/RefundPolicy";
import ChangePolicy from "./components/ChangePolicy";
import TermConditions from "./components/TermConditions";
import MarketingConsent from "./components/MarketingConsent";
import TheStory from "./components/TheStory";
import MobileMenu from "./components/MobileMenu";
//import MemberRegister from "./components/MemberRegister";
import ForgetPassword from "./components/ForgetPassword";
import ResetPassword from "./components/ResetPassword";
import NotFound from "./components/NotFound";
import PrivateRoute from "./utils/PrivateRoute";
import ScrollToTop from "./utils/ScrollToTop";
import "semantic-ui-less/semantic.less";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import MyHeader from "./parts/MyHeader";
import MyFooter from "./parts/MyFooter";
import { connect } from "react-redux";
import { Sticky } from "semantic-ui-react";
import LiquorLicences from "./components/LiquorLicences";
import YourRebates from "./components/YourRebates";
import Brands from "./components/Brands";
import Categories from "./components/Categories";
import PastOrders from "./components/PastOrders";
import Help from "./components/Help";
import ReLaunchTermConditions from "./components/ReLaunchTermConditions";
import UserCreation from "./components/UserCreation";
import CricketCampaignVic from "./components/CricketCampaignVic";
import JoelSelwoodGiveAway from "./components/JoelSelwoodGiveAway";
import CoolRidgeGiveAway from "./components/CoolRidgeGiveAway";
import AFL_Club_Activiation from "./components/AFLClubActivationCampaign";
import GATORADE_NSWRL from "./components/GatoradexNSWRL";
import CUBTC_AFLSE from "./components/CUBTC-AFLSE";
import CUBTC_AFLWA from "./components/CUBTC-AFLWA";
import WelcomeNonAlc from "./components/WelcomeNonAlc";
import AFLPromoteTC from "./components/AFLPromoteTC";
import VicCampaignTermsCondition from "./components/VicCampaignTermsCondition";
import BowlAusPromo from "./components/BowlAusPromo";
import WAAFLPromoteTC from "./components/WAAFLPromoteTC";
import YourRebateHistory from "./components/YourRebateHistory";
import ActivationTermConditions from "./components/ActivationTermConditions";
import ActivationTermConditions2022 from "./components/ActivationTermConditions2022";
import CricketTermConditions from "./components/CricketTermConditions";
import NonAlcActivationTermConditions from "./components/NonAlcActivationTermConditions";
import NonAlcActivationTermConditions2022 from "./components/NonAlcActivationTermConditions2022";
import Testimonials from "./components/Testimonials";
import JulyBonusCaseTermsCondition from "./components/JulyBonusCaseTermsCondition";
import NrlNswActivationCampaign from "./components/NrlNswActivationCampaign";
import NrlQldActivationCampaign from "./components/NrlQldActivationCampaign";
import AugBonusCase from "./components/AugBonusCase";
import CricketAUSPromoteTC from "./components/CricketAUSPromoteTC";
import { Fragment } from "react";

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuActive: false,
      stickySideActive: false,
    };
  }

  componentDidMount() {
    document.title = "Club Connect";
  }

  openMobileMenu() {
    document.body.classList.toggle("mobilemenu-open");
    this.setState(
      {
        mobileMenuActive: !this.state.mobileMenuActive,
      },
      () => {
        if (this.state.stickySideActive === true) {
          setTimeout(() => {
            this.setState({
              stickySideActive: false,
            });
          }, 300);
        } else {
          this.setState({
            stickySideActive: true,
          });
        }
      }
    );
  }

  contextRef = React.createRef();

  render() {
    return (
      <Fragment>
        <Router onUpdate={() => window.scrollTo(0, 0)}>
          <div ref={this.contextRef}>
            {(() => {
              if (this.props.root.hideHeader === false) {
                return (
                  <Sticky
                    context={this.contextRef}
                    className={this.state.stickySideActive ? "stickySide" : ""}
                  >
                    <div
                      className={
                        "blackBackground " +
                        (this.state.mobileMenuActive ? "" : " hide")
                      }
                    />
                    <MobileMenu
                      handleMobileMenu={() => this.openMobileMenu()}
                      active={this.state.mobileMenuActive}
                    />
                  </Sticky>
                );
              }
            })()}

            <ScrollToTop>
              <div className="wrapperContainer">
                {(() => {
                  if (this.props.root.hideHeader === false) {
                    return (
                      <Sticky context={this.contextRef}>
                        <MyHeader
                          handleMobileMenu={() => this.openMobileMenu()}
                        />
                      </Sticky>
                    );
                  }
                })()}
                <Route
                  render={({ location }) => (
                    <TransitionGroup
                      className={`${
                        this.props.root.hideHeader &&
                        this.props.root.page !== "checkout"
                          ? "height100"
                          : ""
                      } ${
                        !this.props.root.slideRegister ? "overflowHidden" : ""
                      }`}
                    >
                      <CSSTransition
                        key={location.key}
                        classNames="fade"
                        timeout={300}
                      >
                        <Switch location={location}>
                          <Route exact path="/welcome/" component={Welcome} />
                          <Route
                            exact
                            path="/welcomealb"
                            component={WelcomeNonAlc}
                          />
                          <Route exact path="/login" component={Login} />
                          <Route
                            exact
                            path="/forgetpassword"
                            component={ForgetPassword}
                          />
                          <Route
                            exact
                            path="/club-register"
                            component={ClubRegister}
                          />
                          <Route
                            exact
                            path="/password/find/:token"
                            component={ResetPassword}
                          />
                          <Route
                            exact
                            path="/privacy-policy"
                            component={PrivacyPolicy}
                          />
                          <Route exact path="/faqs" component={FAQS} />
                          <Route
                            exact
                            path="/testimonials"
                            component={Testimonials}
                          />
                          <Route
                            exact
                            path="/refund-policy"
                            component={RefundPolicy}
                          />
                          <Route
                            exact
                            path="/change-of-mind"
                            component={ChangePolicy}
                          />
                          <Route
                            exact
                            path="/terms-conditions"
                            component={TermConditions}
                          />
                          <Route
                            exact
                            path="/august-bonus-case"
                            component={AugBonusCase}
                          />
                          <Route
                            exact
                            path="/marketing-consent"
                            component={MarketingConsent}
                          />
                          <Route exact path="/our-story" component={TheStory} />
                          <Route
                            exact
                            path="/liquor-licences"
                            component={LiquorLicences}
                          />
                          <Route
                            exact
                            path="/relaunch-term-conditions"
                            component={ReLaunchTermConditions}
                          />
                          <Route
                            exact
                            path="/activation-t-c"
                            component={ActivationTermConditions}
                          />
                          <Route
                            exact
                            path="/cub-cricket-t-c"
                            component={CricketAUSPromoteTC}
                          />
                          <Route
                            exact
                            path="/activation-t-c-2022"
                            component={ActivationTermConditions2022}
                          />
                          <Route
                            exact
                            path="/nonalc-activation-t-c"
                            component={NonAlcActivationTermConditions}
                          />
                          <Route
                            exact
                            path="/nonalc-activation-t-c-2022"
                            component={NonAlcActivationTermConditions2022}
                          />
                          <Route
                            exact
                            path="/cricket-campaign-vic-t-c"
                            component={CricketCampaignVic}
                          />
                          <Route
                            exact
                            path="/cricket-t-c"
                            component={CricketTermConditions}
                          />
                          <Route
                            exact
                            path="/cub-aflse-t-c"
                            component={CUBTC_AFLSE}
                          />
                          <Route
                            exact
                            path="/cub-aflwa-t-c"
                            component={CUBTC_AFLWA}
                          />
                          <Route
                            exact
                            path="/cub-afl-t-c"
                            component={AFLPromoteTC}
                          />
                          <Route
                            exact
                            path="/cub-waafl-t-c"
                            component={WAAFLPromoteTC}
                          />
                          <Route
                            exact
                            path="/afl-club-activation-t-c"
                            component={AFL_Club_Activiation}
                          />
                          <Route
                            exact
                            path="/bowls-australia-campaign-t-c"
                            component={BowlAusPromo}
                          />
                          <Route
                            exact
                            path="/vic-campaign-t-c"
                            component={VicCampaignTermsCondition}
                          />
                          <Route
                            exact
                            path="/gatorade-nswrl-t-c"
                            component={GATORADE_NSWRL}
                          />
                          <Route
                            exact
                            path="/joel-selwood-t-c"
                            component={JoelSelwoodGiveAway}
                          />
                          <Route
                            exact
                            path="/cool-ridge-t-c"
                            component={CoolRidgeGiveAway}
                          />
                          <Route
                            exact
                            path="/july-bonus-case-t-c"
                            component={JulyBonusCaseTermsCondition}
                          />
                          <Route
                            exact
                            path="/nrl-nsw-t-c"
                            component={NrlNswActivationCampaign}
                          />
                          <Route
                            exact
                            path="/nrl-qld-t-c"
                            component={NrlQldActivationCampaign}
                          />
                          <Route
                            exact
                            path="/payment/:userId/:orderNumbers"
                            component={Repayment}
                          />
                          {/*<Route exact path="/member-register" component={MemberRegister} />*/}
                          <PrivateRoute exact path="/" component={Home} />
                          <PrivateRoute exact path="/shop" component={Shop} />
                          <PrivateRoute
                            exact
                            path="/favourites"
                            component={Favourite}
                          />
                          <PrivateRoute exact path="/orders" component={Orders} />
                          <PrivateRoute
                            exact
                            path="/past-orders"
                            component={PastOrders}
                          />
                          <PrivateRoute exact path="/cart" component={Cart} />
                          <PrivateRoute
                            exact
                            path="/checkout"
                            component={Checkout}
                          />
                          <PrivateRoute
                            exact
                            path="/your-account"
                            component={YourAccount}
                          />
                          <PrivateRoute
                            exact
                            path="/your-rebates"
                            component={YourRebates}
                          />
                          <PrivateRoute
                            exact
                            path="/rebate-history"
                            component={YourRebateHistory}
                          />
                          <PrivateRoute exact path="/brand" component={Brands} />
                          <PrivateRoute
                            exact
                            path="/add-users"
                            component={UserCreation}
                          />
                          <PrivateRoute
                            exact
                            path="/category"
                            component={Categories}
                          />
                          <PrivateRoute
                            exact
                            path="/category/:slug"
                            component={CategoryList}
                          />
                          <PrivateRoute
                            exact
                            path="/brand/:slug"
                            component={BrandList}
                          />
                          <PrivateRoute
                            exact
                            path="/product/:slug"
                            component={ProductListing}
                          />
                          <PrivateRoute
                            exact
                            path="/search/:slug/:search"
                            component={SearchResult}
                          />
                          <PrivateRoute exact path="/help" component={Help} />
                          <Route path="/404" component={NotFound} />
                          <Redirect to="/404" />
                        </Switch>
                      </CSSTransition>
                    </TransitionGroup>
                  )}
                />
              </div>
              <MyFooter />
            </ScrollToTop>
          </div>
        </Router>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
