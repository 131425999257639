import React, { Component } from "react";
import { Grid, Header, Input, Icon, Button, Image, Dimmer, Loader } from 'semantic-ui-react';
import { Braintree, HostedField } from 'react-braintree-fields';
import { withRouter, Link } from 'react-router-dom';
import { connect } from "react-redux";
import { getTokenRepayment, submitRepayment } from '../utils/PaymentAPI';
import { getRepaymentOrder } from '../utils/OrderAPI';
import { formatCurrency } from '../utils/Utils';


function mapStateToProps(state) {
    return state;
}

class Repayment extends Component {
    constructor(props) {
        super(props);
        this.scrollEl = React.createRef();

        this.state = {
            submitLoad: false,
            order_success: false,
            numberFocused: false,
            repaymentOrders: '',
            amount: '',
            type: 'unknown',
            valid: false,
            authorization: '',
            token: '',
            error: '',
            loading: false,
            authorizationLoading: true,
            slideValue: 0,
            cleaveNumber: null,
            orderIds: [],
            orderNumbers: [],
            userId: '',
            clubName: '',
            hasOrders: true,
            user: '',
        }

        this.numberField = React.createRef();
        this.braintree = React.createRef();
        [
            'onError',
            'getToken',
            'onCardTypeChange',
            'onAuthorizationSuccess',
        ].forEach(prop => (this[prop] = this[prop].bind(this)));
    }

    onError(error) {
        this.setState({ error: error.message || String(error) });
    }

    getToken() {
        this.tokenize().then(
            token => this.setState({ token, error: null }),
        ).catch(error => this.onError(error));
    }

    onCardTypeChange({ cards }) {
        if (1 === cards.length) {
            const [card] = cards;

            this.setState({ card: card.type });

            if (card.code && card.code.name) {
                this.cvvField.setPlaceholder(card.code.name);
            } else {
                this.cvvField.setPlaceholder('CVV');
            }
        } else {
            this.setState({ card: '' });
            this.cvvField.setPlaceholder('CVV');
        }
    }

    componentDidMount() {
        var orderNumbers = this.props.match.params.orderNumbers.split('&');
        const userId = this.props.match.params.userId;
        getTokenRepayment((result) => this.setState({ authorization: result }), userId, orderNumbers);

        this.setState({
            userId: userId
        })

        getRepaymentOrder((orders, hasOrders) => {
            if (hasOrders) {
                this.setState({
                    amount: orders.reduce((partialSum, a) => partialSum + parseInt(a.braintree.payable), 0),
                    repaymentOrders: orders,
                    clubName: orders[0].club,
                    user: orders[0].name,
                    orderIds: orders.reduce((prev, cur) => [...prev, cur.order_id], []),
                    orderNumbers: orders.reduce((prev, cur) => [...prev, cur.order_no], [])
                })
            } else {
                this.setState({ hasOrders: hasOrders, message: orders })
            }
        }, orderNumbers, userId)
    }

    renderResult(title, obj) {
        if (!obj) { return null; }
        return (
            <div>
                <b>{title}:</b>
                <pre>{JSON.stringify(obj, null, 4)}</pre>
            </div>
        );
    }

    checkValidInput = (e) => {
        if (e.fields.number.isValid === true && e.fields.expirationDate.isValid === true && e.fields.cvv.isValid === true) {
            this.setState({
                valid: true
            })
        } else {
            this.setState({
                valid: false
            })
        }
    }

    onAuthorizationSuccess() {
        this.setState({
            authorizationLoading: false
        })
        this.numberField.current.focus();
    }

    getTokenNonce = (flag) => {
        const amount = this.state.amount;

        if (flag) {
            if (this.state.valid) {

                this.setState({
                    loading: true,
                    valid: false
                });

                this.tokenize().then((token) => {
                    this.setState({ token, error: null });

                    submitRepayment((res) => {
                        this.setState({
                            loading: false,
                            submitOrder: false
                        })

                        if (res.code === 201) {
                            alert(res.message);
                        } else if (res.code === 200) {
                            this.setState({
                                order_success: true
                            })
                            alert('Thank you for your payment.')
                            window.location = '/';
                        }
                    }, amount, token.nonce, this.state.orderIds, this.state.userId, this.state.orderNumbers);

                }).catch((error) => {
                    this.setState({ token: null, error, loading: false, valid: true }, () => alert('Your credit/debit card is invalid.'));
                });
            }
        }
    }

    render() {
        const orders = this.state.repaymentOrders;
        const user = this.state.user;
        const amount = this.state.amount;
        const clubName = this.state.clubName;
        const orderNumbers = this.state.orderNumbers

        if (this.state.hasOrders) {
            return (
                <Grid container>
                    <Grid.Row verticalAlign='middle' style={{ height: '150px' }} className='zIndex1 fixed top left bgGradientGray'>
                        <Grid.Column verticalAlign='middle' width={16}>
                            <Link to='/'><Image id='GoToHome' inline style={{ width: '7em' }} src='/img/ClubConnect_Landscape.svg' /></Link>
                        </Grid.Column>
                    </Grid.Row>

                    <Header className='largeMedium noMargin primaryColor' style={{ paddingTop: '150px' }}>Payment</Header>

                    <Grid.Row mobile={16} tablet={16} computer={16} >
                        {(user && orderNumbers.length > 0) &&
                            <p className="flex text-xl mx-12 pt-12">
                                Hi {user}, We wanted to inform you that we have encountered an issue between the Club Connect website and our payment gateway provider.Unfortunately {clubName} was one of the clubs impacted by this issue. The orders this impacts for {clubName} are order{orderNumbers.length > 1 && 's'} {orderNumbers.join(' and ')}.
                            </p>
                        }
                    </Grid.Row>

                    <Grid.Row mobile={16} tablet={16} computer={16} style={{ paddingBottom: '75px' }}>
                        <div className="flex w-full mx-12">
                            {orders.length > 0 && orders.map((order, key) => {
                                return (
                                    <div className={`basis-1/${key} flex-1 mr-4 bg-white p-8 rounded-lg`} key={key}>
                                        <p className='small marginBottom'>Order No: <strong className='regular'>#{order.order_no}</strong></p>
                                        <p className='small marginBottom'>Placed on: <strong className='regular'>{order.created_at}</strong></p>
                                        <p className='small marginBottom'>Delivered Address: <strong className='regular'>{order.delivery_address}</strong></p>
                                        <p className='small marginBottom'>Delivered Date: <strong className='regular'>{order.delivery_date}</strong></p>
                                        <p className='small marginBottom'>Customer Name: <strong className='regular'>{order.name}</strong></p>
                                        <p className='small marginBottom'>Club Name: <strong className='regular'>{order.club}</strong></p>
                                        <p className='small'>Delivery Note: <strong className='regular'>{order.delivery_note}</strong></p>
                                        <p className='small'>Partnership Fund Used: <strong className='regular'>{order.partnership_fund ? order.partnership_fund : 0}</strong></p>
                                        <p className='small'><strong className='regular'>Items: </strong></p>
                                        {order.product_info.length > 0 && order.product_info.map((product, key) => {
                                            return (
                                                <div className="flex items-center w-full justify-between" key={key} >
                                                    <div width={2} className='noPaddingRight'>
                                                        <div className='orderItem'>
                                                            <Image src={product.image} className='cartProduct' />
                                                        </div>
                                                    </div>

                                                    <div width={3}>
                                                        <p className='small'>{product.quantity} x</p>
                                                    </div>

                                                    <div width={7} className='noPaddingLeft'>
                                                        <Header className='small'>
                                                            {product.name}
                                                            <Header.Subheader className='lightGray small noMargin'>
                                                                {product.quantity_type}
                                                            </Header.Subheader>
                                                        </Header>
                                                    </div>

                                                    <div width={4} className='noPaddingLeft'>
                                                        <strong className='primaryColor small'>{formatCurrency(product.price * product.quantity)}</strong>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={16} computer={16} style={{ paddingBottom: '75px' }}>

                            <Dimmer inverted active={this.state.submitLoad}>
                                <Loader size='medium'>Submitting payment</Loader>
                            </Dimmer>

                            <Grid.Column width={16} className='marginTop10'>

                                <Braintree
                                    ref={this.braintree}
                                    authorization={this.state.authorization}
                                    onAuthorizationSuccess={this.onAuthorizationSuccess}
                                    onError={this.onError}
                                    getTokenRef={t => (this.tokenize = t)}
                                    onCardTypeChange={this.onCardTypeChange}
                                    onValidityChange={this.checkValidInput}
                                >
                                    <Grid>
                                        <Dimmer inverted active={this.state.authorizationLoading}>
                                            <Loader size='medium'>Authorisation</Loader>
                                        </Dimmer>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <Input fluid iconPosition='left' className='borderRadius10 noBorder bgLightGray small paddingTopBottom10 paddingLeft3em'>
                                                    <HostedField
                                                        type="number" disabled={this.state.loading || this.props.loading}
                                                        ref={this.numberField}
                                                    />
                                                    <Icon name='credit card outline' />
                                                </Input>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row className='noPadding'>
                                            <Grid.Column width={10}>
                                                <Input fluid iconPosition='left' className='borderRadius10 noBorder bgLightGray small paddingTopBottom10 paddingLeft3em'>
                                                    <HostedField type="expirationDate" placeholder="MM/YY" />
                                                    <Icon name='calendar outline' />
                                                </Input>
                                            </Grid.Column>

                                            <Grid.Column width={6}>
                                                <Input fluid iconPosition='left' className='borderRadius10 noBorder bgLightGray small paddingTopBottom10 paddingLeft3em'>
                                                    <HostedField type="cvv" placeholder="CCV" ref={cvvField => { this.cvvField = cvvField; }} />
                                                    <Icon name='lock' />
                                                </Input>
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row>
                                            <Grid.Column width={16}>

                                                <Button onClick={() => this.getTokenNonce(true)} loading={this.state.loading || this.props.loading} className='regular bgPrimary borderRadius10 white normal' fluid disabled={!this.state.valid || this.props.loading}>Pay {formatCurrency(amount)}</Button>

                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Braintree>
                            </Grid.Column>
                        </Grid.Column>
                    </Grid.Row>

                    <div style={{ float: 'left', clear: 'both' }} ref={this.scrollEl}></div>
                </Grid>
            );
        } else {
            return (
                <Grid container>
                    <Grid.Row verticalAlign='middle' style={{ height: '150px' }} className='zIndex1 fixed top left bgGradientGray'>
                        <Grid.Column verticalAlign='middle' width={16}>
                            <Link to='/'><Image id='GoToHome' inline style={{ width: '7em' }} src='/img/ClubConnect_Landscape.svg' /></Link>
                        </Grid.Column>
                    </Grid.Row>

                    <Header className='largeMedium noMargin primaryColor' style={{ paddingTop: '150px' }}>Payment</Header>
                    <Grid.Row>
                        <div className="flex w-full mx-12 mt-24 content-center">
                            <h5 className="leading-6" dangerouslySetInnerHTML={{ __html: this.state.message }}></h5>
                        </div>
                    </Grid.Row>
                    <Grid.Row>
                        <div className="flex w-full mx-12 content-center">
                            <Link to='/'><Button primary>Return to Homepage</Button></Link>
                        </div>
                    </Grid.Row>
                </Grid>
            )
        }
    }
}

export default withRouter(connect(
    mapStateToProps,
)(Repayment));
