import React, { Component } from "react";
import AddToCartNumber from './AddToCartNumber';
import { Button, Icon, Transition, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../utils/Utils';

export default class MyMiniCartBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            addToCartNum: 0,
            id: this.props.product.id
        };
    }

    handleOpenAddToCart() {
        const currentState = this.state.active;
        this.setState({ active: !currentState });
    };

    onAddToCart() {
        const currentCart = this.state.addToCartNum;
        let nextNum = parseInt(currentCart) + 1;

        this.setState({
            addToCartNum: nextNum
        })
    }

    onSubtractToCart() {
        const currentCart = this.state.addToCartNum;
        let nextNum = parseInt(currentCart) - 1;

        if (nextNum < 0) {
            nextNum = 0;
        }

        this.setState({
            addToCartNum: nextNum
        })
    }

    render() {
        const product = this.props.product;
        const visible = !this.state.active;

        return (
            <div>
                <div className='relative width100 height145px'>
                    <Transition visible={visible} animation='fade' duration={300}>
                        <div className='absolute center0 height145px maxWidth150'>
                            <Link to={'/product/' + product.slug} className='productImageLink'>
                                <Image src={product.image_link} centered className='productImage' />
                            </Link>
                        </div>
                    </Transition>

                    <Transition visible={this.state.active} animation='fade' duration={300}>
                        <div className='absolute center10 height145px maxWidth150'>
                            <p className='small'>Cases</p>
                            <AddToCartNumber product={product} description={true} size='regular' />
                        </div>
                    </Transition>
                </div>
                <div className='relative minHeight45 marginTop30'>
                    {
                        <Transition visible={visible} animation='fade' duration={300}>
                            <div className='price'>
                                <p className='primaryColor bold largeMedium noMargin'>{formatCurrency(product.price)}</p>
                                {
                                    product.rebate === '' | product.rebate === null ? '' : <p className='small miniFont cub-yellow bold'>{'+ ' + formatCurrency(product.rebate) + ' Credit'}</p>
                                }
                            </div>
                        </Transition>
                    }
                    <div className='addToCartMiniContainer absolute top right alignRight width100'>
                        <Button size='small' icon data-id={product.id} onClick={this.handleOpenAddToCart.bind(this)} className={'borderRadius5 bgPrimary noMargin addToCartMini relative ' + (this.state.active ? 'width100' : '')} style={{ color: '#fff' }}>
                            <Transition visible={visible} animation='fade' duration={100}>
                                <Icon className='absolute center10' name='add' />
                            </Transition>
                            <Transition visible={this.state.active} animation='fade' duration={300}>
                                <span className='absolute center10 small'>Cancel</span>
                            </Transition>
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}