import React, { Component } from "react";
import { Grid, Header, Image, Icon } from 'semantic-ui-react';
import ListCartItem from '../parts/ListCartItem';
import { formatCurrency, calculateGST } from '../utils/Utils';

export default class PastOrderDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false
        }
    }

    handleActive() {
        this.setState({
            active: !this.state.active
        })
    }

    render() {
        const order = this.props.data;

        const renderNormal = () => {
            return(
                <div className='section card borderRadius10 bgWhite shadow20 padding15 marginBottom20'>
                    <Grid>
                        <Grid.Row columns={1}>
                            <Grid.Column textAlign='left'>
                                <Header size='tiny' className='gray marginBottom tiny'>ORDER SUMMARY</Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <p className='small marginBottom'>Order No: <strong className='regular'>#{order.order_no}</strong></p>
                    <p className='small marginBottom'>Placed on: <strong className='regular'>{order.created_at}</strong></p>
                    <p className='small marginBottom'>Items: <strong className='regular'>{order.total_quantity}</strong></p>
                    <p className='small marginBottom'>Partnership Fund Used: <strong className='regular'>{order.used_partner_fund ? order.used_partner_fund : 0}</strong></p>

                    <Grid className='marginTop10'>
                        <Grid.Row columns={1}>
                            <Grid.Column textAlign='left'>
                                <Header size='tiny' className='gray marginBottom tiny'>DISPATCHED TO</Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <p className='regular noMargin'><strong>{order.name}</strong></p>
                    <p className='small noMargin'>{order.shipping_address}</p>
                    <p className='small noMargin'>{order.suburb}</p>
                    <p className='small noMargin'>{order.state}</p>
                    <p className='small noMargin'>{order.postcode}</p>

                    <Grid className='marginTop10'>
                        <Grid.Row>
                            <Grid.Column textAlign='left' width={16}>
                                <Header size='tiny' className='gray marginBottom10 tiny'>ITEMS</Header>
                            </Grid.Column>

                            {
                                order.products.map((item, k) => {
                                    return (
                                        <Grid.Column key={k} width={4} className='marginBottom20'>
                                            <div className='orderItem'>
                                                <Image src={item.image_link} fluid />
                                            </div>
                                        </Grid.Column>
                                    )
                                })
                            }
                        </Grid.Row>
                    </Grid>

                    <Grid className={'pastCartDetails bgLightGray borderRadius10 overflowHidden ' + (!this.state.active ? 'maxHeight60 height42 ' : 'maxHeight50vh heightAuto')} padded>
                        <Grid.Row>
                            <Grid.Column textAlign='center' className='cursor' onClick={this.handleActive.bind(this)}>
                                <p className='bold primaryColor small'>
                                    <Icon name='plus' />
                                    {this.state.active ? 'HIDE DETAILS' : 'TOTAL PAID ' + formatCurrency(order.total_price)}
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Grid className='orderItems' relaxed>
                                    <ListCartItem data={order.products} />
                                </Grid>

                                <Grid className='bottom15 marginTop40'>
                                    <Grid.Row>
                                        <Grid.Column width={4} textAlign='left' verticalAlign='bottom'>
                                            <p className='regular lightGray'>Total:</p>
                                        </Grid.Column>

                                        <Grid.Column width={12} textAlign='right'>
                                            <Header className='largeMedium'>
                                                <Header.Subheader className={'red small noMargin' + (order.rebate === 0 ? ' hidden' : '')}>
                                                    Partnership Fund {formatCurrency(order.total_rebate)}
                                                </Header.Subheader>
                                                {formatCurrency(order.total_price)}
                                                <p className='small lightGray noMargin normal'>Inc {formatCurrency(calculateGST(order.total_price))} GST</p>
                                            </Header>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )
        }


        const renderHistory = (id) => {
            return (
                <div className={'section card borderRadius10 shadow20 padding15 marginBottom20 pastOrder cursor ' + (this.props.isSelected ? 'bgPrimary white' : 'bgWhite')} onClick={() => this.props.handleDetail(id)}>
                    <p className='small marginBottom'>Order No: <strong className='regular'>#{order.order_no}</strong></p>
                    <p className='small marginBottom'>Placed on: <strong className='regular'>{order.created_at}</strong></p>
                </div>
            )
        }

        if(this.props.isOrderHistory) {
            return (
                renderHistory(this.props.id)
            )
        } else {
            return (
                renderNormal()
            )
        }
    }
}