import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();

// 1 club only has 1 bank statement
export function setBankStatement(callback, objectArray) {
    /*let bank_name = objectArray.bank_name ? objectArray.bank_name : '';
    let account_name = objectArray.account_name ? objectArray.account_name : '';
    let account_num = objectArray.account_num ? objectArray.account_num : '';
    let bsb = objectArray.bsb ? objectArray.bsb : '';
    let file = objectArray.file ? objectArray.file : null;*/

    const options = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/create_bank',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        data: objectArray /*{
            'bsb': bsb,
            'account_name': account_name,
            'account_num': account_num,
            'bank_name': bank_name,
            'file': file
        }*/
    }

    axios(options).then(res => {
        let result = res.data.message;
        let code = res.data.code;
        callback(result, code);
    }).catch(error => {
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
            callback('There is an issue with the server. Please try again.', 401);
        }
    })
}