import React, { Component } from "react";
import Slider from 'react-slick';
import { Image } from 'semantic-ui-react';


class LandingPageTestimonial extends Component {

    render() {
        const settings = {
            dots: false,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 2.5,
            slidesToScroll: 1,
            lazyLoad: 'progressive',
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1.1,
                        slidesToScroll: 1,
                    },
                }
            ]
        };

        const testimonials = [
            {
                comment: 'Club Connect has significantly improved our ability to manage the price we pay for alcohol, resulting in a better return for the Club.  As a volunteer-based Club, the usage of our account with Club Connect is also a huge timesaver.  We particularly appreciate the delivery service, especially doing so out of normal business hours, so our volunteers can be on hand to accept the delivery.',
                company: 'Stacey, Treasurer, Moorebank Liverpool District Hockey Club​',
                image_link: 'company-1.png'
            },
            {
                comment: 'Club Connect is definitely an easier solution for ordering most of the beverages for our bar. It was simple to sign-up, and we can now place the beverage order online and choose the date and time for the delivery to arrive, we also appreciate the updates with the delivery time. We have placed orders for CUB brand beers, non-alcoholic beer and also from their range of pre-mixed drinks. We’ve been accumulating the Partnership Fund and looking forward to accessing this for additional fundraising for the Club shortly.​​',
                company: 'Bob, Bar Manager, Balnarring Bowls & Social Club​​',
                image_link: 'company-2.png'
            },
            {
                comment: 'We were looking for an easier solution for our volunteers when sourcing our beverages, and very happy to have found Club Connect. We have been ordering weekly from the great range of beer, cider, RTD’s and sports drink and appreciate the updates with the delivery time – always arriving within the Friday delivery window before our home matches. Club Connect has saved us a lot of time and money this year, it was simple to sign-up and place the orders and we’re looking forward to accessing the sponsorship fund at the end of the year. With such an easy process in place, we know it will be easier to attract more volunteers to help out at the Club moving forward.​​',
                company: 'Greg, Club President, Greensborough Hockey Club​',
                image_link: 'company-3.jpg'
            },
            {
                comment: 'We started using Club Connect at the start of the season and have been really happy with the service. We’ve ordered from the great range of beer, cider and RTD’s and appreciate the updates with the delivery time – always arriving within the Thursday delivery window before our home matches. Club Connect has saved us a lot of time and money this year, it was simple to sign-up and place the orders and we’re looking forward to accessing the sponsorship fund at the end of the year.​​',
                company: 'Neil, Vice President, Mooroolbark Football Club',
                image_link: 'company-4.jpg'
            },
            {
                comment: 'We started using Club Connect for the 2021 season and will definitely be using the platform again next season. We were impressed with the cash back and Partnership Fund, and the free delivery was really convenient - we would choose the 4pm-7pm delivery time on a Tuesday night when we would be at the club for training. We’re also really grateful for the support from Club Connect when our Club was impacted by the storms.',
                company: 'Mick Hill, President, Olinda Ferny Creek Football Netball Club',
                image_link: 'company-5.jpg'
            },
            {
                comment: "The ease of ordering and punctual delivery makes Club Connect our club’s first choice when purchasing alcohol, plus the cash back from each sale has helped us with the season's running costs.We look forward to working with Club Connect for years to come.",
                company: 'Josh, Bar Manager North Footscray Football Netball Club',
                image_link: 'company-6.png'
            },
            {
                comment: "We are huge supporters of Club Connect as is that it provides great value for money and service for volunteer organisations, by allowing flexible and convenient free deliveries, coupled with very competitive pricing and a wide range of products.",
                company: 'Manny Scata, President, Keysborough Football Club',
                image_link: 'company-7.jpg'
            },

            {
                comment: "We’re really happy with Club Connect and will definitely be using to platform again in 2022. The free delivery was most appealing, meaning we don’t have to pick-up the orders and drive around with stock in our cars. We always selected the 4pm-7pm delivery window on a Thursday night, so the stock arrived when we were at the club for training. There is a wide and expanding range of products available, the pricing is competitive and the cash back on every order was a great additional fundraising tool for our club.",
                company: 'Tony Fisher, Maribyrnong Park Football Club',
                image_link: 'company-8.jpg'
            },
            {
                comment: "We used Club Connect during the 2021 season and were so happy with the convenience of the service. Being able to order our beverages online and have delivered for free to the club has saved so much time for our volunteers.",
                company: 'Ivan Delac, President Glen Eira Amateur Football Club',
                image_link: 'company-9.png'
            },
            {
                comment: "We started ordering from Club Connect at the beginning of last season and have been really happy with the service. I am always looking to save money for the Club and found Club Connect offered great prices and often has special offers and discounts available plus free delivery was an added bonus. They offer cash back on every purchase which accumulates in a Partnership Fund which we used towards our next order, saving even more money! We’ll definitely be using Club Connect for the 2022 season.",
                company: 'Tammie Ethell, Bar Manager, North Fremantle Football Club',
                image_link: 'company-10.png'
            },
        ]

        return (
            <>
                <div className="container mx-auto flex-col px-4 md:px-0 mb-14 md:mb-0">
                    <h2 className="text-2xl font-bold">Don’t take our word for it. Trust our customers.</h2>
                    <hr className="w-24 h-1.5 bg-black my-8" />
                </div>
                <div className="container mx-auto mb-28 flex-col p-4 md:px-0">
                    <Slider {...settings} className='testimonial-slider'>
                        {
                            testimonials.map((testimonial, key) => {
                                return (
                                    <div key={key} className="testimonial pt-14 pl-14 pr-16 pb-16 bg-grey relative">
                                        <p className="text-lg">{testimonial.comment}</p>
                                        <h2 className="text-lg font-bold mt-12 max-w-350 mb-0">{testimonial.company}</h2>
                                        {testimonial.image_link && <div className="w-24 h-auto absolute right-8 bottom-8">
                                            <Image verticalAlign='middle' fluid src={'/img/' + (testimonial.image_link)} className="mix-blend-darken" />
                                        </div>}
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            </>
        )
    }
}

export default LandingPageTestimonial;
