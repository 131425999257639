import React, { Component } from "react";
import { Grid, Header, Divider, Button } from 'semantic-ui-react';
import { formatCurrency } from '../utils/Utils';
import { connect } from "react-redux";
import { pageNotLogin } from '../action/view';
import { getUserRebateHistory } from '../utils/UserAPI';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageNotLogin,
};

class YourRebateHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rebate_history: [],
            currentPage: 1,
            limit: 20,
            isLoading: false,
            total: 0,
            user: '',
            club: '',
            bank_account: '',
            loading: false,
            openModal: false,
            loadingData: true
        }
    }

    componentDidMount() {
        getUserRebateHistory((result, total) => {
            this.setState({
                'rebate_history': result,
                'total': total
            })
        }, this.state.limit * this.state.currentPage)

        this.props.pageNotLogin();
    }

    handleNextPage() {

        let currentPage = this.state.currentPage + 1

        this.setState({
            isLoading: true,
            currentPage: currentPage
        })

        getUserRebateHistory((result, total) => {
            this.setState({
                rebate_history: result,
                isLoading: false,
                total: total
            })
        }, this.state.limit * currentPage)
    }

    render() {
        const rebateHistory = this.state.rebate_history;
        const isLoading = this.state.isLoading;

        return (
            <Grid container className='marginTop10'>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Header as='h2' className='noMarginBottom'>Your Partnership Fund History</Header>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row className='marginBottom20' verticalAlign='top'>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                        <div className='borderRadius10 bgWhite padding30 shadow20 marginBottom20'>
                            <Grid>
                                <Grid.Row doubling>
                                    <Grid.Column computer={3} only='computer'>
                                        <strong>Date</strong>
                                    </Grid.Column>
                                    <Grid.Column computer={4} tablet={4}>
                                        <strong>Type</strong>
                                    </Grid.Column>
                                    <Grid.Column computer={3} tablet={4}>
                                        <strong>Debit</strong>
                                    </Grid.Column>
                                    <Grid.Column computer={3} tablet={4}>
                                        <strong>Credit</strong>
                                    </Grid.Column>
                                    <Grid.Column computer={3} tablet={4}>
                                        <strong>Total Available</strong>
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        <Divider clearing />
                                    </Grid.Column>
                                </Grid.Row>
                                {
                                    rebateHistory.map((value, key) => {
                                        const createdDate = Date.parse(value.created_at + ' UTC');
                                        let correctDate = new Date();
                                        correctDate.setTime(createdDate);

                                        return (
                                            <Grid.Row key={key}>
                                                <Grid.Column computer={3} only='computer'>
                                                    {new Date(value.created_at + ' UTC').toLocaleDateString()}
                                                </Grid.Column>
                                                <Grid.Column computer={4} tablet={4} mobile={16}>
                                                    {value.type}
                                                <br/>
                                                    {
                                                        value.note && <>Note: {value.note}</>
                                                    }
                                                </Grid.Column>
                                                <Grid.Column only='mobile' mobile={4}/>
                                                <Grid.Column computer={3} tablet={4}>
                                                    {
                                                        value.operator === 'minus' && <strong><span className='red'>-</span>{formatCurrency(value.rebate)}</strong>
                                                    }
                                                </Grid.Column>
                                                <Grid.Column computer={3} tablet={4}>
                                                    {
                                                        value.operator === 'add' && <strong><span className='green'>+</span>{formatCurrency(value.rebate)}</strong>
                                                    }
                                                </Grid.Column>
                                                <Grid.Column computer={3} tablet={4}>
                                                    {formatCurrency(value.current_rebate_available)}
                                                </Grid.Column>
                                            </Grid.Row>
                                        )
                                    })
                                }
                                {(() => {
                                    if (rebateHistory) {
                                        if (this.state.total > rebateHistory.length) {
                                            return (
                                                <Grid.Row>
                                                    <Grid.Column textAlign='center'>
                                                        <Button loading={isLoading} disabled={isLoading} size='small' className='borderRadius5 bgPrimary white' onClick={this.handleNextPage.bind(this)}>Load More</Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )
                                        }
                                    }
                                })()}
                            </Grid>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(YourRebateHistory);
