import React, { Component, createRef } from "react";
import { Grid, Header, Button, Sticky} from 'semantic-ui-react';
import ListCartItem from '../parts/ListCartItem';
import { formatCurrency, calculateGST } from '../utils/Utils';
import PastOrderDetail from "../parts/PastOrderDetail";
import { connect } from "react-redux";
import { pageNotLogin } from '../action/view';
import { getOrderList } from '../utils/OrderAPI';
import OrderModal from '../parts/OrderModal';
import { PDFLink } from '../components/PDFInvoice';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageNotLogin,
};

const limit = 20;

class PastOrders extends Component {
    contextRef = createRef();

    constructor(props) {
        super(props);
        this.state = {
            past_orders: [],
            currentPage: 1,
            total: 0,
            selected: '',
            isLoading: false,
            modalLoading: false,
            openModal: false
        }
    }

    componentDidMount() {
        this.props.pageNotLogin();

        getOrderList((result, total) => {
            this.setState({
                past_orders: result,
                total: total,
                selected: result[0]
            })
        }, false, limit * this.state.currentPage)
    }

    handleDetail(key) {
        this.setState({
            selected: this.state.past_orders[key],
        })

        if (window.innerWidth <= 991) {
            this.setState({
                openModal: true
            })
        }
    }

    handleNextPage() {
        const nextPage = this.state.currentPage + 1;

        getOrderList((result, total) => {
            this.setState({
                past_orders: result,
                total: total,
                currentPage: nextPage
            })
        }, false, limit * nextPage)
    }

    handleCloseModal() {
        this.setState({
            openModal: false
        })
    }

    handleGeneratePDF() {
        
    }

    render() {
        const pastOrders = this.state.past_orders;
        const order = this.state.selected;
        const total = this.state.total;
        const isLoading = this.state.isLoading;
        return (
            <Grid container verticalAlign='top'>
                <Grid.Row>
                    <Grid.Column className='marginTop20'>
                        <Header className='large'>
                            Past Orders
                    </Header>
                    </Grid.Column>
                </Grid.Row>
                <div ref={this.contextRef} className='width100'>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column mobile={16} tablet={16} computer={4}>
                                {
                                    pastOrders.map((order, key) => {
                                        return (
                                            <PastOrderDetail data={order} key={key} id={key} isOrderHistory={true} handleDetail={(res) => this.handleDetail(res)} isSelected={this.state.selected === order} />
                                        )
                                    })
                                }

                                {(() => {
                                    if (pastOrders) {
                                        if (total > pastOrders.length) {
                                            return (
                                                <Grid.Row>
                                                    <Grid.Column textAlign='center'>
                                                        <Button loading={isLoading} disabled={isLoading} size='small' className='borderRadius5 bgPrimary white' onClick={this.handleNextPage.bind(this)}>Load More</Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )
                                        }
                                    }
                                })()}
                            </Grid.Column>

                            <OrderModal isLoading={this.state.modalLoading} isOpen={this.state.openModal && window.innerWidth <= 991} closeModal={this.handleCloseModal.bind(this)} order={this.state.selected}/>
                            
                            <Grid.Column computer={12} only='computer'>
                                <Sticky context={this.contextRef} offset={110}>
                                    <div className='section card borderRadius10 bgWhite shadow20 padding15 marginBottom20 pastOrder'>
                                        {(() => {
                                            if (order !== '') {
                                                return (
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <Grid>
                                                                <Grid.Row columns={2}>
                                                                    <Grid.Column>
                                                                        <Header className='large'>
                                                                            Order Details: #{order.order_no}
                                                                        </Header>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                            <Grid>
                                                                <Grid.Row columns={2}>
                                                                    <Grid.Column>
                                                                        <p className='small marginBottom'>Items: <strong className='regular'>{order.total_quantity}</strong></p>
                                                                        <p className='small marginBottom'>Delivery Window: <strong className='regular'>{order.delivery_window}</strong></p>
                                                                        <p className='small marginBottom'>Tracking ID: <strong className='regular'><a href={order.tracking_url} target='_blank' rel="noopener noreferrer">{order.shipping_number}</a></strong></p>
                                                                        <p className='small'>Delivery Note: <strong className='regular'>{order.delivery_note}</strong></p>
                                                                    </Grid.Column>
                                                                    <Grid.Column>
                                                                        <p className='regular noMargin'><strong>{order.name}</strong></p>
                                                                        <p className='small noMargin'>{order.shipping_address}</p>
                                                                        <p className='small noMargin'>{order.suburb}</p>
                                                                        <p className='small noMargin'>{order.state}</p>
                                                                        <p className='small noMargin'>{order.postcode}</p>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                            <Grid className='orderItems' relaxed>
                                                                <ListCartItem data={order.products} />
                                                            </Grid>

                                                            <Grid className='bottom15 marginTop40'>
                                                                <Grid.Row>
                                                                    <Grid.Column width={4} textAlign='left' verticalAlign='bottom'>
                                                                        <p className='regular lightGray'>Total:</p>
                                                                    </Grid.Column>

                                                                    <Grid.Column width={12} textAlign='right'>
                                                                        <Header className='largeMedium'>
                                                                            <Header.Subheader className={'red small noMargin' + (order.rebate === 0 ? ' hidden' : '')}>
                                                                                Partnership Fund {formatCurrency(order.total_rebate)}
                                                                            </Header.Subheader>
                                                                            {formatCurrency(order.total_price)}
                                                                            <p className='small lightGray noMargin normal'>Inc {formatCurrency(calculateGST(order.total_price))} GST</p>
                                                                        </Header>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                )
                                            }
                                        })()}
                                    </div>
                                    <PDFLink order={this.state.selected}/>
                                </Sticky>
                            </Grid.Column>
                        </Grid.Row> 
                    </Grid>
                </div>
            </Grid>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PastOrders);