import React, { Component } from "react";
import { Image, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';


class LandingPageFooter extends Component {
    render() {
        return (
            <>
                <div className="container mx-auto flex px-4 md:px-0 mb-48 flex-col md:flex-row">
                    <div className="flex-1">
                        <h2 className="text-2xl font-bold">Got a question for us?</h2>
                        <hr className="w-24 h-1.5 bg-black my-8" />
                        <a href='mailto:admin@clubconnect.net.au' className='inline-block regular bgYellow white noDecoration contact-button hoverDarkenYellowBg py-6 px-12'>EMAIL <Icon name='arrow right' /></a>
                    </div>
                    <div className="flex-1 mt-8 md:mt-0">
                        <h2 className="text-2xl font-bold">Documents</h2>
                        <hr className="w-24 h-1.5 bg-black my-8" />

                        <div className="grid md:grid-cols-2 gap-4 text-left">
                            <div className="bg-grey hover:bg-gray-200 text-lg py-4 px-8">
                                <Link to='/terms-conditions' className="flex text-black hover:text-black items-center">
                                    <Image src='/img/file.png' className="w-16 p-2 mr-4" />
                                    Terms and Conditions
                                </Link>

                            </div>
                            <div className="bg-grey hover:bg-gray-200 text-lg py-4 px-8">
                                <Link to='/privacy-policy' className="flex text-black hover:text-black items-center">
                                    <Image src='/img/file.png' className="w-16 p-2 mr-4" />
                                    Privacy Policy
                                </Link>

                            </div>
                            <div className="bg-grey hover:bg-gray-200 text-lg py-4 px-8">
                                <Link to='/marketing-consent' className="flex text-black hover:text-black items-center">
                                    <Image src='/img/file.png' className="w-16 p-2 mr-4" />
                                    Marketing Policy
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default LandingPageFooter;
