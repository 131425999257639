import React, { Component } from "react";
import { Grid, Header } from 'semantic-ui-react';
import { connect } from "react-redux";
import { pageLogin, pageNotLogin } from '../action/view';
import BackButton from "../parts/BackButton";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageLogin,
    pageNotLogin
};

class TheStory extends Component {
    constructor(props) {
        super(props);

        this.props.pageNotLogin();
    }

    render() {

        return (
            <Grid padded className='loginBg'>
                <Grid.Row>
                    <Grid.Column width={16} className='marginTop30 alignCenter'>
                        <Header as='h2'>
                            Our Story
                        </Header>

                        <div className='maxWidth70p overflowYAuto center padding15 alignLeft'>
                            <p>Community sporting clubs are at the heart of Australian society, from the players to the spectators to the volunteers – sports clubs bring people together.</p>
                            
                            <p>Seeking to support our community sporting clubs further, Carlton and United Breweries (now under Asahi Beverages) has developed Club Connect.</p>
                            
                            <p>Club Connect is a platform where sporting clubs can purchase all their beverage requirements in a single location, at a great price, have it delivered directly to their club at a time that is convenient for you, and most importantly earn cash back Partnership Fund for the club on every purchase which acts as a key fundraising tool for the club.</p>

                            <p>Through Club Connect, Asahi Beverages aims to strengthen their commitment to local communities to ensure every club can realise their potential.</p>

                        </div>
                        <div className='login maxWidth70p center marginTop20'>
                            <BackButton />
                        </div>

                    </Grid.Column>

                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TheStory);