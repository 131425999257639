import React, { Component } from "react";
import ProductList from '../parts/ProductList';
import {getProductList} from '../utils/ProductAPI';
import { connect } from "react-redux";
import { pageNotLogin } from '../action/view';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageNotLogin,
};

const limit = 12;

class BrandList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'products': [],
            'isLoading': true,
            'sortOrder': 'asc',
            'currentFilter': {
                price: [],
                brands: [],
                cates: [],
                best:[]
            }
        }
    }

    componentDidMount() {
        this.handleSortList('popular');
        this.props.pageNotLogin();
    }

    handleFilterlist(filter) {
        const brandSlug = filter.brands = [this.props.match.params.slug];
        const cateSlug = filter.cates;
        const price = filter.price;
        const best = filter.best.length ? 'true' : '';

        this.setState({
            'isLoading': true
        })

        // get current product number
        const currentProductList = this.state.products.products;
        let currentProductNumber = currentProductList.length;
        
        if (currentProductNumber < limit) {
            currentProductNumber = limit
        }

        getProductList((result) => this.setState({
            'products': result,
            'isLoading': false,
            'currentFilter': filter
        }), { 'cateSlug': cateSlug, 'brandSlug': brandSlug, 'price': price, 'sortOrder': this.state.sortOrder, 'best': best }, currentProductNumber)
    }

    handleSortList(sort) {
        const brandSlug = this.props.match.params.slug;
        const currentFilter = { ...this.state.currentFilter };
        currentFilter.brands = [brandSlug];
        
        this.setState({
            'isLoading': true
        })

        const cateSlug = this.state.currentFilter.cates;
        const price = this.state.currentFilter.price;

        // get current product number
        const currentProductList = this.state.products.products ? this.state.products.products : [];
        const currentProductNumber = currentProductList.length === 0 ? limit : currentProductList.length;

        getProductList((result) => this.setState({
            'products': result,
            'isLoading': false,
            'sortOrder': sort,
            'currentFilter': currentFilter
        }), { 'cateSlug': cateSlug, 'brandSlug': [brandSlug], 'price': price, 'sortOrder': sort, 'best': this.state.best }, currentProductNumber)
    }

    handleNextPage(sort) {
        const brandSlug = this.props.match.params.slug;

        this.setState({
            'isLoading': true
        })

        const cateSlug = this.state.currentFilter.cates;
        const price = this.state.currentFilter.price;

        // get current product number
        const currentProductList = this.state.products.products;
        const currentProductNumber = currentProductList.length;

        getProductList((result) => this.setState({
            'products': result,
            'isLoading': false,
            'sortOrder': sort
        }), { 'cateSlug': cateSlug, 'brandSlug': [brandSlug], 'price': price, 'sortOrder': sort, 'best': this.state.best }, currentProductNumber + limit)
    }

    render() {

        return (
            <ProductList title={this.props.match.params.slug} data={this.state.products} isAnimate={false} isLoading={this.state.isLoading} nextPage={(sort) => this.handleNextPage(sort)} sortList={(sort) => this.handleSortList(sort)} filterList={(filter) => this.handleFilterlist(filter)} currentFilter={this.state.currentFilter}/>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BrandList);
