import React, { Component } from "react";
import { Grid, Header } from 'semantic-ui-react';
import Product from '../parts/Product';
import ProductDetail from '../parts/ProductDetail';
import { getProductDetail } from '../utils/ProductAPI';
import { connect } from "react-redux";
import { pageNotLogin } from '../action/view';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageNotLogin,
};

class ProductListing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            'product': []
        }
    }

    componentDidMount() {
        const slug = this.props.match.params.slug;
        getProductDetail((result) => {
            this.setState({
                'product': result
            })
        }, slug)

        this.props.pageNotLogin();
    }

    renderRelatedProduct() {
        if (this.state.product.related_product) {
            return (
                <Grid.Row stretched>
                    <Header size='medium' className='noMarginBottom'>You might also like</Header>
                    {
                        this.state.product.related_product.map((value, key) => {
                            return (
                                <Grid.Column className='relative' mobile={8} tablet={4} computer={3} key={key}>
                                    <div className='section card bgWhite shadow20 padding15'>
                                        <Product product={value} />
                                    </div>
                                </Grid.Column>
                            )
                        })
                    }
                </Grid.Row>
            )
        } else {
            return null;
        }
    }

    render() {
        const product = this.state.product;
        return (
            <Grid container className='marginTop10'>
                <Grid.Row>
                    <Grid.Column className='relative'>
                        {
                            product.map((value, key) => {
                                return (
                                    <ProductDetail product={value} key={key} />
                                )
                            })
                        }
                    </Grid.Column>
                </Grid.Row>
                {this.renderRelatedProduct()}
            </Grid>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductListing);
