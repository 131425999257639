import React, { useState } from 'react';
import { Header } from 'semantic-ui-react';


function LongPopup(props) {
  const [showPopup1, setShowPopup1] = useState(true);

  const handleClosePopup1 = () => {
    localStorage.setItem(`message-${props.id}`, Date.now());
    setShowPopup1(false);
  };

  return (
    <>
      {showPopup1 && (
        <div className='absolute w-full h-full z-40 pointer-events-none'>
          <div
            className='relative top-32 w-9/12 p-4 shadow-lg pointer-events-auto'
            style={{
              backgroundColor: 'white',
              left: '50%',
              transform: 'translate(-50%)',
              border: '1px solid lightgray',
              borderRadius: '5px',
              zIndex: '40',
            }}
          >
            <Header size='huge' className='m-0'  >{props.heading}</Header>
            <hr className="m-1"/>
            <p>{props.body}</p>
            <button className='bg-primary-blue absolute right-4 bottom-4' style={{ color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer',display:'flex' }} onClick={handleClosePopup1}>Close</button>
          </div>
        </div>
      )}
    </>
  );
}

export default LongPopup;
