import React, { Component } from "react";
import { Grid, Header } from 'semantic-ui-react';
import OrderSummary from '../parts/OrderSummary';
import ProductSlider from '../parts/ProductSlider';
import CategorySlider from '../parts/CategorySlider';
//import BrandSlider from '../parts/BrandSlider';
import '../assets/slick.min.css';
import { connect } from "react-redux";
import { pageNotLogin } from '../action/view';
import { getRecentProductList, getBestProductList, getBrandList, getCategoryList, getPromoImage } from '../utils/ProductAPI';
import ProductFeatures from "../parts/ProductFeatures";
//import _ from 'lodash';
import { getUserDetail } from "../utils/UserAPI";
import { PopUpController } from "./popups/PopUpController";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageNotLogin
};

class Home extends Component {
    constructor(props) {
        super(props);
        this.props.pageNotLogin();
        this.state = {
            'recentView': [],
            'bestSeller': [],
            'brands': [],
            'categories': [],
            'isLoadingRecentView': true,
            'isLoadingbestSellerView': true,
            'isLoadingBrandsView': true,
            'isLoadingCateView': true,
            'promo': {
                'image_link': '',
                'mobile_image_link': '',
                'description': ''
            },
            'user': ''
        }
    }

    componentDidMount() {
        getRecentProductList((result) => this.setState({
            'recentView': result.products,
            'isLoadingRecentView': false
        }))

        getBestProductList((result) => this.setState({
            'bestSeller': result.products,
            'isLoadingbestSellerView': false
        }))

        getBrandList((result) => this.setState({
            'brands': result,
            'isLoadingBrandsView': false
        }))

        getCategoryList((result) => this.setState({
            'categories': result,
            'isLoadingCateView': false
        }))

        getPromoImage((result) => this.setState({
            'promo': result
        }))

        getUserDetail((result) => {
            this.setState({
                'user': result.user
            })
        })
    }

    render() {
        let imageBg = this.state.promo.image_link;
        let imageMobileBg = this.state.promo.mobile_image_link;

        const club = this.state.user.club;
        if (club) {
            if (parseInt(club.licence_verify) === 1) {
            } else {
                imageBg = 'nonalcbg.jpg';
                imageMobileBg = 'nonalcbg.jpg';
            }
        }

        const desktopStyle = {
            backgroundImage: 'url(' + process.env.REACT_APP_HOST_URL + '/storage/images/' + imageBg + ')',
            height: '400px',
            backgroundSize: 'cover',
            position: 'relative',
            backgroundPosition: 'center',
            backgroundColor: '#000'
        };

        const mobileStyle = {
            backgroundImage: 'url(' + process.env.REACT_APP_HOST_URL + '/storage/images/' + imageMobileBg + ')',
            height: '400px',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundColor: '#000'
        };

        return (
            <div>
                <PopUpController/>
                {(() => {
                    if (this.state.promo.image_link === '' || this.state.promo.mobile_image_link === '') {
                        return null;
                    } else {
                        return (<Grid padded><Grid.Row className='noPaddingTop'>
                            <Grid.Column width={16} style={desktopStyle} only='computer tablet' className='alignEnd flex'>
                                <Grid container>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <div className='promoDescription desktop bottom200 zIndex1 paddingTopBottom40px' dangerouslySetInnerHTML={{ __html: this.state.promo.description }} />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                {/*<Grid className='noMargin noPadding' style={{ 'position': 'absolute', 'bottom': '-1px', 'left': '0', 'width': '100%' }}>
                                    <Grid.Row className='noMargin noPadding'>
                                        <Grid.Column width={16} only='computer tablet' className='noMargin noPadding'>
                                            <div className='bgGradientGrayReverse' style={{ 'height': '25em' }} />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>*/}
                            </Grid.Column>

                            <Grid.Column width={16} style={mobileStyle} only='mobile' className='alignEnd flex'>
                                <Grid container>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <div className='promoDescription mobile zIndex1 paddingTopBottom40px' dangerouslySetInnerHTML={{ __html: this.state.promo.description }} />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row></Grid>)
                    }
                })()}
                <Grid container columns='equal' className='marginTop10 relative marginBottom20'>
                    <Grid.Row style={{ paddingBottom: 0 }}>
                        <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={12} widescreen={12} className='marginBottom20'>
                            <Header size='huge' className='noMarginBottom centerMobile'>Recently Viewed</Header>
                            <div className='section card padding5'>
                                <ProductSlider data={this.state.recentView} isLoading={this.state.isLoadingRecentView} />
                            </div>
                        </Grid.Column>
                        <Grid.Column largeScreen={4} widescreen={4} only='large screen'>
                            <Header size='huge'>Recent Orders</Header>
                            <div className='section card bgWhite shadow20 padding15 minHeight349px'>
                                <OrderSummary />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    {
                        (club && parseInt(club.licence_verify)) === 1 &&
                        <Grid.Row stretched style={{ marginTop: '10px' }}>
                            <Grid.Column mobile={16} tablet={16} computer={16}>
                                <Header size='huge' className='noMarginBottom alignCenter'>Featured Products</Header>
                                <div className='section card padding5'>
                                    <ProductFeatures data={this.state.bestSeller} isLoading={this.state.isLoadingbestSellerView} />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    }

                    <Grid.Row stretched style={{ marginTop: '10px', marginBottom: '30px' }}>
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                            <CategorySlider data={this.state.categories} isLoading={this.state.isLoadingCateView} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);