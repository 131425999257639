import React, { Component } from "react";
import { Modal, Header, Button, Grid} from 'semantic-ui-react';
import ListCartItem from '../parts/ListCartItem';
import { formatCurrency, calculateGST } from '../utils/Utils';

export default class OrderModal extends Component {
    handleClose(e) {
        this.props.closeModal();
    }

    handleDelete(e) {
        this.props.isDelete();
    }

    render() {
        const order = this.props.order;

        if(order !== '') {
            return <Modal
                open={this.props.isOpen}
                onClose={this.handleClose.bind(this)}
                size='small'
                className='hide'
            >
                <Header icon='info circle' content='Order Detail' />
                <Modal.Content>
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <p className='small marginBottom'>Order No: <strong className='regular'>#{order.order_no}</strong></p>
                                <p className='small marginBottom'>Placed on: <strong className='regular'>{order.created_at}</strong></p>
                                <p className='small marginBottom'>Items: <strong className='regular'>{order.total_quantity}</strong></p>
                            </Grid.Column>
                            <Grid.Column>
                                <p className='regular noMargin'><strong>{order.name}</strong></p>
                                <p className='small noMargin'>{order.shipping_address}</p>
                                <p className='small noMargin'>{order.suburb}</p>
                                <p className='small noMargin'>{order.state}</p>
                                <p className='small noMargin'>{order.postcode}</p>
                            </Grid.Column>
                        </Grid.Row>

                        
                        <ListCartItem data={order.products} />
                    
                        <Grid.Row>
                            <Grid.Column width={4} textAlign='left' verticalAlign='bottom'>
                                <p className='regular lightGray'>Total:</p>
                            </Grid.Column>

                            <Grid.Column width={12} textAlign='right'>
                                <Header className='largeMedium'>
                                    <Header.Subheader className={'red small noMargin' + (order.rebate === 0 ? ' hidden' : '')}>
                                        Partnership Fund {formatCurrency(order.total_rebate)}
                                    </Header.Subheader>
                                    {formatCurrency(order.total_price)}
                                    <p className='small lightGray noMargin normal'>Inc {formatCurrency(calculateGST(order.total_price))} GST</p>
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                        
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleClose.bind(this)}>
                        Close
                </Button>
                </Modal.Actions>
            </Modal>;
        } else {
            return null;
        }
    }
}