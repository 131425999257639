import React, { Component } from "react";
import { Grid, Container } from "semantic-ui-react";
import CartRender from "../parts/CartRender";
import { connect } from "react-redux";
import { pageNotLogin } from "../action/view";

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  pageNotLogin,
};

class Cart extends Component {
  componentDidMount() {
    this.props.pageNotLogin();
  }

  render() {
    return (
      <Container>
        <Grid padded>
          <Grid.Row stretched style={{ paddingBottom: 0, paddingTop: "20px" }}>
            <Grid.Column
              mobile={16}
              tablet={12}
              computer={12}
              className="noPaddingLeft noPaddingRight"
            >
              <div className="section card borderRadius10 bgWhite shadow20 padding15 minHeight450">
                <CartRender />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
