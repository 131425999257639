import React, { Component } from "react";
import { Progress } from 'semantic-ui-react';

export default class OrderProgressBar extends Component {
    render() {
        const orderStatus = this.props.orderStatus;

        return (
            <Progress percent={orderStatus} size='tiny' className='labelTopMinus20'>
                <div className='floatLeft regular primaryColor'>
                    ORDERED
                </div>
                <div className={'floatRight regular ' + (orderStatus < 100 ? 'disabled lightGray' : 'primaryColor')}>
                    DELIVERIED
                </div>
                <div className={'regular ' + (orderStatus < 50 ? 'disabled lightGray' : 'primaryColor')}>
                    DISPATCHED
                </div>
            </Progress>
        );
    }
}