import React, { Component } from "react";
import { Modal, Icon, Header, Button } from 'semantic-ui-react';

export default class RebateModal extends Component {
    handleClose(e) {
        this.props.closeModal();
    }

    handleDelete(e) {
        this.props.isDelete();
    }

    render() {
        let text = '';
        let icon = '';
        let deleteText = 'Delete';

        switch (this.props.isType) {
            case 'users':
                text = 'user';
                icon = 'user delete';
                break;

            case 'clubs':
                text = 'club'
                icon = 'group';
                break;

            case 'rebates':
                text = 'Partnership Fund'
                icon = 'money bill alternate outline';
                deleteText = 'Redeem'
                break;

            default:
                break;
        }

        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose.bind(this)}
            size='small'
        >
            <Header icon='warning circle' content='Confirmation' />
            <Modal.Content>
                <h3>You are about to {deleteText} your club's {text}(s)</h3>
            </Modal.Content>
            <Modal.Actions>
                <Button loading={this.props.isLoading} disabled={this.props.isLoading} color='green' onClick={this.handleDelete.bind(this)} inverted>
                    <Icon name={icon} /> {deleteText}
                </Button>
                <Button color='red' onClick={this.handleClose.bind(this)} inverted>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>;
    }
}