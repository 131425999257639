import React from "react";
import { Grid, Icon, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import TagManager from "react-gtm-module";

export const RegisterNavigator = ({currentStep, stepCount, handleBack, handleNext, loading, }) => {
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column mobile={8} tablet={6} computer={5} textAlign='left' floated='left'>
                    {
                        (parseInt(currentStep) > 1) &&
                        <Button id={'goToStep' + (parseInt(currentStep) - 1)} tabIndex='7' loading={loading} disabled={loading} compact floated='left' className='regular marginTop10 white minHeight40 yellowText noBg' onClick={handleBack}>
                            <Button.Content>
                                <Icon name='arrow left' />
                                <span className='marginLeft10'>
                                    PREV
                                </span>
                            </Button.Content>
                        </Button>
                    }

                </Grid.Column>
                <Grid.Column mobile={8} tablet={6} computer={5} textAlign='right' floated='right'>
                    <Button id={currentStep === stepCount ? 'submitRegistration' : 'goToStep' + (parseInt(currentStep) + 1)} tabIndex='7' fluid loading={loading} disabled={loading} compact floated='left' className='regular marginTop10 bgYellow white minHeight40 hoverDarkenYellowBg' onClick={handleNext}>
                        <Button.Content>
                            <span className='marginRight10'>
                                {
                                    currentStep === stepCount ? 'DONE' : 'Next'
                                }
                            </span>
                            {
                                currentStep !== stepCount && <Icon name='arrow right' />
                            }

                        </Button.Content>
                    </Button>
                </Grid.Column>
                <Grid.Column mobile={16} only='mobile'>
                    <p className='fontSize16px marginTop10 noMarginBottom alignCenter'>Already a member? <Link className='marginLeft10 yellowText noDecoration' to='/login' onClick={() => TagManager.dataLayer({
                        dataLayer: {
                            'event': 'go_to_login_page'
                        }
                    })}>Sign In</Link></p>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}