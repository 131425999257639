import React, { Component } from "react";
import { Grid} from 'semantic-ui-react';
import { connect } from "react-redux";
import { pageNotLogin } from '../action/view';
import BlankClubRegister from "./BlankClubRegister";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageNotLogin,
};

class UserCreation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: '',
            club: '',
            bank: {},
            orders: [],
            currentPage: 1,
            total: 0,
            saveLoading: false,
            selected: 'user',
            userGone: false,
            clubGone: false,
            invoice: false,
            modalOpen: false,
            infoLoading: true
        }
    }

    componentDidMount() {
        this.props.pageNotLogin();
    }

    render() {
        return (
            <Grid container className='marginTop10'>
                <Grid.Row verticalAlign='top'>
                    <Grid.Column width={16} className='minHeight50vh overflowHidden'>
                        <BlankClubRegister/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserCreation);
