import React, { Component } from "react";
import { Icon } from 'semantic-ui-react';
import { setFavouriteProduct } from '../utils/ProductAPI';

export default class FavouriteButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            'favourite': this.props.product.favourite
        };
    }

    handleFavourite(e) {
        e.preventDefault();
        e.stopPropagation();

        const sku = e.currentTarget.getAttribute('data-id');
        const favourite = this.state.favourite ? parseInt(this.state.favourite) : 0;
        setFavouriteProduct(() => {
            
            if(this.props.setFavourite) {
                this.props.setFavourite(sku);
            }

            if(favourite === 1) {
                this.setState({
                    'favourite': 0
                });
            } else {
                this.setState({
                    'favourite': 1
                });
            }
        },  sku)

    }
    

    render() {
        const product = this.props.product;
        
        return (
            <div className='absolute link right top zIndex1 circle padding5 border1' id={'favourite-' + product.product_sku} data-id={product.product_sku} onClick={this.handleFavourite.bind(this)}>
                <Icon name='heart' className={'noMargin ' + (this.state.favourite && (this.state.favourite === true || parseInt(this.state.favourite) === 1) ? 'red pulsate-fwd' : 'gray pulsate-bck')} />
            </div>
        )
    }
}