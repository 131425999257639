import React, { Component } from "react";
import { Grid, Header, Image, Icon } from 'semantic-ui-react';
import FavouriteButton from '../parts/FavouriteButton';
import AddToCartNumber from '../parts/AddToCartNumber';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../utils/Utils';
import TagManager from "react-gtm-module";

export default class ProductDetail extends Component {
    componentDidMount() {
        if (this.props.product) {
            TagManager.dataLayer({
                dataLayer: {
                    'ecommerce': null
                }
            });

            TagManager.dataLayer({
                dataLayer: {
                    'event': 'view_item',
                    'ecommerce': {
                        'items': [
                            {
                                'item_name': this.props.product.name,
                                'item_id': this.props.product.id,
                                'price': this.props.product.price,
                                'item_sku': this.props.product.product_sku,
                                'item_brand': this.props.product.brand_name,
                                'item_category': this.props.product.category_name
                            }
                        ]
                    }
                }
            })
        }
    }

    render() {
        const product = this.props.product;
        return (
            <div className='section card borderRadius10 bgWhite shadow20 padding25 marginBottom20'>
                <br /><br />
                <div className='absolute left35 top20'>
                    <Link to={'/category/' + product.category} style={{ color: '#454545' }}>
                        <Icon name='chevron left' className='big' />
                    </Link>
                </div>
                <div className='absolute top15 right35 large'>
                    <FavouriteButton product={product} />
                </div>
                <Grid>
                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={4} computer={6} className='marginBottom10'>
                            <Image src={product.image_link} className='maxHeight450' centered />
                        </Grid.Column>

                        <Grid.Column mobile={16} tablet={12} computer={10}>
                            <Header className='big primaryColor relative'>
                                {product.name}
                                <Header.Subheader className='noMargin large lightGray'>
                                    {product.quantity_type}
                                </Header.Subheader>
                            </Header>

                            <Grid>
                                <Grid.Row>
                                    <Grid.Column className='relative' mobile={8} tablet={5} computer={4} verticalAlign='middle'>
                                        <div className='inline alignCenter'>
                                            <AddToCartNumber product={product} description={true} size='regular' />
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column className='relative' mobile={8} tablet={4} computer={4} verticalAlign='middle' textAlign='right'>
                                        <div>
                                            <Header style={{ fontSize: '25px' }}>
                                                {formatCurrency(product.price)}
                                                <Header.Subheader className='noMargin cub-yellow bold'>
                                                    + {formatCurrency(product.rebate)} Credit
                                                </Header.Subheader>
                                            </Header>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Header className='large'>
                                Description
                            </Header>
                            <div>
                                <p className='regular'>{product.description}</p>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}