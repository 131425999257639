import React, { Component } from "react";
import { Grid, Placeholder, Header } from 'semantic-ui-react';
import { getCategoryList } from '../utils/ProductAPI';
import { connect } from "react-redux";
import { pageNotLogin } from '../action/view';
import { Link } from 'react-router-dom';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageNotLogin,
};

class Categories extends Component {
    constructor(props) {
        super(props);

        this.state = {
            'categories': [],
            'isLoading': true
        }
    }

    componentDidMount() {
        getCategoryList((result) => this.setState({
            'categories': result,
            'isLoading': false
        }))

        this.props.pageNotLogin();
    }

    loadingCategorySlide(categories, isLoading) {
        if (isLoading === true) {
            const arrayLoop = 30;
            let placeholderList = [];
            let count = 0;

            while (count < arrayLoop) {
                count++;
                placeholderList.push(
                    <Grid.Column key={count} className='marginBottom20'>
                        <div className='bgBlackOpacity borderRadius10 relative'>
                            <Placeholder>
                                <Placeholder.Image />
                            </Placeholder>
                        </div>
                    </Grid.Column>
                )
            }

            return (
                <Grid>
                    <Grid.Row columns='6' only='computer'>
                        {placeholderList}
                    </Grid.Row>
                    <Grid.Row columns='4' only='tablet'>
                        {placeholderList}
                    </Grid.Row>
                    <Grid.Row columns='2' only='mobile'>
                        {placeholderList}
                    </Grid.Row>
                </Grid>
            );
        } else {
            if (categories.length > 0) {
                const cateRender = categories.map((value, key) => {
                    return (
                        <Grid.Column key={key} verticalAlign='middle' className='marginBottom20'>
                            <Link to={'/category/' + value.slug.toLowerCase()} className='link'>
                                <div className='borderRadius10 relative alignCenter' style={{ padding: '60px 5px', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: 'url(' + process.env.REACT_APP_HOST_URL + '/storage/images/' + (value.image_link) + ')' }}>
                                    <Header className='textCapitalize' inverted style={{ fontSize: '12px' }}>
                                        {value.name.toLowerCase()}
                                    </Header>
                                </div>
                            </Link>
                        </Grid.Column>
                    )
                });

                return (
                    <Grid>
                        <Grid.Row columns='6' only='computer'>
                            {
                                cateRender
                            }
                        </Grid.Row>
                        <Grid.Row columns='4' only='tablet'>
                            {
                                cateRender
                            }
                        </Grid.Row>
                        <Grid.Row columns='2' only='mobile'>
                            {
                                cateRender
                            }
                        </Grid.Row>
                    </Grid>
                )
            } else {
                return null
            }
        }
    }

    render() {
        let categories = this.state.categories;

        return (
            <Grid container columns='equal'>
                <Grid.Row stretched style={{ marginTop: '20px' }}>
                    <Grid.Column width={16} className='marginBottom20'>
                        <Header size='large'>
                            ALL TYPES
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {this.loadingCategorySlide(categories, this.state.isLoading)}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Categories);
