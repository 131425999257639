import React, { Component } from "react";
import { Grid, Header, Input, Dropdown, Checkbox, Image, Transition, Radio, Tab, Menu, Popup, Icon } from 'semantic-ui-react';
import { myAuth } from '../utils/PrivateRoute';
import { Redirect, Link } from 'react-router-dom';
import { connect } from "react-redux";
import { pageLogin, pageNotLogin } from '../action/view';
import { ValidateEmail, ValidateABN, ValidatePhoneNumber, ValidatePassword, ValidateLicenceNumber } from '../utils/Utils';
import { CSSTransition } from "react-transition-group";
import RegisterComplete from '../parts/RegisterComplete';
import Cleave from 'cleave.js/react';
import { registerUser, checkClubName, checkUserEmail, registerUserToClub, checkClubCode } from '../utils/RegisterAPI';
import { seasons, sportOptions, stateOptions, userClubRoles } from '../Constants';
import TagManager from "react-gtm-module";
import { RegisterNavigator } from "./RegisterNavigator";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageLogin,
    pageNotLogin
};

class ClubRegister extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user_name: '',
            club_name: '',
            club_user_role: '',
            sport_type: '',
            league_name: '',
            clubRelationship: '',
            email: '',
            password: '',
            confirm_password: '',
            contact_number: '',
            licence_number: '',
            licence_name: '',
            abn: '',
            shipping_address: '',
            state: '',
            postcode: '',
            suburb: '',
            hasRelationship: false,
            billing_diff: false,
            password_hidden: true,
            confirm_hidden: true,
            currentStep: 1,
            nextStep: '',
            success: false,
            loading: false,
            agreedTerm: true,
            haveLiquor: false,
            invite_code: '',
            join_club_code: props.clubCode ? props.clubCode : '',
            club_name_from_code: '',
            season: '',
            activeIndex: 0
        }

        this.props.pageLogin();

        // check if user has login already?
        if (myAuth.isAuthenticated) {
            this.props.pageNotLogin();
        }
        this.RenderSwitchExistingClub = this.RenderSwitchExistingClub.bind(this);
        this.RenderSwitch = this.RenderSwitch.bind(this);
    }

    componentDidMount() {
        TagManager.dataLayer({
            dataLayer: {
                'event': 'newClubRegister_start'
            }
        })
    }

    handleChangeLiquorRadio() {
        this.setState({
            haveLiquor: !this.state.haveLiquor
        })
    }

    handleChangeTerm() {
        this.setState({
            agreedTerm: !this.state.agreedTerm
        })
    }

    handleChangeRelationship() {
        this.setState({
            hasRelationship: !this.state.hasRelationship
        })
    }

    checkAgreed(e) {
        e.stopPropagation();

        if (!this.state.agreedTerm) {
            e.preventDefault();
            alert('Please agree to the terms and conditions.');
            return null;
        }
    }

    onHandleNumber(e) {
        let name = e.currentTarget.getAttribute('name');

        this.setState({
            [name]: e.currentTarget.value
        })
    }

    onHandleChange(e, { value }) {
        let name = '';
        if (e.key !== undefined) {
            name = 'state';
        } else {
            name = e.currentTarget.getAttribute('name');
            let type = e.currentTarget.getAttribute('type');

            if (name === null && type === null) {
                name = 'state';
            }
        }

        this.setState({
            [name]: value
        })
    }

    onHandleSportChange(e, { value }) {
        this.setState({
            sport_type: value
        })
    }

    onHandleUserRoleChange(e, { value }) {
        this.setState({
            club_user_role: value
        })
    }

    keyPressed(e) {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            if (this.state.activeIndex === 0 && this.props.clubCode == null) {
                this.handleNext();
            } else {
                this.handleNextExistingClub();
            }
        }
    }

    handleBack() {
        const step = this.state.currentStep;

        if (step - 1 === 0) {
            this.props.history.push('/register');
        } else {
            this.setState({
                currentStep: '',
                nextStep: step - 1
            })

            TagManager.dataLayer({
                dataLayer: {
                    'event': 'newClubRegister_goToStep' + (step - 1)
                }
            })
        }
    }

    handleNext() {
        const step = this.state.currentStep;

        switch (step) {
            case 1:
                if (this.state.user_name === '' || this.state.club_name === '' || this.state.sport_type === '' || this.state.league_name === '') {
                    alert('Please complete all required fields')
                } else {
                    this.setState({ loading: true });

                    checkClubName((result) => {
                        if (result === 'false' || result === false) {
                            this.setState({
                                currentStep: '',
                                nextStep: 2,
                                loading: false
                            })

                            TagManager.dataLayer({
                                dataLayer: {
                                    'event': 'newClubRegister_goToStep2'
                                }
                            })
                        } else {
                            this.setState({ loading: false });
                            alert('The Club name has already been registered.');
                        }
                    }, this.state.club_name)

                }

                break;
            case 2:
                if (this.state.email === '' || this.state.password === '' || this.state.confirm_password === '' || this.state.contact_number === '') {
                    alert('Please complete all required fields')
                } else {

                    if (!ValidateEmail(this.state.email)) {
                        break;
                    }

                    if (!ValidatePassword(this.state.password)) {
                        break;
                    }

                    if (!ValidatePhoneNumber(this.state.contact_number)) {
                        break;
                    }

                    if (this.state.password !== this.state.confirm_password) {
                        alert('Password and Confirm Password are not the same.')
                        break;
                    }

                    this.setState({ loading: true });
                    checkUserEmail((result) => {
                        if (result === false) {
                            this.setState({
                                currentStep: '',
                                nextStep: 3,
                                loading: false
                            })

                            TagManager.dataLayer({
                                dataLayer: {
                                    'event': 'newClubRegister_goToStep3'
                                }
                            })
                        } else {
                            this.setState({ loading: false });
                            alert('This email address has already been registered.');
                        }

                    }, this.state.email)

                }
                break;

            case 3:
                if ((this.state.haveLiquor === true && this.state.licence_name === '') || (this.state.haveLiquor === true && this.state.licence_number === '') || this.state.abn === '' || (this.state.hasRelationship === true && this.state.clubRelationship === '')) {
                    alert('Please complete all required fields.')
                } else {
                    if (ValidateABN(this.state.abn) && ValidateLicenceNumber(this.state.licence_number)) {
                        this.setState({
                            currentStep: '',
                            nextStep: 4
                        })

                        TagManager.dataLayer({
                            dataLayer: {
                                'event': 'newClubRegister_goToStep4'
                            }
                        })
                    }
                }
                break;

            case 4:
                if (this.state.shipping_address === '' || this.state.state === '' || this.state.postcode === '' || this.state.suburb === '') {
                    alert('Please complete all required fields')
                } else {
                    // check for address validation
                    //let currentAddress = this.state.shipping_address + ', ' + this.state.suburb + ', ' + this.state.state + ', ' + this.state.postcode;

                    this.setState({ loading: true });
                    let object = {
                        'email': this.state.email,
                        'password': this.state.password,
                        'c_password': this.state.confirm_password,
                        'name': this.state.club_name,
                        'phone': this.state.contact_number,
                        'role': 2, // this is the main club account
                        'sport_type': this.state.sport_type,
                        'league': this.state.league_name,
                        'license_number': this.state.licence_number,
                        'abn': this.state.abn,
                        'shipping_address': this.state.shipping_address,
                        'suburb': this.state.suburb,
                        'state': this.state.state,
                        'postcode': this.state.postcode,
                        'user_name': this.state.user_name,
                        'licence_name': this.state.licence_name,
                        'clubRelationship': this.state.clubRelationship,
                        'invite_code': this.state.invite_code,
                        'club_user_id': this.state.club_user_role,
                        'season': this.state.season
                    };

                    registerUser((result) => {
                        if (result) {
                            this.setState({
                                currentStep: '',
                                nextStep: 4,
                                success: true,
                                loading: false
                            })

                            TagManager.dataLayer({
                                dataLayer: {
                                    'event': 'newClubRegister_success'
                                }
                            })
                        } else {
                            this.setState({
                                loading: false
                            })

                            TagManager.dataLayer({
                                dataLayer: {
                                    'event': 'newClubRegister_failed'
                                }
                            })
                        }
                    }, object);

                    /*
                    getAddress((result) => {
                        let payload = result.message.payload;

                        if (payload.length > 0) {
                            this.setState({
                                currentStep: '',
                                nextStep: 5
                            })
                        } else {
                            alert('The address is not correct.');
                        }
                    }, currentAddress)*/


                }
                break;

            default:
                break;
        }
    }

    handleNextExistingClub() {
        const step = this.state.currentStep;

        switch (step) {
            case 1:
                if (this.state.user_name === '' || this.state.club_user_role === '' || this.state.join_club_code === '') {
                    alert('Please complete all required fields')
                } else {
                    this.setState({ loading: true });

                    checkClubCode((result) => {
                        if (result.result === 'true' || result.result === true) {
                            this.setState({
                                currentStep: '',
                                nextStep: 2,
                                loading: false,
                                club_name_from_code: result.name,
                            })

                            TagManager.dataLayer({
                                dataLayer: {
                                    'event': 'newClubRegister_goToStep2'
                                }
                            })
                        } else {
                            this.setState({ loading: false });
                            alert('There is no club with this code.');
                        }
                    }, this.state.join_club_code)

                    TagManager.dataLayer({
                        dataLayer: {
                            'event': 'newClubRegister_goToStep2'
                        }
                    })

                }

                break;
            case 2:
                if (this.state.email === '' || this.state.password === '' || this.state.confirm_password === '' || this.state.contact_number === '') {
                    alert('Please complete all required fields')
                } else {

                    if (!ValidateEmail(this.state.email)) {
                        break;
                    }

                    if (!ValidatePassword(this.state.password)) {
                        break;
                    }

                    if (this.state.password !== this.state.confirm_password) {
                        alert('Password and Confirm Password are not the same.')
                        break;
                    }

                    this.setState({ loading: true });
                    checkUserEmail((result) => {
                        if (result === false) {

                            TagManager.dataLayer({
                                dataLayer: {
                                    'event': 'newClubRegister_goToStep3'
                                }
                            })

                            this.setState({ loading: true });

                            let object = {
                                'email': this.state.email,
                                'password': this.state.password,
                                'c_password': this.state.confirm_password,
                                'user_name': this.state.user_name,
                                'club_user_id': this.state.club_user_role,
                                'club_code': this.state.join_club_code,
                                'contact_number': this.state.contact_number
                            };

                            registerUserToClub((result) => {
                                if (result) {
                                    this.setState({
                                        currentStep: '',
                                        nextStep: 3,
                                        success: true,
                                        loading: false
                                    })

                                    TagManager.dataLayer({
                                        dataLayer: {
                                            'event': 'newClubRegister_success'
                                        }
                                    })
                                } else {
                                    this.setState({
                                        loading: false,
                                        nextStep: 3,
                                    })

                                    TagManager.dataLayer({
                                        dataLayer: {
                                            'event': 'newClubRegister_failed'
                                        }
                                    })
                                }
                            }, object);

                        } else {
                            this.setState({ loading: false });
                            alert('This email address has already been registered.');
                        }

                    }, this.state.email)



                }
                break;

            default:
                break;
        }
    }

    handleOnPasswordToggle() {
        this.setState({
            password_hidden: !this.state.password_hidden
        })
    }

    handleOnConfirmPasswordToggle() {
        this.setState({
            confirm_hidden: !this.state.confirm_hidden
        })
    }

    handleChangeSelection() {
        this.setState({
            billing_diff: !this.state.billing_diff
        })
    }

    handleAddress(address) {
        let addr = address.fullAddress.split(', ');

        if (addr.length > 0) {
            this.setState({
                shipping_address: addr[0],
                state: address.state,
                postcode: address.postcode,
                suburb: address.locality
            })
        } else {
            alert('The address is not correct.');
        }
    }

    RenderSwitchExistingClub({ step }) {
        const step1 =
            <Grid>
                <Grid.Row stretched>

                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Your Name</label>
                        <Input tabIndex='1' value={this.state.user_name} name='user_name' className='landingInput' placeholder='Your Name' onKeyPress={this.keyPressed.bind(this)} onChange={this.onHandleChange.bind(this)} />
                    </Grid.Column>

                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Your Role</label>
                        <Dropdown tabIndex='2' name='your_role' className='landingInput' onChange={this.onHandleUserRoleChange.bind(this)}
                            fluid
                            search
                            selection
                            options={userClubRoles}
                            value={this.state.club_user_role}
                            placeholder='Your Role' />
                    </Grid.Column>
                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Club Code</label>
                        <Input tabIndex='4' value={this.state.join_club_code} disabled={this.props.clubCode != null} name='join_club_code' className='landingInput' placeholder='Your Club Code' onKeyPress={this.keyPressed.bind(this)} onChange={this.onHandleChange.bind(this)} />
                    </Grid.Column>

                    <Grid.Column width={16} textAlign='left'>
                        <Checkbox onChange={this.handleChangeTerm.bind(this)} checked={this.state.agreedTerm} label={<label>Create an account means you are okay with our <Link to='/terms-conditions' className='yellowText textDecoration'>Terms & Conditions</Link>, <Link to='/marketing-consent' className='yellowText textDecoration'>Marketing Policy</Link> and <Link to='/privacy-policy' className='yellowText textDecoration'>Privacy Policy</Link>.</label>} className='myCheckbox marginTop20' fitted />
                    </Grid.Column>
                </Grid.Row>
            </Grid>;

        const step2 =
            <Grid>
                <Grid.Row stretched>
                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label>Welcome to {this.state.club_name_from_code}! Continue your signup below.</label>
                    </Grid.Column>
                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Your Email</label>
                        <Input tabIndex='1' autoFocus value={this.state.email} name='email' className='landingInput' placeholder='Email' onKeyPress={this.keyPressed.bind(this)} onChange={this.onHandleChange.bind(this)} />
                    </Grid.Column>
                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="relative landingLabel">Contact Number <Popup style={{ height: 'min-content' }} content='Please enter your mobile number to recieve shipping SMS from our courier' trigger={<Icon className='relative block' name='question circle outline' />} /></label>
                        <Input tabIndex='1' autoFocus value={this.state.contact_number} name='contact_number' className='landingInput' placeholder='Contact Number' onKeyPress={this.keyPressed.bind(this)} onChange={this.onHandleChange.bind(this)} />
                    </Grid.Column>
                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Your Password</label>
                        <Input value={this.state.password} tabIndex='2' name='password' type={this.state.password_hidden ? 'password' : 'text'} onKeyPress={this.keyPressed.bind(this)} onChange={this.onHandleChange.bind(this)} placeholder='Password' className='landingInput' />
                        <i aria-hidden="true" onClick={this.handleOnPasswordToggle.bind(this)} className={this.state.password_hidden ? 'eye slash icon absolute right20 top45px link' : 'eye icon absolute right20 top45px link'} />
                    </Grid.Column>
                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Confirm Password</label>
                        <Input value={this.state.confirm_password} tabIndex='3' name='confirm_password' type={this.state.confirm_hidden ? 'password' : 'text'} onKeyPress={this.keyPressed.bind(this)} onChange={this.onHandleChange.bind(this)} className=' landingInput' placeholder='Password must match' />
                        <i aria-hidden="true" onClick={this.handleOnConfirmPasswordToggle.bind(this)} className={this.state.confirm_hidden ? 'eye slash icon absolute right20 top45px link' : 'eye icon absolute right20 top45px link'} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>;

        return (
            <div>
                <CSSTransition in={step === 1} unmountOnExit classNames="fade" timeout={150} onExited={() => this.setState({ currentStep: this.state.nextStep })}>
                    {step1}
                </CSSTransition>
                <CSSTransition in={step === 2} unmountOnExit classNames="fade" timeout={150} onExited={() => this.setState({ currentStep: this.state.nextStep })}>
                    {step2}
                </CSSTransition>
            </div>
        );
    }

    RenderSwitch({ step }) {
        const step1 =
            <Grid>
                <Grid.Row stretched>
                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Your Name</label>
                        <Input tabIndex='1' value={this.state.user_name} name='user_name' className='landingInput' placeholder='Your Name' onKeyPress={this.keyPressed.bind(this)} onChange={this.onHandleChange.bind(this)} />
                    </Grid.Column>

                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Your Role</label>
                        <Dropdown tabIndex='2' name='your_role' className='landingInput' onChange={this.onHandleUserRoleChange.bind(this)}
                            fluid
                            search
                            selection
                            options={userClubRoles}
                            value={this.state.club_user_role}
                            placeholder='Your Role' />
                    </Grid.Column>

                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Club Registered Business Name</label>
                        <Input tabIndex='3' value={this.state.club_name} name='club_name' className='landingInput' placeholder="Club Registered Business Name" onKeyPress={this.keyPressed.bind(this)} onChange={this.onHandleChange.bind(this)} />
                    </Grid.Column>

                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Club Type</label>
                        <Dropdown tabIndex='4' name='sport_type' className='landingInput' onChange={this.onHandleSportChange.bind(this)}
                            fluid
                            search
                            selection
                            options={sportOptions}
                            value={this.state.sport_type}
                            placeholder='Club Type' />
                    </Grid.Column>

                    <Grid.Column width={16} textAlign='left'>
                        <label className="landingLabel">League Name</label>
                        <Input tabIndex='5' value={this.state.league_name} name='league_name' className='landingInput' placeholder='League Name' onKeyPress={this.keyPressed.bind(this)} onChange={this.onHandleChange.bind(this)} />
                    </Grid.Column>

                    <Grid.Column width={16} textAlign='left'>
                        <Checkbox onChange={this.handleChangeTerm.bind(this)} checked={this.state.agreedTerm} label={<label>Create an account means you are okay with our <Link to='/terms-conditions' className='yellowText textDecoration'>Terms & Conditions</Link>, <Link to='/marketing-consent' className='yellowText textDecoration'>Marketing Policy</Link> and <Link to='/privacy-policy' className='yellowText textDecoration'>Privacy Policy</Link>.</label>} className='myCheckbox marginTop20' fitted />
                    </Grid.Column>
                </Grid.Row>
            </Grid>;

        const step2 =
            <Grid>
                <Grid.Row stretched>
                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Contact Number</label>
                        <Input name='contact_number' autoFocus className=' landingInput' type='number'>
                            <Cleave tabIndex='1' placeholder='Contact Number' name='contact_number' value={this.state.contact_number} options={{ phone: true, phoneRegionCode: 'AU' }}
                                onChange={this.onHandleNumber.bind(this)} onKeyPress={this.keyPressed.bind(this)} />
                        </Input>
                    </Grid.Column>
                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Your Email</label>
                        <Input tabIndex='1' autoFocus value={this.state.email} name='email' className='landingInput' placeholder='Email' onKeyPress={this.keyPressed.bind(this)} onChange={this.onHandleChange.bind(this)} />
                    </Grid.Column>
                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Your Password</label>
                        <Input value={this.state.password} tabIndex='2' name='password' type={this.state.password_hidden ? 'password' : 'text'} onKeyPress={this.keyPressed.bind(this)} onChange={this.onHandleChange.bind(this)} placeholder='Password' className='landingInput' />
                        <i aria-hidden="true" onClick={this.handleOnPasswordToggle.bind(this)} className={this.state.password_hidden ? 'eye slash icon absolute right20 top45px link' : 'eye icon absolute right20 top45px link'} />
                    </Grid.Column>
                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Confirm Password</label>
                        <Input value={this.state.confirm_password} tabIndex='3' name='confirm_password' type={this.state.confirm_hidden ? 'password' : 'text'} onKeyPress={this.keyPressed.bind(this)} onChange={this.onHandleChange.bind(this)} className=' landingInput' placeholder='Password must match' />
                        <i aria-hidden="true" onClick={this.handleOnConfirmPasswordToggle.bind(this)} className={this.state.confirm_hidden ? 'eye slash icon absolute right20 top45px link' : 'eye icon absolute right20 top45px link'} />
                    </Grid.Column>
                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Invite Code</label>
                        <Input tabIndex='4' value={this.state.invite_code} name='invite_code' className='landingInput' placeholder='Your Invite Code (if applicable)' onKeyPress={this.keyPressed.bind(this)} onChange={this.onHandleChange.bind(this)} />
                    </Grid.Column>
                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Club Season</label>
                        <Dropdown
                            tabIndex='2'
                            name='your_role'
                            className='landingInput'
                            onChange={(_, { value }) => { this.setState({ season: value }) }}
                            fluid
                            search
                            selection
                            options={seasons}
                            value={this.state.season}
                            placeholder='Season' />
                    </Grid.Column>
                </Grid.Row>
            </Grid>;

        const step3 =
            <Grid>
                <Grid.Row stretched>
                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel flexBetween flex">
                            ABN
                            <a className='yellowText fontWeightRegular' tabIndex='6' href='https://abr.business.gov.au/' target='_blank' rel="noopener noreferrer">ABN Lookup</a>
                        </label>

                        <Input tabIndex='4' maxLength='11' value={this.state.abn} name='abn' className=' landingInput' placeholder='ABN' type='text' onChange={this.onHandleChange.bind(this)} onKeyPress={this.keyPressed.bind(this)} />
                    </Grid.Column>
                    {
                        this.props.nonAlc === false &&
                        <>
                            <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                                <label className="landingLabel">A liquor licence will allow you to purchase beer, cider, wine and spirits from the Club Connect website?</label>
                                <div className='flex'>
                                    <Radio
                                        className='large'
                                        label='Yes'
                                        name='radioGroup'
                                        value='Yes'
                                        checked={this.state.haveLiquor === true}
                                        onChange={this.handleChangeLiquorRadio.bind(this)}
                                    />
                                    <Radio
                                        className='marginLeft10 large'
                                        label='No'
                                        name='radioGroup'
                                        value='No'
                                        checked={this.state.haveLiquor === false}
                                        onChange={this.handleChangeLiquorRadio.bind(this)}
                                    />
                                </div>
                            </Grid.Column>
                            <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                                <label className="landingLabel">Liquor Licence Number</label>
                                <Input tabIndex='2' disabled={!this.state.haveLiquor} value={this.state.licence_number} name='licence_number' className=' landingInput' placeholder='Liquor Licence Number' onChange={this.onHandleChange.bind(this)} onKeyPress={this.keyPressed.bind(this)} />
                            </Grid.Column>

                            <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                                <label className="landingLabel">Liquor Licence Licensee</label>
                                <Input tabIndex='3' disabled={!this.state.haveLiquor} value={this.state.licence_name} name='licence_name' className=' landingInput' placeholder='Liquor Licence Licensee' onChange={this.onHandleChange.bind(this)} onKeyPress={this.keyPressed.bind(this)} />
                            </Grid.Column>
                        </>
                    }

                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <Checkbox onChange={this.handleChangeRelationship.bind(this)} checked={this.state.hasRelationship} label='Do you currently have a sponsorship with a pub, liquor store or wholesaler' className='small myCheckbox' fitted />
                        <Transition visible={this.state.hasRelationship} animation='fade down' duration={300}>
                            <Input tabIndex='3' maxLength='11' value={this.state.clubRelationship} name='clubRelationship' className='marginTop10  landingInput width100' placeholder='Pub, Liquor Store or Wholesaler name' type='text' onChange={this.onHandleChange.bind(this)} onKeyPress={this.keyPressed.bind(this)} />
                        </Transition>
                    </Grid.Column>
                </Grid.Row>
            </Grid>;

        const step4 =
            <Grid>
                <Grid.Row stretched>
                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Shipping Address</label>
                        <Input autoFocus tabIndex='2' placeholder="Street address" name='shipping_address' value={this.state.shipping_address} className=' landingInput' onChange={this.onHandleChange.bind(this)} onKeyPress={this.keyPressed.bind(this)} />
                        {/* <Autocomplete updateAddressFreeText={(address) => this.setState({shipping_address : address})} ={true} placeholder="Shipping address" name='shipping_address' value={this.state.shipping_address} tabIndex='1' updateAddress={(address) => this.handleAddress(address)}/> */}
                    </Grid.Column>

                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Suburb</label>
                        <Input tabIndex='2' value={this.state.suburb} name='suburb' className=' landingInput' placeholder='Suburb' onChange={this.onHandleChange.bind(this)} onKeyPress={this.keyPressed.bind(this)} />
                    </Grid.Column>

                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">State</label>
                        <Dropdown tabIndex='3' name='state' className=' landingInput' onChange={this.onHandleChange.bind(this)}
                            fluid
                            search
                            selection
                            options={stateOptions}
                            value={this.state.state}
                            placeholder='State' />
                    </Grid.Column>

                    <Grid.Column width={16} className='marginBottom20' textAlign='left'>
                        <label className="landingLabel">Postcode</label>
                        <Input tabIndex='4' value={this.state.postcode} name='postcode' className=' landingInput' placeholder='Postcode' type='number' onChange={this.onHandleChange.bind(this)} onKeyPress={this.keyPressed.bind(this)} />
                    </Grid.Column>

                    {/*<Grid.Column width={16} textAlign='left'>
                        <Checkbox onChange={this.handleChangeSelection.bind(this)} checked={this.state.billing_diff} label='Billing Address is different from shipping' className='small myCheckbox' fitted />
                    </Grid.Column>*/}
                </Grid.Row>
            </Grid>;

        return (
            <div>
                <CSSTransition in={step === 1} unmountOnExit classNames="fade" timeout={150} onExited={() => this.setState({ currentStep: this.state.nextStep })}>
                    {step1}
                </CSSTransition>
                <CSSTransition in={step === 2} unmountOnExit classNames="fade" timeout={150} onExited={() => this.setState({ currentStep: this.state.nextStep })}>
                    {step2}
                </CSSTransition>
                <CSSTransition in={step === 3} unmountOnExit classNames="fade" timeout={150} onExited={() => this.setState({ currentStep: this.state.nextStep })}>
                    {step3}
                </CSSTransition>
                <CSSTransition in={step === 4} unmountOnExit classNames="fade" timeout={150} onExited={() => this.setState({ currentStep: this.state.nextStep })}>
                    {step4}
                </CSSTransition>
            </div>
        );
    }

    render() {
        if (myAuth.isAuthenticated) {
            return (
                <Redirect
                    to={{
                        pathname: '/'
                    }}
                />
            )
        }

        const navigator = (
            <RegisterNavigator currentStep={this.state.currentStep} stepCount={4} handleBack={this.handleBack.bind(this)} handleNext={this.handleNext.bind(this)} loading={this.state.loading} />
        );

        const navigatorExistingClub = (
            <RegisterNavigator currentStep={this.state.currentStep} stepCount={2} handleBack={this.handleBack.bind(this)} handleNext={this.handleNextExistingClub.bind(this)} loading={this.state.loading} />
        );

        const panes = [
            {
                menuItem: <Menu.Item key='new-club' disabled={this.props.clubCode != null}>New Club</Menu.Item>,
                render: () =>
                    <Tab.Pane className='no-border'>
                        <div className='minHeight466px'>
                            <this.RenderSwitch step={this.state.currentStep} />
                        </div>
                        {navigator}
                    </Tab.Pane>
            },
            {
                menuItem:
                    <Menu.Item key='existing-club' className="to-right flex flex-col">
                        <div className='text-cub-orange'>
                            Join Existing Club
                        </div>
                        <div className='text-xs font-normal'>
                            use a club reference code
                        </div>
                    </Menu.Item>,
                render: () =>
                    <Tab.Pane className='no-border'>
                        <div className='minHeight466px'>
                            <this.RenderSwitchExistingClub step={this.state.currentStep} />
                        </div>
                        {navigatorExistingClub}
                    </Tab.Pane>,
            },
        ]

        const steps = <Grid.Column style={{ textAlign: 'center' }} width={16} className='maxWidth525 center stepMobile'>
            <div className="alignLeft marginBottom40">
                <Header as='h1' color='black' icon className='noMarginBottom alignLeft mobileSmallText'>
                    Create a Free Account
                </Header>

                {/*<p className='largeMedium noTabletNoMobile'>Sign Up now and you will receive <span className='yellowText'>5 free cases</span> of beer for your club when you place your first order.</p>*/}
            </div>

            <Tab menu={{ tabular: true }} defaultActiveIndex={this.props.clubCode == null ? 0 : 1} panes={panes} onTabChange={(e, { activeIndex }) => { this.setState({ nextStep: '', currentStep: 1, activeIndex }) }} />
        </Grid.Column>;

        return (
            <Grid padded columns={1} className='mobileMaxHeight'>
                <Grid.Row only='mobile' className=' top10 right alignRight zIndex1 maxWidth525 mx-auto'>
                    <Grid.Column mobile={4} className=' top left'>
                        <Link to='/'><Image src='/img/ClubConnect_Landscape.svg' className='homeLogoWelcome w-28 h-28' /></Link>
                    </Grid.Column>
                    <Grid.Column mobile={12} floated='right'>
                        <div className='marginBottom20 inline large'>Any Questions?</div> <a href='mailto:admin@clubconnect.net.au' className='marginLeft5 yellowText borderYellow padding10-20 noDecoration hoverYellowBg inlineFlex'>Contact</a>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    {this.state.success ? <RegisterComplete /> : steps}
                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClubRegister);