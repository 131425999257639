import React, { Component } from "react";
import { Grid, Header, Button } from 'semantic-ui-react';
import ListCartItem from '../parts/ListCartItem';
import OrderProgressBar from '../parts/OrderProgressBar';
import { formatCurrency, calculateGST } from '../utils/Utils';
import Slider from 'react-slick';
import PastOrderDetail from "../parts/PastOrderDetail";
import { connect } from "react-redux";
import { pageNotLogin } from '../action/view';
import { getOrderList } from '../utils/OrderAPI';
import { Link } from 'react-router-dom';
import { PDFLink } from '../components/PDFInvoice';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageNotLogin,
};

class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            past_orders: []
        }
    }

    componentDidMount() {
        this.props.pageNotLogin();

        getOrderList((result) => {
            this.setState({
                orders: result
            })
        }, true, 6)

        getOrderList((result) => {
            this.setState({
                past_orders: result
            })
        }, false, 1)
    }

    render() {
        const settings = {
            dots: false,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            lazyLoad: 'progressive',
            pauseOnDotsHover: true,
            pauseOnFocus: true,
            className: 'order',
            swipeToSlide: true
        };

        let orders = this.state.orders;
        let pastOrders = this.state.past_orders;

        let currentOrderList = () => {
            if (orders.length === 0) {
                return (
                    <div className='orderDetail relative'>
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column textAlign='left'>
                                    <Header size='large' className='marginBottom'>There is no current order.</Header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                )
            } else {
                return (
                    <Slider {...settings}>
                        {
                            orders.length > 0 && orders.map((order, key) => {
                                return (
                                    <div className='orderDetail relative' key={key}>
                                        <Grid>
                                            <Grid.Row columns={2}>
                                                <Grid.Column textAlign='left'>
                                                    <Header size='tiny' className='gray marginBottom tiny'>ORDER SUMMARY</Header>
                                                </Grid.Column>
                                                <Grid.Column textAlign='right'>
                                                    <Header size='tiny' className='gray marginBottom tiny'>ORDER {key + 1}/{orders.length}</Header>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>

                                        <p className='small marginBottom'>Order No: <strong className='regular'>#{order.order_no}</strong></p>
                                        <p className='small marginBottom'>Placed on: <strong className='regular'>{order.created_at}</strong></p>
                                        <p className='small marginBottom'>Items: <strong className='regular'>{order.total_quantity}</strong></p>
                                        <p className='small marginBottom'>Delivery Window: <strong className='regular'>{order.delivery_window}</strong></p>
                                        <p className='small marginBottom'>Tracking ID: <strong className='regular'><a href={order.tracking_url} target='_blank' rel="noopener noreferrer">{order.shipping_number}</a></strong></p>
                                        <p className='small'>Delivery Note: <strong className='regular'>{order.delivery_note}</strong></p>
                                        <p className='small'>Partnership Fund Used: <strong className='regular'>{order.used_partner_fund ? order.used_partner_fund : 0}</strong></p>

                                        <a target='_blank' className="flex" rel="noopener noreferrer" href={order.tracking_url}><Button basic fluid className='borderPrimary borderRadius10 primaryColor small marginBottom20'><strong>TRACKING</strong></Button></a>
                                        <OrderProgressBar orderStatus={order.status} />

                                        <Grid className='orderItems' relaxed>
                                            <ListCartItem data={order.products} />
                                        </Grid>

                                        <Grid className='bottom15 marginTop40'>
                                            <Grid.Row>
                                                <Grid.Column width={4} textAlign='left' verticalAlign='bottom'>
                                                    <p className='regular lightGray'>Total:</p>
                                                </Grid.Column>

                                                <Grid.Column width={12} textAlign='right'>
                                                    <Header className='largeMedium'>
                                                        <Header.Subheader className={'bold cub-yellow small noMargin' + (order.rebate === 0 ? ' hidden' : '')}>
                                                            + {formatCurrency(order.total_rebate)} Credit
                                                        </Header.Subheader>
                                                        {formatCurrency(order.total_price)}
                                                        <p className='small lightGray noMargin normal'>Inc {formatCurrency(calculateGST(order.total_price))} GST</p>
                                                    </Header>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                        <br />

                                        <PDFLink order={order} />
                                    </div>
                                )
                            })
                        }
                    </Slider>
                )
            }
        }

        let pastOrderList = () => {
            if (pastOrders.length === 0) {
                return (
                    <Grid.Column mobile={16} tablet={6} computer={5} className='marginTop20'>
                        <Header className='large'>
                            Past Orders
                        </Header>
                        <Header size='large' className='marginBottom'>There is no past order.</Header>
                    </Grid.Column>
                )
            } else {
                return (
                    <Grid.Column mobile={16} tablet={6} computer={5} className='marginTop20'>
                        <Header className='large'>
                            Past Orders
                            {<Link to='/past-orders' className='link menu normal darkGray marginLeft10'>See all</Link>}
                        </Header>
                        {
                            pastOrders.map((order, key) => {
                                return (
                                    <PastOrderDetail data={order} key={key} />
                                )
                            })
                        }
                    </Grid.Column>
                )
            }
        }

        return (
            <Grid container>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={10} computer={11} className='marginTop20'>
                        <Header className='large'>Current Orders</Header>
                        <div className='section card borderRadius10 bgWhite shadow20 padding15'>
                            {currentOrderList()}
                        </div>
                    </Grid.Column>

                    {pastOrderList()}

                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Orders);