import React, { Component } from "react";
import { Grid, Header, Divider, Button, Dimmer, Loader } from 'semantic-ui-react';
import { formatCurrency } from '../utils/Utils';
import { connect } from "react-redux";
import { pageNotLogin } from '../action/view';
import { getOrderList } from '../utils/OrderAPI';
import { getUserDetail, requestRebate } from '../utils/UserAPI';
import RebateModal from '../parts/RebateModal';
import { Link } from 'react-router-dom';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageNotLogin,
};

class YourRebates extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            currentPage: 1,
            limit: 20,
            isLoading: false,
            total: 0,
            user: '',
            club: '',
            bank_account: '',
            loading: false,
            openModal: false,
            loadingData: true
        }
    }

    componentDidMount() {
        getOrderList((result, total) => {
            this.setState({
                orders: result,
                total: total,
                loadingData: false
            })
        }, null, this.state.limit * this.state.currentPage)

        getUserDetail((result) => {
            this.setState({
                'user': result.user,
                'club': result.club,
                'bank_account': result.bank_account
            })
        })

        this.props.pageNotLogin();
    }

    handleNextPage() {
        let currentPage = this.state.currentPage + 1;

        this.setState({
            isLoading: true
        })

        getOrderList((result, total) => {
            this.setState({
                orders: result,
                total: total,
                currentPage: currentPage,
                isLoading: false
            })
        }, null, this.state.limit * currentPage)
    }

    handleCloseModal() {
        this.setState({
            openModal: false,
            loading: false
        })
    }

    handleRequestSubmit() {
        this.setState({
            loading: true,
            openModal: false
        })

        requestRebate((result) => {
            if (result) {
                alert('You have successfully released your clubs Partnership Fund.');
            } else {
                alert(result);
            }

            this.setState({
                loading: false
            })
        })
    }

    handleRequestRebate() {
        let club = this.state.club;

        // you are not able to release your clubs Partnership Fund until July or December
        if (club.rebate <= 0) {
            alert('Your club does not have any Partnership Fund available.');
        } else {
            this.setState({
                openModal: true
            })
        }
    }

    render() {
        const orders = this.state.orders;
        const total = this.state.total;
        const isLoading = this.state.isLoading;
        const user = this.state.user;
        const club = this.state.club;
        const bank_account = this.state.bank_account ? this.state.bank_account : '';

        return (
            <Grid container className='marginTop10'>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Header as='h2' className='noMarginBottom'>Your Contributed Partnership Fund</Header>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row className='marginBottom20' verticalAlign='top'>
                    <Grid.Column computer={4} tablet={16} mobile={16}>
                        <div className='borderRadius10 bgWhite padding30 shadow20 marginBottom20'>
                            <Dimmer active={this.state.loadingData} inverted>
                                <Loader>Loading</Loader>
                            </Dimmer>
                            <Grid>
                                <Grid.Row className='noPaddingBottom'>
                                    <Grid.Column width={10}>
                                        <strong>Current Partnership Fund: </strong>
                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                        {formatCurrency(club.rebate)}
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className='noPaddingBottom'>
                                    <Grid.Column width={10}>
                                        <strong>Total Contributed Partnership Fund: </strong>
                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                        {formatCurrency(club.total_rebate)}
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={10}>
                                        <strong>Total Redeemed: </strong>
                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                        {formatCurrency(club.rebate_released)}
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        {(() => {
                                            if (parseInt(user.role) === 1 || parseInt(user.role) === 2 || parseInt(user.role) === 3) {
                                                if(bank_account !== '' && bank_account !== null && parseInt(bank_account.verified) === 1) {
                                                    return (
                                                        <div>
                                                            <Button disabled={this.state.loading} loading={this.state.loading} primary compact fluid className='regular borderRadius5 normal' onClick={() => { this.handleRequestRebate() }}>Request Partnership Fund</Button>
                                                            <p className='small marginTop10'>* You are able to access your partnership fund in March and September</p>
                                                        </div>
                                                    )
                                                }
                                            }
                                        })()}
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Link to='/rebate-history'><strong>Check your Partnership Fund History here</strong></Link>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            
                        </div>
                    </Grid.Column>
                    
                    <Grid.Column computer={12} tablet={16} mobile={16}>
                        <div className='borderRadius10 bgWhite padding30 shadow20 marginBottom20'>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={3} only='computer'>
                                        <strong>Date</strong>
                                    </Grid.Column>
                                    <Grid.Column computer={5} tablet={11} mobile={11}>
                                        <strong>Order No.</strong>
                                    </Grid.Column>
                                    <Grid.Column width={5} only='computer'>
                                        <strong>Club Name</strong>
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                        <strong>Contributed Partnership Fund</strong>
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        <Divider clearing />
                                    </Grid.Column>
                                </Grid.Row>
                                {
                                    orders.map((value, key) => {
                                        return (
                                            <Grid.Row key={key}>
                                                <Grid.Column computer={3} mobile={16} tablet={16}>
                                                    {value.created_at}
                                                </Grid.Column>
                                                <Grid.Column computer={5} tablet={11} mobile={11}>
                                                    {value.order_no}
                                                </Grid.Column>
                                                <Grid.Column width={5} only='computer'>
                                                    {value.club_register_name}
                                                </Grid.Column>
                                                <Grid.Column width={3}>
                                                    {formatCurrency(value.total_rebate)}
                                                </Grid.Column>
                                            </Grid.Row>
                                        )
                                    })
                                }
                                {(() => {
                                    if (orders) {
                                        if (total > orders.length) {
                                            return (
                                                <Grid.Row>
                                                    <Grid.Column textAlign='center'>
                                                        <Button loading={isLoading} disabled={isLoading} size='small' className='borderRadius5 bgPrimary white' onClick={this.handleNextPage.bind(this)}>Load More</Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )
                                        }
                                    }
                                })()}
                            </Grid>
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <RebateModal isType='rebates' isLoading={this.state.loading} isOpen={this.state.openModal} closeModal={this.handleCloseModal.bind(this)} isDelete={this.handleRequestSubmit.bind(this)} />
            </Grid>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(YourRebates);
