import React, { Component } from "react";
import {
  Grid,
  Header,
  Divider,
  Button,
  Input,
  Icon,
  Menu,
  Transition,
  Label,
  Dimmer,
  Dropdown,
  Loader,
  Radio,
  Modal,
  Checkbox
} from "semantic-ui-react";
import {
  getUserDetail,
  updateUserDetail,
  saveLicence,
  getUserInviteCode,
  sendInviteList,
  resendInvite,
  getClubUsers,
  updateClubUser,
  inviteClubUser,
} from "../utils/UserAPI";
import { getOrderList } from "../utils/OrderAPI";
import {
  ValidateEmail,
  ValidateLicenceNumber,
  formatCurrency,
} from "../utils/Utils";
import BankStatementModal from "../parts/BankStatementModal";
import { connect } from "react-redux";
import { pageNotLogin } from "../action/view";
import Cleave from "cleave.js/react";
import { PDFLink } from "../components/PDFInvoice";
import * as Constants from "../Constants";
import { updateClubDetails } from "../utils/ClubAPI";

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  pageNotLogin,
};

const limit = 20;

class YourAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: "",
      club: "",
      bank: {},
      orders: [],
      currentPage: 1,
      total: 0,
      saveLoading: false,
      selected: "user",
      userGone: false,
      clubGone: false,
      inviteGone: false,
      clubUsersGone: true,
      invoice: false,
      modalOpen: false,
      infoLoading: true,
      haveLiquor: false,
      licence_number: null,
      licence_name: null,
      currentPageInviteCode: 1,
      inviteCodes: [],
      totalInviteCodes: 0,
      inviteList: "",
      clubUsers: [],
      loadingClubUsers: false,
      inviteUserEmail: "",
      inviteUserEmailSuccess: false
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({
        selected: this.props.location.state.selected,
      });
    } else {
      const queryParameters = new URLSearchParams(window.location.search)
      if (queryParameters.has("selected")) {
        this.setState({
          selected: queryParameters.get("selected"),
        });
      }
    }

    getUserDetail((result) => {
      this.setState({
        user: result.user,
        club: result.club,
        bank: result.bank_account ? result.bank_account : {},
      });
    });

    getClubUsers((result) => {
      this.setState({
        clubUsers: result,
      });
    });

    getOrderList(
      (result) => {
        this.setState({
          orders: result,
          total: result.length,
          infoLoading: false,
        });
      },
      null,
      limit * this.state.currentPage
    );

    getUserInviteCode((result, total) => {
      this.setState({
        inviteCodes: result,
        totalInviteCodes: total,
      });
    }, limit * this.state.currentPageInviteCode);

    this.props.pageNotLogin();
  }

  // ------------------------- Invoices  ------------------------------------

  handleNextPage() {
    const nextPage = this.state.currentPage + 1;
    this.setState({
      saveLoading: true,
    });

    getOrderList(
      (result, total) => {
        this.setState({
          orders: result,
          currentPage: nextPage,
          saveLoading: false,
        });
      },
      null,
      limit * nextPage
    );
  }

  // ------------------------- Club Users Editing ------------------------------

  handleCheckUpdateChange(id, status) {

    this.setState({
      loadingClubUsers: true
    })

    updateClubUser(
      (res) => this.setState({
        clubUsers: res,
        loadingClubUsers: false,
      }),
      { user_id: id, status: status }
    )
  }

  handleInviteUser() {

    if (!ValidateEmail(this.state.inviteUserEmail)) {
      return;
    }

    if (window.confirm("Are you sure you want to invite " + this.state.inviteUserEmail)) {
      this.setState({
        saveLoading: true,
      });

      inviteClubUser(() => {
        this.setState({
          saveLoading: false,
          inviteUserEmailSuccess: true,
        });
        setTimeout(() => {
          this.setState({
            inviteUserEmailSuccess: false
          })
        }, 5000);
      }, { email: this.state.inviteUserEmail });
    }
  }

  // ------------------------- Your account Editing ----------------------------

  handleNameChange(e) {
    let user = { ...this.state.user };

    user.name = e.currentTarget.value;

    this.setState({
      user: user,
    });
  }

  handleEmailChange(e) {
    let user = { ...this.state.user };

    user.email = e.currentTarget.value;

    this.setState({
      user: user,
    });
  }

  handlePhoneChange(e) {
    let user = { ...this.state.user };

    user.phone = e.currentTarget.value;

    this.setState({
      user: user,
    });
  }

  handleRoleChange(e) {
    let user = { ...this.state.user };

    user.club_user_id = e.currentTarget.value;

    this.setState({
      user: user,
    });
  }

  handleUpdateChange(e) {
    e.stopPropagation();

    let user = this.state.user;

    if (ValidateEmail(user.email)) {
      // update user data
      this.setState({
        saveLoading: true,
      });

      updateUserDetail(
        () => {
          this.setState({
            saveLoading: false,
          });
        },
        {
          name: user.name,
          email: user.email,
          phone: user.phone,
          club_user_id: user.club_user_id,
        }
      );
    }
  }

  // ------------------------- Invite Club Editing -----------------------------

  handleInviteListChange(e) {
    this.setState({
      inviteList: e.currentTarget.value,
    });
  }

  handleSendInviteList(e) {
    e.stopPropagation();

    const inviteList = this.state.inviteList;

    this.setState({
      saveLoading: true,
    });

    sendInviteList(() => {
      getUserInviteCode((result, total) => {
        this.setState({
          inviteCodes: result,
          totalInviteCodes: total,
        });

        this.setState({
          saveLoading: false,
        });
      }, limit * this.state.currentPageInviteCode);

      this.setState({
        saveLoading: false,
        inviteList: "",
      });
    }, inviteList);
  }

  // ----------------------------- Licence Editing -----------------------------

  handleChangeLicenceNumber(e) {
    this.setState({
      licence_number: e.currentTarget.value,
    });
  }

  handleChangeLicenceName(e) {
    this.setState({
      licence_name: e.currentTarget.value,
    });
  }

  handleChangeLiquorRadio() {
    this.setState({
      haveLiquor: !this.state.haveLiquor,
    });
  }

  handleSaveLicence() {
    this.setState({
      saveLoading: true,
    });
    if (
      ValidateLicenceNumber(this.state.licence_number) &&
      this.state.licence_name !== ""
    ) {
      saveLicence(
        (result) => {
          this.setState({
            saveLoading: false,
            haveLiquor: true,
            user: result.user,
            club: result.club,
            bank: result.bank_account ? result.bank_account : {},
          });

          window.location.reload();
        },
        {
          licence_name: this.state.licence_name,
          licence_number: this.state.licence_number,
        }
      );
    } else {
      alert("Licence Name and Number should not be blank!");
      this.setState({
        saveLoading: true,
      });
    }
  }

  // ----------------------------- Bank Editing --------------------------------

  handleClose(e) {
    this.setState({
      modalOpen: false,
    });
  }

  handleUpdate(result) {
    this.setState({
      bank: result,
    });
  }

  handleResend(id) {
    this.setState({
      saveLoading: true,
    });
    resendInvite((result) => {
      this.setState({
        saveLoading: false,
      });
    }, id);
  }

  // ----------------------------- Club Editing --------------------------------
  handleUpdateClub() {

    // if (window.confirm("If you change your ABN or Liquor License details an Admin will be notified to check your details.")) {
    this.setState({
      saveLoading: true,
    });
    updateClubDetails(() => {
      this.setState({
        saveLoading: false,
      });
    }, this.state.club);
    // }
  }

  // ----------------------------- Render --------------------------------

  render() {
    const user = this.state.user;
    const club = this.state.club;
    const bank = this.state.bank;

    let clubUserRender = (
      <div
        className={
          "width100 regular bgWhite padding30 shadow20 marginBottom20 "
          // + (this.state.userGone ? "desktopAbsolute" : "")
        }
      >
        <Header className="largeMedium">Manage Club Users</Header>
        <Divider clearing />
        <Grid className="marginBottom10">
          <Grid.Row className="noPaddingBottom">
            <Grid.Column width={3}>
              <strong>Name</strong>
            </Grid.Column>
            <Grid.Column width={4}>
              <strong>Email</strong>
            </Grid.Column>
            <Grid.Column width={3}>
              {/* <strong>Club Manager</strong> */}
            </Grid.Column>
            <Grid.Column width={3}>
              <strong>Role</strong>
            </Grid.Column>
            <Grid.Column width={3}>
              <strong>Approved</strong>
            </Grid.Column>
          </Grid.Row>
          {
            this.state.clubUsers.length === 0 ?
              <Grid.Column width={12}>
                Only club managers can manage users
              </Grid.Column>
              :
              this.state.clubUsers.map((x) => {
                return (
                  <Grid.Row className="noPaddingBottom" key={x.name + x.email}>
                    <Grid.Column width={3}>
                      {x.name}
                    </Grid.Column>
                    <Grid.Column width={4} style={{ wordWrap: "break-word" }}>
                      {x.email}
                    </Grid.Column>
                    <Grid.Column width={3}>
                      {Number(x.is_club_manager) === 1 ? <Label>Club Manager</Label> : <></>}
                      {/* <Checkbox checked={x.is_club_manager} disabled /> */}
                    </Grid.Column>
                    <Grid.Column width={3}>
                      {x.club_user && x.club_user.name}
                    </Grid.Column>
                    {/* <Grid.Column width={3}>
                      <Checkbox checked={x.is_club_manager} disabled />
                    </Grid.Column> */}
                    <Grid.Column width={3}>
                      {
                        Number(x.is_club_manager) === 0 &&
                        <Checkbox
                          checked={Number(x.is_approved_by_manager) === 1}
                          disabled={this.state.loadingClubUsers}
                          onChange={(_, data) => this.handleCheckUpdateChange(x.id, data.checked)} />
                      }
                    </Grid.Column>
                  </Grid.Row>)
              })
          }
        </Grid>
        <Header className="largeMedium">Invite Club Users</Header>
        <Divider clearing />
        <Grid className="marginBottom10">
          {
            this.state.clubUsers.length === 0 ?
              <Grid.Column width={12}>
                Only club managers can invite users
              </Grid.Column>
              :

              <React.Fragment>
                <Grid.Row className="noPaddingBottom">
                  <Grid.Column width={12}>
                    <strong>Invite with code</strong>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="noPaddingBottom">
                  <Grid.Column width={8}>
                    Club Code
                  </Grid.Column>
                  <Grid.Column width={8}>
                    {this.state.club.referral_code}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="noPaddingBottom">
                  <Grid.Column width={12}>
                    <strong>Send Invite Email</strong>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="noPaddingBottom">
                  <Grid.Column width={8}>
                    <Input placeholder="Email" fluid>
                      <input
                        defaultValue={this.state.inviteUserEmail}
                        onChange={(e) => { this.setState({ inviteUserEmail: e.currentTarget.value }) }}
                      />
                    </Input>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Button
                      icon
                      labelPosition="left"
                      disabled={this.state.saveLoading}
                      loading={this.state.saveLoading}
                      onClick={this.handleInviteUser.bind(this)}
                      className="noMargin"
                    >
                      <Icon name="send" />
                      Invite User
                    </Button>
                  </Grid.Column>
                  <Grid.Column width={16}>
                    {this.state.inviteUserEmailSuccess && <p>Invite email has been sent to {this.state.inviteUserEmail}</p>}
                  </Grid.Column>
                </Grid.Row>
              </React.Fragment>
          }
        </Grid>
      </div>
    );

    let userRender = (
      <div
        className={
          "width100 regular bgWhite padding30 shadow20 marginBottom20 " +
          (this.state.userGone ? "desktopAbsolute" : "")
        }
      >
        <Header className="largeMedium">Your Account Details</Header>
        <Divider clearing />
        <Grid className="marginBottom10">
          <Grid.Row className="noPaddingBottom">
            <Grid.Column width={7}>
              <strong>Your Name:</strong>
            </Grid.Column>
            <Grid.Column width={9}>
              <Input placeholder="Name" fluid>
                <input
                  defaultValue={user.name}
                  onChange={this.handleNameChange.bind(this)}
                />
              </Input>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className="noPaddingBottom">
            <Grid.Column width={7}>
              <strong>Your Role:</strong>
            </Grid.Column>
            <Grid.Column width={9}>
              <Dropdown tabIndex='2' name='your_role' 
                  onChange={(_, { value }) => this.setState({
                    user: { ...this.state.user, club_user_id: value }
                  })}
                  fluid
                  search
                  selection
                  options={Constants.userClubRoles}
                  value={this.state.user.club_user_id}
                  placeholder='Your Role' />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className="noPaddingBottom">
            <Grid.Column width={7}>
              <strong>Email:</strong>
            </Grid.Column>
            <Grid.Column width={9}>
              <Input placeholder="Email" fluid>
                <input
                  defaultValue={user.email}
                  onChange={this.handleEmailChange.bind(this)}
                />
              </Input>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className="noPaddingBottom">
            <Grid.Column width={7}>
              <strong>Phone:</strong>
            </Grid.Column>
            <Grid.Column width={9}>
              <Input placeholder="Contact Number" fluid>
                {/*<input disabled={this.state.saveLoading} defaultValue={user.phone} onChange={this.handlePhoneChange.bind(this)} />*/}
                <Cleave
                  placeholder="Contact Number"
                  name="contact_number"
                  value={user.phone}
                  options={{ phone: true, phoneRegionCode: "AU" }}
                  onChange={this.handlePhoneChange.bind(this)}
                />
              </Input>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="right">
              <Button
                icon
                labelPosition="left"
                disabled={this.state.saveLoading}
                loading={this.state.saveLoading}
                onClick={this.handleUpdateChange.bind(this)}
                className="noMargin"
              >
                <Icon name="save" />
                Save
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );

    let inviteCodeRender = (
      <div
        className={
          "width100 regular bgWhite padding30 shadow20 marginBottom20 " +
          (this.state.userGone ? "desktopAbsolute" : "")
        }
      >
        <Header className="largeMedium">
          Invite other Clubs via Email
          <Header.Subheader className="noMargin regular">
            Your unique code will automatically be shared with your invites
          </Header.Subheader>
        </Header>
        <Divider clearing />
        <Grid className="marginBottom10">
          <Grid.Row className="noPaddingBottom marginBottom">
            <Grid.Column width={16}>
              <strong>Invite List:</strong>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Input
                placeholder="Enter one or more email(s) separated by a comma"
                fluid
              >
                <input onChange={this.handleInviteListChange.bind(this)} />
              </Input>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column textAlign="right">
              <Button
                disabled={this.state.saveLoading}
                loading={this.state.saveLoading}
                onClick={this.handleSendInviteList.bind(this)}
                className="regular marginTop10 bgYellow white minHeight40 hoverDarkenYellowBg"
              >
                SUBMIT
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Header className="largeMedium">Invite Sent</Header>
        <Divider clearing />
        <Grid className="marginBottom10">
          {this.state.inviteCodes.map((invite, key) => {
            return (
              <Grid.Row key={key} className="marginBottom10">
                <Grid.Column
                  mobile={16}
                  tablet={10}
                  computer={10}
                  className="marginBottom10"
                >
                  {invite.email}
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={6}
                  computer={6}
                  textAlign="right"
                >
                  {parseInt(invite.active) === 0 ? (
                    <Button
                      className="noPadding noShadow"
                      basic
                      disabled={this.state.saveLoading}
                      loading={this.state.saveLoading}
                      onClick={() => this.handleResend(invite.id)}
                    >
                      <strong className="yellowText">Send Reminder</strong>
                    </Button>
                  ) : (
                    <strong className="green">Confirmed</strong>
                  )}
                </Grid.Column>
              </Grid.Row>
            );
          })}
        </Grid>
      </div>
    );

    let invoiceRender = (
      <div
        className={
          "width100 regular bgWhite padding30 shadow20 marginBottom20 " +
          (this.state.invoiceGone ? "desktopAbsolute" : "")
        }
      >
        <Header className="largeMedium">Your Invoices</Header>
        <Divider clearing />
        <Grid className="marginBottom10">
          <Grid.Row className="noPaddingBottom">
            <Grid.Column mobile={8} tablet={8} computer={4}>
              <strong>Order Number</strong>
            </Grid.Column>
            <Grid.Column only="tablet computer" tablet={8} computer={4}>
              <strong>Order Date</strong>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={8} computer={4}>
              <strong>Amount</strong>
            </Grid.Column>
            <Grid.Column mobile={1} tablet={8} computer={4} />
          </Grid.Row>
          {this.state.orders.map((order, key) => {
            return (
              <Grid.Row className="noPaddingBottom" key={key}>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <div>
                    {order.order_no}
                    {order.repayment &&
                      order.repayment === Constants.REPAYMENT_STATUS.needed ? (
                      <div className="rounded-full bg-red-500 white text-center">
                        Payment Required
                      </div>
                    ) : order.repayment === Constants.REPAYMENT_STATUS.paid ? (
                      <div className="rounded-full bg-green-500 white text-center">
                        Payment Received{" "}
                      </div>
                    ) : null}
                  </div>
                </Grid.Column>
                <Grid.Column only="tablet computer" tablet={8} computer={4}>
                  {order.created_at}
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={4}>
                  {formatCurrency(order.total_price)}
                </Grid.Column>
                <Grid.Column
                  mobile={8}
                  tablet={8}
                  computer={4}
                  textAlign="right"
                >
                  <PDFLink order={order} customStyle={true} />
                </Grid.Column>
              </Grid.Row>
            );
          })}
          {this.state.total <= limit * this.state.currentPage && (
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Button
                  disabled={this.state.saveLoading}
                  loading={this.state.saveLoading}
                  onClick={this.handleNextPage.bind(this)}
                  className="marginTop30 borderRadius10 white bgPrimary"
                >
                  Load More
                </Button>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </div>
    );

    let bankRender = (
      <Grid className="marginBottom10 regular">
        <Grid.Row className="noPaddingBottom">
          <Grid.Column width={7}>
            <strong>Bank Name:</strong>
          </Grid.Column>
          <Grid.Column width={9}>
            {bank.bank_name ? bank.bank_name : "-"}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="noPaddingBottom">
          <Grid.Column width={7}>
            <strong>Account Name:</strong>
          </Grid.Column>
          <Grid.Column width={9}>
            {bank.account_name ? bank.account_name : "-"}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="noPaddingBottom">
          <Grid.Column width={7}>
            <strong>BSB:</strong>
          </Grid.Column>
          <Grid.Column width={9}>{bank.bsb ? bank.bsb : "-"}</Grid.Column>
        </Grid.Row>

        <Grid.Row className="noPaddingBottom">
          <Grid.Column width={7}>
            <strong>Account Number:</strong>
          </Grid.Column>
          <Grid.Column width={9}>
            {bank.account_num ? bank.account_num : "-"}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="noPaddingBottom">
          <Grid.Column width={16}>
            <p className="small">
              * Contact{" "}
              <a href="mailto:admin@clubconnect.net.au">
                admin@clubconnect.net.au
              </a>{" "}
              if you would like to amend your clubs account details
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );

    let clubRender = (
      <div
        className={
          "width100 regular bgWhite padding30 shadow20 marginBottom20 " +
          (this.state.clubGone ? "desktopAbsolute" : "")
        }
      >
        <Header className="largeMedium">Your Club Details</Header>
        <Divider clearing />
        <Grid className="marginBottom10">

          <Grid.Row className="noPaddingBottom">
            <Grid.Column width={7}>
              <strong>Club Registered Business Name:</strong>
            </Grid.Column>
            <Grid.Column width={9}>
              {club.name}
              {/* <Input placeholder="Club Name" fluid>
                <input
                  defaultValue={club.name}
                  onChange={(e) => this.setState({ 
                    club: { ...this.state.club, name: e.currentTarget.value} 
                  })}
                />
              </Input> */}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className="noPaddingBottom">
            <Grid.Column width={7}>
              <strong>Sport Type:</strong>
            </Grid.Column>
            <Grid.Column width={9}>
              {this.state.club &&
                <Input placeholder="Sport Type" fluid>
                  {/* <input
                    defaultValue={club.sport_type}
                    onChange={(e) => this.setState({ 
                      club: { ...this.state.club, sport_type: e.currentTarget.value} 
                    })}
                  /> */}
                  <Dropdown tabIndex='3' name='sport_type'
                    onChange={(_, { value }) => this.setState({
                      club: { ...this.state.club, sport_type: value }
                    })}
                    fluid
                    search
                    selection
                    options={Constants.sportOptions}
                    value={this.state.club.sport_type}
                    placeholder='Club Type' />
                </Input>
              }
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className="noPaddingBottom">
            <Grid.Column width={7}>
              <strong>Season:</strong>
            </Grid.Column>
            <Grid.Column width={9}>
              {this.state.club &&
                <Input placeholder="Season" fluid>
                  <Dropdown tabIndex='3' name='sport_type'
                    onChange={(_, { value }) => this.setState({
                      club: { ...this.state.club, season: value }
                    })}
                    fluid
                    search
                    selection
                    options={Constants.seasons}
                    value={this.state.club.season}
                    placeholder='Season' />
                </Input>
              }
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className="noPaddingBottom">
            <Grid.Column width={7}>
              <strong>League:</strong>
            </Grid.Column>
            <Grid.Column width={9}>
              {club &&
                <Input placeholder="League" fluid>
                  <input
                    defaultValue={club.league}
                    onChange={(e) => this.setState({
                      club: { ...this.state.club, league: e.currentTarget.value }
                    })}
                  />
                </Input>
              }
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className="noPaddingBottom">
            <Grid.Column width={7}>
              <strong>Contact Number:</strong>
            </Grid.Column>
            <Grid.Column width={9}>
              {club &&
                <Input placeholder="Number" fluid>
                  <input
                    defaultValue={club.phone}
                    onChange={(e) => this.setState({
                      club: { ...this.state.club, phone: e.currentTarget.value }
                    })}
                  />
                </Input>
              }
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className="noPaddingBottom">
            <Grid.Column width={7}>
              <strong>ABN:</strong>
            </Grid.Column>
            <Grid.Column width={9}>
              {club.abn}
              {/* <Input placeholder="ABN" fluid>
                <input
                  defaultValue={club.abn}
                  onChange={(e) => this.setState({ 
                    club: { ...this.state.club, abn: e.currentTarget.value} 
                  })}
                />
              </Input> */}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className="noPaddingBottom">
            <Grid.Column width={7}>
              <strong>Address:</strong>
            </Grid.Column>
            <Grid.Column width={9}>
              {club.shipping_address}
              {/* <Input placeholder="Address" fluid>
                <input
                  defaultValue={club.shipping_address}
                  onChange={(e) => this.setState({ 
                    club: { ...this.state.club, shipping_address: e.currentTarget.value} 
                  })}
                />
              </Input> */}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className="noPaddingBottom">
            <Grid.Column width={7}>
              <strong>Suburb:</strong>
            </Grid.Column>
            <Grid.Column width={9}>
              {club.suburb}
              {/* <Input placeholder="Suburb" fluid>
                <input
                  defaultValue={club.suburb}
                  onChange={(e) => this.setState({
                    club: { ...this.state.club, suburb: e.currentTarget.value }
                  })}
                />
              </Input> */}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className="noPaddingBottom">
            <Grid.Column width={7}>
              <strong>State:</strong>
            </Grid.Column>
            <Grid.Column width={9}>
              {club.state}
              {/* <Input placeholder="State" fluid>
                <input
                  defaultValue={club.state}
                  onChange={(e) => this.setState({
                    club: { ...this.state.club, state: e.currentTarget.value }
                  })}
                />
              </Input> */}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className="noPaddingBottom">
            <Grid.Column width={7}>
              <strong>Postcode:</strong>
            </Grid.Column>
            <Grid.Column width={9}>
              {club.postcode}
              {/* <Input placeholder="Post Code" fluid>
                <input
                  defaultValue={club.postcode}
                  onChange={(e) => this.setState({
                    club: { ...this.state.club, postcode: e.currentTarget.value }
                  })}
                />
              </Input> */}
            </Grid.Column>
          </Grid.Row>

          {(
            club.license_number === null ||
            club.licence_name === null) && (
              <Grid.Row className="noPaddingBottom">
                <Grid.Column width={7}>
                  <strong>
                    A liquor licence will allow you to purchase beer, cider, wine
                    and spirits from the Club Connect website?
                  </strong>
                </Grid.Column>
                <Grid.Column width={9}>
                  <div className="flex">
                    <Radio
                      className=""
                      label="Yes"
                      name="radioGroup"
                      value="Yes"
                      checked={this.state.haveLiquor}
                      onChange={this.handleChangeLiquorRadio.bind(this)}
                    />
                    <Radio
                      className="marginLeft10"
                      label="No"
                      name="radioGroup"
                      value="No"
                      checked={!this.state.haveLiquor}
                      onChange={this.handleChangeLiquorRadio.bind(this)}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            )}
          <Modal
            dimmer="blurring"
            size="tiny"
            open={this.state.haveLiquor}
            onClose={() => this.setState({ haveLiquor: false })}
          >
            <Modal.Header>Liquor Licence</Modal.Header>
            <Modal.Content>
              <div>
                <label className="landingLabel">Liquor Licence Number</label>
                <Input
                  tabIndex="2"
                  disabled={!this.state.haveLiquor}
                  value={this.state.licence_number}
                  name="licence_number"
                  fluid
                  className="landingInput marginTop10"
                  placeholder="Liquor Licence Number"
                  onChange={this.handleChangeLicenceNumber.bind(this)}
                />
              </div>
              <div className="marginTop20">
                <label className="landingLabel">Liquor Licence Licensee</label>

                <Input
                  tabIndex="3"
                  disabled={!this.state.haveLiquor}
                  value={this.state.licence_name}
                  name="licence_name"
                  fluid
                  className="marginTop10 landingInput"
                  placeholder="Liquor Licence Licensee"
                  onChange={this.handleChangeLicenceName.bind(this)}
                />
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => this.handleSaveLicence()}>DONE</Button>
            </Modal.Actions>
          </Modal>

          {club.license_number !== null &&
            club.licence_name !== null && (
              <>
                <Grid.Row className="noPaddingBottom">
                  <Grid.Column width={7}>
                    <strong>Liquor Licence Number:</strong>
                  </Grid.Column>
                  <Grid.Column width={9}>
                    {club.license_number}
                    {/* <Input placeholder="Liquor Licence Number" fluid>
                      <input
                        defaultValue={club.license_number}
                        onChange={(e) => this.setState({
                          club: { ...this.state.club, license_number: e.currentTarget.value }
                        })}
                      />
                    </Input> */}
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row className="noPaddingBottom">
                  <Grid.Column width={7}>
                    <strong>Liquor Licence Licensee:</strong>
                  </Grid.Column>
                  <Grid.Column width={9}>
                    {club.licence_name}

                    {/* <Input placeholder="Liquor Licence Licensee" fluid>
                      <input
                        defaultValue={club.licence_name}
                        onChange={(e) => this.setState({
                          club: { ...this.state.club, licence_name: e.currentTarget.value }
                        })}
                      />
                    </Input> */}
                  </Grid.Column>
                </Grid.Row>


                <Grid.Row className="noPaddingBottom">
                  <Grid.Column width={7}>
                    <strong>Referral Code:</strong>
                  </Grid.Column>
                  <Grid.Column width={9}>{club.referral_code}</Grid.Column>
                </Grid.Row>
              </>
            )}

          <Grid.Row>
            <Grid.Column textAlign="right">
              <Button
                icon
                labelPosition="left"
                disabled={this.state.saveLoading}
                loading={this.state.saveLoading}
                onClick={this.handleUpdateClub.bind(this)}
                className="noMargin"
              >
                <Icon name="save" />
                Save
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Header>
          Bank Details
          {Object.keys(bank).length > 0 &&
            (parseInt(bank.verified) === 1 || bank.verified === true) && (
              <Label color="green" basic className="verifiedCheck">
                <Icon name="check circle" />
                Verified
              </Label>
            )}
        </Header>
        <Divider clearing />
        {Object.keys(bank).length === 0 && (
          <Grid padded>
            <Grid.Row className="paddingBottom15rem  paddingTop15rem  borderYellow">
              <Grid.Column
                mobile={16}
                tablet={10}
                computer={12}
                className="marginBottom10"
              >
                <Header className="yellowText large">
                  Action Required
                  <Header.Subheader className="noMargin yellowText regular">
                    In order to recieve your partnership fund we need to verify
                    your club's bank details.
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={6} computer={4}>
                <Button
                  onClick={() => {
                    this.setState({ modalOpen: true });
                  }}
                  fluid
                  className="bgYellow white regular hoverDarkenYellowBg"
                >
                  Take Action
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        {Object.keys(bank).length > 0 &&
          (parseInt(bank.verified) === 0 ||
            bank.verified === false ||
            bank.verified === null) && (
            <Grid padded>
              <Grid.Row className="paddingBottom15rem  paddingTop15rem borderBlue borderRadius10">
                <Grid.Column
                  mobile={16}
                  tablet={10}
                  computer={12}
                  className="marginBottom10"
                >
                  <Header className="blueText large">
                    Thanks
                    <Header.Subheader className="noMargin blueText regular">
                      We have received your details and will verify soon.
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={6} computer={4}>
                  <Button
                    onClick={() => {
                      this.setState({ modalOpen: true });
                    }}
                    fluid
                    className="bgPrimary white borderRadius10 regular"
                  >
                    Resubmit
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        {bankRender}
      </div>
    );

    return (
      <Grid container className="marginTop10">
        <Grid.Row verticalAlign="top">
          <Grid.Column width={4} only="computer">
            <Menu fluid vertical className="accountVerticalMenu">
              <Menu.Item
                active={this.state.selected === "user"}
                onClick={() =>
                  this.setState({
                    selected: "user",
                    userGone: false,
                    clubGone: true,
                    clubUsersGone: true,
                    invoiceGone: true,
                    inviteGone: true,
                  })
                }
              >
                <p>
                  Your Account Details
                  <Icon name="chevron right" className="floatRight" />
                </p>
              </Menu.Item>
              <Menu.Item
                active={this.state.selected === "club"}
                onClick={() =>
                  this.setState({
                    selected: "club",
                    userGone: true,
                    clubUsersGone: true,
                    clubGone: false,
                    invoiceGone: true,
                    inviteGone: true,
                  })
                }
              >
                <p>
                  Your Club Details
                  <Icon name="chevron right" className="floatRight" />
                </p>
              </Menu.Item>
              <Menu.Item
                active={this.state.selected === "clubUsers"}
                onClick={() =>
                  this.setState({
                    selected: "clubUsers",
                    userGone: true,
                    clubGone: true,
                    clubUsersGone: false,
                    invoiceGone: true,
                    inviteGone: true,
                  })
                }
              >
                <p>
                  Club Users
                  <Icon name="chevron right" className="floatRight" />
                </p>
              </Menu.Item>
              <Menu.Item
                active={this.state.selected === "invoices"}
                onClick={() =>
                  this.setState({
                    selected: "invoices",
                    userGone: true,
                    clubUsersGone: true,
                    clubGone: true,
                    invoiceGone: false,
                    inviteGone: true,
                  })
                }
              >
                <p>
                  Invoices
                  <Icon name="chevron right" className="floatRight" />
                </p>
              </Menu.Item>
              <Menu.Item
                active={this.state.selected === "invites"}
                onClick={() =>
                  this.setState({
                    selected: "invites",
                    userGone: true,
                    clubUsersGone: true,
                    clubGone: true,
                    invoiceGone: true,
                    inviteGone: false,
                  })
                }
              >
                <p>
                  Invite Clubs
                  <Icon name="chevron right" className="floatRight" />
                </p>
              </Menu.Item>
              {/*<Menu.Item
                                active={this.state.selected === 'friends'}
                                onClick={() => this.setState({ selected: 'friends', userGone: true, clubGone: true, invoiceGone: false })}
                            >
                                <p>
                                    Invite Friends
                                    <Icon name='chevron right' className='floatRight' />
                                </p>
                            </Menu.Item>*/}
            </Menu>
          </Grid.Column>

          <Grid.Column
            computer={12}
            className="minHeight50vh overflowHidden"
            only="computer"
          >
            <Dimmer active={this.state.infoLoading} inverted>
              <Loader inverted content="Loading" />
            </Dimmer>
            {!this.state.infoLoading &&
              <React.Fragment>
                <Transition
                  visible={this.state.selected === "user"}
                  animation="fade down"
                  duration={500}
                  onComplete={() => this.setState({ userGone: false })}
                >
                  {userRender}
                </Transition>
                <Transition
                  visible={this.state.selected === "clubUsers"}
                  animation="fade down"
                  duration={500}
                  onComplete={() => this.setState({ clubUsersGone: false })}
                >
                  {clubUserRender}
                </Transition>
                <Transition
                  visible={this.state.selected === "club"}
                  animation="fade up"
                  duration={500}
                  onComplete={() => this.setState({ clubGone: false })}
                >
                  {clubRender}
                </Transition>
                <Transition
                  visible={this.state.selected === "invoices"}
                  animation="fade up"
                  duration={500}
                  onComplete={() => this.setState({ invoiceGone: false })}
                >
                  {invoiceRender}
                </Transition>
                <Transition
                  visible={this.state.selected === "invites"}
                  animation="fade up"
                  duration={500}
                  onComplete={() => this.setState({ inviteGone: false })}
                >
                  {inviteCodeRender}
                </Transition>
              </React.Fragment>
            }
          </Grid.Column>

          <Grid.Column width={16} only="tablet mobile">
            {userRender}
            {clubUserRender}
            {clubRender}
            {invoiceRender}
            {inviteCodeRender}
          </Grid.Column>
        </Grid.Row>
        <BankStatementModal
          modalOpen={this.state.modalOpen}
          closeModal={this.handleClose.bind(this)}
          handleUpdate={(result) => this.handleUpdate(result)}
        />
      </Grid>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(YourAccount);
