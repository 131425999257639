import React, { Component } from "react";
import { Grid, Header, Icon, Button, Form, TextArea, Dropdown, Dimmer, Loader, Placeholder} from 'semantic-ui-react';
import { connect } from "react-redux";
import { pageNotLogin } from '../action/view';
import { createNewTicket, updateTicket, getTickets, getTicket } from '../utils/ZendeskAPI';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageNotLogin,
};

const subjectObtions = [
    {
        key: 1,
        text: 'Delivery',
        value: 'Delivery'
    },
    {
        key: 2,
        text: 'Order',
        value: 'Order'
    },
    {
        key: 3,
        text: 'Products',
        value: 'Products'
    },
    {
        key: 4,
        text: 'Partnership Fund',
        value: 'Partnership Fund'
    },
    {
        key: 5,
        text: 'Others',
        value: 'Others'
    }
];

class Help extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_ticket: '',
            selected_ticket_subject: '',
            selected_subject: '',
            selected_comments: [],
            subjects: subjectObtions,
            description: '',
            more_comment: '',
            loading: false,
            submitLoading: false,
            listLoading: true,
            hoverOpen: false,
            ticketList: []
        }
    }

    componentDidMount() {
        this.props.pageNotLogin();

        getTickets((result) => {
            if(result) {
                this.setState({
                    ticketList: result
                })
            }

            this.setState({
                listLoading: false
            })
        }, 20)
    }

    handleSelect(e) {
        const id = parseInt(e.currentTarget.getAttribute('id'));
        const subject = e.currentTarget.getAttribute('subject');

        this.setState({
            selected_ticket: id,
            selected_ticket_subject: subject,
            loading: true
        })

        getTicket((result) => {
            if (result) {
                this.setState({
                    selected_comments: result
                })
            }

            this.setState({
                loading: false,
                hoverOpen: false
            })
        }, id)
    }

    onChangeSelect(e, {value}) {
        this.setState({
            selected_subject: value
        })
    }

    handleDescription(e) {
        this.setState({
            description: e.currentTarget.value
        })
    }

    handleComment(e) {
        this.setState({
            more_comment: e.currentTarget.value
        })
    }

    handleSubmit(e) {
        const subject = this.state.selected_subject;
        const description = this.state.description;

        if (subject !== '' && description !== '') {
            this.setState({
                submitLoading: true,
                listLoading: true
            })

            // submitting
            createNewTicket((result) => {
                if(result) {
                    getTickets((result) => {
                        if (result) {
                            this.setState({
                                ticketList: result
                            })
                        }

                        this.setState({
                            listLoading: false
                        })
                    }, 20)
                }

                this.setState({
                    submitLoading: false,
                    selected_subject: '',
                    description: '',
                    more_comment: ''
                })
            }, subject, description)
        } else {
            alert('Please fill out the helpdesk form.');
        }
    }

    handleSubmitMoreComment(e) {
        const comment = this.state.more_comment;
        const id = this.state.selected_ticket;

        if (comment !== '') {
            this.setState({
                submitLoading: true,
                loading: true
            })

            // submitting
            updateTicket((result) => {
                if (result) {
                    getTicket((result) => {
                        if (result) {
                            this.setState({
                                selected_comments: result
                            })
                        }

                        this.setState({
                            loading: false,
                            hoverOpen: false
                        })
                    }, id)
                }

                this.setState({
                    submitLoading: false,
                    selected_subject: '',
                    description: '',
                    more_comment: ''
                })
            }, this.state.selected_ticket, comment)

        } else {
            alert('Please fill out the comment form.');
        }
    }

    handleOpen() {
        this.setState({
            hoverOpen: !this.state.hoverOpen
        })
    }

    ticketPlaceholder() {
        let count = 0;
        let placeholderCount = 0;

        const arrayLoop = count === 0 ? 5 : count;

        let placeholderList = [];

        while (placeholderCount < arrayLoop) {
            placeholderCount++;
            placeholderList.push(
                <div key={placeholderCount} className='section card borderRadius10 shadow20 marginBottom10 cursor relative bgWhite padding15'  >
                    <Placeholder>
                        <Placeholder.Header>
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Header>
                    </Placeholder>

                </div>
            );
        }

        return placeholderList
    }

    render() {
        const ticketList = this.state.ticketList;

        const ticket = (isNewTicket, ticket) => {
            if(isNewTicket) {
                return (
                    <div onClick={() => this.setState({selected_ticket: '', selected_ticket_subject: ''})} className={'section card borderRadius10 shadow20 padding15 marginBottom10 cursor relative ' + (this.state.selected_ticket === '' ? 'bgPrimary white' : 'bgWhite')}>
                        <p className='noMarginBottom'>New Conversation</p>
                        <Icon name='chevron right' className='absolute right15 bottom20' />
                    </div>
                )
            } else {
                let date = new Date(ticket.created_at);

                return (
                    <div className={'section card borderRadius10 shadow20 marginBottom10 cursor relative ' + (this.state.selected_ticket === ticket.id ? 'bgPrimary white' : 'bgWhite')}  >
                        <Dimmer active={this.state.loading && this.state.selected_ticket === ticket.id} inverted>
                            <Loader>Loading Conversation</Loader>
                        </Dimmer>
                        <div id={ticket.id} onClick={this.handleSelect.bind(this)} subject={ticket.subject} className='padding15'>
                            <p className='noMarginBottom'>Conversation #{ticket.id} </p>
                            <p className='noMarginBottom paddingRight20'>Subject: <strong>{ticket.subject}</strong></p>
                            <p className='noMarginBottom inline paddingRight20'>Date: <strong>{date.toLocaleString()}</strong></p>
                            <p className='noMarginBottom inline'>Status: <strong>{ticket.status ? ticket.status : ''}</strong></p>

                            <Icon name='chevron right' className='absolute right15 top32 bottom30 hide-mobile' />
                        </div>
                        
                    </div>
                )
            }
        }

        const ticketForm = (isNewTicket) => {
            if(isNewTicket) {
                return (
                    <div>
                        <Form>
                            <Dropdown disabled={this.state.submitLoading} className='marginBottom10' tabIndex='1' placeholder='What do you need help with?' fluid options={this.state.subjects} value={this.state.selected_subject} onChange={this.onChangeSelect.bind(this)} selection search noResultsMessage='Try another subject name.' />

                            <TextArea disabled={this.state.submitLoading} placeholder='Tell us more...' tabIndex='2' icon='edit' className='marginBottom10' rows={10} value={this.state.description} onChange={this.handleDescription.bind(this)}/>

                            <div className='alignRight'>
                                <Button primary icon labelPosition='left' className='noMarginRight' loading={this.state.submitLoading} disabled={this.state.submitLoading} onClick={this.handleSubmit.bind(this)}>
                                    <Icon name='send'/>
                                    Send
                                </Button>
                            </div>
                        </Form>
                    </div>
                )
            } else {
                // get ticket comments
                return (
                    <Grid>
                        <Dimmer active={this.state.loading} inverted>
                            <Loader>Loading Conversation</Loader>
                        </Dimmer>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Header className='large'>
                                    Conversation: #{this.state.selected_ticket}
                                    <Header.Subheader className='primaryColor noMargin bold regular'>
                                        Subject: {this.state.selected_ticket_subject}
                                    </Header.Subheader>
                                </Header>
                                <Grid className='overflowYAuto maxHeight450'>
                                {
                                    this.state.selected_comments.map((value, key) => {
                                        let date = new Date(value.created_at);

                                        return (
                                            <Grid.Row key={key}>
                                                <Grid.Column width={13} floated={value.is_user ? 'right':'left'}>
                                                    <div className={'relative ' + (value.is_user ? 'floatRight paddingRight4em' : 'floatLeft paddingLeft4em')}>
                                                        <div className={'borderRadius10 relative padding5-10 white ' + (value.is_user ? 'bgPrimary beforeArrowRight' :'bgSecondary beforeArrowLeft')}>
                                                            {value.body}
                                                        </div>
                                                        <div className={'tiny ' + (value.is_user ? 'alignRight' :'')}>
                                                            {date.toLocaleString()}
                                                        </div>
                                                        <div className={'absolute top userChatLogo white ' + (value.is_user ? 'right5 bgPrimary' : 'left5 bgSecondary')}>
                                                            {value.name ? value.name.charAt(0) : ''}
                                                        </div>
                                                    </div>
                                                </Grid.Column>
                                            </Grid.Row>
                                        )
                                    })
                                }  
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Form>
                                    <TextArea disabled={this.state.submitLoading} placeholder='Let us know more...' tabIndex='2' icon='edit' className='marginBottom10' rows={3} value={this.state.more_comment} onChange={this.handleComment.bind(this)} />

                                    <div className='alignRight'>
                                        <Button primary icon labelPosition='left' className='noMarginRight' loading={this.state.submitLoading} disabled={this.state.submitLoading} onClick={this.handleSubmitMoreComment.bind(this)}>
                                            <Icon name='send' />
                                            Send
                                        </Button>
                                    </div>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )
            }
        }

        return (
            <Grid container>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={6} computer={5} className='marginTop20'>
                        <Header className='large'>Conversations</Header>
                        <p>Any requests will be responded to within 1 business day.</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column tablet={6} computer={5} only='computer tablet' className='maxHeight450 overflowYAuto'>
                        {ticket(true)}

                        {(() => {
                            if(this.state.listLoading) {
                                return (this.ticketPlaceholder())
                            } else {
                                return (
                                    ticketList.map((value, key) => {
                                        return (
                                            <div key={key}>
                                                {ticket(false, value)}
                                            </div>
                                        )
                                    })
                                )
                            }
                        })()}
                    </Grid.Column>

                    <Grid.Column only='mobile' className='btnTicketWrapper'>
                        <Button circular icon='list' primary onClick={() => this.handleOpen()} />
                        <div className={'section card borderRadius10 bgPrimary shadow20 padding15 hoverTicketList ' + (this.state.hoverOpen ? '':'hide') }>
                            <Grid columns={1} className='overflowYAuto maxHeight350px'>
                                <Grid.Row >
                                    <Grid.Column>
                                        {ticket(true)}

                                        {
                                            ticketList.map((value, key) => {
                                                return (
                                                    <div key={key}>
                                                        {ticket(false, value)}
                                                    </div>
                                                )
                                            })
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                                
                            </Grid>
                        </div>
                        
                        
                    </Grid.Column>

                    <Grid.Column tablet={10} computer={11} mobile={16} >
                        <div className='section card borderRadius10 bgWhite shadow20 padding15'>
                            {(() => {
                                if (this.state.selected_ticket === '') {
                                    return ticketForm(true)
                                } else {
                                    return ticketForm(false)
                                }
                            })()}
                            
                        </div>
                    </Grid.Column>

                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Help);