import React, { Component } from "react";
import { Grid, Header, Input, Container, Button, Icon, Image } from 'semantic-ui-react';
import { CSSTransition } from "react-transition-group";
import { myAuth } from '../utils/PrivateRoute';
import { Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { pageLogin, pageNotLogin } from '../action/view';
import LoginFooter from "../parts/LoginFooter";
import { checkResetPasswordToken, resetPassword } from '../utils/UserAPI';
import LandingFooter from "../parts/LandingFooter";
import LandingPart from "../parts/LandingPart";
import { encrypt } from "../utils/Utils";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageLogin,
    pageNotLogin
};

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            password_hidden: true,
            confirm_hidden: true,
            password: '',
            confirm_password: '',
            token: '',
            email: '',
            loading: false
        };

        this.props.pageLogin();

        // check if user has login already?
        if (myAuth.isAuthenticated) {
            this.props.pageNotLogin();
        }
    }

    componentDidMount() {
        checkResetPasswordToken((result) => {
            this.setState({
                email: result.email,
                token: result.token
            })
        }, this.props.match.params.token)
    }

    keyPressed(e) {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this.handleNext();
        }
    }

    handleNext() {
        const step = this.state.step;

        switch (step) {
            case 1:
                if (this.state.password === '' || this.state.confirm_password === '') {
                    alert('Please enter your password.');
                } else {
                    if(this.state.password === this.state.confirm_password) {
                        this.setState({
                            loading: true
                        })

                        resetPassword((result) => {
                            if(result) {
                                this.setState({
                                    step: 2,
                                    loading: false
                                })
                            } else {
                                this.setState({
                                    loading: false
                                })
                            }
                            
                        }, {
                            email: this.state.email,
                            token: this.state.token,
                            password: encrypt(this.state.password),
                            password_confirmation: encrypt(this.state.confirm_password)
                        })
                    } else {
                        alert('Password and Confirm Password are not the same.');
                    }
                }
                break;

            default:
                break;
        }
    }

    handleOnPasswordToggle() {
        this.setState({
            password_hidden: !this.state.password_hidden
        })
    }

    handleOnConfirmPasswordToggle() {
        this.setState({
            confirm_hidden: !this.state.confirm_hidden
        })
    }

    onHandleChange(e, { value }) {
        let name = e.currentTarget.getAttribute('name');

        this.setState({
            [name]: value
        })
    }

    render() {
        if (myAuth.isAuthenticated) {
            return (
                <Redirect
                    to={{
                        pathname: '/'
                    }}
                />
            )
        }

        return (
            <Grid celled padded columns={2} className='height100vh bgWhite'>
                <Grid.Row>
                    <Grid.Column only='tablet computer' tablet={8} computer={6} className='padding30 primaryGradient nonAlcbackgroundCover center'>
                        <LandingPart nonAlc={true}/>
                        <LandingFooter />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={10} className='center landing height100vh'>
                        <Grid className='absolute top small width100 left15 padding30 zIndex1' style={{ textAlign: 'right' }}>
                            <Grid.Row >
                                <Grid.Column width={16}>
                                    <p className='fontSize16px bold'>Already a member? <Link className='marginLeft10 yellowText borderYellow padding10-20 noDecoration hoverYellowBg inlineFlex' to='/login'>Sign In</Link></p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Grid padded verticalAlign='middle' columns={1} className='height100vh'>
                            <Grid.Row>
                                <Grid.Column className='maxWidth525 center'>
                                    <Container>
                                        <Header color='black' as='h1' className={(this.state.step === 1 ? 'alignLeft' : 'alignCenter') + ' marginTop10 marginBottom20 headerLarge'}>
                                            {
                                                this.state.step === 1 ?
                                                    'Reset Your Password' : ''
                                            }
                                            <CSSTransition in={this.state.step === 2} unmountOnExit classNames="fade"
                                                timeout={200}>
                                                <div className='inline'>
                                                    You have set a new password
                                                </div>
                                            </CSSTransition>
                                            <Header.Subheader className='regular'>
                                                <CSSTransition in={this.state.step === 2} unmountOnExit classNames="fade" timeout={200} onExited={() => this.displayStep1()}
                                                >
                                                    <div className='fontSize16px' style={{ paddingBottom: '5px' }}>You can now <Link to='/login'>login</Link> using your new password.</div>
                                                </CSSTransition>
                                            </Header.Subheader>
                                        </Header>
                                    </Container>
                                    <Container>
                                        <div className='login'>
                                            {
                                                this.state.step === 1 ?
                                                    (
                                                        <div>
                                                            <Grid.Column width={16} className='marginBottom20 relative'>
                                                                <Input disabled={this.state.loading} value={this.state.password} name='password' type={this.state.password_hidden ? 'password' : 'text'} onKeyPress={this.keyPressed.bind(this)} onChange={this.onHandleChange.bind(this)} placeholder='Password' fluid className='borderRadius10 landingInput' />
                                                                <i aria-hidden="true" onClick={this.handleOnPasswordToggle.bind(this)} className={this.state.password_hidden ? 'eye slash icon absolute right15 top20 link' : 'eye icon absolute right15 top20 link'} />
                                                            </Grid.Column>

                                                            <Grid.Column width={16} className='marginBottom20 relative'>
                                                                <Input disabled={this.state.loading} value={this.state.confirm_password} name='confirm_password' type={this.state.confirm_hidden ? 'password' : 'text'} onKeyPress={this.keyPressed.bind(this)} onChange={this.onHandleChange.bind(this)} className='borderRadius10 landingInput' fluid placeholder='Confirm Password' />
                                                                <i aria-hidden="true" onClick={this.handleOnConfirmPasswordToggle.bind(this)} className={this.state.confirm_hidden ? 'eye slash icon absolute right15 top20 link' : 'eye icon absolute right15 top20 link'} />
                                                            </Grid.Column>
                                                        </div>
                                                    )
                                                    : ''
                                            }

                                            <CSSTransition in={this.state.step === 2} unmountOnExit classNames="fade"
                                                timeout={200}>
                                                <Image src='/img/positive-vote.svg' width='80' centered />
                                            </CSSTransition>
                                        </div>
                                    </Container>
                                    <Container>
                                    {
                                        this.state.step === 1 ? <div className='login marginTop20'>
                                            <Button animated primary compact loading={this.state.loading} disabled={this.state.loading} floated='right' className='borderRadius5 maxWidth90 regular bgYellow white minHeight40' onClick={this.handleNext.bind(this)}>
                                                <Button.Content visible>
                                                    Next
                                            </Button.Content>
                                                <Button.Content hidden>
                                                    <Icon name='arrow right' />
                                                </Button.Content>
                                            </Button>
                                        </div> : ''
                                    }
                                    </Container>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <LoginFooter />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword);