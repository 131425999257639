import * as Constants from '../Constants';
import { getUserDetail } from '../utils/UserAPI';

const dispatchGetUser = (data) => ({
    type: Constants.GET_USER,
    user: data.user,
    club: data.club
});

const getUserLoading = () => ({ type: Constants.GET_USER_LOADING });

export function getUser() {
    return dispatch => {
        dispatch(getUserLoading());

        return getUserDetail((result) => {
            dispatch(dispatchGetUser(result));
        })
    }
}
