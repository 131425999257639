import React from "react";
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { encrypt } from "./Utils";

const cookies = new Cookies();

export const myAuth = {
    isAuthenticated: cookies.get('token') ? true : false,
    authenticate(email, password, loadingState, state) {
        const options = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/login',
            data: {
                email: email,
                password: encrypt(password),
                isAdminMode: false
            },
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        loadingState(true);

        axios(options).then(res => {
            cookies.set('token', res.data.message.token, {path: '/'});
            cookies.set('name', res.data.message.name, {path: '/'});
            this.isAuthenticated = cookies.get('token');
            
            setTimeout(state, 0);
        }).catch(error => {
            loadingState(false);
            console.log(error.message)
            if (error.response.data && error.response.data.message) {
                setTimeout(alert(error.response.data.message), 100);
            } else {
                setTimeout(alert('An Unknown Error Occurred'), 100);
            }
        })
    },
    getUserName(email, loadingState, state) {

        const options = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/get_name',
            params: {
                email: email
            },
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }

        loadingState(true);

        axios(options).then(res => {
            state(res.data.message.name);
        }).catch(error => {
            loadingState(false);
            setTimeout(alert('There is no user with this email.'), 100);
        })
    },
    signout(cb) {
        const options = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/logout',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + cookies.get('token')
            }
        }

        axios(options).then(res => {
            cookies.remove('token', {path: '/'});
            cookies.remove('name', {path: '/'});
            setTimeout(cb, 100);
        }).catch(error => {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            alert('You have already logged out.');
            setTimeout(cb, 100);
        })
    }
};

function PrivateRoute({ component: Component, ...rest }) {    
    return (
        <Route
            {...rest}
            render={props =>
                myAuth.isAuthenticated ? (
                    <Component {...props} />
                ) : (
                        <Redirect
                            to={{
                                pathname: "/welcome",
                                state: { from: props.location }
                            }}
                        />
                    )
            }
        />
    );
}

export default PrivateRoute;