import Cookies from "universal-cookie";

const cookies = new Cookies();

export function applyCouponCode(code) {
  const options = {
    method: "post",
    url: process.env.REACT_APP_API_URL + "/apply_coupon",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("token"),
    },
    data: {
      code: code,
    },
  };

  return options;
}

export function updateCouponProducts() {
  const options = {
    method: "get",
    url: process.env.REACT_APP_API_URL + "/update_coupon_products",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("token"),
    },
  };

  return options;
}

export function removeCouponCode() {
  const options = {
    method: "post",
    url: process.env.REACT_APP_API_URL + "/remove_coupon",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("token"),
    },
  };

  return options;
}
