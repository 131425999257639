import React, { Component } from "react";
import { Grid, Placeholder } from 'semantic-ui-react';
import Product from '../parts/Product';
import Slider from 'react-slick';

const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    lazyLoad: 'progressive',
    pauseOnDotsHover: true,
    pauseOnFocus: true,
    className: 'product',
    swipeToSlide: true,
    responsive: [
        {
            breakpoint: 999,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 700,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 1.3,
            }
        }
    ]
};

export default class ProductSlider extends Component {
    loadingProductSlide(recentViews, isLoading) {
        if (isLoading === true) {
            const arrayLoop = [1, 2, 3, 4, 5, 6, 7, 8];

            return (
                <Slider {...settings} className='productSlider'>
                    {
                        arrayLoop.map((value, key) => {
                            return (
                                <div className='relative productSliderCustom' key={key}>
                                    <Grid columns='equal'>
                                        <Grid.Column className='productSlide bgWhite relative margin25'>
                                            <Placeholder>
                                                <Placeholder.Header>
                                                    <Placeholder.Line />
                                                    <Placeholder.Line />
                                                </Placeholder.Header>
                                                <Placeholder.Image />
                                                <Placeholder.Paragraph>
                                                    <Placeholder.Line length='medium' />
                                                    <Placeholder.Line length='short' />
                                                </Placeholder.Paragraph>
                                            </Placeholder>
                                        </Grid.Column>
                                    </Grid>
                                </div>
                            )
                        })
                    }
                </Slider>
            )
        } else {
            if (recentViews.length > 0) {
                return (
                    <Slider {...settings} className='productSlider'>
                        {
                            recentViews.map((value, key) => {
                                return (
                                    <div className='relative productSliderCustom' key={key}>
                                        <Grid columns='equal'>
                                            <Product product={value} isSlider={true} />
                                        </Grid>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                )
            } else {
                return null
            }
        }
    }

    render() {
        const recentViews = this.props.data;
        const isLoading = this.props.isLoading;

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column className='noPadding'>
                        {this.loadingProductSlide(recentViews, isLoading)}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}