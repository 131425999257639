import * as Constants from '../Constants';

const root = (state = Constants.rootInitialState, action) => {
    switch (action.type) {
        case Constants.LOGIN_PAGE:
            return {
                hideHeader: true,
                modalOpen: state.modalOpen,
                slideRegister: state.slideRegister,
                page: action.page
            }
        case Constants.NOT_LOGIN_PAGE:
            return {
                hideHeader: false,
                modalOpen: state.modalOpen,
                slideRegister: state.slideRegister,
                page: ''
            }
        case Constants.SLIDE_REGISTER:
            return {
                hideHeader: state.hideHeader,
                modalOpen: state.modalOpen,
                slideRegister: true,
                page: state.page
            }
        case Constants.OPEN_MODAL:
            return {
                hideHeader: state.hideHeader,
                modalOpen: true,
                slideRegister: state.slideRegister,
                page: state.page
            }
        case Constants.CLOSE_MODAL:
            return {
                hideHeader: state.hideHeader,
                modalOpen: false,
                slideRegister: state.slideRegister,
                page: state.page
            }

        default:
            return {
                hideHeader: state.hideHeader,
                modalOpen: state.modalOpen,
                slideRegister: state.slideRegister,
                page: state.page
            }
    }
}

export default root;