import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();

export function createNewTicket(callback, subject, desc) {
    const options = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/post_ticket',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        data: {
            'subject': subject,
            'desc': desc
        }
    }
    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch(error => {
        callback();
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            
            console.log(error.response);
        }
    })
}

export function updateTicket(callback, id, desc) {
    const options = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/update_ticket',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        data: {
            'id': id,
            'desc': desc
        }
    }
    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch(error => {
        callback();
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })
}

export function getTickets(callback, limit) {
    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/get_tickets',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        data: {
            'limit': limit
        }
    }
    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch(error => {
        callback();
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })
}

export function getTicket(callback, id) {
    const options = {
        method: 'get',
        url: process.env.REACT_APP_API_URL + '/get_ticket',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('token')
        },
        params: {
            'ticket_id': id
        }
    }
    let result = [];

    axios(options).then(res => {
        result = res.data.message;
        callback(result);
    }).catch(error => {
        callback();
        if (error.response.status === 403) {
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            window.location.reload();
        } else {
            console.log(error.response);
        }
    })
}