import * as Constants from '../Constants';

const nonalc = (state = Constants.WELCOME_PAGE, action) => {
    switch (action.type) {
        case Constants.WELCOME_ALC:
            return (false)
        case Constants.WELCOME_ALB:
            return (true)
        default:
            return state
    }
}

export default nonalc;
