import React, { Component } from "react";
import { Button, Icon, Input } from "semantic-ui-react";
import { connect } from "react-redux";
import { addToCart } from "../action/cart";
import TagManager from "react-gtm-module";

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  addToCart,
};

class AddToCartNumber extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      loading: false,
    };
  }

  handleOpenAddToCart() {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  }

  onAddToCart(currentProduct) {
    const currentCartNum = currentProduct ? currentProduct.quantity : 0;
    let nextNum = parseInt(currentCartNum) + 1;
    this.setState({ loading: true });

    if (nextNum > this.props.product.qty) {
      nextNum = this.props.product.qty;
      alert("This product is currently unavailable.");
      this.setState({ loading: false });
    } else {
      this.props.addToCart(this.props.product.id, nextNum);
      TagManager.dataLayer({
        dataLayer: {
          ecommerce: null,
        },
      });

      TagManager.dataLayer({
        dataLayer: {
          event: "add_product_to_cart",
          ecommerce: {
            items: [
              {
                item_name: this.props.product.name,
                item_id: this.props.product.id,
                price: this.props.product.price,
                item_brand: this.props.product.brand_name,
                item_category: this.props.product.category_name,
                item_sku: this.props.product.product_sku,
                quantity: nextNum,
              },
            ],
          },
        },
      });
      this.setState({ loading: false });
    }
  }

  onSubtractToCart(currentProduct) {
    const currentCartNum = currentProduct ? currentProduct.quantity : 0;
    let nextNum = parseInt(currentCartNum) - 1;
    this.setState({ loading: true });

    if (nextNum < 0) {
      nextNum = 0;
      this.setState({ loading: false });
    } else {
      this.props.addToCart(this.props.product.id, nextNum);
      this.setState({ loading: false });
      TagManager.dataLayer({
        dataLayer: {
          ecommerce: null,
        },
      });

      TagManager.dataLayer({
        dataLayer: {
          event: "remove_from_cart",
          ecommerce: {
            items: [
              {
                item_name: this.props.product.name,
                item_id: this.props.product.id,
                price: this.props.product.price,
                item_brand: this.props.product.brand_name,
                item_category: this.props.product.category_name,
                item_sku: this.props.product.product_sku,
                quantity: nextNum,
              },
            ],
          },
        },
      });
    }
  }

  render() {
    const currentCartItems = this.props.cart.cartItems;
    let currentProduct = null;
    currentCartItems.forEach((element) => {
      if (element.id) {
        if (parseInt(element.id) === parseInt(this.props.product.id)) {
          currentProduct = element;
        }
      }
    });
    return (
      <div>
        <Input
          type="number"
          placeholder="0"
          value={currentProduct ? currentProduct.quantity : 0}
          action
          transparent
          size="mini"
          className="borderRadius23 border1"
        >
          <Button
            id={"removeQuantity-" + this.props.product.product_sku}
            loading={this.state.loading}
            disabled={this.state.loading}
            className={
              "transparent " +
              (this.props.size === "mini" ? "paddingLeftRight5" : "")
            }
            icon
            onClick={() => {
              this.onSubtractToCart(currentProduct);
            }}
          >
            <Icon name="minus" className="small" />
          </Button>
          <input
            size="small"
            className={
              "alignCenter " +
              (this.props.size === "mini" ? "maxWidth15" : "maxWidth30")
            }
          />
          <Button
            id={"addToCart-" + this.props.product.product_sku}
            loading={this.state.loading}
            disabled={this.state.loading}
            className={
              "transparent " +
              (this.props.size === "mini" ? "paddingLeftRight5" : "")
            }
            icon
            onClick={() => {
              this.onAddToCart(currentProduct);
            }}
          >
            <Icon name="plus" className="small" />
          </Button>
        </Input>
        {this.props.description ? (
          <p className="small lightGray marginTop1em">
            <em>In cart</em>
          </p>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartNumber);
