import React, { Component } from "react";
import { Grid, Header } from 'semantic-ui-react';
import { connect } from "react-redux";
import { pageLogin, pageNotLogin } from '../action/view';
import BackButton from "../parts/BackButton";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageLogin,
    pageNotLogin
};

class MarketingConsent extends Component {
    constructor(props) {
        super(props);

        this.props.pageNotLogin();
    }

    render() {
        return (
            <Grid padded className='loginBg'>
                <Grid.Row>
                    <Grid.Column width={16} className='marginTop30 alignCenter'>
                        <Header as='h2'>
                            Marketing Consent
                        </Header>

                        <div className='maxWidth70p center padding15 alignLeft'>
                            <p>
                                By registering an account with Club Connect you agree to hear from us and our partners in relation to offers that might be of interest to you
                            </p>
                        </div>
                        <div className='login maxWidth70p center marginTop20'>
                            <BackButton />
                        </div>

                    </Grid.Column>

                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MarketingConsent);