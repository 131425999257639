import React, { Component } from "react";
import ProductList from "../parts/ProductList";
import { getFavouriteProductList } from "../utils/ProductAPI";
import { connect } from "react-redux";
import { pageNotLogin } from "../action/view";

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  pageNotLogin,
};

const limit = 12;

class Favourite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      isLoading: true,
      sortOrder: "asc",
      currentFilter: {
        price: [],
        brands: [],
        cates: [],
        best: [],
      },
    };
  }

  componentDidMount() {
    this.handleSortList("popular");
    this.props.pageNotLogin();
  }

  handleRemove(sku) {
    let currentProducts = this.state.products;

    currentProducts.products.forEach((element) => {
      if (element.product_sku === sku) {
        element.favourite = 0;
        currentProducts.total -= 1;
      }
    });

    this.setState({
      products: currentProducts,
    });
  }

  handleFilterlist(filter) {
    const brandSlug = filter.brands;
    const cateSlug = filter.cates;
    const price = filter.price;
    const best = filter.best.length ? "true" : "";

    this.setState({
      isLoading: true,
    });

    // get current product number
    const currentProductList = this.state.products.products;
    const currentProductNumber = currentProductList.length;

    getFavouriteProductList(
      (result) => {
        this.setState({
          products: result,
          isLoading: false,
          currentFilter: filter,
        });
      },
      {
        cateSlug: cateSlug,
        brandSlug: brandSlug,
        price: price,
        sortOrder: this.state.sortOrder,
        best: best,
      },
      currentProductNumber
    );
  }

  handleSortList(sort) {
    this.setState({
      isLoading: true,
    });

    const brandSlug = this.state.currentFilter.brands;
    const cateSlug = this.state.currentFilter.cates;
    const price = this.state.currentFilter.price;

    // get current product number
    const currentProductList = this.state.products.products
      ? this.state.products.products
      : [];
    const currentProductNumber =
      currentProductList.length === 0 ? limit : currentProductList.length;

    getFavouriteProductList(
      (result) => {
        this.setState({
          products: result,
          isLoading: false,
          sortOrder: sort,
        });
      },
      {
        cateSlug: cateSlug,
        brandSlug: brandSlug,
        price: price,
        sortOrder: sort,
        best: this.state.best,
      },
      currentProductNumber
    );
  }

  handleNextPage(sort) {
    this.setState({
      isLoading: true,
    });

    const brandSlug = this.state.currentFilter.brands;
    const cateSlug = this.state.currentFilter.cates;
    const price = this.state.currentFilter.price;

    // get current product number
    const currentProductList = this.state.products.products;
    const currentProductNumber = currentProductList.length;

    getFavouriteProductList(
      (result) => {
        this.setState({
          products: result,
          isLoading: false,
          sortOrder: sort,
        });
      },
      {
        cateSlug: cateSlug,
        brandSlug: brandSlug,
        price: price,
        sortOrder: sort,
        best: this.state.best,
      },
      currentProductNumber + limit
    );
  }

  render() {
    let products = this.state.products;
    return (
      <div>
        <ProductList
          data={products}
          nextPage={(sort) => this.handleNextPage(sort)}
          isFavourite={(sku) => this.handleRemove(sku)}
          isLoading={this.state.isLoading}
          sortList={(sort) => this.handleSortList(sort)}
          filterList={(filter) => this.handleFilterlist(filter)}
          currentFilter={this.state.currentFilter}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Favourite);
